import React from "react";
import { Redirect } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
} from "reactstrap";
import { connect } from 'react-redux';
import TimelineTabs from "./TimelineTabs";
import User from "../../../components/Social/UserCard/User";
import store from "../../../redux/store/index";
import Loader from "../../../components/Loader";
import {
  API_USER_FOLLOWING,
  API_USER_FOLLOWERS,
  API_USER_PHOTOS_ALBUMS_MASTER
 } from '../../../redux/actions/API';
import axios from "axios";
import { keyConfig } from '../../../redux/actions/auth';
import { loadUser } from '../../../redux/actions/auth';
import { loginFlag } from '../../../redux/actions/auth';
import NextWorkoutTable from "../Default/NextWorkoutTable"
class TimelineIndex extends React.Component {

  fetchTimelineInitial = () =>  {
    const memberID = this.props.user_status.pk
    axios.get(`${API_USER_FOLLOWING}${memberID}`, keyConfig(store.getState)).then(res => {
      this.setState({
        api_user_following: res.data,
        api_user_following_loading: false,
      });
    });
    axios.get(`${API_USER_FOLLOWERS}${memberID}`, keyConfig(store.getState)).then(res => {
      this.setState({
        api_user_followers: res.data,
        api_user_followers_loading: false,
      });
    });
    axios.get(`${API_USER_PHOTOS_ALBUMS_MASTER}${memberID}`, keyConfig(store.getState)).then(res => {
      this.setState({
        api_albums_master: res.data,
        api_albums_master_loading: false,
      });
    });
  }

  handlerPhotoChange = (val) => {
    this.setState({
      photo_change_flag: val
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.user_status === null) {
      store.dispatch(loadUser());
    }
    if (this.state.photo_change_flag !== 0) {
      this.fetchTimelineInitial();
    }
  }

  componentDidMount() {
    if (this.props.user_status === null) {
      store.dispatch(loadUser());
    }
    this.fetchTimelineInitial();
    store.dispatch(loginFlag());
  }

  constructor(props) {
    super(props);
    this.state = {
      photo_change_flag: 0,
      flag_timeline_change: 0,
      flag_lazy_change: 0,
      lazy_page_number: 1,
      api_timeline:[],
      api_timeline_loading: true,
      api_user_following : [],
      api_user_following_loading: true,
      api_user_followers : [],
      api_user_followers_loading: true,
      api_albums_master: [],
      api_albums_master_loading: true,
    };
  }

  render() {
    if (this.props.user_status === null) {
      return <Loader />
    }
    else if (this.props.user_status !== null && this.props.user_status.first_name==="") {
      return (<Redirect to={{pathname: "/onboarding/step1",}} />)
    }
    else if (
      this.state.api_user_following_loading ||
      this.state.api_user_followers_loading ||
      this.state.api_albums_master_loading
    ) {
      return <Loader />

    }
    return (
      <Container className="p-0">
        <Row className="flex-column-reverse flex-lg-row">
        <Col lg="8" >
          <TimelineTabs
            api_timeline={this.state.api_timeline}

            first_name={this.props.user_status.first_name}
            last_name={this.props.user_status.last_name}
            avatar_color={store.getState().auth.user.avatar_color}
            avatar_letter={store.getState().auth.user.avatar_letter}
            email_lower={store.getState().auth.user.email_lower}

            />
        </Col>
        <Col lg="4">
          <User
            username={this.props.user_status.username}
            first_name={this.props.user_status.first_name}
            last_name={this.props.user_status.last_name}
            avatar_color={this.props.user_status.avatar_color}
            avatar_letter={this.props.user_status.avatar_letter}
            avatar_image={this.props.user_status.avatar_image}
            heading={this.props.user_status.heading}
            photo_change_flag = {this.handlerPhotoChange}
            source = {"timeline"}
          />
          <NextWorkoutTable source={"timeline_right"}/>
        </Col>
        </Row>
      </Container>
  );
 }
}

const mapStateToProps = (state) => ({
  user_status: state.auth.user,
  general: state.general.isLoading,
  theme: state.theme.currentTheme
});

export default connect(mapStateToProps, {  })(TimelineIndex);
