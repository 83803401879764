import React from "react";

import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown
} from "reactstrap";

import { Filter, RefreshCw } from "react-feather";

const Header = (props) => {
  return (
    <Row className="mb-2 mb-xl-4">
      <Col xs="auto" className="d-none d-sm-block">
        <h3>{props.title}</h3>
      </Col>

      <Col xs="auto" className="ml-auto text-right mt-n1">
        <UncontrolledDropdown className="d-inline mr-2">
          <DropdownToggle caret color="light" className="bg-white shadow-sm">
            <Filter className="feather align-middle mt-n1" /> {props.postTypeWord}
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem onClick={() => props.postType(0,'All')}>All</DropdownItem>
            <DropdownItem onClick={() => props.postType(1,'Workouts')}>Workouts</DropdownItem>
            <DropdownItem onClick={() => props.postType(2,'Albums')}>Photo Albums</DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={() => props.postType(3,'Friends')}>Friends</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>

        <Button color="primary" className="shadow-sm mr-1">
          <RefreshCw className="feather" />
        </Button>
        {props.myProfile === "no" ?
          props.followButtons
          :
          null
        }
      </Col>
    </Row>
  );
};

export default Header;
