import React, { useState }  from "react";
import { toastr } from "react-redux-toastr";
import { Redirect } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { logout } from "../../../redux/actions/auth";
import { loadUser } from "../../../redux/actions/auth";
import { connect } from 'react-redux';
import store from "../../../redux/store/index";
import ApiSocial from '../../../config/social';

const ModalDelete = (props) => {
    const {
      className
    } = props;

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);



    const deleteuser = async (userid) => {
       await setModal(!modal);
       await props.toaster()
       await setTimeout(async () => {
         await props.deleteProfile()
       }, 1500);
     };

    return (
      <Card>

        <CardHeader className="pb-0 mb-0">
          <CardTitle tag="h5">Delete Account</CardTitle>
        </CardHeader>


         <CardBody className="text-left float-left pt-1">
         <p>
            If you no longer want to use ironroom, you can delete your account. <br /><br/>

            <strong>WARNING</strong>: Deleting your account does not automatically unusbscribe you from any subscriptions. <br />
            If you subscribed on the web, visit thie "Subscriptions and Payments" to review your status. <br />
            If you subscribed from an app store, visit your account on the respective store
         </p>

          <Button style={{width: '150px'}} color="danger" onClick={toggle}>Delete Account</Button>
          <Modal isOpen={modal} toggle={toggle} className={className}>
            <ModalHeader toggle={toggle}>Delete Account</ModalHeader>
            <ModalBody>
              This will delete your account forever. All your data will be gone. Feel free to signup again when you are ready to lift again!
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={toggle}>Cancel</Button>{' '}
              <Button color="danger" onClick={() => {deleteuser(props.userid);} }>Delete Account Forever</Button>
            </ModalFooter>
          </Modal>
        </CardBody>

      </Card>
    );
  }

class Delete extends React.Component {

  componentDidMount() {
    if (this.props.user_status === null) {
      store.dispatch(loadUser());
    }
  }


  deleteProfile = async (e) => {
      await ApiSocial.deleteUser(
        this.props.user_status.pk,
        this.state.key,
        (success, data) => {
        if ( success) {
          console.log('success')
          this.setState({delete_success: true})
        } else {
          console.log('fail')
        }
      });
    };

  showToastr = () => {
    const options = {
      timeOut: parseInt(this.state.timeOut),
      showCloseButton: this.state.showCloseButton,
      progressBar: this.state.progressBar,
      position: this.state.position
    };

    const toastrInstance =
      this.state.type === "info"
        ? toastr.info
        : this.state.type === "warning"
        ? toastr.warning
        : this.state.type === "error"
        ? toastr.error
        : toastr.success;

    toastrInstance(
      this.state.title,
      this.state.message || "Destroying Your Account",
      options
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      title: "",
      message: "",
      type: "error",
      timeOut: 5000,
      showCloseButton: true,
      progressBar: true,
      position: "top-right",
      delete_success: false,
      key: this.props.auth.key,
    };
  }

  render() {
    if (this.state.delete_success) {
      return(
      <Redirect to={{
          pathname: "/",
      }} />)

     }
     else if (this.props.user_status === null) {
       return(
         null
       )
     }
    return(


          <ModalDelete
          userid = {this.props.user_status.pk}
          deleteProfile = {this.deleteProfile}
          toaster = {this.showToastr}/>

    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  user_status: state.auth.user,
});

export default connect(mapStateToProps, {  })(Delete);
