import React from "react";
import { Link } from "react-router-dom";
import DatePicker from './DatePicker'
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown
} from "reactstrap";
import moment from "moment";

import { Calendar, Flag } from "react-feather";

const Header = (props) => {
  return (
    <Row className="mb-2 mb-xl-4">
      <Col xs="auto" className="d-none d-sm-block">


            <UncontrolledDropdown className="d-inline ">
              <DropdownToggle caret color="light" className="bg-white shadow-sm mr-2" size="lg" style={{height:'100%',  width:'175px'}}>
                <Calendar className="feather align-middle mt-n1" /> {props.range}
              </DropdownToggle>
              <DropdownMenu left="true">
                <DropdownItem onClick={() => props.datefilter(moment().subtract(1, "days"),moment(),"Today","date_range")}>Today</DropdownItem>
                <DropdownItem onClick={() => props.datefilter(moment().subtract(6, "days"),moment(),"Last 7 Days","date_range")}>Last 7 Days</DropdownItem>
                <DropdownItem onClick={() => props.datefilter(moment().subtract(30, "days"),moment(),"Last 30 Days","date_range")}>Last 30 Days</DropdownItem>
                <DropdownItem onClick={() => props.datefilter(moment().startOf('year'),moment(),"Start of Year","date_range")}>Start of Year</DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => props.datefilter(moment().startOf('year'),moment(),"All Time","date_range")}>All Time</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>



      {props.workout_programs_recent === null? null :
      <UncontrolledDropdown className="d-inline mr-2">
        <DropdownToggle caret color="light" className="bg-white shadow-sm" size="lg" style={{height:'100%', width:'250px'}}>
          <Flag className="feather align-middle mt-n1" /> {props.workout_programs_recent}
        </DropdownToggle>
        <DropdownMenu left="true">
        {props.workout_programs.map((a,b) =>
          <div key={b}>
          {a.enrollment_history_resume_date.length > 0?
          <DropdownItem onClick={async () => {await
            props.datefilter(
              a.recorded_enroll_date,
              a.enrollment_history_resume_date[0].pause_date,
              a.routine_name,
              "workout_range",);
              }}>{a.routine_name}</DropdownItem>
          :
          <DropdownItem onClick={async () => {
            await props.datefilter(
              moment(a.recorded_enroll_date).subtract(0,'days').startOf('day').utc(),
              moment().subtract(0,'days').endOf('day').utc(),
              a.routine_name,
              "workout_range",);
          }}>{a.routine_name}</DropdownItem>
          }
          </div>
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
    }




      <Button color="warning" size = "lg" className="ml-2" tag={Link} to="/myprogression/bodyweight">
         Body Weight
      </Button>

      <Button color="primary" size = "lg" className="ml-2" tag={Link} to="/lift_tracking">
         Record Lifts
      </Button>

      </Col>

      <Col xs="auto" className="ml-auto text-right mt-n1">



              <DatePicker
                datechange={props.datechange}
                start={props.start}
                end={props.end}
                changer={props.changer}/>


      </Col>
    </Row>
  );
};

export default Header;
