import React, { useState } from "react";
import {  withRouter} from 'react-router-dom';
import { connect } from "react-redux";
import store from "../../redux/store/index";
import { Button, Modal, ModalHeader } from 'reactstrap';
import AddExerciseModalBody from './AddExerciseModalBody'

const ModalExercise = (props) => {
  const {
    className
  } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <div>

      <Button color="dark" className="shadow-sm" onClick={toggle}>
         Add Exercise
      </Button>


      <Modal isOpen={modal} toggle={toggle} className={className} size="lg">
        <ModalHeader toggle={toggle}>Add An Exercise</ModalHeader>

        <AddExerciseModalBody toggle={toggle} workout_id={props.workout_id} fetchWorkouts={props.fetchWorkouts} workouts_detail={props.workouts_detail}/>


      </Modal>
    </div>
  );
}

class AddExercise extends React.Component {

  componentDidMount() {
    console.log(this.props.workout_id)
  }

  render() {
    return (
      <ModalExercise source={this.props.source} workout_id={this.props.workout_id} fetchWorkouts={this.props.fetchWorkouts} workouts_detail={this.props.workouts_detail}/>
    );
  }
}

const mapStateToProps = (state) => ({
  user_status: state.auth.user,
  auth: state.auth,
  sidebar: store.sidebar,
});

export default connect(mapStateToProps, {  })(withRouter(AddExercise));
