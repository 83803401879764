import React from "react";
import { Col, Card, CardBody, CardHeader, Row } from "reactstrap";
import { calendarStrings } from '../../../redux/actions/timeconfig';
import moment from "moment";
import TimeAgo from 'react-timeago'
import prettyNum from 'pretty-num';

const Statistics = (props) => (
  <Row>
    <Col lg="6" xl="3">
      <Card className="flex-fill">
        <CardHeader>
          <span className="badge badge-dark float-right">{props.exercise_last_lifted !==null ? moment(props.exercise_last_lifted).calendar(null,calendarStrings) : "Never" }</span>
          <h5 className="card-title mb-0">Last Lifted</h5>
        </CardHeader>
        <CardBody className="">
          <Row className="d-flex align-items-center mb-2">
            <Col xs="12">
              <h2 className="d-flex align-items-center mb-0 font-weight-light">

                {props.exercise_last_lifted !== null?  <TimeAgo date={props.exercise_last_lifted} /> : "Never Lifted" }

              </h2>
            </Col>
          </Row>

        </CardBody>
      </Card>
    </Col>
    <Col lg="6" xl="3">
      <Card className="flex-fill">
        <CardHeader>
          <span className="badge badge-dark float-right">Sets x Reps x Weight</span>
          <h5 className="card-title mb-0">Last Volume</h5>
        </CardHeader>
        <CardBody className="">
          <Row className="d-flex align-items-center mb-2">
            <Col xs="8">
              <h2 className="d-flex align-items-center mb-0 font-weight-light">
                  {props.exercise_last_volume !== null?

                  prettyNum(props.exercise_last_volume.volume_std , {precision: 1, thousandsSeparator: ','})


                    : "Never Lifted"
                  }
              </h2>
            </Col>
            <Col xs="4" className="text-right">
              <span className="text-muted">{
                props.exercise_last_volume !== null?

                prettyNum(props.exercise_last_volume.measure_value, {precision: 1, thousandsSeparator: ','})
                : props.exercise_detail.measure_value
              }</span>
            </Col>
          </Row>


        </CardBody>
      </Card>
    </Col>
    <Col lg="6" xl="3">
      <Card className="flex-fill">
        <CardHeader>
          <span className="badge badge-dark float-right">Rep x Weight</span>
          <h5 className="card-title mb-0">Heaviest Set</h5>
        </CardHeader>
        <CardBody className="">
          <Row className="d-flex align-items-center mb-2">
            <Col xs="8">
              <h2 className="d-flex align-items-center mb-0 font-weight-light">
                {props.exercise_detail.best_set}
              </h2>
            </Col>
            <Col xs="4" className="text-right">
              <span className="text-muted">{props.exercise_detail.measure_value}</span>
            </Col>
          </Row>


        </CardBody>
      </Card>
    </Col>
    <Col lg="6" xl="3">
      <Card className="flex-fill">
        <CardHeader>
          <span className="badge badge-dark float-right">All Time</span>
          <h5 className="card-title mb-0">Total Volume</h5>
        </CardHeader>
        <CardBody className="">
          <Row className="d-flex align-items-center mb-2">
            <Col xs="8">
              <h2 className="d-flex align-items-center mb-0 font-weight-light">
                {prettyNum(props.exercise_detail.total_volume, {precision: 1, thousandsSeparator: ','})}
              </h2>
            </Col>
            <Col xs="4" className="text-right">
              <span className="text-muted">{props.exercise_detail.measure_value}</span>
            </Col>
          </Row>


        </CardBody>
      </Card>
    </Col>
  </Row>
);

export default Statistics;
