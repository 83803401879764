import React from "react";
import { Container, Row, Col } from "reactstrap";

import HeatMap from "./HeatMap";
import Header from "./Header";
import Languages from "./Languages";
import PieChart from "./PieChart";
import Statistics from "./Statistics";
import LineChart from "./LineChart"
import LiftTable from "./LiftTable"
import SubscribeModal from "../../../components/SubscribeModal.js"
import SubscribeValidate from '../../../config/subscribe_validate.js';
import moment from "moment";
import { connect } from 'react-redux';
import store from "../../../redux/store/index";
import {
  API_ANALYTICS_REP_COUNT,
  API_ANALYTICS_SESSION_COUNT,
  API_ANALYTICS_EXERCISE_COUNT,
  API_ANALYTICS_VOLUME_MOVED,
  API_ANALYTICS_AVERAGE_TIME,
  API_ANALYTICS_TOTAL_TIME,
  API_ANALYTICS_EXERCISE_TABLE,
  API_ANALYTICS_MUSCLE_GROUP,
  API_ANALYTICS_THIS_WEEK,
  API_ANALYTICS_WORKOUT_PROGRAMS,
  API_ANALYTICS_VOLUME_TIMESERIES,
  API_ANALYTICS_VOLUME_HEAT_MAP
 }
  from '../../../redux/actions/API'
import axios from "axios";
import { keyConfig } from '../../../redux/actions/auth';


import { Spinner, } from 'reactstrap';




const lineLabel = [
    moment().subtract(11,'months').endOf('month').format('MMM'),
    moment().subtract(10,'months').endOf('month').format('MMM'),
    moment().subtract(9,'months').endOf('month').format('MMM'),
    moment().subtract(8,'months').endOf('month').format('MMM'),
    moment().subtract(7,'months').endOf('month').format('MMM'),
    moment().subtract(6,'months').endOf('month').format('MMM'),
    moment().subtract(5,'months').endOf('month').format('MMM'),
    moment().subtract(4,'months').endOf('month').format('MMM'),
    moment().subtract(3,'months').endOf('month').format('MMM'),
    moment().subtract(2,'months').endOf('month').format('MMM'),
    moment().subtract(1,'months').endOf('month').format('MMM'),
    moment().format('MMM'),
  ]



const lineData =[
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0
]





class Default extends React.Component {

  fetchAnalyticsStartEnd = (start,end) =>  {

    axios.get(`${API_ANALYTICS_REP_COUNT}day_start=${start}&day_end=${end}`, keyConfig(store.getState)).then(res => {
      this.setState({
        api_rep_count: res.data[0].rep_count,
        api_rep_count_loading: false,
      });
    });

    axios.get(`${API_ANALYTICS_SESSION_COUNT}day_start=${start}&day_end=${end}`, keyConfig(store.getState)).then(res => {
      this.setState({
        api_session_count: res.data[0].session_count,
        api_session_count_loading: false
      });
    });

    axios.get(`${API_ANALYTICS_EXERCISE_COUNT}day_start=${start}&day_end=${end}`, keyConfig(store.getState)).then(res => {
      this.setState({
        api_exercise_count: res.data[0].exercise_count,
        api_exercise_count_loading: false
      });
    });

    axios.get(`${API_ANALYTICS_VOLUME_MOVED}day_start=${start}&day_end=${end}`, keyConfig(store.getState)).then(res => {
      this.setState({
        api_volume_moved: res.data[0].volume_moved,
        api_volume_moved_measurement: res.data[0].volume_measurement,
        api_volume_moved_loading: false
      });
    });

    axios.get(`${API_ANALYTICS_AVERAGE_TIME}day_start=${start}&day_end=${end}`, keyConfig(store.getState)).then(res => {
      this.setState({
        api_average_time: res.data[0].average_time,
        api_average_time_loading: false
      });
    });

    axios.get(`${API_ANALYTICS_TOTAL_TIME}day_start=${start}&day_end=${end}`, keyConfig(store.getState)).then(res => {
      this.setState({
        api_total_time: res.data[0].total_time,
        api_total_time_loading: false
      });
    });

    axios.get(`${API_ANALYTICS_EXERCISE_TABLE}day_start=${start}&day_end=${end}`, keyConfig(store.getState)).then(res => {
      if (res.data.length > 0) {
        this.lineGrpahMuscleGroupExercise(start, end, res.data[0].id)
        this.setState({lineGraphSelectExercise: res.data[0].exercise})
      }
      else if (res.data.length === 0) {
        this.lineGrpahMuscleGroupExercise(start, end, "Total")
        this.setState({lineGraphSelectExercise: "Total"})
      }
      this.setState({
        api_table: res.data,
        api_table_graph: res.data.length ===0? [] : res.data.map(({ id,exercise }) => {return{id: id, muscle_group: exercise}}),
        api_table_loading: false
      });
    });

    axios.get(`${API_ANALYTICS_MUSCLE_GROUP}day_start=${start}&day_end=${end}`, keyConfig(store.getState)).then(res => {
      this.setState({
        api_muscle_group: res.data,
        api_muscle_group_array: Array(res.data.length).fill(0).map((a,b) => res.data[b].volume_moved),
        api_muscle_group_labels: Array(res.data.length).fill(0).map((a,b) => res.data[b].muscle_group),
        api_muscle_group_loading: false,
        api_muscle_group_measurement: res.data[0].volume_measurement
      });
    });

    axios.get(`${API_ANALYTICS_THIS_WEEK}day_start=${start}&day_end=${end}`, keyConfig(store.getState)).then(res => {
      this.setState({
        this_week: res.data,
        this_week_loading: false,
      });
    });

    axios.get(`${API_ANALYTICS_VOLUME_HEAT_MAP}year_start=${'2019'}`, keyConfig(store.getState)).then(res => {
      this.setState({
        heat_map: res.data,
        heat_map_loading: false,
      });
    });


  }

  workoutPrograms = () =>  {
    axios.get(`${API_ANALYTICS_WORKOUT_PROGRAMS}`, keyConfig(store.getState)).then(res => {
      this.setState({
        workout_programs_recent: res.data.length > 0? '--' : null,
        workout_programs: res.data,
        workout_programs_loading: false,
      });
    });
  }


  lineGrpahMuscleGroup = (start,end, id) =>  {
    console.log(start)
    console.log(end)
    axios.get(`${API_ANALYTICS_VOLUME_TIMESERIES}day_start=${start}&day_end=${end}&muscle_group=${id}`, keyConfig(store.getState)).then(res => {
      this.setState({
        volume_series: res.data,
        volume_series_loading: false,
        volume_series_data: res.data.length === 0? lineData : Array(res.data.length).fill(0).map((a,b) => res.data[b].volume),
        volume_series_label: res.data.length === 0? lineLabel :Array(res.data.length).fill(0).map((a,b) => moment(res.data[b].time).format('MMM-DD')),
      });
    });
  }

  lineGrpahMuscleGroupExercise = async (start,end, id) =>  {
    const call = id === "Total"? `${API_ANALYTICS_VOLUME_TIMESERIES}day_start=${start}&day_end=${end}` : `${API_ANALYTICS_VOLUME_TIMESERIES}day_start=${start}&day_end=${end}&exercise=${id}`


    axios.get(call, keyConfig(store.getState)).then(res => {
      this.setState({
        volume_series_exercise: res.data,
        volume_series_exercise_loading: false,
        volume_series_data_exercise: res.data.length === 0? lineData : Array(res.data.length).fill(0).map((a,b) => res.data[b].volume),
        volume_series_label_exercise: res.data.length === 0? lineLabel :Array(res.data.length).fill(0).map((a,b) => moment(res.data[b].time).format('MMM-DD')),
      });
    });
  }

  componentDidMount() {
    this.fetchAnalyticsStartEnd(
      moment().subtract(6, 'days').startOf('day').utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      moment().subtract(0,'days').endOf('day').utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
    ) // last 7 days
    this.workoutPrograms()
    this.lineGrpahMuscleGroup(
      moment().subtract(6, 'days').startOf('day').utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      moment().subtract(0,'days').endOf('day').utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      100
    )
  }

  handlerWorkoutSelected = (name) => {
    this.setState({range: '--', workout_programs_recent: name})
  }

  constructor(props) {
    super(props);
    this.state = {
      start: moment().subtract(6, "days"),
      end: moment(),
      fetch: 0,

      heat_map_loading: true,
      heat_map: [],

      volume_series_measure_type: null,
      volume_series: [],
      volume_series_data: [],
      volume_series_label: [],
      volume_series_loading: true,
      lineGraphSelect: 100,
      lineGraphSelectExercise: 100,



      volume_series_exercise: [],
      volume_series_data_exercise: [],
      volume_series_label_exercise: [],
      volume_series_exercise_loading: true,

      workout_programs_loading: true,
      workout_programs: [],

      api_muscle_group: [],
      api_muscle_group_array: null,
      api_muscle_group_loading: true,

      api_table_loading: true,
      api_table: [],
      api_table_graph: [],

      api_total_time_loading: true,
      api_total_time: null,

      api_rep_count_loading: true,
      api_rep_count: null,

      api_session_count_loading: true,
      api_session_count: null,

      api_exercise_count_loading: true,
      api_exercise_count: null,

      api_volume_moved_loading:true,
      api_volume_moved:null,

      api_average_time_loading: true,
      api_average_time: null,

      range:"Last 7 Days",
      changer: 0
    };
  }

  datechange = async (start, end) => {
    await this.fetchAnalyticsStartEnd(moment.utc(start).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"), moment.utc(end).add(12, 'hours').format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"))
    await this.lineGrpahMuscleGroup(moment.utc(start).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"), moment.utc(end).add(12, 'hours').format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),100)
  }

  datefilter = async (start, end, range, type) => {
    await this.setState({start:start, end:end, range:type==="date_range"? range : '--', changer:1, workout_programs_recent: type==="workout_range"? range : '--'})
    await this.fetchAnalyticsStartEnd(moment.utc(start).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"), moment().subtract(0,'days').endOf('day').utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"))
    await this.setState({changer:0})
  }


  toggleLineGraphMuscleGroup = async(id,index) => {
    await this.setState({lineGraphSelect: id === 100? 100 : this.state.api_muscle_group[id-1].muscle_group})
    await this.lineGrpahMuscleGroup(
      moment.utc(this.state.start).startOf('day').utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      moment(this.state.end).endOf('day').utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),id)
  }

  toggleLineGraphExercise = async(id,index) => {
    await this.setState({lineGraphSelectExercise: index === 100? 100 : this.state.api_table_graph[index].muscle_group})
    await this.lineGrpahMuscleGroupExercise(moment.utc(this.state.start).startOf('day').utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"), moment(this.state.end).endOf('day').utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),id)
  }

  render() {
    if (this.state.workout_programs_loading){
      return(
        <div className="d-flex flex justify-content-center align-items-center">
      <Spinner color="primary p-2" />
      <SubscribeValidate />
      </div>
      )
    }

    return (
      <Container fluid className="p-0 customBlur ">

        <SubscribeModal />

        <Header
        datefilter = {this.datefilter}
        datechange = {this.datechange}
        start={this.state.start}
        end={this.state.end}
        range={this.state.range}
        changer={this.state.changer}
        workout_programs = {this.state.workout_programs}
        workout_programs_recent = {this.state.workout_programs_recent}
        handlerWorkoutSelected = {this.handlerWorkoutSelected}
        fetchAnalyticsStartEnd={this.fetchAnalyticsStartEnd}/>

        <Row>
          <Col lg="6" xl="5" className="d-flex">
            <Statistics
              api_rep_count_loading = {this.state.api_rep_count_loading}
              api_rep_count={this.state.api_rep_count}
              api_session_count_loading = {this.state.api_session_count_loading}
              api_session_count={this.state.api_session_count}
              api_exercise_count={this.state.api_exercise_count}
              api_exercise_count_loading = {this.state.api_exercise_count_loading}
              api_volume_moved = {this.state.api_volume_moved}
              api_volume_moved_measurement = {this.state.api_volume_moved_measurement}
              api_volume_moved_loading = {this.state.api_volume_moved_loading}
              api_average_time = {this.state.api_average_time}
              api_average_time_loading = {this.state.api_average_time_loading}
              api_total_time = {this.state.api_total_time}
              api_total_time_loading = {this.state.api_total_time_loading}
              />
          </Col>
          <Col lg="6" xl="7" className="d-flex">
            <LineChart
            source="MG"
            lineGraphSelect= {this.state.lineGraphSelect}
            toggleLineGraphMuscleGroup = {this.toggleLineGraphMuscleGroup}
            api_muscle_group = {this.state.api_muscle_group}
            api_muscle_group_loading = {this.state.api_muscle_group_loading}
            volume_series={this.state.volume_series}
            volume_series_loading = {this.state.volume_series_loading}
            volume_series_data={this.state.volume_series_data} volume_series_label={this.state.volume_series_label} measure_type={this.props.user_status.usa_imperial? "Lbs" : "Kgs"}/>
          </Col>
        </Row>



        <Row>
          <Col lg="6" xl="5" className="d-flex">

            <Languages api_muscle_group = {this.state.api_muscle_group} api_muscle_group_loading = {this.state.api_muscle_group_loading} api_muscle_group_array={this.state.api_muscle_group_array} api_muscle_group_labels={this.state.api_muscle_group_labels} api_muscle_group_measurement = {this.state.api_muscle_group_measurement} api_volume_moved={this.state.api_volume_moved}/>
          </Col>
          <Col lg="6" xl="7" className="d-flex">
            <LineChart
            source="EX"
            lineGraphSelect= {this.state.lineGraphSelectExercise}
            toggleLineGraphMuscleGroup = {this.toggleLineGraphExercise}

            api_muscle_group = {this.state.api_table_graph}
            api_muscle_group_loading = {this.state.api_table_loading}
            volume_series={this.state.volume_series_exercise}
            volume_series_loading = {this.state.volume_series_exercise_loading}
            volume_series_data={this.state.volume_series_data_exercise}
            volume_series_label={this.state.volume_series_label_exercise}
            measure_type={this.props.user_status.usa_imperial? "Lbs" : "Kgs"}/>
          </Col>
        </Row>
        <Row>
          <Col lg="6" xl="5" className="d-flex">
            <PieChart api_muscle_group = {this.state.api_muscle_group} api_muscle_group_loading = {this.state.api_muscle_group_loading} api_muscle_group_array={this.state.api_muscle_group_array} api_muscle_group_labels={this.state.api_muscle_group_labels} api_muscle_group_measurement= {this.state.api_muscle_group_measurement}/>
          </Col>
          <Col lg="6" xl="7" className="d-flex">
            <LiftTable api_table_loading = {this.state.api_table_loading} api_table = {this.state.api_table}/>
          </Col>
        </Row>




        <Row>
          <Col lg="12" xl="12" className="d-flex">
          {this.state.heat_map_loading?
            null :
            <HeatMap heat_map_loading = {this.state.heat_map_loading} heat_map={this.state.heat_map} measure_type={this.props.user_status.usa_imperial? "Lbs" : "Kgs"}/>
          }
          </Col>
        </Row>


      </Container>
  );
 }
}

const mapStateToProps = (state) => ({
  user_status: state.auth.user,
  theme: state.theme.currentTheme
});

export default connect(mapStateToProps, {  })(Default);
