import React, { useState } from "react";
import {  withRouter} from 'react-router-dom';
import { connect } from "react-redux";
import store from "../../redux/store/index";
import { Button, Modal, ModalHeader,} from 'reactstrap';
import PatchFullSetModalBody from './PatchFullSetModalBody'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen
} from "@fortawesome/free-solid-svg-icons";

const ModalExercise = (props) => {
  const {
    className
  } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <>

      <Button color="info" className="shadow-sm mr-1" onClick={toggle}>
         <FontAwesomeIcon icon={faPen} />
      </Button>



      <Modal size="lg" isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>Update Exercise Set</ModalHeader>

        <PatchFullSetModalBody toggle={toggle} fullID = {props.fullID} fetchWorkouts = {props.fetchWorkouts} routine = {props.routine} workouts_detail={props.workouts_detail}/>


      </Modal>
    </>
  );
}

class PatchWorkout extends React.Component {

  componentDidMount() {
    console.log(this.props.workouts_detail)
  }

  render() {
    return (
      <ModalExercise fullID = {this.props.fullID} fetchWorkouts={this.props.fetchWorkouts} routine = {this.props.routine} workouts_detail={this.props.workouts_detail}/>
    );
  }
}

const mapStateToProps = (state) => ({
  user_status: state.auth.user,
  auth: state.auth,
  sidebar: store.sidebar,
});

export default connect(mapStateToProps, {  })(withRouter(PatchWorkout));
