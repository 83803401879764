import React, { useState } from "react";
import {  withRouter} from 'react-router-dom';
import { connect } from "react-redux";
import store from "../../redux/store/index";
import { Button, Modal, ModalHeader,} from 'reactstrap';
import PatchExerciseModalBody from './PatchExerciseModalBody'

const ModalExercise = (props) => {
  const {
    className
  } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <>

      <Button color="info" className="shadow-sm mr-1" onClick={toggle}>
         Options
      </Button>



      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>Update Exercise</ModalHeader>

        <PatchExerciseModalBody toggle={toggle} exerciseID = {props.exerciseID} fetchDetail = {props.fetchDetail}/>


      </Modal>
    </>
  );
}

class CreateExercise extends React.Component {

  componentDidMount() {

  }

  render() {
    return (
      <ModalExercise exerciseID = {this.props.exerciseID} fetchDetail={this.props.fetchDetail}/>
    );
  }
}

const mapStateToProps = (state) => ({
  user_status: state.auth.user,
  auth: state.auth,
  sidebar: store.sidebar,
});

export default connect(mapStateToProps, {  })(withRouter(CreateExercise));
