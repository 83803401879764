import React from "react";
import { Link } from "react-router-dom";
import { keyConfig } from '../../redux/actions/auth';
import axios from "axios";
import store from "../../redux/store/index";
import {
  API_WORKOUTS_CATEGORY,
  API_WORKOUTS_CATEGORY_PUBLIC,
  API_WORKOUTS_CUSTOM_LIST,
  API_WORKOUTS_DEFAULT_IMAGE
} from '../../redux/actions/API'
import { connect } from 'react-redux';
import Default from '../dashboards/Social/index'
import { loadUser } from '../../redux/actions/auth';
import Loader from "../../components/Loader";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardImg,
  CardTitle,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Progress,
  Row,
} from "reactstrap";
import Header from './HeaderList'
import { Redirect } from 'react-router-dom';
import WorkoutNotFound from './WorkoutNotFound'

const CardLifted = (props) => (
  <Card>
  <Link to={"/workouts/detail/" + props.workout_details.id} >
    <CardImg top src={props.workout_cover_image} alt={props.workout_details.id} className="img-fluid" />

    <CardHeader className="px-4 pt-4">

      <CardTitle tag="h5" className="mb-0">
        <strong>{props.workout_details.routine}</strong>
      </CardTitle>
      <Badge
        className="my-2">
      </Badge>
    </CardHeader>
    </Link>
    <CardBody className="px-4 pt-1 pb-0" >
      <div dangerouslySetInnerHTML={ { __html: props.workout_details.description } }></div>
    </CardBody>
    <ListGroup flush >
      <ListGroupItem className="px-4 pb-4">
        <Progress
        color={
          props.workout_details.enroll_history[0].derived__status_memo === "In Progress"?
          "primary"
          :
          props.workout_details.enroll_history[0].derived__status_memo === "Paused"?
          "dark"
          :
          props.workout_details.enroll_history[0].derived__status_memo === "Completed"?
          "success"
          :
          null
        }
        value={props.workout_details.enroll_history[0].derived__workout_progress_int} className="pl-0 pr-0 mr-0 ml-0">
          {props.workout_details.enroll_history[0].derived__workout_progress_pct}
        </Progress>

          {props.workout_details.enroll_history[0].derived__status_memo === "In Progress"?
                <Button
                className="btn-block mt-2"
                color="success"
                tag={Link} to={"/workouts/detail/" + props.workout_details.id}> In Progress </Button>
                :
                props.workout_details.enroll_history[0].derived__status_memo === "Paused"?

                <Button
                className="btn-block mt-2"
                color="secondary"
                tag={Link} to={"/workouts/detail/" + props.workout_details.id}> Paused </Button>
                :

                  props.workout_details.enroll_history[0].derived__status_memo === "Completed"?

                  <Button
                  className="btn-block mt-2"
                  color="dark"
                  tag={Link} to={"/workouts/detail/" + props.workout_details.id}> Completed </Button>

                  :
                  <Button className="btn-block mt-2" color="dark" tag={Link} to={"/workouts/detail/" + props.workout_details.id}> View </Button>

              }
      </ListGroupItem>
    </ListGroup>
  </Card>
);

const CardNeverLifted = (props) => (
  <Card>
    <Link to={"/workouts/detail/" + props.workout_details.id} >
      <CardImg top src={props.workout_cover_image} alt={props.workout_details.id} className="img-fluid"  />
        <CardHeader className="px-4 pt-4">
          <CardTitle tag="h5" className="mb-0">
            <strong>{props.workout_details.routine}</strong>
          </CardTitle>
          <Badge className="my-2" color="info">

          </Badge>
        </CardHeader>
    </Link>
    <CardBody className="px-4 pt-1 pb-0" >
      <div dangerouslySetInnerHTML={ { __html: props.workout_details.description } }></div>
    </CardBody>
    <ListGroup flush >
      <ListGroupItem className="px-4 pb-4">
        <Progress value="0" className="p-0 m-0">
          0
        </Progress>
        <Button className="btn-block mt-2" color="dark" tag={Link} to={"/workouts/detail/" + props.workout_details.id}> View </Button>
      </ListGroupItem>
    </ListGroup>
  </Card>
);

class WorkoutsList extends React.Component {

  fetchWorkouts = (categoryID) =>  {
    axios.get(`${API_WORKOUTS_CATEGORY}${categoryID}/`, keyConfig(store.getState)).then(res => {
      console.log(res.data)
      this.setState({
        api_workouts_category: res.data.workouts,
        api_workouts_category_loading: false,
      });
    }).catch((err) => {
      this.setState({
        api_workouts_category_loading: false,
      });
    });

    axios.get(`${API_WORKOUTS_CATEGORY_PUBLIC}`, keyConfig(store.getState)).then(res => {
      console.log(res.data)
      this.setState({
        api_workouts_category_public: res.data,
        api_workouts_category_public_loading: false,
      });
    });
  }

  fetchDefaultImage = () =>  {
    axios.get(`${API_WORKOUTS_DEFAULT_IMAGE}`, keyConfig(store.getState)).then(res => {
      this.setState({
        workouts_default_image: res.data[0].default,
        workouts_default_image_loading: false,
      });
    });
  }

  fetchWorkoutsCustom = () =>  {
    axios.get(`${API_WORKOUTS_CUSTOM_LIST}`, keyConfig(store.getState)).then(res => {
      this.setState({
        api_workouts_category: res.data,
        api_workouts_category_loading: false,
      });
    });
  }

  loginFlag = () => {
    try{
      this.setState({login: this.props.location.state.login})
      console.log('ee')
    } catch(err){
      console.log('aa')
      this.setState({login: 1})
    }
  }

  componentDidMount() {
    if(this.props.match.params.categoryID) {
      if (this.props.user_status === null) {
        store.dispatch(loadUser());
      }
      this.fetchWorkouts(this.props.match.params.categoryID);
      this.fetchDefaultImage();
      this.loginFlag()
    }
    else if(!this.props.match.params.categoryID) {
      this.fetchWorkoutsCustom();
      this.fetchDefaultImage();
      this.loginFlag()
    }
  }

  refresh = async () => {
    await this.loginFlag()
    await this.setState({api_workouts_category: [], api_workouts_category_loading: true})
    await this.fetchWorkouts(this.props.match.params.categoryID);
  }

  componentWillReceiveProps(){
    this.refresh()
}

  constructor(props) {
    super(props);
    this.state = {
      api_workouts_category: [],
      api_workouts_category_loading: true,
      login: null,
      workouts_default_image: [],
      workouts_default_image_loading:true,
      showDrop: this.props.location.pathname === "/myworkouts/"? false : true
    };
  }


  render() {
    if (this.props.user_status === null || this.state.login === null) {
      return <Loader />;
    }
    else if (this.props.user_status !== null && this.props.user_status.first_name==="") {
      return (<Redirect to={{pathname: "/onboarding/step1",}} />)
    }
    else if (this.props.location?.state?.login === true) {
      return <Default />;
    }
    else if (this.state.api_workouts_category_loading || this.state.workouts_default_image_loading || this.state.api_workouts_category_public_loading) {
      return <Loader />
    }
    else if (!this.props.match.params.categoryID && this.state.api_workouts_category.length === 0) {
      return(
        <WorkoutNotFound />
      )

    }
    return (

      <Container fluid>

      <Header
      categoryID = {this.props.match.params.categoryID}
      path={this.props.match.params.categoryID}
      api_workouts_category_public={this.state.api_workouts_category_public}
      showDrop={this.state.showDrop}
      />

           <Row >
              {this.state.api_workouts_category.map( (dummy,y) =>

                <>
                {(y<3 && this.props.match.params.categoryID === "All")?


             <Col md="4" lg="4" key={y}>
              {dummy.enroll_history.length > 0 ?
               <CardLifted workout_details={dummy} workout_cover_image= {dummy.cover_image?dummy.cover_image : dummy.default_cover_image? dummy.default_cover_image : this.state.workouts_default_image}/>
               :
               <CardNeverLifted workout_details = {dummy}  workout_cover_image= {dummy.cover_image?dummy.cover_image : dummy.default_cover_image? dummy.default_cover_image : this.state.workouts_default_image}/>
             }
             </Col>

             :

             <Col md="3" lg="3" key={y}>
              {dummy.enroll_history.length > 0 ?
               <CardLifted workout_details={dummy}  workout_cover_image= {dummy.cover_image?dummy.cover_image : dummy.default_cover_image? dummy.default_cover_image : this.state.workouts_default_image}/>
               :
               <CardNeverLifted workout_details = {dummy} workout_cover_image= {dummy.cover_image?dummy.cover_image : dummy.default_cover_image? dummy.default_cover_image : this.state.workouts_default_image}/>
             }
             </Col>

           }

             </>

             )}
           </Row>


      </Container>

    );
  }
}

const mapStateToProps = (state) => ({
  loginflag: state.auth.loginFlag,
  user_status: state.auth.user,
});

export default connect(mapStateToProps)(WorkoutsList);
