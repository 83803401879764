import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  CardText,
  Col,
  Row,
  CardImg,
} from "reactstrap";
import unsplash_1 from "./1.jpg";
import unsplash_2 from "./2.jpg";
import unsplash_3 from "./3.jpg";

class PromoCards extends React.Component {


  render() {
    return (



            <Row>
              <Col sm="6" md="4" className="ml-auto">
                <Card>
                  <CardImg top width="100%" src={unsplash_1} alt="Card image cap" />
                  <CardHeader>
                    <CardTitle tag="h5" className="mb-0 text-strong">
                      Discover Workouts
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <CardText>
                    Join and log any workout that's right for you, with no limits. Or create as many custom workouts as you like
                    </CardText>
                    <Button href="#" color="dark" tag={Link} to="/workouts/All">
                      See Workouts
                    </Button>
                  </CardBody>
                </Card>
              </Col>
              <Col sm="6" md="4" className="mr-auto">
              <Card>
                <CardImg top width="100%" src={unsplash_2} alt="Card image cap" />
                <CardHeader>
                  <CardTitle tag="h5" className="mb-0">
                    Add Progress Pics
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <CardText>
                  Nothing beats before and after pictures. Capture your journey and progress. Awe your friends
                  </CardText>
                  <Button href="#" color="dark" tag={Link} to="/albums">
                    Add Photos
                  </Button>
                </CardBody>
              </Card>
              </Col>
              <Col sm="6" md="4" className="ml-auto">
              <Card>
                <CardImg top width="100%" src={unsplash_3} alt="Card image cap" />
                <CardHeader>
                  <CardTitle tag="h5" className="mb-0">
                    Deeper Analytics
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <CardText>
                    See all your training in one place and chart your progress with unique analysis tools & an interactive record of your activities.
                  </CardText>
                  <Button href="#" color="dark" tag={Link} to="/myprogression/analytics">
                    See Analytics
                  </Button>
                </CardBody>
              </Card>
              </Col>

            </Row>


    );
  }
}

export default PromoCards;
