import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Media,
} from "reactstrap";
import TimeAgo from 'react-timeago'
import moment from "moment";
import { calendarStrings } from '../../redux/actions/timeconfig';
import { connect } from 'react-redux';
import store from "../../redux/store/index";
import {
  API_NOTIFICATIONS,
} from '../../redux/actions/API';
import axios from "axios";
import { keyConfig } from '../../redux/actions/auth';
import Loader from './Loader'
import ProfilePhoto from '../../components/Social/ProfilePhoto/ProfilePhoto'





class Notifcations extends React.Component {

  fetchNotifications = () =>  {
    axios.get(`${API_NOTIFICATIONS}`, keyConfig(store.getState)).then(res => {
      this.setState({
        api_notifications : res.data.results,
        api_notifications_loading : false
      });
    })
  }



  renderMemoEnd = (a,b,event) => {
    if (event===2 || event===3 || (event===4 && a.comment_reply_event ===2) || (event===4 && a.comment_reply_event ===1) ) {
      return(
        <Link to={"/profile/" + a.timeline_user_id + "/workout/" + a.timelike_xref} style={{color:"	#696969",'textDcoration':'none'}}> <strong>{a.memo_end}</strong> </Link>
      )
    }
    else if (event===5 || event===6 || event===7) {
      return(
        <Link to={"/profile/" + a.timeline_user_id + "/albums_alt/" + a.timelike_xref} style={{color:"	#696969",'textDcoration':'none'}}> <strong>{a.memo_end}</strong> </Link>
      )
    }
    else if (event===8 || event===9 || event===10) {
      return(
        <Link to={"/profile/" + a.photo_user_id + "/photos_alt/" + a.photo_xref} style={{color:"	#696969",'textDcoration':'none'}}> <strong>{a.memo_end}</strong> </Link>
      )
    }
  }


  redirect = () => {
  }

  componentDidMount = async() => {
    this.fetchNotifications()
  }

  constructor(props) {
    super(props);
    this.state = {
      api_notifications: [],
      api_notifications_loading: true
    };
  }

  render() {
    if(this.state.api_notifications_loading){
      return (<Loader />)
    }
    return(
      <Card className="flex-fill mb-3">
        <CardHeader>
          <div className="card-actions float-right">

          </div>
          <CardTitle tag="h5" className="mb-0">Notifications ({Math.min(this.state.api_notifications.length, 1000)})</CardTitle>
        </CardHeader>
        <CardBody>

        {this.state.api_notifications.slice(0,1000).map((a,b) =>
          <div key={b}>
          <Media>
          <div className="pr-2">
          <Link
          to={"/profile/" + a.action_user_xref.pk + "/home"}
          style={{color:"	#696969",'textDcoration':'none'}}>
              <ProfilePhoto
                profile_photo={a.action_user_xref.profile_photo}
                avatar_color = {a.action_user_xref.avatar_color}
                avatar_letter = {a.action_user_xref.avatar_letter}
                avatar_size={"36px"}
                letter_size={"18px"}
                active_user_id = {this.props.user_status.pk }
                user_id = {a.action_user_xref.id}
                />
              </Link>
          </div>
            <Media body>
              <small className="float-right text-navy"><TimeAgo date={a.timestamp}  /> </small>
              <Link to={"/profile/" + a.action_user_xref.pk + "/home"}
              style={{color:"	#696969",'textDcoration':'none'}}><strong>{a.action_user_xref.firstname_lastname}</strong></Link> {a.memo_start}{" "}
              {this.renderMemoEnd(a,b,a.event)}
              <br />
              <small className="text-muted"> {moment(a.timestamp).calendar(null,calendarStrings)}</small>
              <br />
            </Media>
          </Media>

          <hr />
          </div>
)}


      {this.state.api_notifications.length === 0?
        <div>
        <p className="text-center"> <strong>  You don't have any notificaionts yet, but when you do, this is where you will see your activity</strong></p>
        </div>
        :
        <div>
        <p className="text-center"> <strong>  Nothing More To Show </strong></p>
        </div>
      }


        </CardBody>
      </Card>
  )
  }
}

const mapStateToProps = (state) => ({
  user_status: state.auth.user,
});

export default connect(mapStateToProps, {  })(Notifcations);
