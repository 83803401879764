import React from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import {  CardImg, Card, CardBody, CardHeader, CardTitle, Badge, Button, Progress, Row, Col } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import {
  API_LIFT_TRACKER_INPUT }
  from '../../../redux/actions/API'
import store from "../../../redux/store/index";
import axios from "axios";
import { keyConfig } from '../../../redux/actions/auth';
import { lifttracking } from '../../../redux/actions/general';
import { Spinner } from 'reactstrap';
import StatusNewYouAndFollowers from "../../../components/Social/StatusNewYouAndFollowers"
import TimeAgo from 'react-timeago'
import WorkoutCompleted from '../../lift_tracking/WorkoutCompleted';



const NextWorkoutTable = ({ theme, ...props }) => {


  if (props.data[0].routine_xref.enroll_history[0].derived__status_memo === "Completed") {
    return(
      <WorkoutCompleted source="sidebar" workout={props.data[0].routine_xref.routine}/>
    )
  }
  return (
    <section className="flex-fill w-100">
      <Card>
        <CardHeader className="mb-0 pb-0">
          <div className="d-flex flex justify-content-between align-items-center">
          <CardTitle tag={Link} to="/lift_tracking" className="mb-0 float-left" ><span>Next Workout: </span></CardTitle>
          <Button size="sm" color="secondary" className="btn-pill float-right" tag={Link} to={'/lift_tracking'} outline> Record Lifts Now </Button>
          </div>
          <Badge color="info" className="float-left mt-1 mb-0" tag={Link} to="/lift_tracking">
            {props.next_workout_title}
          </Badge>
        </CardHeader>
        <CardBody>
          <BootstrapTable
            keyField='id'
            data={props.next_workout_data}
            columns={
            [
              {
                dataField: "id",
                isKey: true,
                sort: false,
                hidden: true,
                text:"id",
                headerStyle: { "borderColor": 'green', 'marginTop':"-2px","border":'none', }
              },
              {
                dataField: "exercise_xref.exercise",
                isKey: false,
                sort: false,
                hidden: false,
                text:"Exercise",
                headerStyle: { "borderColor": 'green', 'marginTop':"-2px","border":'none', }
              },
              {
                dataField: "derived__set_count",
                isKey: false,
                sort: false,
                hidden: false,
                text:"Sets",
                headerStyle: { "borderColor": 'green', 'marginTop':"-2px","border":'none'},
              },
              {
                dataField: "derived__rep_count",
                isKey: false,
                sort: false,
                hidden: false,
                text:"Reps",
                headerStyle: { "borderColor": 'green', 'marginTop':"-2px","border":'none'},
              },
            ]
          }
            hover={true}
            rowEvents={ {
              style: { cursor: 'pointer'},
              onClick: (e, row) => { this.setState({referrer: `/exercises/detail/${row.exercise_xref.id}`}) }
            }}
            bootstrap4
            bordered={false}
            headerStyle= {{ backgroundColor: 'green' }}
            hiddenHeader = {false}
          />
        </CardBody>
      </Card>
    </section>

  );
};

const Status = (props) => (
  <Card>
  <CardImg
    top
    src={
      props.data[0].routine_xref.cover_image === null || props.data[0].routine_xref.cover_image === '' || !props.data[0].routine_xref.cover_image?
      props.data[0].routine_xref.default_cover_image
      :
      props.data[0].routine_xref.cover_image
  }
    alt={props.data[0].routine_xref.id}
    className="img-fluid"
    style={{'maxHeight': '300px'}}/>
    <CardBody>
      <Row>
        <Col className="mt-0">
          <CardTitle tag={Link} to={"/workouts/detail/" + props.data[0].routine_xref.id}><strong>{props.data[0].routine_xref.routine}</strong></CardTitle>
        </Col>

        <Col className="col-auto">
          <div className="avatar">
            <div className="avatar-title rounded-circle bg-primary-dark">

            </div>
          </div>
        </Col>
      </Row>
      {props.data[0].routine_xref.enroll_history[0].derived__recorded_end_time_rfc === "No History" ?

      <div className="mb-0">
          Waiting on your first lift...
      </div>

      :
      <div className="mb-0">
          Last Lifted <TimeAgo date={props.data[0].routine_xref.enroll_history[0].derived__recorded_end_time_iso}  />
      </div>
      }

      {props.data.map(x=>(x.routine_xref.enroll_history.slice(0,1).map((s,t)=>
      <h1 className="display-5 mt-1" key={t}>{s.derived__total_days_completed}/{s.derived__total_expected_days} Completed</h1>
      )))}

      {props.data.map(x=>(x.routine_xref.enroll_history.slice(0,1).map((s,t)=>
      <Progress
      color={
        s.derived__status_memo === "Completed"?
        "success"
        :
        "primary"
      }
      value={s.derived__workout_progress_int}
      className="" key={t}>
        {s.derived__workout_progress_pct}
      </Progress>
    )))}
    </CardBody>
  </Card>
);

class Default extends React.Component {

  fetchLiftData = () =>  {
    axios.get(`${API_LIFT_TRACKER_INPUT}`, keyConfig(store.getState)).then(res => {
      this.props.lifttracking(res.data)
      this.setState({
        api_dashboard_lti: res.data,
        api_dashboard_lti_loading: false
      });
    });
  }

  componentDidMount() {
    this.fetchLiftData();
  }

  constructor(props) {
    super(props);
    this.state = {
      api_dashboard_lti:this.props.lift_tracking_input_data,
      api_dashboard_lti_loading: this.props?.lift_tracking_input_data?.length > 0? false : true,

    };
  }

  render() {
console.log(this.state.api_dashboard_lti)
    if (this.state.api_dashboard_lti_loading === true) {
      return(
        <Card>
          <CardBody>
            <Row>
              <Col className="mt-0 d-flex flex justify-content-center align-items-center">
                <Spinner color="primary"  />
              </Col>
            </Row>
          </CardBody>
        </Card>
      )
    }
    else if (this.state.api_dashboard_lti.length === 0 && this.props.source === "dashboard") {
      return(
        <>
          <StatusNewYouAndFollowers source={"dashboard"}/>

        </>
      )
    }
    else if (this.state.api_dashboard_lti.length === 0 && this.props.source === "timeline_right") {
      return(
        <>
          <StatusNewYouAndFollowers source={"timeline_right"}/>

        </>
      )
    }
    return (

      <>
        <Status data={this.state.api_dashboard_lti} />
      <NextWorkoutTable
      data={this.state.api_dashboard_lti}
      next_workout_data = {this.state.api_dashboard_lti[0].full_quick}
      next_workout_title = {this.state.api_dashboard_lti[0].day_title}
      workout_id={this.state.api_dashboard_lti[0].routine_xref.id}/>

      </>


  );
 }
}

const mapStateToProps = (state) => ({
  user_status: state.auth.user,
  theme: state.theme.currentTheme,
  lift_tracking_input_data: state.general.lift_tracking_input_data,
});

export default connect(mapStateToProps, { lifttracking })(Default);
