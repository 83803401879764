import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import store from "../../redux/store/index";
import {
  Button,
  Card,
  CardBody,
  Spinner,
} from "reactstrap";
import { API_BASE_URL, STRIPE_PUB_KEY } from '../../redux/actions/API'
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(STRIPE_PUB_KEY);


class PricingCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false
    };
  }


  click = async(e) => {
    await this.setState({loader:true})
    await this.props.handleClick(e,this.props.type, this.props.freetrial)
  }

  render() {
    return (
      <Card className="text-center">
        <CardBody className="d-flex flex-column">
                  {this.props.type === 'month' ?
          <div className="mb-4">

          {this.props.landing?
            <div>
          <h2> Subscribe Now </h2>
          <span>and get the very best of ironroom</span>
          </div>
          :
          null}
            <span className="display-4">$5</span>
            <span>/mo*</span>
            <br />
            <span>when billed each month</span>
          </div>

          :

          <div className="mb-4">
          {this.props.landing?
            <div>
          <h2> Subscribe Now </h2>
          <span>and get the very best of ironroom</span>
          </div>
          :
          null}
            <span className="display-4">$3</span>
            <span>/mo*</span>
            <br />
            <span>*when billed once annually</span>
          </div>
        }

        {!this.props.freetrial?

          <div className="mt-auto">
            <Button size="lg" color="dark" role="link" onClick={(event) => this.click(event)} style={{width:'180px'}}>
            {!this.state.loader? "Continue To Billing" : <Spinner color="light" size="sm" />}
            </Button>
          </div>

          :
          <div className="mt-auto">
            <Button size="lg" color="dark" role="link" onClick={(event) => this.click(event)} style={{width:'220px'}}>
            {!this.state.loader? "Start Free " + this.props.freetrial + " Day Trial" : <Spinner color="light" size="sm" />}
            </Button>
          </div>


        }
        </CardBody>
      </Card>
    );
  }
}

const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
);

export default function App(props) {
  const [message, setMessage] = useState("");

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setMessage("Order placed! You will receive an email confirmation.");
    }

    if (query.get("canceled")) {
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, []);

  const handleClick = async (event,type, freetrial) => {
    const stripe = await stripePromise;
    const key = await store.getState().auth.key;

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${key}`
      },
      method: "POST"
    };

    const response = await fetch(`${API_BASE_URL}/stripe/?type=${type}&freetrial=${freetrial}`, config);

    const session = await response.json();

    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (result.error) {
      console.log(result.error)
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    }
  };

  return message ? (
    <Message message={message} />
  ) : (
    <PricingCard handleClick={handleClick} type={props.type} freetrial={props.freetrial} landing={props.landing}/>
  );
}
