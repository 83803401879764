import React from "react";
import { Link } from "react-router-dom";
import store from "../../../redux/store/index";
import {
  Card,
  CardBody,
  CardTitle,
  Button,
} from "reactstrap";
import UnfollowButton from "../Buttons/UnfollowButton"
import FollowButton from "../Buttons/FollowButton"
import { connect } from 'react-redux';
import UploadProfilePicture from '../UploadProfilePhoto/Index'
import ProfilePhoto from '../ProfilePhoto/ProfilePhoto'



class Default extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      image: null,
      imagecropped: null,
    }
  }




  renderUserButtons = () => {
    if (this.props.active_user_id === this.props.user_id && (this.props.avatar_image === null || this.props.avatar_image==="None" || !this.props.avatar_image) ){
      return(
        <UploadProfilePicture
          photo_change_flag = {this.props.photo_change_flag}
          profile_picture_status = {this.props.user_status.avatar_image}
        />
      )
    }

    else if (this.props.api_following_this_user_length >= 1) {
      return(
        <UnfollowButton
          followingid = {this.props.api_following_this_user[0].followed_user.derived__following_id}
          myid = {store.getState().auth.user.pk}
          user_id = {this.props.api_following_this_user[0].followed_user.id}
          currentTime = {this.props.api_following_this_user[0].derived__current_time}
          change_flag = {this.props.change_flag}
        />
      )
    }

    else if (this.props.active_user_id !== this.props.user_id) {
      return(
        <FollowButton
          myid = {store.getState().auth.user.pk}
          user_id = {this.props.user_id}
          change_flag = {this.props.change_flag}
          />
        )
      }
    }



    renderButtons = () => {

      if (this.props.user_status.avatar_image === null && (this.props.source === "timeline")) {
        return(

          <div className={"d-flex flex justify-content-center align-items-center"}>
            <Button color="primary" size="sm" tag={Link} to={"/profile/" + this.props.user_status.pk + "/home"} className="mr-1">
              My Profile
            </Button>{" "}

            <UploadProfilePicture source={this.props.source}/>
          </div>
                  )
      }
      else if (this.props.user_status.avatar_image === null && (this.props.source === "my_profile" || this.props.source === "onboarding")) {
        return(

          <div className={"d-flex flex justify-content-center align-items-center"}>
            <UploadProfilePicture source={this.props.source}/>
          </div>
                  )
    }
  }


  render() {
    return (
      <Card>
        <CardBody className="text-center">
            <ProfilePhoto
              avatar_image={this.props.avatar_image}
              avatar_color = {this.props.avatar_color}
              avatar_letter = {this.props.avatar_letter}
              avatar_size={"128px"}
              letter_size={"56px"}
              photo_change_flag = {this.props.photo_change_flag}
              active_user_id = {this.props.active_user_id }
              user_id = {this.props.user_id}
              />

          <CardTitle tag="h5" className="mb-0 mt-1">
            {this.props.first_name} {this.props.last_name}
             <div className="text-muted mb-2">{this.props.heading}</div>
          </CardTitle>

          <div className="text-muted mt-0 pt-0 mb-2"></div>

          {this.renderButtons()}


        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  user_status: state.auth.user,
  theme: state.theme.currentTheme
});

export default connect(mapStateToProps, {  })(Default);
