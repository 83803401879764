import React from "react";

import {
  Card,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
  Spinner
} from "reactstrap";



const WorkoutsDetailLoading = () => (
  <Container fluid>


    <Row>


          <Col xs="12" className="col-xxl-9">

            <Card>
              <CardHeader>
                <CardTitle tag="h5" className="mb-0">
                <Spinner color="dark" type="grow" size="sm" className="mr-2" />
                  <Spinner
                    color="primary"
                    type="grow"
                    size="sm"
                    className="mr-2"
                  />
                </CardTitle>
              </CardHeader>

            </Card>



          </Col>
          
      <Col xs="12" className="col-xxl-3">
        <Card>
          <CardHeader>
            <CardTitle tag="h5" className="mb-0">
            <Spinner color="dark" type="grow" size="sm" className="mr-2" />
              <Spinner
                color="primary"
                type="grow"
                size="sm"
                className="mr-2"
              />
            </CardTitle>
          </CardHeader>


        </Card>


      </Col>


    </Row>

  </Container>
);

export default WorkoutsDetailLoading;
