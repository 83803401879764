import React from "react";
import { Link, Redirect } from 'react-router-dom';
import {  withRouter} from 'react-router-dom';
import { connect } from "react-redux";
import store from "../../redux/store/index";
import ApiLiftTracker from '../../config/lift_tracking';
import {
  API_WORKOUTS_GOAL,
  API_WORKOUTS_TRAINING_LEVEL,
  } from '../../redux/actions/API';
import axios from "axios";
import { keyConfig } from '../../redux/actions/auth';
import {
  Button,
  Row,
  Col,
  Spinner,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput,
} from 'reactstrap';

class CreateWorkout extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      quick_post: [],
      loading: false,
      id: null,
      data:null,
      workout_name: this.props.user_status.first_name + "'s Workout",
      days: 3,
      weeks: 4,
      abc: false,
      repMax: false,

      api_goal: [],
      api_goal_loading: true,
      goal: null,


      api_training_level: [],
      api_training_levelloading: true,
      training_level: null,

    };
  }


  createQuick = async (routine_data) => {
    if (!this.state.abc) {
      var i;
      for (i = 0; i < this.state.days; i++) {
        var day_name = await "Day " + (i+1)
        await ApiLiftTracker.workoutQuickCreate(routine_data.id,i+1, day_name, i+1, (success, data) => {
          if ( success) {
            console.log('success')
            this.setState({quick_post: this.state.quick_post.concat(data)})
          } else {
            console.log('fail')
          }
        });
      }
    }
    else if (this.state.abc){
      var k;
      for (k = 0; k < 2; k++) {
        await ApiLiftTracker.workoutQuickCreate(routine_data.id,k+15, k===0?"Workout A":"Workout B", k+1, (success, data) => {
          if ( success) {
            console.log('success')
            this.setState({quick_post: this.state.quick_post.concat(data)})
          } else {
            console.log('fail')
          }
        });
      }
    }
  };




  componentDidUpdate() {
    if(parseInt(Number(this.state.quick_post.length)) === parseInt(Number(this.state.days)) && this.state.loading) {
      this.setState({id:this.state.data.id})
    }
    else if(parseInt(Number(this.state.quick_post.length)) === parseInt(Number(this.state.days-1)) && this.state.loading && this.state.abc) {
      this.setState({id:this.state.data.id})
    }
  }

  onSubmit = async (e) => {
    await this.setState({loading: true})
    await e.preventDefault();
    await ApiLiftTracker.workoutCreate(
      this.state.workout_name,
      this.state.goal,
      this.state.training_level,
      this.state.weeks,
      this.state.days,
      this.props.user_status.pk,
      this.state.abc,
      this.state.repMax,
      (success, data) => {
      if ( success) {
        this.setState({data:data})
        console.log('success')
        this.createQuick(data)
      } else {
        console.log('fail')
      }
    });
  };

  fetchData = async () =>  {
    await axios.get(`${API_WORKOUTS_GOAL}`, keyConfig(store.getState)).then(res => {
      console.log(res.data)
      this.setState({
          api_goal: res.data,
          api_goal_loading: false,
      });
    }).catch((err) => {
        this.setState({
          api_goal: [],
          api_goal_loading: false,
        });
      });

    await axios.get(`${API_WORKOUTS_TRAINING_LEVEL}`, keyConfig(store.getState)).then(res => {
      console.log(res.data)
      this.setState({
          api_training_level: res.data,
          api_training_levelloading: false,
      });
    }).catch((err) => {
        this.setState({
          api_training_level: [],
          api_training_levelloading: false,
        });
      });
  }

  componentDidMount() {
    console.log(this.props.match.params.workoutID)
    this.fetchData()
  }

  onChange = (e) => {
    if(e.target.name === "name"){
      this.setState({workout_name: e.target.value})
    }
    else if(e.target.name === "goal"){
      this.setState({goal: e.target.value})
    }
    else if(e.target.name === "days"){
      this.setState({days: this.state.abc? 3 : e.target.value === null || !e.target.value? e.target.value : e.target.value > 7? 7 : e.target.value < 1? 1 :  e.target.value})
    }
    else if(e.target.name === "weeks"){
      this.setState({weeks: e.target.value === null || !e.target.value? e.target.value : e.target.value > 52? 52 : e.target.value < 2? 2 :  e.target.value})
    }
    else if(e.target.name === "training_level"){
      this.setState({training_level: e.target.value})
    }
    else if(e.target.name === "ABC"){
      if(this.state.abc) {
        this.setState({abc: false})
      }
      else if(!this.state.abc) {
        this.setState({abc: true, days:3})
      }
    }
    else if(e.target.name === "repMax"){
      if(this.state.repMax) {
        this.setState({repMax: false})
      }
      else if(!this.state.repMax) {
        this.setState({repMax: true})
      }
    }
  }

  render() {
    if (
      this.state.api_goal_loading ||
      this.state.api_training_levelloading
    ) {
      return(
        <div className="flex d-flex justify-content-center align-items-center">
        <Spinner color="primary" />
        </div>
      )
    }
    else if (this.props.match.params.workoutID && this.state.id !== null){
      return(
      <Redirect to={{pathname: "/myworkouts/"}} />
      )
    }
    else if (this.state.id !== null){
      return(
      <Redirect to={{pathname: "/workouts/detail/" + this.state.id }} />
      )
    }
    return (
      <div>
      <ModalBody>
          <Form>
            <FormGroup>
              <Label>Workout Name</Label>
              <Input
                type="text"
                name="name"
                id="name"
                required
                value={this.state.workout_name}
                pattern="[a-zA-Z]+"
                onChange={this.onChange}
                className={this.state.workout_name !== null && this.state.workout_name.length >0? "border-success mb-2 " : "mb-2" }
                />
            </FormGroup>

            <FormGroup>
              <Label>Goal</Label>

                <CustomInput
                  type="select"
                  id="goal"
                  name="goal"
                  className={this.state.goal > 0? "border-success mb-2 " : "mb-2" }
                  onChange={this.onChange}
                >
                {this.state.goal  === null?
                  <option value="null">Select a goal</option> : null}
                  {this.state.api_goal.map((a,b) =>
                  <option key={b} value={a.id}>{a.goal}</option>
                  )}
                </CustomInput>
            </FormGroup>

            <FormGroup>
            <Row>
            <Col md="6">
              <Label>Workout Days Per Week</Label>
              <Input
                type="number"
                name="days"
                id="days"
                required
                min="1"
                max="7"
                value={this.state.days}
                placeholder="Workout Days Per Week"
                onChange={this.onChange}
                className={this.state.days !== null ? "border-success mb-2 " : "mb-2" }
                />


                </Col>
                  <Col md="6">
              <Label>Number Of Weeks</Label>

              <Input
                type="number"
                name="weeks"
                id="weeks"
                required
                min="2"
                max="23"
                value={this.state.weeks}
                placeholder="Number Of Weeks"
                onChange={this.onChange}
                className={this.state.weeks !== null? "border-success mb-2 " : "mb-2" }
                />

                </Col>
                </Row>
            </FormGroup>

              <FormGroup>
                <Label>Training Level</Label>

                  <CustomInput
                    type="select"
                    id="training_level"
                    name="training_level"
                    className={this.state.training_level > 0? "border-success mb-2 " : "mb-2" }
                    onChange={this.onChange}
                  >
                  {this.state.training_level === null?
                    <option value="">Training Level</option> : null }
                    {this.state.api_training_level.map((a,b) =>
                    <option key={b} value={a.id}>{a.training_level}</option>
                    )}
                  </CustomInput>
              </FormGroup>



                      <FormGroup>
                        <CustomInput checked={this.state.abc} type="checkbox" id="ABC" name="ABC" label="Is this an A-B styled programme? (ex: Monday-A, Wednesday-B, Friday-A)"   onChange={this.onChange}/>
                      </FormGroup>

                    {/*
                      <FormGroup>
                        <CustomInput checked={this.state.repMax} type="checkbox" id="repMax" name="repMax"  label="Are exercises percentages of your one rep max?"   onChange={this.onChange}/>
                      </FormGroup>
                      */}


          </Form>
      </ModalBody>

      <ModalFooter className={this.props.match.params.categoryID? "modal-footer" : "modal-footer justify-content-between"}>

      {this.props.match.params.categoryID?
        null
        :
        <Button color="secondary" tag={Link} to="/workouts/All" className="float-left" >See all workouts</Button>
      }


        <div>
        <Button color="secondary" onClick={this.props.toggle}>Cancel</Button>{' '}


        { ( this.state.days > 0 && this.state.weeks > 0&& this.state.training_level > 0 && this.state.goal > 0 && this.state.workout_name !== null && this.state.workout_name.length >0 )?

          <Button style={{width:'110px'}} color="primary"  type="submit" onClick = {this.onSubmit}> Create</Button>

          :
          this.state.loading?

          <Button style={{width:'110px'}} className="disabled" color="primary">  <Spinner color="light" size="sm" /> </Button>

          :

          <Button style={{width:'110px'}} className="disabled" color="primary"> Create </Button>
        }


        </div>
      </ModalFooter>
      </div>

    );
  }
}

const mapStateToProps = (state) => ({
  user_status: state.auth.user,
  auth: state.auth,
  sidebar: store.sidebar,
});

export default connect(mapStateToProps, {  })(withRouter(CreateWorkout));
