import React from "react";
import { Link } from "react-router-dom";

import {
  Button,
  ButtonGroup,
  Col,
  Row,
} from "reactstrap";

import { RefreshCw } from "react-feather";
import { ArrowLeft } from "react-feather";

const Header = (props) => {
  return (
    <Row className="mb-2 mb-xl-4">
      <Col xs="auto" className="d-none d-sm-block">

      </Col>

      <Col xs="auto" className="ml-auto text-right mt-n1">


      <ButtonGroup>
        <Button color="primary" className="shadow-sm mr-1" tag={Link} to={"/profile/" + props.data.user_id_xref  + "/albums/" + props.data.album_xref}>
          <ArrowLeft className="feather" /> Back to Album
        </Button>
        <Button color="primary" className="shadow-sm mr-1" tag={Link} to={"/profile/" + props.data.user_id_xref  + "/home"}>
          <ArrowLeft className="feather" /> Back to Profile
        </Button>
        <Button color="primary" className="shadow-sm mr-1">
          <RefreshCw className="feather" />
        </Button>
        </ButtonGroup>
      </Col>
    </Row>
  );
};

export default Header;
