import React from 'react'
import AvatarEditor from 'react-avatar-editor'
import store from "../../../redux/store/index";
import { connect } from 'react-redux';
import {
  Button,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { loadUserSocial } from "../../../redux/actions/auth";
import ApiAuth from '../../../config/auth';

const index = 1

class ImageCropper extends React.Component {


  completeonboarding = async () => {
    await ApiAuth.onboardingComplete("true", (success, data) => {
      if ( success) {
        console.log('success')
      } else {
        console.log(data)
      }
    });
  };



  uploadPhoto = async (e) => {
    await ApiAuth.patchPhoto(this.editor.getImage().toDataURL(), (success, data) => {
      if ( success) {
        console.log('success')
          store.dispatch(loadUserSocial())
      } else {
        console.log('fail')
      }
    });
  };


  onClickSave = async () => {
    if (this.editor) {
      await this.uploadPhoto()
      await this.props.avatarfunction(null)
      await this.completeonboarding()
      }
    }


  setEditorRef = (editor) => this.editor = editor

  render () {
    return (
      <div className="bg-dark">
        <ModalBody className="text-center m-3">
          <p className="mb-0 text-light">
            Keep your profile fresh. Crop and adjust it to perfection!
          </p>
          <AvatarEditor
            ref={this.setEditorRef}
            image={this.props.avatarphoto}
            width={250}
            height={250}
            border={50}
            color={[255, 255, 255, 0.6]} // RGBA
            scale={1.2}
            rotate={0}
            borderRadius={200}
          />
        </ModalBody>

        <ModalFooter>
          <Button color="secondary" onClick={() => {this.props.toggle(index);  this.props.avatarfunction(null)}}>
            Close
          </Button>{" "}

          <Button
            color="primary"
            onClick={() => {this.props.toggle(index);  this.onClickSave();}}>
            Save changes
          </Button>
        </ModalFooter>

      </div>

    )
  }
}

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, {  })(ImageCropper);
