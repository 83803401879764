import React, { Component } from 'react';
import { connect } from 'react-redux';
import { login, loginSocialApple } from '../../redux/actions/auth';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faApple,
} from "@fortawesome/free-brands-svg-icons";
import {
  Button,
  Spinner,
} from "reactstrap";
import {
  API_UI_URL,
 } from '../../redux/actions/API';
import AppleSignin from 'react-apple-signin-auth';

export class SignIn extends Component {



  state = {
    loader: false,
    message: false
  };


  trigger = async (code) => {
    const result = await this.props.loginSocialApple(code, 0)
    if (result !== null && result.response) {
      this.setState({loader: false, message: true})
    }
  }

  render() {
    return(

      <AppleSignin
        /** Auth options passed to AppleID.auth.init() */

        authOptions={{
          /** Client ID - eg: 'com.example.com' */
          clientId: 'io.ironroom.applesignin',
          /** Requested scopes, seperated by spaces - eg: 'email name' */
          scope: 'email name',
          /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
          redirectURI: API_UI_URL,
          /** State string that is returned with the apple response */
          state: '',
          /** Nonce */
          nonce: 'nonce',
          /** Uses popup auth instead of redirection */
          usePopup: true,
        }} // REQUIRED
        /** General props */
        uiType="dark"
        /** className */
        className="apple-auth-btn"
        /** Removes default style tag */
        noDefaultStyle={false}
        /** Allows to change the button's children, eg: for changing the button text */
        buttonExtraChildren="Continue with Apple"
        /** Extra controlling props */
        /** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
        onSuccess={(response) => this.trigger(response.authorization.code)} // default = undefined
        /** Called upon signin error */
        onError={(error) => console.error(error)} // default = undefined
        /** Skips loading the apple script if true */
        skipScript={false} // default = undefined
        /** Apple image props */
        iconProp={{ style: { marginTop: '10px' } }} // default = undefined
        /** render function - called with all props - can be used to fully customize the UI by rendering your own component  */
        render={(props) =>
          <>
          <Button
            {...props}
            color="github"
            size="lg"
            type="submit"
            className="mr-1 btn-block flex d-flex justify-content-center align-items-center"
            icon="facebook"
            >

            {this.state.loader?
              <Spinner color="secondary" />
              :



              <>
              <FontAwesomeIcon icon={faApple} className="align-middle float-left mr-3" />{" "}
              {this.props.source === "signin" ?
                    <div>Login in using <b> Apple</b> </div>
                    :
                      this.props.source === "landing" ?
                        <div>  Continue with{" "} <b> Apple</b> </div>
                        :
                <div>  Sign Up with{" "} <b> Apple</b> </div>
                }
                </>
              }




            </Button>

            {this.state.message?<p className="text-danger">Something Went Wrong. Check If Email Already Exists</p> : null}

          </>

        }
      />
    );

  }
}

const mapStateToProps = state => {
  let errors = [];
  if (state.auth.error) {
    errors = Object.keys(state.auth.error).map(field => {
      return {field, message: state.auth.error[field]};
    });
  }
  return {
    errors,
    auth: state.auth,
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated
  };
}

export default connect(mapStateToProps, { login, loginSocialApple })(SignIn);
