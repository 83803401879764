import React from "react";

import {
  Col,
  Row,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from "reactstrap";
import CreateWorkout from "../../components/Create/CreateWorkout.js"
import { Link } from "react-router-dom";

const Header = (props) => {
  return (
    <Row className="mb-2 mb-xl-4">
    {props.path?
      <Col xs="auto" className="d-none d-sm-block ">
        <h3>{props.categoryID} Workouts</h3>
      </Col>
      :
      <Col xs="auto" className="d-none d-sm-block">
        <h3>My Custom Workouts</h3>
      </Col>
    }

      <Col xs="auto" className="ml-auto text-right mt-n1">

      <div className="d-flex flex justify-content-start align-items-center">

      {props.showDrop?
      <UncontrolledDropdown className="d-inline mr-2">
        <DropdownToggle caret color="light" className="bg-white shadow-sm text-align-start" size="lg" style={{height:'100%', width:'200px'}}>
         {props.categoryID}
        </DropdownToggle>
        <DropdownMenu left="true">

        {props.api_workouts_category_public.map((a,b) =>
        <Link to={"/workouts/" + a.category}>  <DropdownItem >  {a.category}   </DropdownItem></Link>
          )}




        </DropdownMenu>
      </UncontrolledDropdown>
      :
      null}



      <CreateWorkout source="workout_list" />
      </div>

      </Col>
    </Row>
  );
};

export default Header;
