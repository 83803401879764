import React from "react";

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Table
} from "reactstrap";


const Intro = (props) => (


  <Card className="flex-fill w-100">
    <CardHeader>

      <CardTitle tag="h5" className="mb-0">
        {props.exercise_detail.exercise}
      </CardTitle>
    </CardHeader>
    <CardBody>
      <Row noGutters>


          <img
            src={props.exercise_detail.cover_photo? props.exercise_detail.cover_photo : props.exercise_detail.default_cover_photo? props.exercise_detail.default_cover_photo : props.exercises_default_image}
            width="auto"
            height="auto"
            alt="Exercise Ramos"
            className="flex d-flex justify-content-center align-items-center display-flex"
            style={{objectFit: "contain", minWidth: "100%", midHeight: "100%", backgroundColor:'black'}}
          />



      </Row>

      <Table size="sm" className="my-2">
        <tbody>
          <tr>
            <th>Muscle Group</th>
            <td>{props.exercise_detail.muscle_group_xref}</td>
          </tr>
          <tr>
            <th>Muscle Sub Group</th>
            <td>{props.exercise_detail.muscle_sub_group_xref}</td>
          </tr>
          <tr>
            <th>Body Group</th>
            <td>{props.exercise_detail.body_group_xref}</td>
          </tr>
          <tr>
            <th>Type</th>
            <td>{props.exercise_detail.push_pull_xref}</td>
          </tr>
          <tr>
            <th>Modality</th>
            <td>{props.exercise_detail.modality_xref.modality}</td>
          </tr>
          <tr>
            <th>Level</th>
            <td>{props.exercise_detail.training_level_xref}</td>
          </tr>

        </tbody>
      </Table>


    </CardBody>
  </Card>


);

export default Intro;
