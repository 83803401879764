import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Likes from './Likes'

const ModalExample = (props) => {
  const {
    className
  } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <div>

      <span
      color="primary"
      onClick={() => toggle(toggle)}
      className="text-primary customComments pl-1"
    >
      {' ' + props.count + ' ' + props.word}

    </span>

      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>Likes</ModalHeader>
        <ModalBody>
            <Likes likes={props.likes} />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>Close</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ModalExample;
