import React from "react";
import {  Redirect } from 'react-router-dom';
import {  withRouter} from 'react-router-dom';
import { connect } from "react-redux";
import store from "../../redux/store/index";
import ApiLiftTracker from '../../config/lift_tracking';
import {
  API_EXERCISE_BODY_GROUP,
  API_EXERCISE_MUSCLE_GROUP,
  API_EXERCISE_MUSCLE_SUB_GROUP,
  API_EXERCISE_PUSH_PULL,
  API_EXERCISE_TRAINING_LEVEL,
  API_EXERCISE_MODALITY,
  API_EXERCISE_JOINT,
  API_EXERCISE_LIST_CUSTOM_PATCH,
  } from '../../redux/actions/API';
import axios from "axios";
import { keyConfig } from '../../redux/actions/auth';
import {
  Button,
  Row,
  Col,
  Spinner,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput,
} from 'reactstrap';



class PatchExercise extends React.Component {

  constructor(props) {
    super(props);
    this.state = {

      loading: false,
      id: null,
      confirm: false,

      exercise_name: null,

      api_body_group: [],
      api_body_group_loading: true,
      body_group: null,

      api_muscle_group: [],
      api_muscle_group_loading: true,
      muscle_group: null,

      api_muscle_sub_group: [],
      api_muscle_sub_group_loading: true,
      muscle_sub_group: null,

      api_push_pull: [],
      api_push_pull_loading: true,
      push_pull: null,

      api_modality: [],
      api_modality_loading: true,
      modality: null,

      api_joint: [],
      api_joint_loading: true,
      joint: null,

      api_training_level: [],
      api_training_levelloading: true,
      training_level: null,

    };
  }


  onSubmit = async (e) => {
    await this.setState({loading: true})
    await e.preventDefault();
    await ApiLiftTracker.exercisePatch(
      this.props.exerciseID,
      this.state.exercise_name,
      this.state.muscle_group,
      this.state.muscle_sub_group,
      this.state.training_level,
      this.state.body_group,
      this.state.push_pull,
      this.state.modality,
      this.state.joint,
      this.props.user_status.pk,
      (success, data) => {
      if ( success) {
        console.log('success')
        this.props.fetchDetail(this.props.exerciseID)
        this.props.toggle()
      } else {
        console.log('fail')
      }
    });
  };

  onDelete = async (e) => {
    await e.preventDefault();
    await ApiLiftTracker.exerciseDelete(this.props.exerciseID, (success, data) => {
      if ( success) {
        console.log('success')
        this.setState({id: 0})
      } else {
        console.log('fail')
      }
    });
  };

  fetchSubGroup = async () =>  {

    await axios.get(`${API_EXERCISE_MUSCLE_SUB_GROUP}?muscle_group_xref=${this.state.muscle_group}`, keyConfig(store.getState)).then(res => {
      console.log(res.data)
      this.setState({
          api_muscle_sub_group: res.data,
          api_muscle_sub_group_loading: false,
      });
    }).catch((err) => {
        this.setState({
          api_muscle_sub_group: [],
          api_muscle_sub_group_loading: false,
        });
      });

  }

  onDeleteConfirm = () => {
    this.setState({confirm: true})
  }

  fetchData = async () =>  {

    await axios.get(`${API_EXERCISE_LIST_CUSTOM_PATCH}/${this.props.exerciseID}`, keyConfig(store.getState)).then(res => {
      this.setState({
          exercise_name: res.data.exercise,
          body_group: res.data.body_group_xref,
          muscle_group: res.data.muscle_group_xref,
          muscle_sub_group: res.data.muscle_group_xref,
          push_pull: res.data.push_pull_xref,
          modality: res.data.modality_xref,
          joint: res.data.joint_xref,
          training_level: res.data.training_level_xref,
          api_detail_loading: false,
      });
        this.fetchSubGroup()
    }).catch((err) => {
        this.setState({
          api_detail: [],
          api_detail_loading: false,
        });
      });


    await axios.get(`${API_EXERCISE_BODY_GROUP}`, keyConfig(store.getState)).then(res => {
      this.setState({
          api_body_group: res.data,
          api_body_group_loading: false,
      });
    }).catch((err) => {
        this.setState({
          api_body_group: [],
          api_body_group_loading: false,
        });
      });

    await axios.get(`${API_EXERCISE_MUSCLE_GROUP}`, keyConfig(store.getState)).then(res => {
      this.setState({
          api_muscle_group: res.data,
          api_muscle_group_loading: false,
      });
    }).catch((err) => {
        this.setState({
          api_muscle_group: [],
          api_muscle_group_loading: false,
        });
      });

    await axios.get(`${API_EXERCISE_PUSH_PULL}`, keyConfig(store.getState)).then(res => {
      this.setState({
          api_push_pull: res.data,
          api_push_pull_loading: false,
      });
    }).catch((err) => {
        this.setState({
          api_push_pull: [],
          api_push_pull_loading: false,
        });
      });

    await axios.get(`${API_EXERCISE_MODALITY}`, keyConfig(store.getState)).then(res => {
      this.setState({
          api_modality: res.data,
          api_modality_loading: false,
      });
    }).catch((err) => {
        this.setState({
          api_modality: [],
          api_modality_loading: false,
        });
      });

    await axios.get(`${API_EXERCISE_TRAINING_LEVEL}`, keyConfig(store.getState)).then(res => {
      this.setState({
          api_training_level: res.data,
          api_training_levelloading: false,
      });
    }).catch((err) => {
        this.setState({
          api_training_level: [],
          api_training_levelloading: false,
        });
      });


    await axios.get(`${API_EXERCISE_JOINT}`, keyConfig(store.getState)).then(res => {
      this.setState({
          api_joint: res.data,
          api_joint_loading: false,
      });
    }).catch((err) => {
        this.setState({
          api_joint: [],
          api_joint_loading: false,
        });
      });
  }


  componentDidMount() {
    this.fetchData()
    this.fetchSubGroup()
  }


  updateMuscleGroup = async (e) =>{
    await this.setState({muscle_group: e.target.value, muscle_sub_group:null})
    await this.fetchSubGroup()
  }

  onChange = (e) => {
    if(e.target.name === "body_group"){
      this.setState({body_group: e.target.value})
    }
    else if(e.target.name === "muscle_group"){
      this.updateMuscleGroup(e)
    }
    else if(e.target.name === "muscle_sub_group"){
      this.setState({muscle_sub_group: e.target.value})
    }
    else if(e.target.name === "push_pull"){
      this.setState({push_pull: e.target.value})
    }
    else if(e.target.name === "modality"){
      this.setState({modality: e.target.value})
    }
    else if(e.target.name === "joint"){
      this.setState({joint: e.target.value})
    }
    else if(e.target.name === "training_level"){
      this.setState({training_level: e.target.value})
    }
    else if(e.target.name === "name"){
      this.setState({exercise_name: e.target.value})
    }
  }

  render() {
    if (
      this.state.api_body_group_loading ||
      this.state.api_joint_loading ||
      this.state.api_modality_loading ||
      this.state.api_push_pull_loading ||
      this.state.api_muscle_group_loading ||
      this.state.api_training_levelloading ||
      this.state.api_muscle_sub_group_loading
    ) {
      return(
        <div className="flex d-flex justify-content-center align-items-center">
        <Spinner color="primary" />
        </div>
      )
    }
    else if (this.state.id !== null){
      return(
      <Redirect to={{pathname: "/exercises/" }} />
      )
    }
    return (
      <div>
      <ModalBody>
          <Form>
            <FormGroup>
              <Label>Exercise Name</Label>
              <Input
                type="text"
                name="name"
                id="name"
                required
                placeholder="Exercise Name"
                pattern="[a-zA-Z]+"
                value={this.state.exercise_name}
                onChange={this.onChange}
                className={this.state.exercise_name !== null && this.state.exercise_name.length >0? " mb-2 " : "mb-2" }
                />
            </FormGroup>

            <FormGroup>
              <Label>Body Group</Label>

                <CustomInput
                  type="select"
                  id="body_group"
                  name="body_group"
                  value={this.state.body_group}
                  className={this.state.body_group > 0? " mb-2 " : "mb-2" }
                  onChange={this.onChange}
                >
                  {this.state.api_body_group.map((a,b) =>
                  <option value={a.id}>{a.body_group}</option>
                  )}
                </CustomInput>
            </FormGroup>

            <FormGroup>
            <Row>
            <Col md="6">
              <Label>Muscle Group</Label>

                <CustomInput
                  type="select"
                  id="muscle_group"
                  name="muscle_group"
                  value={this.state.muscle_group}
                  className={this.state.muscle_group > 0? " mb-2 " : "mb-2" }
                  onChange={this.onChange}
                >
                  {this.state.api_muscle_group.map((a,b) =>
                  <option value={a.id}>{a.muscle_group}</option>
                  )}
                </CustomInput>
                </Col>
                  <Col md="6">
              <Label>Muscle Sub Group</Label>

                <CustomInput
                  type="select"
                  id="muscle_sub_group"
                  name="muscle_sub_group"
                  value={this.state.muscle_sub_group}
                  className={this.state.muscle_sub_group > 0? " mb-2 " : "mb-2" }
                  onChange={this.onChange}
                >
                  {this.state.api_muscle_sub_group.map((a,b) =>
                  <option value={a.id}>{a.muscle_sub_group}</option>
                  )}
                </CustomInput>
                </Col>
                </Row>
            </FormGroup>

            <FormGroup>
              <Label>Push Pull</Label>

                <CustomInput
                  type="select"
                  id="push_pull"
                  name="push_pull"
                  value={this.state.push_pull}
                  className={this.state.push_pull > 0? " mb-2 " : "mb-2" }
                  onChange={this.onChange}
                >
                  {this.state.api_push_pull.map((a,b) =>
                  <option value={a.id}>{a.push_pull}</option>
                  )}
                </CustomInput>
            </FormGroup>


            <FormGroup>
              <Label>Joint Type</Label>

                <CustomInput
                  type="select"
                  id="joint"
                  name="joint"
                  value={this.state.joint}
                  className={this.state.joint > 0? " mb-2 " : "mb-2" }
                  onChange={this.onChange}
                >
                  {this.state.api_joint.map((a,b) =>
                  <option value={a.id}>{a.joint}</option>
                  )}
                </CustomInput>
            </FormGroup>



                <FormGroup>
                  <Label>Modality</Label>

                    <CustomInput
                      type="select"
                      id="modality"
                      name="modality"
                      value={this.state.modality}
                      className={this.state.modality > 0? " mb-2 " : "mb-2" }
                      onChange={this.onChange}
                    >
                      {this.state.api_modality.map((a,b) =>
                      <option value={a.id}>{a.modality}</option>
                      )}
                    </CustomInput>
                </FormGroup>






                    <FormGroup>
                      <Label>Training Level</Label>

                        <CustomInput
                          type="select"
                          id="training_level"
                          name="training_level"
                          value={this.state.training_level}
                          className={this.state.training_level > 0? " mb-2 " : "mb-2" }
                          onChange={this.onChange}
                        >
                          {this.state.api_training_level.map((a,b) =>
                          <option value={a.id}>{a.training_level}</option>
                          )}
                        </CustomInput>
                    </FormGroup>


          </Form>
      </ModalBody>

      <ModalFooter className="modal-footer justify-content-end">


        <div>
        <Button color="secondary" onClick={this.props.toggle}>Cancel</Button>{' '}


        <Button
        style={{width:'110px'}}
        className={
          this.state.loading || (
          this.state.body_group > 0 &&
          this.state.joint > 0 &&
          this.state.modality > 0&&
          this.state.push_pull > 0 &&
          this.state.muscle_group >0  &&
          this.state.training_level > 0 &&
          this.state.muscle_sub_group >0 &&
          this.state.exercise_name !== null && this.state.exercise_name.length >0 )?
          null
          :"disabled"
        }
        color="primary" onClick = {this.onSubmit}>{this.state.loading?   <Spinner color="light" size="sm" /> : "Update"}</Button>
        </div>
      </ModalFooter>
      </div>

    );
  }
}

const mapStateToProps = (state) => ({
  user_status: state.auth.user,
  auth: state.auth,
  sidebar: store.sidebar,
});

export default connect(mapStateToProps, {  })(withRouter(PatchExercise));
