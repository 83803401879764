import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import store from "../../../redux/store/index";
import moment from "moment";
import {
  Button,
  ButtonGroup,
  Card,
  CardImg,
  CardBody,
  Col,
  Modal as ModalMain,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Media,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faAngleLeft,
 } from "@fortawesome/free-solid-svg-icons";
import { deletePhoto } from '../../../redux/actions/album';
import ProfilePhoto from '../../../components/Social/ProfilePhoto/ProfilePhoto';
import Comments from '../../../components/Social/Comments/Comments';
import LikeCommentsBar from '../../../components/Social/LikeCommentsBar/LikeCommentsBar';
import LikeCommentsSummary from '../../../components/Social/LikeCommentsSummary/LikeCommentsSummary';
import { calendarStrings } from '../../../redux/actions/timeconfig';

 async function DeletePhotoAction(userid, photoID, changeflag) {
   await store.dispatch(deletePhoto(userid, photoID, "false"));
   await changeflag(2)
  }



const ModalPhoto = (props) => {


  const {
    className
  } = props;

  const [modal, setModal] = useState(false);
  const [nestedModal, setNestedModal] = useState(false);
  const [closeAll, setCloseAll] = useState(false);

  const toggle = () => {setModal(!modal); props.clear_ids()}
  const toggleNested = () => {
    setNestedModal(!nestedModal);
    setCloseAll(false);
  }
  const toggleAll = () => {
    setNestedModal(!nestedModal);
    setCloseAll(true);
    props.clear_ids();
  }

  const clear = async () => {
     await setModal(!modal);
     await props.clear_ids()
   };





  return (
    <div>
      {props.source === "profile" ?
      <CardImg
        style = {{'maxWidth': '100%', 'maxHeight': '100%', 'height': '350px', 'width': '350px', 'objectFit': 'cover', cursor:'pointer'}}
        top
        onClick={toggle}
        src={props.photo_data_orig.photo}
        alt="Card image cap" />
      :
      <CardImg
        style = {{'maxWidth': '100%', 'maxHeight': '100%', 'height': '350px', 'width': '500px', 'objectFit': 'cover', cursor:'pointer'}}
        top
        onClick={toggle}
        src={props.photo_data_orig.photo}
        alt="Card image cap" />

      }

      <ModalMain
        isOpen={modal}
        toggle={() => {clear();} }
        className={className}
        //contentClassName="custommodalstyle"
        //style={{'max-height': '95vh'}}
        size="lg"
        >

        <div  className="modal-header justify-content-between " >
          <div >
            {props.album_name + '  (' + (props.photo_index_id+1) +'/'+props.album_count + ')'}
            </div>
            <div>
            {props.time_history}
            <button className="close" onClick={toggle}>&times;</button>

            </div>
        </div>
        <ModalBody >


        <Row style={{height:'100%'}} className="m-0 p-0">
             <Col md="8" lg="8" className="m-0 p-0 img-fluid d-flex flex justify-content-center align-items-center" style={{width: '100%', height:"100%", backgroundColor:'black'}}>


             {props.photo_data.width_asp < props.photo_data.height_asp || props.photo_data.width_asp === props.photo_data.height_asp?

               <div
               style={{
                 height:  ((props.photo_data.width_asp*100)/props.photo_data.height_asp).toString() + '%',
                 width: '100%',
                 maxWidth: ((props.photo_data.width_asp*100)/props.photo_data.height_asp).toString() + '%',
                 maxHeight:'100%'
                 }}
                 className="bg-secondary d-flex flex justify-content-center align-item-center">
               <img className="flowerImg"
                 src={props.photo_data.photo}
                 alt=""
                 style={{maxHeight: '100%', width: '100%', maxWidth: '100%', height:'100%'}}
                 />
                </div>

                :

                <div
                style={{
                    width:  ((props.photo_data.height_asp*1000)/props.photo_data.width_asp).toString() + '%',
                    height: '1000%',
                    maxHeight: ((props.photo_data.height_asp*1000)/props.photo_data.width_asp).toString() + '%',
                    maxWidth:'1000%'
                  }}
                className="bg-secondary d-flex flex justify-content-center align-item-center">
                 <img className="flowerImg"
                 alt=""
                   src={props.photo_data.photo}
                   style={{maxHeight: '100%', width: '100%', maxWidth: '100%', height:'100%'}}
                   />

                 </div>


             }




                 </Col>
             <Col md="4" lg="4" className="">
              <Card>
               <Media className="mt-3 ml-2 mr-2 mb-1" >
               <div className="pr-2 float-left">

               <Link
               to={"/profile/" + props.user_data.pk + "/home"}
               style={{color:"#696969",'textDcoration':'none'}}>
                 <ProfilePhoto
                   avatar_image={props.user_data.avatar_image}
                   avatar_color = {props.user_data.avatar_color}
                   avatar_letter = {props.user_data.avatar_letter}
                   avatar_size={"36px"}
                   letter_size={"18px"}
                   />

                   </Link>

               </div>
               <Media body>
                 <p className="text-muted m-0 p-0">
                 <Link
                 to={"/profile/" + props.user_data.pk + "/home"}
                 style={{color:"#696969",'textDcoration':'none'}}>

                   <strong>{props.user_data.firstname_lastname}</strong>
                 </Link>


                 </p>
                 <small className="m-0 p-0 text-muted">{props.time_history}
                 </small>

               </Media>




                </Media>

                <div className="mt-3 ml-2 mr-2 mb-1" >

                <LikeCommentsSummary
                x={props.photo_data}
                handlerOpenCommentWindow = {props.handlerOpenCommentWindow}
                flag_like_spinner_value = {props.flag_like_spinner_value}
                source = {"photo"}
                />
                <LikeCommentsBar
                  x={props.photo_data}
                  handlerOpenCommentWindow = {props.handlerOpenCommentWindow}
                  callbackPageReload = {props.callbackPageReload}
                  index = {""}
                  source = {"photo"}
                  source2 = {"photo_modal"}
                  you_liked = {props.photo_you_liked}
                  flag_like_spinner_value = {props.flag_like_spinner_value}
                  flag_like_spinner_handler = {props.flag_like_spinner_handler}
                  handlerLikeChangePhotosReload = {props.handlerLikeChangePhotosReload}
                  />

                  <Comments
                    x={props.photo_data}
                    callbackPageReload = {props.callbackPageReload}
                    source = {"photo"}
                    photo_id={props.photo_id}
                    index = {""}
                    flag_comment_spinner_value = {props.flag_comment_spinner_value}
                    flag_comment_spinner_handler = {props.flag_comment_spinner_handler}
                    handlerLikeChangePhotosReload = {props.handlerLikeChangePhotosReload}
                    />



                    </div>

              </Card>



             </Col>

        </Row>





          <ModalMain isOpen={nestedModal} toggle={toggleNested} onClosed={closeAll ? toggle : undefined}>
            <ModalHeader  className="pt-2 pb-2" style={{'background-color':"#dc3545"}}><span style={{color:'#fff'}}>Delete Photo</span></ModalHeader>
            <ModalBody><p className="mb-0">Are you sure you want to delete this photo? This cannot be undone.</p></ModalBody>
            <ModalFooter>
              <Button color="secondary" outline onClick={toggleNested}>Cancel</Button>{" "}
              <Button color="danger" onClick={() => {toggleAll(); props.deletePhoto(props.photo_id)}}> Delete Photo </Button>
            </ModalFooter>
          </ModalMain>

        </ModalBody>
        <ModalFooter className="modal-footer justify-content-between">
          <div>
            <Button className="mr-1" color="primary" outline onClick={() => {toggle(); props.modalclose()}}>Close</Button>
            {props.source === "album" || props.user_data.pk === props.user_status.pk?
            <Button color="danger" onClick={toggleNested}>Delete This Photo</Button>
              :
              null
            }
          </div>

        <ButtonGroup>


                    <Button
                    color="primary"
                    className="ml-1 float-right"
                    onClick={() => props.photo_change(-1)}
                    >
                      <FontAwesomeIcon icon={faAngleLeft} /> Previous
                    </Button>




            <Button
            color="primary float-right"
            className="ml-1"
            onClick={() => props.photo_change(1)}
            >
            Next  <FontAwesomeIcon icon={faAngleRight} />
            </Button>



          </ButtonGroup>




        </ModalFooter>
      </ModalMain>
    </div>
  );
}

class PhotoView extends React.Component {





  handlerPhotoChange = (val) => {
    if (this.state.photo_index_id_update + val < 0) {
      this.setState({
        photo_index_id_update: this.state.photo_count-1,
      })
    }
    else if (this.state.photo_index_id_update + val > this.state.photo_count-1) {
      this.setState({
        photo_index_id_update: 0,
      })
    }
    else {
      this.setState({
        photo_index_id_update: this.state.photo_index_id_update + val,
      })
    }
  }



  handlerDeletePhoto = (val) => {
    DeletePhotoAction(
      this.props.user_status.pk,
      val,
      this.props.change_flag
    );
  }


  handlerLikeChangePhotosReload = async (val) => {

    this.setState({
      photo_data: this.props.album_data[this.state.photo_index_id_update],
      flag_like_change_photo_reload_value:val
    })
  }


  handlerModalClose = (val) => {
    this.setState({
      photo_index_id_update: this.props.photo_index_id,
      photo_display_url: this.props.album_data[this.props.photo_index_id].photo,
      photo_id: this.props.album_data[this.props.photo_index_id].id
    })
  }


  constructor(props) {
    super(props);
    this.state = {
      photo_data: this.props.photo_data,
      photo_data_orig: this.props.photo_data,
      photo_index_id: this.props.photo_index_id,
      photo_index_id_update: this.props.photo_index_id,
      photo_display_url: this.props.album_data[this.props.photo_index_id].photo,
      photo_id: this.props.album_data[this.props.photo_index_id].id,
      photo_you_liked: this.props.album_data[this.props.photo_index_id].you_liked,
      photo_count: this.props.album_count,
      scroll_right_count: this.props.scroll_right_count,
      scroll_left_count: this.props.scroll_left_count,
      myValue: '',
      likeSpin: 0,
      flag_loader: 0,
      flag_like_change_photo_reload_value: 0,
    };
  }


  componentDidMount() {

  }


  componentDidUpdate() {
    if (this.state.photo_index_id !== this.state.photo_index_id_update ) {
      this.setState({
        photo_index_id: this.state.photo_index_id_update,
        photo_display_url:this.props.album_data[this.state.photo_index_id_update].photo,
        photo_id: this.props.album_data[this.state.photo_index_id_update].id,
        photo_you_liked: this.props.album_data[this.state.photo_index_id_update].you_liked,
        photo_data: this.props.album_data[this.state.photo_index_id_update],
      })
    }
    else if (this.state.photo_count !== this.props.album_count ) {
      this.setState({
        photo_data: this.props.photo_data,
        photo_data_orig: this.props.photo_data,
        photo_index_id: this.props.photo_index_id,
        photo_index_id_update: this.props.photo_index_id,
        photo_display_url: this.props.album_data[this.props.photo_index_id].photo,
        photo_id: this.props.album_data[this.props.photo_index_id].id,
        photo_you_liked: this.props.album_data[this.props.photo_index_id].you_liked,
        photo_count: this.props.album_count,
        scroll_right_count: this.props.scroll_right_count,
        scroll_left_count: this.props.scroll_left_count,
        myValue: '',
        likeSpin: 0,
        flag_loader: 0,
        flag_like_change_photo_reload_value: 0,
      })
    }
    else if (this.state.scroll_right_count !== this.props.scroll_right_count) {
      this.setState({
        scroll_right_count: this.props.scroll_right_count
      })
        this.handlerPhotoChange(1)
    }
    else if (this.state.scroll_left_count !== this.props.scroll_left_count) {
      this.setState({
        scroll_left_count: this.props.scroll_left_count
      })
        this.handlerPhotoChange(-1)
    }
    else if (this.props.flag_like_spinner_value === 2 && this.state.photo_data !== this.props.photo_data){
      this.setState({
        photo_index_id: this.state.photo_index_id_update,
        photo_display_url:this.props.album_data[this.state.photo_index_id_update].photo,
        photo_id: this.props.album_data[this.state.photo_index_id_update].id,
        photo_you_liked: this.props.album_data[this.state.photo_index_id_update].you_liked,
        photo_count: this.props.album_count,
        flag_like_change_photo_reload_value: 0,
        photo_data: this.props.album_data[this.state.photo_index_id_update],
      })
    }
  }


  onChange = async (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      comment_value: e.target.value,
      myValue: e.target.value
    });
  }


  onSend = async (e) => {
    e.preventDefault();

  };




  openCommentWindow = () => {
    if (this.state.openComment === 0) {
      this.setState({openComment: 1})
    }
  }


render() {
  return (
  <Card>
    <CardBody className="">
      <ModalPhoto
        photo_data = {this.state.photo_data}
        photo_data_orig = {this.state.photo_data_orig}
        photo_data_galary = {[this.state.photo_data].map(({ photo, width, height }) => {return{src: photo, width: width, height: height }})}
        photo_index_id = {this.state.photo_index_id}
        photo_id = {this.state.photo_id}
        photo_change = {this.handlerPhotoChange}
        photo_display_url = {this.state.photo_display_url}
        modalclose = {this.handlerModalClose}
        album_count = {this.props.album_count}
        source = {this.props.source}
        deletePhoto = {this.handlerDeletePhoto}
        album_name = {this.props.album_name}
        time_history = {moment(this.props.time_history).calendar(null,calendarStrings)}
        clear_ids = {this.handlerModalClose}
        user_data = {this.props.user_data}
        photo_you_liked = {this.state.photo_you_liked}
        likeSpin = {this.state.likeSpin}
        callbackPageReload = {this.props.change_flag}
        flag_like_spinner_value = {this.props.flag_like_spinner_value}
        flag_like_spinner_handler = {this.props.flag_like_spinner_handler}
        flag_comment_spinner_value = {this.props.flag_comment_spinner_value}
        flag_comment_spinner_handler = {this.props.flag_comment_spinner_handler}
        handlerLikeChangePhotosReload = {this.handlerLikeChangePhotosReload}
        handlerOpenCommentWindow = {this.openCommentWindow}
        user_status = {this.props.user_status}
        />
    </CardBody>
  </Card>
  );

  }
}

const mapStateToProps = (state) => ({
  user_status: state.auth.user,
  theme: state.theme.currentTheme
});

export default connect(mapStateToProps, {  })(PhotoView);
