import React from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from 'reactstrap';
import UploadProfilePicture from '../../../components/Social/UploadProfilePhoto/Index';
import { connect } from 'react-redux';
import store from '../../../redux/store/index';
import ProfilePhoto from '../../../components/Social/ProfilePhoto/ProfilePhoto';
import { loadUser } from '../../../redux/actions/auth';
import { loadUserSocial } from '../../../redux/actions/auth';
import Api from '../../../config/auth';
import Location from '../../../components/Social/UserInfo/Location';

class Public extends React.Component {

    componentDidMount() {
      if (this.props.user_status === null) {
        store.dispatch(loadUser());
      }
      console.log(this.props.user_status.heading)
    }

    constructor(props) {
      super(props);
      this.state = {
        photo_change_flag: 0,
        form_change_flag: 0,
        firstName: this.props.user_status.first_name,
        lastName: this.props.user_status.last_name,

        saved1: null,
        saved2: null,
        saved3: null,
        loading: 0,


        heading: this.props.user_status.heading,
      };
    }

  componentDidUpdate() {
    if (this.state.loading === 1 &&
      this.state.saved1 !== null &&
      this.state.saved2 !== null &&
      this.state.saved3 !== null) {
      this.setState({ loading: 0 });
    }
  };

  onSubmit = async (e) => {
      await this.setState({ loading: 1 });
      await Api.patchUserFNLN(this.state.firstName, this.state.lastName, (success, data) => {
        if (success) {
          store.dispatch(loadUserSocial());
          this.setState({ saved1: 1 });
        } else {
          console.log('fail');
        }
      });
      await Api.patchUserCity(this.state.location, (success, data) => {
        if ( success) {
          console.log('m')
          store.dispatch(loadUserSocial());
          this.setState({saved2: 1})
        } else {

        }
      });
      await Api.updateProfileHeading(this.state.heading, (success, data) => {
        if (success) {
          store.dispatch(loadUserSocial());
          this.setState({ saved3: 1 });
        } else {
          console.log('fail');
        }
      });
    };

    handlerPhotoChange = (val) => {
     this.setState({
       photo_change_flag: val
     })
   }

    handlerFormChange = (val) => {
     this.setState({
       form_change_flag: val
     })
   }

   handlerLocationChange = (val) => {
     this.setState({
       location: val
     })
   }

    onChange = (e) => {
     this.setState({ [e.target.name]: e.target.value });
   }


   onChangeHEading = (e) => {
     this.setState({ [e.target.name]: e.target.value });
     if ( e.target.name === "heading") {
       this.setState({heading:e.target.value})
     }
   }


    render() {

      return(
      <Card>
        <CardHeader>
          <CardTitle tag="h5" className="mb-0">
            Public info
          </CardTitle>
        </CardHeader>
        <CardBody>

            <Row>
              <Col md="8">

                    <Form >
                <Row form>
                  <Col md={6}>
                    <FormGroup>
                      <Label>First Name</Label>
                      <Input
                        type="text"
                        name="firstName"
                        id="firstName"
                        placeholder= {this.state.firstName}
                        onChange={this.onChange}
                        required
                        pattern="[a-zA-Z]+"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>Last Name</Label>
                      <Input
                        type="text"
                        name="lastName"
                        id="lastName"
                        placeholder= {this.state.lastName}
                        onChange={this.onChange}
                        required
                        pattern="[a-zA-Z]+"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                        </Form>

                        <Location location_change={this.handlerLocationChange}/>

                        <FormGroup>
                          <Label for="inputBio">{this.props.title? this.props.title : "Heading" }</Label>
                          <Input
                            type="textarea"
                            rows="2"
                            id="inputBio"
                            name="heading"
                            onChange={this.onChangeHEading}
                            bsSize={this.props.size}
                            required
                            placeholder={this.state.heading === null ||  this.state.heading === ''? "Say something that will grab attention" : this.state.heading}
                          />
                        </FormGroup>
              </Col>
              <Col md="4">

              <div className="text-center">
                <ProfilePhoto
                  avatar_image={this.props.user_status.avatar_image}
                  avatar_letter = {this.props.user_status.avatar_letter}
                  avatar_color = {this.props.user_status.avatar_color}
                  avatar_size={"128px"}
                  letter_size={"64px"}
                  />
                <div className="mt-2">
                  <UploadProfilePicture
                    photo_change_flag = {this.handlerPhotoChange}
                    profile_picture_status = {this.props.user_status.avatar_image}
                    userid={this.props.user_status.pk}
                    source = {"settings"}
                  />
                </div>
                <small>
                  For best results, use an image at least 128px by 128px in .jpg
                  format
                </small>
              </div>


              </Col>
            </Row>



            <Button color={this.state.saved1 ===null && this.state.saved2 ===null? 'primary' : 'success'} onClick={this.onSubmit} style={{width: "120px"}}>
            {this.state.loading === 1 ?
              <Spinner color="info" size="sm" />
              :
              (this.state.saved1 !==null && this.state.saved2 !==null) ?
              "Saved"
              :
              "Save changes"
            }
               </Button>

        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  user_status: state.auth.user,
  theme: state.theme.currentTheme
});

export default connect(mapStateToProps, {  })(Public);
