import async from "../components/Async";


import {
  faAnchor,
  faCheckSquare,
  faHome,
  faCamera,
  faDumbbell,
  faDotCircle,
  faUserPlus
} from "@fortawesome/free-solid-svg-icons";


import {
  Bell as Bellicon,
  BookOpen as BookOpenIcon,
  Calendar as CalendarIcon,
  CheckSquare as CheckSquareIcon,
  Grid as GridIcon,
  Heart as HeartIcon,
  Layout as LayoutIcon,
  List as ListIcon,
  MapPin as MapPinIcon,
  Monitor as MonitorIcon,
  PieChart as PieChartIcon,
  Users as UsersIcon,
} from "react-feather";

import { API_WORKOUTS_CATEGORY_PUBLIC } from '../redux/actions/API'

// Landing
import Landing from "../pages/landing/Landing";
import Legal from "../pages/landing/Terms/Legal";
import HelpCenter from "../pages/landing/HelpCenter/HelpCenter";
import Privacy from "../pages/landing/Privacy/Privacy";
import Copyright from "../pages/landing/Copyright/Copyright";
import About from "../pages/landing/About/About";
// Albums
import AlbumList from "../pages/albums/Album/Index";
import AlbumDetail from "../pages/albums/AlbumDetail/AlbumDetail";
import PhotoDetail from "../pages/albums/PhotoDetail/PhotoDetail";

// Lift Tracking
import LiftTracking from "../pages/lift_tracking/LiftTracking";

// Onboarding
import Step1 from "../pages/onboarding/Step1/Index";
import Step2 from "../pages/onboarding/Step2/Index";
import Step3 from "../pages/onboarding/Step3/Index";

// Athletes
import Athletes from "../pages/athletes/Athletes";

// Body Tracking
import BodyWeightTracking from "../pages/body_weight_tracking/BodyWeightTracking";

// Settings
import SettingsProfile from "../pages/settings/Profile/Index";
import SettingsGeneral from "../pages/settings/General/Index";
import SettingsPassword from "../pages/settings/Password/Index";
import SettingsPrivacy from "../pages/settings/Privacy/Index";
import SettingsDelete from "../pages/settings/Delete/Index";
import SettingsSubscriptions from "../pages/settings/Subscriptions/Index";

// Profile
import Profile from "../pages/profile/Profile";

// Workouts
import WorkoutsDetail from "../pages/workouts/WorkoutsDetail";
import WorkoutsList from "../pages/workouts/WorkoutsList";

// Exercise
import ExerciseDetail from "../pages/exercises/detail/index";
import ExerciseList from "../pages/exercises/ExerciseList";

// Auth
import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
import ResetPassword from "../pages/auth/ResetPassword";
import ForgotPassword from "../pages/auth/ForgotPassword";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";
import Verify from "../pages/auth/Verify";

//Subscribe Mobile
import SubscribeOpen from "../pages/pages/SubscribeOpen";
import SubscribeOpenSuccess from "../pages/pages/SubscribeOpenSuccess";
import SubscribeOpenCancel from "../pages/pages/SubscribeOpenCancel";

// Layouts
import Boxed from "../pages/layouts/Boxed";
import SidebarCollapsed from "../pages/layouts/SidebarCollapsed";
import SidebarSticky from "../pages/layouts/SidebarSticky";
import ThemeClassic from "../pages/layouts/ThemeClassic";
import ThemeCorporate from "../pages/layouts/ThemeCorporate";
import ThemeModern from "../pages/layouts/ThemeModern";

// Misc
import Blank from "../pages/misc/Blank";

// UI Elements
import Alerts from "../pages/ui-elements/Alerts";
import Buttons from "../pages/ui-elements/Buttons";
import Cards from "../pages/ui-elements/Cards";
import Carousel from "../pages/ui-elements/Carousel";
import EmbedVideo from "../pages/ui-elements/EmbedVideo";
import General from "../pages/ui-elements/General";
import Grid from "../pages/ui-elements/Grid";
import Modals from "../pages/ui-elements/Modals";
import Tabs from "../pages/ui-elements/Tabs";
import Typography from "../pages/ui-elements/Typography";

// Notifications
import Notifications from "../pages/notifications/Notifications";



// Pages
//import Profile from "../pages/pages/Profile";
import Pricing from "../pages/pages/Pricing";
import Subscribe from "../pages/pages/Subscribe";
import Help from "../pages/help/Index";
import Thankyou from "../pages/pages/Thankyou";


// Documentation
import Introduction from "../pages/docs/Introduction";
import GettingStarted from "../pages/docs/GettingStarted";
import EnvironmentVariables from "../pages/docs/EnvironmentVariables";
import Deployment from "../pages/docs/Deployment";
import StateManagement from "../pages/docs/StateManagement";
import Plugins from "../pages/docs/Plugins";
import Changelog from "../pages/docs/Changelog";

// Dashboards
import WorkoutHistory from "../pages/dashboards/WorkoutHistory/index";
import WorkoutSessions from "../pages/dashboards/WorkoutSessions/index";
import Analytics from "../pages/dashboards/Analytics/index";


const Default = async(() => import("../pages/dashboards/Default"));
const Timeline = async(() => import("../pages/dashboards/Social"));

// Forms
const Layouts = async(() => import("../pages/forms/Layouts"));
const BasicInputs = async(() => import("../pages/forms/BasicInputs"));
const AdvancedInputs = async(() => import("../pages/forms/AdvancedInputs"));
const InputGroups = async(() => import("../pages/forms/InputGroups"));
const Editors = async(() => import("../pages/forms/Editors"));
const Validation = async(() => import("../pages/forms/Validation"));
const Wizard = async(() => import("../pages/forms/Wizard"));

// Tables
const BootstrapTables = async(() => import("../pages/tables/Bootstrap"));
const PaginationTables = async(() => import("../pages/tables/Pagination"));
const RowSelectionTables = async(() => import("../pages/tables/RowSelection"));
const ExportCsvTables = async(() => import("../pages/tables/ExportCsv"));
const ExpandableRowsTables = async(() =>
  import("../pages/tables/ExpandableRows")
);

// Charts
const Chartjs = async(() => import("../pages/charts/Chartjs"));
const ApexCharts = async(() => import("../pages/charts/ApexCharts"));

// Icons
const FontAwesome = async(() => import("../pages/icons/FontAwesome"));
const Feather = async(() => import("../pages/icons/Feather"));

// Calendar
const Calendar = async(() => import("../pages/calendar/Calendar"));

// Maps
const VectorMaps = async(() => import("../pages/maps/VectorMaps"));
const GoogleMaps = async(() => import("../pages/maps/GoogleMaps"));

// Routes
const workout_category_list =[]

async function apiGetWorkoutCategory () {
   try {
     const resp = await fetch(`${API_WORKOUTS_CATEGORY_PUBLIC}`)

     const workoutCateogryJSON = await resp.json()

     for (let i = 0; i < workoutCateogryJSON.length; i++) {
       var newElement = {};
       newElement['path'] = "/workouts/" + workoutCateogryJSON[i].category;
       newElement['name'] = workoutCateogryJSON[i].category;
       newElement['component'] = "WorkoutsList";
       workout_category_list.push(newElement);
     }

     return workout_category_list

   } catch (err) {
        console.log(err)
     }
}

apiGetWorkoutCategory();


// Routes
const landingRoutes = {
  path: "/",
  name: "Landing Page",
  component: Landing,
  children: null
};



const pageRoutes = {
  path: "/pages",
  name: "Pages",
  icon: LayoutIcon,
  children: [
    {
      path: "/pages/pricing",
      name: "Pricing",
      component: Pricing
    },
    {
      path: "/pages/help",
      name: "Help",
      component: Help
    },
    {
      path: "/pages/Subscribe",
      name: "Subscribe",
      component: Subscribe
    },
    {
      path: "/pages/thankyou/:successID",
      name: "Thankyou",
      component: Thankyou
    },
  ]
};

const authRoutes = {
  path: "/auth",
  name: "Auth",
  icon: UsersIcon,
  badgeColor: "secondary",
  badgeText: "Special",
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn
    },
    {
      path: "/auth/sign-up",
      name: "Sign Up",
      component: SignUp
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ForgotPassword
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500
    }
  ]
};



const authPwdReset = {
  path: "/api/v1/rest-auth/password/reset/confirm/:uID/:tokenID/",
  name: "PwdReset",
  badgeColor: "secondary",
  badgeText: "Special",
  component: ResetPassword
};


const layoutRoutes = {
  path: "/layouts",
  name: "Layouts",
  icon: MonitorIcon,
  children: [
    {
      path: "/layouts/sidebar-sticky",
      name: "Sticky Sidebar",
      component: SidebarSticky
    },
    {
      path: "/layouts/sidebar-collapsed",
      name: "Sidebar Collapsed",
      component: SidebarCollapsed
    },
    {
      path: "/layouts/boxed",
      name: "Boxed Layout",
      component: Boxed
    },
    {
      path: "/layouts/theme-classic",
      name: "Classic Theme",
      component: ThemeClassic
    },
    {
      path: "/layouts/theme-corporate",
      name: "Corporate Theme",
      component: ThemeCorporate,
      badgeColor: "primary",
      badgeText: "New"
    },
    {
      path: "/layouts/theme-modern",
      name: "Modern Theme",
      component: ThemeModern,
      badgeColor: "primary",
      badgeText: "New"
    }
  ]
};

const documentationRoutes = {
  path: "/docs",
  name: "Documentation",
  icon: BookOpenIcon,
  children: [
    {
      path: "/docs/introduction",
      name: "Introduction",
      component: Introduction
    },
    {
      path: "/docs/getting-started",
      name: "Getting Started",
      component: GettingStarted
    },
    {
      path: "/docs/environment-variables",
      name: "Environment Variables",
      component: EnvironmentVariables
    },
    {
      path: "/docs/deployment",
      name: "Deployment",
      component: Deployment
    },
    {
      path: "/docs/state-management",
      name: "State Management",
      component: StateManagement
    },
    {
      path: "/docs/plugins",
      name: "Plugins",
      component: Plugins
    },
    {
      path: "/docs/changelog",
      name: "Changelog",
      component: Changelog
    }
  ]
};

const uiRoutes = {
  path: "/ui",
  name: "UI Elements",
  header: "Tools & Components",
  icon: GridIcon,
  children: [
    {
      path: "/ui/alerts",
      name: "Alerts",
      component: Alerts
    },
    {
      path: "/ui/buttons",
      name: "Buttons",
      component: Buttons
    },
    {
      path: "/ui/cards",
      name: "Cards",
      component: Cards
    },
    {
      path: "/ui/carousel",
      name: "Carousel",
      component: Carousel
    },
    {
      path: "/ui/embed-video",
      name: "Embed Video",
      component: EmbedVideo
    },
    {
      path: "/ui/general",
      name: "General",
      component: General,
      badgeColor: "info",
      badgeText: "10+"
    },
    {
      path: "/ui/grid",
      name: "Grid",
      component: Grid
    },
    {
      path: "/ui/modals",
      name: "Modals",
      component: Modals
    },
    {
      path: "/ui/tabs",
      name: "Tabs",
      component: Tabs
    },
    {
      path: "/ui/typography",
      name: "Typography",
      component: Typography
    }
  ]
};

const iconRoutes = {
  path: "/icons",
  name: "Icons",
  icon: HeartIcon,
  badgeColor: "info",
  badgeText: "1500+",
  children: [
    {
      path: "/icons/feather",
      name: "Feather",
      component: Feather
    },
    {
      path: "/icons/font-awesome",
      name: "Font Awesome",
      component: FontAwesome
    }
  ]
};

const formRoutes = {
  path: "/forms",
  name: "Forms",
  icon: CheckSquareIcon,
  children: [
    {
      path: "/forms/layouts",
      name: "Layouts",
      component: Layouts
    },
    {
      path: "/forms/basic-inputs",
      name: "Basic Inputs",
      component: BasicInputs
    },
    {
      path: "/forms/input-groups",
      name: "Input Groups",
      component: InputGroups
    }
  ]
};

const tableRoutes = {
  path: "/tables",
  name: "Tables",
  icon: ListIcon,
  component: BootstrapTables,
  children: null
};

const formPluginsRoutes = {
  path: "/form-plugins",
  name: "Form Plugins",
  icon: CheckSquareIcon,
  header: "Plugin & Addons",
  children: [
    {
      path: "/form-plugins/advanced-inputs",
      name: "Advanced Inputs",
      component: AdvancedInputs
    },
    {
      path: "/form-plugins/editors",
      name: "Editors",
      component: Editors
    },
    {
      path: "/form-plugins/validation",
      name: "Validation",
      component: Validation
    },
    {
      path: "/form-plugins/wizard",
      name: "Wizard",
      component: Wizard
    }
  ]
};

const advancedTablesRoutes = {
  path: "/advanced-tables",
  name: "Advanced Tables",
  icon: ListIcon,
  children: [
    {
      path: "/advanced-tables/pagination",
      name: "Pagination",
      component: PaginationTables
    },
    {
      path: "/advanced-tables/row-selection",
      name: "Row Selection",
      component: RowSelectionTables
    },
    {
      path: "/advanced-tables/expandable-rows",
      name: "Expandable Rows",
      component: ExpandableRowsTables
    },
    {
      path: "/advanced-tables/export-csv",
      name: "Export CSV",
      component: ExportCsvTables
    }
  ]
};

const chartRoutes = {
  path: "/charts",
  name: "Charts",
  icon: PieChartIcon,
  badgeColor: "primary",
  badgeText: "New",
  children: [
    {
      path: "/charts/chartjs",
      name: "Chart.js",
      component: Chartjs
    },
    {
      path: "/charts/apexcharts",
      name: "ApexCharts",
      component: ApexCharts,
      badgeColor: "primary",
      badgeText: "New"
    }
  ]
};

const notificationsRoutes = {
  path: "/notifications",
  name: "Notifications",
  icon: Bellicon,
  component: Notifications,
  children: null
};

const mapRoutes = {
  path: "/maps",
  name: "Maps",
  icon: MapPinIcon,
  children: [
    {
      path: "/maps/google-maps",
      name: "Google Maps",
      component: GoogleMaps
    },
    {
      path: "/maps/vector-maps",
      name: "Vector Maps",
      component: VectorMaps
    }
  ]
};

const calendarRoutes = {
  path: "/calendar",
  name: "Calendar",
  icon: CalendarIcon,
  component: Calendar,
  children: null
};





// This route is not visisble in the sidebar
const privateRoutes = {
  path: "/private",
  name: "Private",
  children: [
    {
      path: "/private/blank",
      name: "Blank Page",
      component: Blank
    }
  ]
};


const workoutDetailRoutes = {
  path: "/workouts/detail/:workoutID",
  name: "Workouts",
  icon: faDumbbell,
  component: WorkoutsDetail,
};

const workoutCategoryDetail = {
  path: "/workouts/:categoryID",
  name: "Workouts",
  icon: faDumbbell,
  component: WorkoutsList,
};


const workoutCategory = {
  path: "/workouts/",
  name: "Workouts",
  icon: faDumbbell,
  children: workout_category_list
};


const exerciseDetailRoutes = {
  path: "/exercises/detail/:exerciseID",
  name: "Exercises",
  icon: faAnchor,
  component: ExerciseDetail,
};

const exerciseRoutes = {
  path: "/exercises",
  name: "My Exercises",
  icon: faAnchor,
  component: ExerciseList,
  children: null
};



const myworkoutRoutes = {
  path: "/myworkouts/",
  name: "My Workouts",
  icon: faDumbbell,
  component: WorkoutsList,
};



const athletesRoutes = {
  path: "/athletes",
  name: "Athletes",
  icon: faUserPlus,
  component: Athletes,
  children: null
};



const athletesSearchRoutes = {
  path: "/athletes/search/:searchVALUE",
  name: "AthletesSearch",
  icon: faUserPlus,
  component: Athletes,
};



// This route is not visisble in the sidebar
const profileRoutes = {
  path: "/profile",
  name: "Profile",
  children: [
    {
      path: "/profile/:memberID/:pathID/",
      name: "Member",
      component: Profile
    },
    {
      path: "/profile/:memberID/albums/:albumID/",
      name: "Member",
      component: Profile
    },
    {
      path: "/profile/:memberID/albums/:albumID/:photoID",
      name: "Member",
      component: Profile
    },
    {
      path: "/profile/:memberID/workout/:workoutID/",
      name: "Member",
      component: Profile
    },
    {
      path: "/profile/:memberID/albums_alt/:timelinealbumID/",
      name: "Member",
      component: Profile
    },
    {
      path: "/profile/:memberID/photos_alt/:timelinephotoID/",
      name: "Member",
      component: Profile
    }
  ]
};


const settingRoutes = {
  path: "/settings",
  name: "Settings",
  children: [
    {
      path: "/settings/profile",
      name: "Account",
      component: SettingsProfile
    },
    {
      path: "/settings/general",
      name: "Account",
      component: SettingsGeneral
    },
    {
      path: "/settings/password",
      name: "Password",
      component: SettingsPassword
    },
    {
      path: "/settings/privacy",
      name: "Privacy",
      component: SettingsPrivacy
    },
    {
      path: "/settings/delete",
      name: "Delete",
      component: SettingsDelete
    },
    {
      path: "/settings/subscriptions",
      name: "Subscriptions",
      component: SettingsSubscriptions
    }
  ]
};

/*
const bodyweight_trackingRoutes = {
  path: "/weight_tracking",
  name: "Record Weight",
  icon: faDotCircle,
  component: BodyWeightTracking,
  children: null
};
*/

const lift_trackingRoutes = {
  path: "/lift_tracking",
  name: "Record Lifts",
  header: "Tracking",
  icon: faDotCircle,
  component: LiftTracking,
  children: null
};



const onboardRoutes = {
  path: "/onboarding",
  name: "Onboarding",
  icon: Bellicon,
  children: [
    {
      path: "/onboarding/step1",
      name: "step1",
      component: Step1
    },
    {
      path: "/onboarding/step2",
      name: "step2",
      component: Step2
    },
    {
      path: "/onboarding/step3",
      name: "step3",
      component: Step3
    }
  ]
};


const verifyRoutes = {
  path: "/verify-email/:verifyID",
  name: "verify",
  component: Verify,
  icon: Bellicon,
  children: null
};


const subscribeRoutes = {
  path: "/subscribeopen/:tokenID/:typeID/:freeTrial/",
  name: "verify",
  component: SubscribeOpen,
  icon: Bellicon,
  children: null
};



const subscribeResultsRoutes = {
  path: "/subscribe",
  name: "Onboarding",
  icon: Bellicon,
  children: [
    {
      path: "/subscribe/success/:checkoutID/:tokenID",
      name: "success",
      component: SubscribeOpenSuccess
    },
    {
      path: "/subscribe/cancel/:checkoutID/:tokenID",
      name: "cancel",
      component: SubscribeOpenCancel
    }
  ]
};




const albumbRoutes = {
  path: "/albums",
  name: "My Albums",
  icon: faCamera,
  component: AlbumList,
};

const albumDetailRoutes = {
  path: "/albums/:albumID",
  name: "AlbumDetail",
  icon: faCamera,
  component: AlbumDetail,
};


const albumbPhotoDetailRoutes = {
  path: "/albums/:albumID/:photoID",
  name: "PhotoDetail",
  icon: UsersIcon,
  component: PhotoDetail,
};


const timelineRoutes = {
  path: "/timeline",
  name: "Timeline",
  header: "Discover",
  icon: faHome,
  component: Timeline,
  children: null
};



const dashboardRoutes = {
  path: "/myprogression",
  name: "My Progress",
  header: "My Overview",
  icon: faCheckSquare,
  badgeColor: "secondary",
  badgeText: "Special",
  children: [
    {
      path: "/myprogression/analytics",
      name: "Training Analytics",
      component: Analytics,
    },
    {
      path: "/myprogression/bodyweight",
      name: "Body Weight Analytics",
      component: BodyWeightTracking,
    },
    {
      path: "/myprogression/dashboard",
      name: "Workout Dashboard",
      component: Default,
    },
    {
      path: "/myprogression/workoutsessions",
      name: "Workout Sessions",
      component: WorkoutSessions
    },
    {
      path: "/myprogression/workouthistory",
      name: "Workout Programs",
      component: WorkoutHistory
    },
    {
      path: "/myprogression/clandar",
      name: "Historic Calandar",
      component: Calendar,
    }
  ]
};




const legalRoutes = {
  path: "/legal",
  name: "Legal",
  component: Legal,
  children: null
};


const helpCenterRoutes = {
  path: "/helpcenter",
  name: "HelpCenter",
  component: HelpCenter,
  children: null
};


const privacyRoutes = {
  path: "/privacy",
  name: "Privacy",
  component: Privacy,
  children: null
};

const copyrightRoutes = {
  path: "/copyright",
  name: "Copyright",
  component: Copyright,
  children: null
};

const aboutRoutes = {
  path: "/about",
  name: "About",
  component: About,
  children: null
};


// Dashboard specific routes
export const dashboard = [
  dashboardRoutes,
  pageRoutes,
  layoutRoutes,
  documentationRoutes,
  uiRoutes,
  iconRoutes,
  formRoutes,
  tableRoutes,
  formPluginsRoutes,
  advancedTablesRoutes,
  chartRoutes,
  notificationsRoutes,
  mapRoutes,
  calendarRoutes,
  privateRoutes,
  profileRoutes,
  exerciseDetailRoutes,
  exerciseRoutes,
  myworkoutRoutes,
  workoutCategory,
  workoutDetailRoutes,
  workoutCategoryDetail,
  settingRoutes,
  lift_trackingRoutes,
  athletesSearchRoutes,
  albumbRoutes,
  albumDetailRoutes,
  albumbPhotoDetailRoutes,
  timelineRoutes,
  dashboardRoutes,
  athletesRoutes,
];




// Landing specific routes
export const landing = [
  landingRoutes,
  legalRoutes,
  privacyRoutes,
  copyrightRoutes,
  aboutRoutes,
  helpCenterRoutes,
];
// Auth specific routes
export const page = [
  authRoutes,
  authPwdReset,
  verifyRoutes,
  subscribeRoutes,
  subscribeResultsRoutes,
];

// Onboarding specific routes
export const onboarding = [onboardRoutes];

// All routes
// /* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default [
  timelineRoutes,
  workoutCategory,
  lift_trackingRoutes,
  dashboardRoutes,
  albumbRoutes,
  myworkoutRoutes,
  exerciseRoutes,
];
