import React from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardImg,
  CardBody,
  ListGroup,
  ListGroupItem,
  Spinner,
} from "reactstrap";
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import { Link } from 'react-router-dom';
import store from "../../redux/store/index";
import moment from "moment";
import Loader from "../../components/Loader";
import Friends from './Friends'
import Unknown404 from "./Unknown404";
import HeaderProfile from "./HeaderProfile";
import HeaderAlbums from "./HeaderAlbums";
import HeaderPhotos from "./HeaderPhotos";
import User from "../../components/Social/UserCard/User";
import About from "../../components/Social/AboutCard/About";
import TimelineLazy from "../../components/Social/Timeline/TimelineLazy";
import WorkoutCard from "../../components/Social/Timeline/WorkoutCard";
import FollowingInit from "../../components/Social/FollowingFeed/FollowingInit";
import AlbumsInit from "../../components/Social/AlbumsFeed/AlbumsInit";
import AlbumsView from '../albums/Album/AlbumsView';
import PhotosView from '../../components/Social/Albums/PhotosView'
import WorkoutDetailGraph from "../../components/Social/Graph/Area";
import OptionsModal from '../albums/AlbumDetail/OptionsModal'
import UnfollowButton from "../../components/Social/Buttons/UnfollowButton"
import FollowButton from "../../components/Social/Buttons/FollowButton"
import UploadProfilePicture from '../../components/Social/UploadProfilePhoto/Index'
import WorkoutSubIndex from './workoutsub/index.js'
import PhotoDetail from './PhotoDetail.js'
import {
  API_USER_PROFILES_FILTER,
  API_USER_FOLLOWING_MASTER_FILTER_OPEN,
  API_USER_FOLLOWING,
  API_USER_FOLLOWERS,
  API_USER_PHOTOS_ALBUMS_MASTER,
  API_USER_PHOTOS_ALBUMS_MASTER_NORMAL,
  API_USER_PHOTOS_PHOTOS_MASTER,
  API_TIMELINE_ALL_USER_NORM,
  API_TIMELINE_ALL_USER,
  API_TIMELINE_EXERCISE_ALT,
  API_BODY_WEIGHT,
  API_LIFT_TRACKER_INPUT
} from '../../redux/actions/API';
import axios from "axios";
import { keyConfig } from '../../redux/actions/auth';
import { loadUser } from '../../redux/actions/auth';
import { lifttracking } from '../../redux/actions/general';
import { ArrowLeft } from "react-feather";
import APISocial from '../../config/social';

const calendarStrings = {
    lastDay : '[Yesterday at] LT',
    sameDay : '[Today at] LT',
    nextDay : '[Tomorrow at] LT',
    lastWeek : '[Last] dddd [at] LT',
    nextWeek : 'dddd [at] LT',
    sameElse : 'llll',
};

class Default extends React.Component {

  fetchTimeline = () =>  {
    const memberID = this.props.match.params.memberID;

    axios.get(`${API_USER_PROFILES_FILTER}${memberID}`, keyConfig(store.getState)).then(res => {
      this.setState({
        api_user_profiles: res.data,
        api_user_profiles_username : res.data[0].username,
        api_user_profiles_first_name : res.data[0].first_name,
        api_user_profiles_last_name : res.data[0].last_name,
        api_user_profiles_firstname_lastname : res.data[0].firstname_lastname,
        api_user_profiles_location : res.data[0].location_city_std,
        api_user_profiles_loading: false,
        photo_change_flag: 0,
      });
    }).catch((err) => {
      this.setState({
        api_user_profiles_username: "error",
        api_user_profiles_loading: false,
      });
    });

    axios.get(`${API_USER_FOLLOWING}${memberID}`, keyConfig(store.getState)).then(res => {
      this.setState({
        api_user_following: res.data,
        api_user_following_loading: false,
      });
    }).catch((err) => {
      this.setState({
        api_user_profiles_username: "error",
        api_user_following_loading: false,
      });
    });


    axios.get(`${API_USER_FOLLOWERS}${memberID}`, keyConfig(store.getState)).then(res => {
      this.setState({
        api_user_followers: res.data,
        api_user_followers_loading: false,
      });
    }).catch((err) => {
      this.setState({
        api_user_profiles_username: "error",
        api_user_followers_loading: false,
      });
    });

    axios.get(`${API_USER_FOLLOWING_MASTER_FILTER_OPEN}?user_id_xref=${this.props.user_status.pk}&followed_user=${memberID}`, keyConfig(store.getState)).then(res => {
      this.setState({
        api_following_this_user: res.data,
        api_following_this_user_length: res.data.length,
        api_following_this_user_loading: false,
        follow_change_flag: 0,
        flag_spinner: 0,
      });
    }).catch((err) => {
      console.log(err)
      this.setState({

        api_following_this_user_loading: false,
      });
    });


    axios.get(`${API_USER_PHOTOS_ALBUMS_MASTER}${memberID}`, keyConfig(store.getState)).then(res => {
      this.setState({
        api_albums_master: res.data,
        api_albums_master_loading: false,
      });
    });
  }

  fetchAlbums = () =>  {
    const memberID = this.props.match.params.memberID;
    axios.get(`${API_USER_PHOTOS_ALBUMS_MASTER}${memberID}`, keyConfig(store.getState)).then(res => {
      this.setState({
        api_albums_master: res.data,
        api_albums_master_loading: false,
      });
    });
  }

  fetchUser = () =>  {
    const memberID = this.props.match.params.memberID;
    axios.get(`${API_USER_PROFILES_FILTER}${memberID}`, keyConfig(store.getState)).then(res => {
      this.setState({
        api_user_profiles: res.data,
        api_user_profiles_username : res.data[0].username,
        api_user_profiles_first_name : res.data[0].first_name,
        api_user_profiles_last_name : res.data[0].last_name,
        api_user_profiles_loading: false,
        photo_change_flag: 0,
      });
    }).catch((err) => {
      this.setState({
        api_user_profiles_username: "error",
        api_user_profiles_loading: false,
      });
    });

    axios.get(`${API_USER_FOLLOWING}${memberID}`, keyConfig(store.getState)).then(res => {
      this.setState({
        api_user_following: res.data,
        api_user_following_loading: false,
      });
    }).catch((err) => {
      this.setState({
        api_user_profiles_username: "error",
        api_user_following_loading: false,
      });
    });

    axios.get(`${API_USER_FOLLOWERS}${memberID}`, keyConfig(store.getState)).then(res => {
      this.setState({
        api_user_followers: res.data,
        api_user_followers_loading: false,
      });
    }).catch((err) => {
      this.setState({
        api_user_profiles_username: "error",
        api_user_followers_loading: false,
      });
    });

    axios.get(`${API_USER_FOLLOWING_MASTER_FILTER_OPEN}?user_id_xref=${this.props.user_status.pk}&followed_user=${memberID}`, keyConfig(store.getState)).then(res => {
      this.setState({
        api_following_this_user: res.data,
        api_following_this_user_length: res.data.length,
        api_following_this_user_loading: false,
        follow_change_flag: 0,
      });
    }).catch((err) => {
      this.setState({
        api_user_profiles_username: "error",
        api_following_this_user_loading: false,
      });
    });



  }

  fetchAlbumDetails = (spin_reset) =>  {
    const albumID = this.props.match.params.albumID;
    axios.get(`${API_USER_PHOTOS_ALBUMS_MASTER_NORMAL}${albumID}`, keyConfig(store.getState)).then(res => {
      this.setState({
        api_albums_details: res.data,
        api_albums_details_loading: false,
      });
      if (spin_reset===1) {
        this.setState({
          flag_like_spinner_value:2,
          flag_comment_spinner_value:2
        })
      }
    });
  }

  fetchAlbumPhotoDetails = (photoID) =>  {
    axios.get(`${API_USER_PHOTOS_PHOTOS_MASTER}${photoID}`, keyConfig(store.getState)).then(res => {
      this.setState({
        api_albums_photo_details: res.data,
        api_albums_photo_details_loading: false,
      });
    }).catch((err) => {
      this.setState({
        api_albums_photo_details: "error",
        api_albums_photo_details_loading: false,
      });
    });
  }

  fetchWorkouts = () =>  {
    const memberID = this.props.match.params.memberID;
    axios.get(`${API_TIMELINE_ALL_USER}${memberID}&event=1`, keyConfig(store.getState)).then(res => {
      this.setState({
        api_workouts: res.data.results,
        api_workouts_loading: false,
      });
    });
  }

  fetchTimeLineFilter = (x) =>  {
    const memberID = this.props.match.params.memberID;
    axios.get(`${API_TIMELINE_ALL_USER}${memberID}&event=${x}`, keyConfig(store.getState)).then(res => {
      this.setState({
        api_workouts: res.data.results,
        api_workouts_loading: false,
      });
    });
  }

  fetchBodyWeightWorkout = (workout_session_id,user_id) => {
    axios
      .get(
        `${API_BODY_WEIGHT}?workout_session_xref=${workout_session_id}&user_id=${user_id}`,
        keyConfig(store.getState),
      )
      .then((res) => {
        if (res.data.length > 0) {
          this.setState({
            get_body_weight: res.data[0].weight,
            get_body_weight_loading: false,
            get_body_weight_measure_value: res.data[0].measure_value
          });
        } else {
          this.setState({
            get_body_weight_loading: false,
          });
        }
      });
  };


  fetchBodyWeightEnroll = (enroll_id,day_end,user_id) => {
    axios
      .get(
        `${API_BODY_WEIGHT}?enrollment_history=${enroll_id}&day_end=${day_end}&user_id=${user_id}`,
        keyConfig(store.getState),
      )
      .then((res) => {
        this.setState({
          weight_progression: res.data,
          weight_progression_loading: false,
          });
      });
  };



  fetchTimelineID = (timelineID,initial) =>  {
    axios.get(`${API_TIMELINE_ALL_USER_NORM}${timelineID}`, keyConfig(store.getState)).then(res => {
      if (res.data.exercises.length > 0) {
        this.fetchBodyWeightWorkout(
          res.data.exercises[0].user_exercise_tracker_xref.id,
          res.data.user_id_xref[0].pk,
        )
      }
      try {
      this.fetchBodyWeightEnroll(
        res.data.exercises[0].user_enrollment_history_xref,
        res.data.timestamp,
        res.data.user_id_xref[0].pk,
      )
    } catch(e) {
      console.log(e);
    };
      if (initial === 0) {
        this.fetchNextLiftData();
        this.setState({
          api_workout_detail: res.data,
          api_workout_detail_loading: false,
          flag_comment_spinner_value: 2,
          flag_like_spinner_value: 2,
          workout_session_id: res.data.exercises[0].user_exercise_tracker_xref.id
        });
      }
      else if (initial=== 1) {
        this.fetchNextLiftData();
        this.setState({
          api_workout_detail: res.data,
          api_workout_detail_loading: false,
          workout_session_id: res.data.exercises.length > 0? res.data.exercises[0].user_exercise_tracker_xref.id : null
        });
      }
    });
    axios.get(`${API_TIMELINE_EXERCISE_ALT}?timeline_xref=${timelineID}&uid=${this.props.match.params.memberID}`, keyConfig(store.getState)).then(res => {
        this.setState({
          api_timeline_exercises_alt: res.data,
          api_timeline_exercises_alt_loading: false,
        });
    });
  }

  componentDidMount() {
    this.fetchTimeline();
    if (this.props.match.params.pathID === "albums") {
      this.fetchAlbums();
      this.setState({
        api_albums_master_loading: true,
      });
      console.log('1111')
    }
    if (this.props.match.params.pathID === "workout") {
      this.fetchWorkouts();
      this.setState({
        api_workouts_loading: true,
      });
      console.log('1111')
    }
    if (this.props.match.params.albumID && !this.props.match.params.photoID) {
      this.fetchAlbumDetails(0);
      this.setState({
        api_albums_details_loading: true,
      });
      console.log('2222')
    }
    if (this.props.match.params.photoID) {
      this.fetchAlbumPhotoDetails(this.props.match.params.photoID);
      this.setState({
        api_albums_photo_details_loading: true,
        album_photo_id: this.props.match.params.photoID,
      });
      console.log('3333')
    }
    if (this.props.match.params.workoutID) {
      console.log('4444')
      this.fetchTimelineID(this.props.match.params.workoutID,1);
    }
    if (this.props.match.params.timelinealbumID) {
      console.log('5555')
      this.fetchTimelineID(this.props.match.params.timelinealbumID,1);
    }
    if (this.props.match.params.timelinephotoID) {
      console.log('6666')
      this.fetchAlbumPhotoDetails(this.props.match.params.timelinephotoID);
    }
   document.addEventListener("keydown", this.escFunction, false);
  }

  fetchNextLiftData = () =>  {
    axios.get(`${API_LIFT_TRACKER_INPUT}`, keyConfig(store.getState)).then(res => {
      this.props.lifttracking(res.data);
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.user_status === null) {
      store.dispatch(loadUser());
      console.log('11111')
    }
    if (this.state.album_photo_id !== this.props.match.params.photoID && this.props.match.params.photoID) {
      this.fetchAlbumPhotoDetails(this.props.match.params.photoID);
      this.setState({
        api_albums_photo_details_loading: true,
        album_photo_id: this.props.match.params.photoID,
      });
      console.log('222222')
    }
    if (this.props.match.params.memberID !== this.state.currentMemberID) {
      this.setState({
        currentMemberID: this.props.match.params.memberID,
        api_user_profiles_loading: true,
        api_user_following_loading: true,
        api_user_followers_loading: true,
        api_following_this_user_loading: true,
      });
      this.fetchTimeline();
      console.log('33333')
    }
    if (this.state.follow_change_flag !== 0) {
      this.fetchUser();
      this.setState({
        follow_change_flag: 0,
      });
      console.log('444444')
    }
    if (this.state.photo_change_flag !== 0) {
      this.fetchTimeline();
      console.log('555555')
    }
    if (this.state.workoutdetail_change_flag === 1 ) {
      this.fetchTimelineID(this.props.match.params.workoutID,0);
      this.setState({
        workoutdetail_change_flag: 0,
      });
      console.log('666666')
    }
    if (this.state.album_reload_flag !==0) {
      this.fetchAlbumDetails(1);
      this.setState({
        album_reload_flag: 0,
      });
      console.log('77777')
    }
    if (this.props.match.params.pathID === "workout" && !this.state.api_workouts) {
      this.fetchWorkouts();
      this.setState({
        api_workouts_loading: true,
      });
      console.log('88888')
    }
  }

  constructor(props) {
    super(props);
    this.escFunction = this.escFunction.bind(this);
    this.state = {
      weight_progression: [],
      weight_progression_loading: true,

      get_body_weight: null,
      get_body_weight_loading: true,
      get_body_weight_measure_value: null,
      workout_session_id: null,

      currentMemberID: this.props.match.params.memberID,
      follow_change_flag: 0,
      photo_change_flag: 0,
      flag_delete_album: 0,
      workoutdetail_change_flag:0,
      album_reload_flag: 0,

      api_user_profiles : [],
      api_user_profiles_loading: true,
      api_user_profiles_username:null,

      api_workouts: [],
      api_workouts_loading: true,

      api_workout_detail: [],
      api_workout_detail_loading: true,

      api_user_following: [],
      api_user_following_loading: true,

      api_user_followers: [],
      api_user_followers_loading: true,

      api_following_this_user: [],
      api_following_this_user_loading: true,

      api_albums_master: [],
      api_albums_master_loading: true,

      album_photo_id:null,
      api_albums_details_loading: null,

      api_albums_photo_details: [],
      api_albums_photo_details_loading: null,

      api_timeline_exercises_alt: [],
      api_timeline_exercises_alt_loading: true,

      scroll_right_count: 0,
      scroll_left_count: 0,
      myValueParent:'',

      flag_comment_spinner_value: 0,
      flag_like_spinner_value: 0,
      flag_spinner: 0,

      postTypeWord: 'All',
      postType: 0,
      postTypeReload: 0

    };
  }

  escFunction(event){
    const myValue = this.state.myValueParent
     if(event.keyCode === 39 && myValue === '') {
       this.setState({
         scroll_right_count: this.state.scroll_right_count + 1
       })
     }
     if(event.keyCode === 37 && myValue === '') {
       this.setState({
         scroll_left_count: this.state.scroll_left_count + 1
       })
     }
   }

   submitBodyWeight = async (weight, timestamp) => {
      await this.setState({bodyWeightLoading:true})
      await APISocial.postBodyWeight(
        weight,
        timestamp,
        this.props.user_status.usa_imperial,
        this.props.user_status.pk,
        this.state.workout_session_id,
        (success, data) => {
        if ( success) {
          console.log('success')
          console.log(data)
          this.componentDidMount();
          this.setState({
            bodyWeightLoading:false,
            get_body_weight: data.value,
            get_body_weight_loading: false,
            get_body_weight_measure_value: data.measure_value
          })
        } else {
          console.log(data)
          console.log('fail')
           this.setState({bodyWeightLoading:false})
        }
      });
    }

  handlermyValueParent = (val) => {
     this.setState({
       myValueParent: val
     })
   }

  handlerFollowStatus = (val) => {
    this.setState({
      follow_change_flag: val
    })
  }

  handlerPhotoChange = (val) => {
    this.setState({
      photo_change_flag: val
    })
  }

  handlerWorkoutDetailChange = (val) => {
    this.setState({
      workoutdetail_change_flag: Number(val)
    })
  }

  handlerDeleteAlbumChamge = (val) => {
    this.setState({
      flag_delete_album: val
    })
  }


  handlerCommentSpinner = (val) => {
    this.setState({
      flag_comment_spinner_value: val,
    })
  }

  handlerLikeSpinner = (val) => {
    this.setState({
      flag_like_spinner_value: val,
    })
  }



  renderUser = () => {
    if (this.state.api_user_profiles_loading ) {
      return <Card style={{height: '250px'}} className="d-flex flex justify-content-center align-items-center"><Spinner color="primary" /></Card>
    }
    else if (this.props.user_status.pk !== this.props.match.params.memberID) {
      return(
          <User
            username={this.state.api_user_profiles_username}
            first_name={this.state.api_user_profiles[0].first_name}
            last_name={this.state.api_user_profiles[0].last_name}
            avatar_color={this.state.api_user_profiles[0].avatar_color}
            avatar_letter={this.state.api_user_profiles[0].avatar_letter}
            avatar_image={this.state.api_user_profiles[0].avatar_image}
            heading={this.state.api_user_profiles[0].heading}
            active_user_id = {this.props.user_status.pk}
            user_id = {this.props.match.params.memberID}
            api_following_this_user_length = {this.state.api_following_this_user_length}
            api_following_this_user = {this.state.api_following_this_user}
            change_flag = {this.handlerFollowStatus}
            photo_change_flag = {this.handlerPhotoChange}
            source={this.props.user_status.pk === this.props.match.params.memberID ? "my_profile" : null}
          />
      )
    }
    else if (this.props.user_status.pk === this.props.match.params.memberID) {
      return(
          <User
            username={null}
            first_name={this.props.user_status.first_name}
            last_name={this.props.user_status.last_name}
            avatar_color={this.props.user_status.avatar_color}
            avatar_letter={this.props.user_status.avatar_letter}
            avatar_image={this.props.user_status.avatar_image}
            heading={this.props.user_status.heading}
            active_user_id = {this.props.user_status.pk}
            user_id = {this.props.match.params.memberID}
            api_following_this_user_length = {this.state.api_following_this_user_length}
            api_following_this_user = {this.state.api_following_this_user}
            change_flag = {this.handlerFollowStatus}
            photo_change_flag = {this.handlerPhotoChange}
            source={this.props.user_status.pk === this.props.match.params.memberID ? "my_profile" : null}
          />
      )
    }
  }

  handlerLoadSpinner = (val) => {
    this.setState({
      flag_spinner: val
    })
  }

  renderFollowButtons = () => {
    if (this.state.api_user_profiles_loading) {
      return(
        null
      )
    }
    else if (this.props.user_status.pk === this.props.match.params.memberID && (this.state.api_user_profiles[0].avatar_image === null || this.state.api_user_profiles[0].avatar_image ==="None" || !this.state.api_user_profiles[0].avatar_image) ){
      return(
        <UploadProfilePicture
          photo_change_flag = {this.props.photo_change_flag}
          profile_picture_status = {this.props.user_status.avatar_image}
        />
      )
    }

    else if (this.state.api_following_this_user_length >= 1) {
      return(
        <UnfollowButton
          followingid = {this.state.api_following_this_user[0].id}
          currentTime = {this.state.api_following_this_user[0].derived__current_time}
          myid = {store.getState().auth.user.pk}
          user_id = {this.props.match.params.memberID}
          change_flag = {this.handlerFollowStatus}
          size = "md"
          handlerLoadSpinner = {this.handlerLoadSpinner}
        />
      )
    }

    else if (this.props.user_status.pk !== this.props.match.params.memberID) {
      return(
        <FollowButton
          myid = {store.getState().auth.user.pk}
          user_id = {this.props.match.params.memberID}
          change_flag = {this.handlerFollowStatus}
          size = "md"
          handlerLoadSpinner = {this.handlerLoadSpinner}
          />
        )
      }
    }

  renderAbout = () => {
      return(
          <About
            location = {this.state.api_user_profiles_location}
          />
      )
  }

  renderFollowingCapped = () => {
    if (  this.state.api_user_following_loading || this.state.api_user_followers_loading || this.state.api_user_profiles_loading ){
      return( <Card> <Loader /> </Card>)

    }
      return(
        <FollowingInit
          api_user_following = {this.state.api_user_following}
          api_user_following_count = {this.state.api_user_following.length}
          api_user_followers = {this.state.api_user_followers}
          api_user_followers_count = {this.state.api_user_followers.length}
          user_id = {this.props.match.params.memberID}
          username={this.state.api_user_profiles_username}
          first_name={this.state.api_user_profiles[0].first_name}
          last_name={this.state.api_user_profiles[0].last_name}
          handlerPostTypeReset = {this.handlerPostTypeReset}
          />
      )
  }

  renderAlbumsList = (activeName) => {
    if (this.state.api_user_profiles_loading) {
      return (null)
    }
      return(
        <AlbumsInit
          albums = {this.state.api_albums_master}
          albums_count = {this.state.api_albums_master.length}
          user_id = {this.props.match.params.memberID}
          username={this.state.api_user_profiles_username}
          first_name={this.state.api_user_profiles[0].first_name}
          last_name={this.state.api_user_profiles[0].last_name}
          />
      )
  }

  renderListGroup = (activeName) => {
    return(
      <ListGroup horizontal className="m-0 p-0">
      <ListGroupItem  action tag={Link} to={"/profile/" + this.props.match.params.memberID + "/home"} >
      Feed
      </ListGroupItem >
      <ListGroupItem  action active={activeName==="workout" ? true : false} tag={Link} to={"/profile/" + this.props.match.params.memberID + "/workout"} >
      Workouts(52)
      </ListGroupItem >
      <ListGroupItem  action active={activeName==="friends" ? true : false} tag={Link} to={"/profile/" + this.props.match.params.memberID + "/following"} >
      {this.state.api_user_following_loading?
        <Spinner color="primary" size="sm"/>
        :
        "Friends("+this.state.api_user_following.length+")"
      }
      </ListGroupItem >
      <ListGroupItem  action active={activeName==="album" ? true : false} tag={Link} to={"/profile/" + this.props.match.params.memberID + "/albums"} >
        {this.state.api_albums_master_loading?
          <Spinner color="primary" size="sm"/>
          :
          "Albums("+this.state.api_albums_master.length+")"
        }
      </ListGroupItem >
      </ListGroup>
    )
  }

  renderProfileTopCard = (activeName) => {

    return(
      <Card>
        {this.renderUser()}
        {this.props.user_status.pk === this.props.match.params.memberID?
          <div style={{width:'100%'}}>
            {this.renderListGroup()}
          </div>
        :
        <Row className="d-flex flex justify-content-between m-0 p-0">
          <Col lg="8" md="8" className="m-0 p-0">
          <div style={{width:'100%'}}>
            {this.renderListGroup()}
          </div>
        </Col>
        <Col lg="4" md="4" className="d-flex flex justify-content-end">
        {this.renderFollowButtons()}
        </Col>
        </Row>
      }
      </Card>
    )
  }


  renderAlbumViewHeader = (count) => {
   if (this.props.user_status.pk === this.props.match.params.memberID) {
      return(
        <HeaderAlbums
          title={"My Albums"}
          myProfile = {"no"}
          count={count}
          link={this.props.match.params.memberID}
          />
      )
    }
    else if (this.props.user_status.pk !== this.props.match.params.memberID){
      return(
        <HeaderAlbums
          title={this.state.api_user_profiles_firstname_lastname + "'s Albums"}
          myProfile = {"no"}
          count={count}
          link={this.props.match.params.memberID}
          />
      )
    }
  }

  renderAlbumView= () => {
    if(this.state.api_albums_master_loading) {
      return(
        <Loader />
      )
    }
      return(
        <div>
        {this.renderAlbumViewHeader(this.state.api_albums_master.length)}
        <Row>
           {this.state.api_albums_master.map((x,y) =>
             <Col md="6" lg="6" key={y}>
              <AlbumsView
                 album_details = {x}
                 albums_empty={this.state.api_albums_empty}
                 url = {"/profile/" + this.props.match.params.memberID + /albums/ + x.id}
                 source = {"profile"}
                 />
           </Col>
          )}
        </Row>
        </div>

      )
  }

  handlerLoaderAlbumFlag = (val) => {
    this.setState({
      album_reload_flag: null
    })
  }

  renderAlbumDetails= () => {
      if(this.state.api_albums_master_loading || this.state.api_albums_details_loading) {
        return (<Loader />)
      }
      return(
        <div>

        <div className="float-right mt-n1">

        {((this.props.user_status.pk === this.props.match.params.memberID) && (this.props.match.params.albumID && !this.state.api_albums_details_loading)) ?
          <div className="d-flex flex justify-content-center align-items-center">
          <OptionsModal
            api_album = {this.state.api_albums_details}
            handlerDeleteAlbumChamge = {this.handlerDeleteAlbumChamge}
            source={this.state.api_albums_details.id}
            size="md"
            />

            <Button className="ml-1" size="md" color="primary" tag={Link} to={"/profile/" + this.props.match.params.memberID + "/albums"}>
            {this.props.user_status.pk === this.props.match.params.memberID?
            "My Albums"
            :
            this.state.api_user_profiles[0].first_name + 's Albums'
          }
            </Button>
            </div>
            :
            <Button color="primary" size="md" tag={Link} to={"/profile/" + this.props.match.params.memberID + "/albums"}>
            <ArrowLeft className="feather" />
            {this.props.user_status.pk === this.props.match.params.memberID?
            "My Albums"
            :
            this.state.api_user_profiles[0].first_name + 's Albums'
          }
            </Button>
          }

        </div>
        <h1 className="h3 mb-1">{this.state.api_albums_details.album_name_normal}</h1>
        <div className="mb-3">
        <strong>{this.state.api_user_profiles[0].firstname_lastname}  •  {moment(this.state.api_albums_details.recorded_time).calendar(null,calendarStrings)} • ({this.state.api_albums_details.photo_count} photos)</strong>
        </div>


        <div className="mb-2" dangerouslySetInnerHTML={ { __html: this.state.api_albums_details.description } }></div>




        <Row>
           {this.state.api_albums_details.photos.map((x,y) =>
             <Col md="4" lg="4" key={y} className="" style = {{'maxWidth': '100%', 'maxHeight': '100%', }}>

              <PhotosView
                 photo_data = {x}
                 photo_index_id = {y}
                 album_data = {this.state.api_albums_details.photos}
                 album_count = {this.state.api_albums_details.photos.length}
                 album_name = {this.state.api_albums_details.album_name_normal}
                 source = {"profile"}
                 time_history = {this.state.api_albums_details.recorded_time}
                 change_flag = {this.handlerLoaderAlbumFlag}
                 scroll_right_count = {this.state.scroll_right_count}
                 scroll_left_count = {this.state.scroll_left_count}
                 user_data = {this.state.api_albums_details.user_id_xref[0]}
                 handlermyValueParent = {this.handlermyValueParent}
                 flag_like_spinner_value = {this.state.flag_like_spinner_value}
                 flag_like_spinner_handler = {this.handlerLikeSpinner}
                 flag_comment_spinner_value = {this.state.flag_comment_spinner_value}
                 flag_comment_spinner_handler = {this.handlerCommentSpinner}
             />
             </Col>
          )}
          </Row>


          </div>

      )
  }

  renderAlbumPhotoDetails= () => {
    if (this.state.api_albums_photo_details_loading || this.state.api_albums_master_loading) {
      return (<Loader />)
    }
      return(
        <Card className="float-center">
          <CardBody className="">
            <CardImg top width="100%" src={this.state.api_albums_photo_details.photo} alt="Card image cap" />
          </CardBody>
        </Card>

      )
  }




  renderPhotoDetail_Alt= () => {
    if (this.state.api_albums_photo_details_loading || this.state.api_albums_master_loading) {
      return (<Loader />)
    }
    else if (this.state.api_albums_photo_details === "error") {
      return (<Unknown404 />)
    }
      return(
          <PhotoDetail data={this.state.api_albums_photo_details} fetchAlbumPhotoDetails = {this.fetchAlbumPhotoDetails}/>
      )
  }


  handlerPostTypeReset = (val) => {
    this.setState({postType:val})
  }

  renderTimelineFeed = () => {
      return(
        <TimelineLazy
          list = {"member_only"}
          source = {"profile"}
          postType = {this.state.postType}
          postTypeReload = {this.state.postTypeReload}
          postTypeUnFilter = {this.postType}
          handlerPostTypeReload = {this.handlerPostTypeReload}
          member_id = {this.state.currentMemberID}
          member_first_name = {this.state.api_user_profiles_first_name}
          />
      )
  }

  renderWorkoutDetail = () => {
    if (this.state.api_workout_detail_loading || this.state.api_timeline_exercises_alt_loading) {
      return(
        <Loader/>
      )
    }
      return(
        <WorkoutDetailGraph
          data={this.state.api_workout_detail.exercises[0].user_exercise_tracker_xref.workout_day_xref.full_quick}
          source={"profile"}
          />
      )
  }

  renderWorkoutSubDetail = () => {
    if (this.state.api_workout_detail_loading ||
      this.state.api_timeline_exercises_alt_loading ||
      this.state.get_body_weight_loading
    ) {
      return(
        <Loader/>
      )
    }
      return(
    <>
        <WorkoutSubIndex
          exercise_data={this.state.api_workout_detail.exercises[0].user_exercise_tracker_xref}
          user_data={this.state.api_workout_detail.user_id_xref[0]}
          active_profile={this.props.match.params.memberID}
          thisuser = {this.props.user_status.pk}
          comments={this.state.api_workout_detail.comments}
          likes={this.state.api_workout_detail.likes}
          data = {this.state.api_workout_detail}
          workoutdetail_change_flag = {this.handlerWorkoutDetailChange}
          timeline_id = {this.props.match.params.workoutID}
          graph_data={this.state.api_workout_detail.exercises[0].user_exercise_tracker_xref.workout_day_xref.full_quick}
          measure_value={this.state.api_workout_detail.exercises[0].user_exercise_tracker_xref.measure_value}
          flag_comment_spinner_handler={this.handlerCommentSpinner}
          flag_comment_spinner_value = {this.state.flag_comment_spinner_value}
          routine_name = {this.state.api_workout_detail.exercises[0].user_exercise_tracker_xref.workout_day_xref.routine_xref.routine}
          exercise_table = {this.state.api_timeline_exercises_alt}
          get_body_weight = {this.state.get_body_weight}
          get_body_weight_loading = {this.state.get_body_weight_loading}
          get_body_weight_measure_value = {this.state.get_body_weight_measure_value}
          submitBodyWeight = {this.submitBodyWeight}
          this_user = {this.props.user_status.pk}
          weight_progression = {this.state.weight_progression}
          weight_progression_loading = {this.state.weight_progression_loading}
          />
          </>
      )
  }

  renderFollowingAll= () => {
      return(
        <Friends
          api_user_following = {this.state.api_user_following}
          api_user_followers = {this.state.api_user_followers}
          handlerPostTypeReset = {this.handlerPostTypeReset}
          />
      )
  }

  postType = (y,z) => {
    this.setState({
      postType: y,
      postTypeWord: z,
      postTypeReload: 1
    })
  }

  renderHeaderHome = () => {
    if (this.state.api_user_profiles_loading) {
      return(
        null
      )
    }
    else if (this.props.user_status.pk === this.props.match.params.memberID) {
      return(
        <HeaderProfile
          title={"Welcome Home, " + this.state.api_user_profiles_first_name}
          postTypeWord = {this.state.postTypeWord}
          postType = {this.postType}
          myProfile = {"yes"}

          />
      )
    }
    else if (this.props.user_status.pk !== this.props.match.params.memberID){
      return(
        <HeaderProfile
          title={this.state.api_user_profiles_firstname_lastname + "'s Profile"}
          postTypeWord = {this.state.postTypeWord}
          postType = {this.postType}
          myProfile = {"no"}
          followButtons = {this.renderFollowButtons()}
          />
      )
    }
  }


  renderHeaderAlbumAlt = () => {
    if (this.state.api_user_profiles_loading || this.state.api_workout_detail_loading || this.state.api_timeline_exercises_alt_loading) {
      return(
        null
      )
    }
      return(
        <HeaderAlbums
          title={this.state.api_workout_detail.albums.user_album_xref.album_name_normal}
          count={this.state.api_workout_detail.albums.user_album_xref.photo_count}
          link={this.props.match.params.memberID}
          postTypeWord = {this.state.postTypeWord}
          postType = {this.postType}
          myProfile = {"yes"}

          />
      )
  }



  renderHeaderPhotosAlt = () => {
    if (this.state.api_albums_photo_details_loading || this.state.api_albums_photo_details === "error") {
      return(
        null
      )
    }
      return(
        <HeaderPhotos
          data={this.state.api_albums_photo_details}
          />
      )
  }

  renderLeftHome = () => {
    if (this.props.match.params.pathID === "home") {
      return(
        <div>
          {this.renderUser()}
          {this.state.postType === 3? null : this.renderFollowingCapped()}
          {this.renderAlbumsList()}
          </div>
      )
    }
    else if (this.props.match.params.pathID === "following") {
      return(
        <div>
          {this.renderUser()}
          {this.renderAlbumsList()}
        </div>
      )
    }
    else if (this.props.match.params.timelinealbumID || this.props.match.params.timelinephotoID) {
      return(
        <div>
          {this.renderUser()}
          {this.renderFollowingCapped()}
          {this.renderAlbumsList()}
        </div>
      )
    }
    else if ((this.props.match.params.pathID === "albums" || !this.props.match.params.albumID) && !this.props.match.params.workoutID) {
      return(
        <div>
          {this.renderUser()}
          {this.renderFollowingCapped()}
        </div>
      )
    }
    else if (this.props.match.params.pathID === "albums" || this.props.match.params.albumID) {
      return(
        <div>
          {this.renderUser()}
          {this.renderFollowingCapped()}
          {this.renderAlbumsList()}
        </div>
      )
    }
    if (this.props.match.params.workoutID) {
      return(
        <div>
          {this.renderWorkoutSubDetail()}
          </div>
      )
    }
  }

  renderRightHome = () => {
    if (this.state.api_albums_photo_details_loading || this.state.api_albums_details_loading) {
      return(
        <Loader/>
      )
    }
    else if (this.props.match.params.pathID === "home" && (this.state.postType===0 || this.state.postType===1 || this.state.postType===2  )) {
      return(
        <div> {this.renderTimelineFeed()}</div>
      )
    }
    else if (this.props.match.params.pathID === "home" && (this.state.postType===3 )) {
      return(
        <div> {this.renderFollowingAll()} </div>
      )
    }
    else if (this.props.match.params.pathID === "following") {
      return(
        <div> {this.renderFollowingAll()} </div>
      )
    }
    else if (this.props.match.params.pathID === "albums") {
      return(
        <div> {this.renderAlbumView()} </div>
      )
    }
    else if (this.props.match.params.workoutID) {
      return(
        <div> {this.renderWorkoutDetail()} </div>
      )
    }
    else if (this.props.match.params.albumID && !this.props.match.params.photoID) {
      return(
        <div> {this.renderAlbumDetails()} </div>
      )
    }
    else if (this.props.match.params.albumID && this.props.match.params.photoID) {
      return(
        <div> {this.renderAlbumPhotoDetails()}</div>
      )
    }

  }


  renderRightAlbumAlt = () => {
    if(this.state.api_workout_detail_loading || this.state.api_timeline_exercises_alt_loading) {
      return(
        <Loader />
      )
    }
    return(
      <TimelineLazy
        list = {"album_only"}
        source = {"profile"}
        handlerPostTypeReload = {this.handlerPostTypeReload}
        member_id = {this.state.currentMemberID}
        timelinealbumid = {this.props.match.params.timelinealbumID}
        member_first_name = {this.state.api_user_profiles_first_name}
        />
    )
  }


  renderRightAlbums = () => {
    if (this.state.api_albums_photo_details_loading || this.state.api_albums_details_loading) {
      return(
        <Loader/>
      )
    }

    else if (this.props.match.params.pathID === "albums") {
      return(
        <div> {this.renderAlbumView()} </div>
      )
    }
    else if (this.props.match.params.albumID && !this.props.match.params.photoID) {
      return(
        <div> {this.renderAlbumDetails()} </div>
      )
    }
    else if (this.props.match.params.albumID && this.props.match.params.photoID) {
      return(
        <div> {this.renderAlbumPhotoDetails()}</div>
      )
    }

  }

  renderWorkoutCards = () => {
    if (this.state.api_workouts_loading) {
      return(
        <div className="d-flex flex justify-content-center align-items-center">
        <Spinner color="primary" />
        </div>
      )
    }
    return(
      <div>
      <WorkoutCard
      start_time = {this.state.api_workouts[0].exercises[0].user_exercise_tracker_xref.recorded_start_time}
      derived__start_time_local = {this.state.api_workouts[0].exercises[0].user_exercise_tracker_xref.derived__start_time_local}
      workout = {this.state.api_workouts[0].exercises[0].user_exercise_tracker_xref.workout_day_xref}
      source={"profile"}
      />

      </div>
    )
  }

  renderWorkoutPage = () => {
    return(
      <div>

      {this.renderProfileTopCard("workout")}
      {this.renderWorkoutCards()}
      </div>
    )
  }

  renderRightWorkout = () => {
    if (this.props.match.params.pathID === "workout") {
      return(
        <div>             {this.renderWorkoutPage()} </div>
      )
    }
    else if (this.props.match.params.workoutID) {
      return(
        <div>             {this.renderWorkoutSubDetail()} </div>
      )
    }
  }

  renderRightFollowing= () => {
    if(this.state.api_user_followers_loading || this.state.api_user_followers_loading) {
      return(
        <Loader />
      )
    }
      return(
        <div>
        {this.renderProfileTopCard("friends")}
        <div> {this.renderFollowingAll()} </div>
        </div>

      )
  }

  render() {
    if (
      this.props.user_status === null || this.state.api_user_profiles_loading
    ) {
      return <Loader />
    }

    else if (this.state.flag_delete_album === 1) {
      return <Redirect to="/albums/" />;
    }

    else if (this.state.api_user_profiles_username === "error") {
      return <Unknown404 />
    }

    else if (this.props.match.params.pathID === "home" ) {
      return  (
          <Container>
            {this.renderHeaderHome()}
            <Row className="">
              <Col lg="4" xl="4">
              { this.renderLeftHome() }
              </Col>
              <Col lg="8" xl="8">
              { this.renderRightHome() }
              </Col>
            </Row>
          </Container>
          )
        }

    else if (this.props.match.params.pathID === "following" ) {
        return       (
            <Container fluid>

              <Row className="">
                <Col lg="12" xl="12">
                { this.renderRightFollowing() }
                </Col>
              </Row>
            </Container>
            )
          }

    else if (this.props.match.params.pathID === "albums" || this.props.match.params.albumID) {
          return       (
              <Container fluid className="p-0">


                  { this.renderRightAlbums() }

              </Container>
              )
            }

    else if (this.props.match.params.pathID === "workout" || this.props.match.params.workoutID) {
        return       (
            <Container fluid>
            { this.renderRightWorkout() }
            </Container>
            )
          }

    else if (this.props.match.params.pathID === "albums_alt" || this.props.match.params.timelinealbumID) {
              return       (
                <Container>
                  {this.renderHeaderAlbumAlt()}
                  <Row className="">
                    <Col lg="4" xl="4">
                    { this.renderLeftHome() }
                    </Col>
                    <Col lg="8" xl="8">
                    { this.renderRightAlbumAlt() }
                    </Col>
                  </Row>
                </Container>
                  )
                }

    else if ((this.props.match.params.pathID === "photos_alt" || this.props.match.params.timelinephotoID) && this.state.api_albums_photo_details !== "error") {
      return(
      <Container>
        {this.renderHeaderPhotosAlt()}
        <Row className="">
          <Col lg="4" xl="4">
          { this.renderLeftHome() }
          </Col>
          <Col lg="8" xl="8">
          {this.renderPhotoDetail_Alt()}
          </Col>
        </Row>
      </Container>
      )
    }
    return (
      <Unknown404 />
  );
 }
}

const mapStateToProps = (state) => ({
  user_status: state.auth.user,
  theme: state.theme.currentTheme
});

export default connect(mapStateToProps, { lifttracking })(Default);
