import React from "react";
import axios from "axios";
import { keyConfig } from '../../../redux/actions/auth';
import store from "../../../redux/store/index";
import { API_WORKOUTS_POPULAR } from '../../../redux/actions/API'
import { Link } from "react-router-dom";
import {
  CardTitle,
  CardImg,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";

import { connect } from 'react-redux';

class Popular extends React.Component {

  fetchData = () =>  {
    axios.get(`${API_WORKOUTS_POPULAR}`, keyConfig(store.getState)).then(res => {
      this.setState({
        workouts_popular: res.data
      });

    });
  }

  componentDidMount() {
    this.fetchData();
  }

  constructor(props) {
    super(props);
    this.state = {
      lift_tracking_input: [],
      workouts_popular: [],
      title: "",
      message: "",
      type: "success",
      timeOut: 5000,
      showCloseButton: true,
      progressBar: true,
      position: "top-right",
    };
  }

  render() {
    return (
      <div>
        {this.state.workouts_popular.slice(0,2).map((a,b) =>
          <Card key={b}>
            <Link to={"/workouts/detail/" + a.id} >
              <CardImg top src={a.cover_image} alt="Card image cap" />
                <CardHeader className="px-4 pt-4">
                  <CardTitle tag="h5" className="mb-0">
                    <strong>{a.routine}</strong>
                  </CardTitle>

                </CardHeader>
            </Link>
            <CardBody className="px-4 pt-1 pb-0" >
              <div dangerouslySetInnerHTML={ { __html: a.description_header_char } }></div>
            </CardBody>
          </Card>
        )}
    </div>

    );
  }
}

const mapStateToProps = (state) => ({
  user_id: state.auth.user.pk,
  user_status: state.auth.user,
  general: state.general.isLoading,
  theme: state.theme.currentTheme,
});

export default connect(mapStateToProps, {  })(Popular);
