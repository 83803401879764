import React from "react";
import {  Row, Col } from "reactstrap";

import Feed from "./Feed";
import Header from "./Header";
import LineChart from "./LineChart";
import Statistics from "./Statistics";
import LiftTable from "./LiftTable";
import Mixed from "../../body_weight_tracking/Mixed.js";
import Progress from "./Progress";

const Default = (props) => (
  <>
    <Header
      exercise_data = {props.exercise_data}
      get_body_weight_measure_value = {props.get_body_weight_measure_value}
      get_body_weight_loading = {props.get_body_weight_loading}
      get_body_weight = {props.get_body_weight}
      submitBodyWeight = {props.submitBodyWeight}
      this_user = {props.this_user}
      user_data = {props.user_data.pk}

      />
    <Statistics exercise_data = {props.exercise_data} exercise_table={props.exercise_table}/>
    <Progress exercise_table={props.exercise_table} />
    <Row>
      <Col lg="8" className="d-flex">
        <LineChart graph_data={props.graph_data} routine_name={props.routine_name} measure_value={props.measure_value}/>
      </Col>
      <Col lg="4" className="d-flex">
        <Feed data= {props.data} timeline_id = {props.timeline_id}/>
      </Col>
    </Row>

    {props.weight_progression_loading || props.get_body_weight === null? null :
    <Row>
      <Col lg="8" className="d-flex">
        <Mixed weight_progression={props.weight_progression}/>
      </Col>
      <Col lg="4" className="d-flex">

      </Col>
    </Row>
    }

    <Row>
      <Col lg="8" className="d-flex">
        <LiftTable exercise_table = {props.exercise_table} active_profile = {props.active_profile} exercise_data = {props.exercise_data}/>
      </Col>
      <Col lg="4" className="d-flex">

      </Col>
    </Row>
  </>
);

export default Default;
