import React from "react";
import { followUser } from "../../../redux/actions/social";
import { Link } from "react-router-dom";
import {
  CardBody,
  Media,
} from "reactstrap";
import { connect } from 'react-redux';
import ProfilePhoto from '../../../components/Social/ProfilePhoto/ProfilePhoto'
import {
  User,
} from "react-feather";

class FollowingAll extends React.Component {

  componentDidMount() {
  }

  constructor(props) {
    super(props);
    this.state = {
      cap: 5
    };
  }

  renderProfileDirectional = (y,x,cap) => {
    return(
      <Media>


      <ProfilePhoto
        avatar_image= {y.avatar_image}
        avatar_letter = {y.avatar_letter}
        avatar_color = {y.avatar_color}
        avatar_size={"56px"}
        letter_size={"23px"}
        />



        <Media body   className="ml-2">
        <div>
        <Link to={"/profile/" + y.id + "/home" }>
            <p className="my-1" style={{color:"black"}}>
              <strong>{y.firstname_lastname}</strong>
            </p>
          </Link>
          {y.location}
          </div>

        </Media>
      </Media>
      )
  }

  renderProfileAvatar = (direction_flag, x) => {
    if (direction_flag === "following") {
      return(
        <div>
        { this.renderProfileDirectional(x.followed_user, x) }
        </div>
      )
    }
    else if (direction_flag === "follower") {
      return(
        <div>
        { this.renderProfileDirectional(x.user_id_xref, x) }
        </div>
      )
    }
  }

  renderProfiles = (input_data, direction_flag, cap) => {
    if (input_data.length === 0 && direction_flag==="follower") {
      return(
        <div className="text-center pr-2 pl-2 pb-2">
          <User width={54} height={54} style={{border:"1px solid black", "borderRadius": "50%", padding: "10px"}} />
          <p className="mt-1 mb-1">
            No Followers Yet
          </p>
        </div>
      )
    }
    if (input_data.length === 0 && direction_flag==="following") {
      return(
        <div className="text-center pr-2 pl-2 pb-2">
          <User width={54} height={54} style={{border:"1px solid black", "borderRadius": "50%", padding: "10px"}} />
          <p className="mt-1 mb-1">
            Not Following Anybody Yet
          </p>
        </div>
      )
    }
      return(
        <div>
          {input_data.map((x,y,arr) =>
            <div key={y}>
              {this.renderProfileAvatar(direction_flag, x)}
              <hr className="my-2" />
            </div>
          )}
        </div>
      )
  }

  render() {
      return (
        <CardBody>
          {this.renderProfiles(this.props.user_profiles, this.props.direction_flag)}
        </CardBody>
      );
  }
}

const mapStateToProps = (state) => ({
  theme: state.theme.currentTheme,
});

export default connect(mapStateToProps, { followUser })(FollowingAll);
