import React from "react";
import {
  Col,
  Row,
} from "reactstrap";
import CreateExercise from '../../components/Create/CreateExercise.js'


const Header = (props) => {
  return (
    <Row className="mb-2 mb-xl-4">
      <Col xs="auto" className="d-none d-sm-block">
        <h3>My Exercises</h3>
      </Col>

      <Col xs="auto" className="ml-auto text-right mt-n1">


      <CreateExercise source={"Main"} showAll={"No"}/>

      </Col>
    </Row>
  );
};

export default Header;
