import React from "react";
import classnames from "classnames";
import {
  Card,
  CardBody,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";
import TimeAgo from 'react-timeago'

import Area from "./Area"

class CardWithTabs extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "TimeSeries",
      loading: true,
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }


    sortTabs = async (x) =>  {
        await x.map((a,b) => {
          return this.setState({
            ['eTabName' + b] : a.exercise_xref.exercise,
            ['eTabID' + b] : b
          })
        })

      await this.setState({
        activeTab: this.state['eTabID' + 0],
        loading:false
      })
    }

  componentDidMount() {
    this.sortTabs(this.props.workout.full_quick);
  }

  render() {
    if (this.state.loading === true) {
      return (
        null
      )
    }
    return (
      <Card className="mb-0 pb-0">
      <div className="d-flex flex-row justify-content-between align-items-end">
        <CardHeader>
          <Nav tabs className="card-header-tabs">

          {this.props.workout.full_quick.slice(0,3).map((a,b) =>
            <div key={b}>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === this.state['eTabID' + b] })}
                onClick={() => {
                  this.toggle(this.state['eTabID' + b]);
                }}
                href="#"
              >
                {this.state['eTabName' + b]}
              </NavLink>
            </NavItem>
            </div>
            )}

          </Nav>
        </CardHeader>

        {this.props.source==="profile" ?
        <CardHeader className="d-flex flex-column align-items-end">
           <strong>{this.props.workout.day_title}</strong>
           <span><TimeAgo date={this.props.derived__start_time_local}  /></span>
        </CardHeader>
        :
        null
      }
        </div>


        <CardBody>

      {this.props.workout.full_quick.map((a,b) =>
        <div key={b}>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId={this.state['eTabID' + b]} className="text-center">
              <Area
                full={this.props.workout.full_quick[b]}
                data={this.props.workout.full_quick[b].exercise_xref.graph_data}
              />
            </TabPane>
          </TabContent>
        </div>
        )}

        </CardBody>
      </Card>
    );
  }
}

const WorkoutCard = (props) => (
  <CardWithTabs
    workout = {props.workout}
    derived__start_time_local = {props.derived__start_time_local}
    source={props.source}
    />
);

export default WorkoutCard;
