import React from "react";
import { Redirect } from "react-router-dom";
import { keyConfig } from '../../../redux/actions/auth';
import axios from "axios";
import store from "../../../redux/store/index";
import { API_ENROLLMENT_HISTORY } from '../../../redux/actions/API';
import Loading from './Loading'
import HeaderList from './HeaderList'
import SubscribeModal from "../../../components/SubscribeModal.js"
import moment from "moment";
import { calendarStrings } from '../../../redux/actions/timeconfig';
import {
  Card,
  CardBody,
  CardHeader,
  Container,
} from "reactstrap";
import {
  isMobile
} from "react-device-detect";

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { connect } from 'react-redux';


const MyExportCSV = props => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div>
        <button className="btn btn-secondary mt-2" onClick={handleClick}>
          Export
        </button>
      </div>
    );
  };

const { SearchBar } = Search;

class WorkoutPrograms extends React.Component {

  fetchWorkouts = () =>  {
    axios.get(`${API_ENROLLMENT_HISTORY}`, keyConfig(store.getState)).then(res => {
      this.setState({
        workout_history: res.data
      });
    });
  }

  componentDidMount() {
    this.fetchWorkouts();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.token) {
      this.fetchWorkouts();
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      workout_history: [],
      referrer: null,
    };
  }




  render() {
    const {referrer} = this.state;
    if (this.props.general) {
      return <Loading />
    }
    else if (referrer) {
      return <Redirect to={referrer} />;
    }
    return (

      <Container fluid>
      <SubscribeModal />
        <HeaderList />
        <Card>
          <ToolkitProvider
            keyField="name"
            data={this.state.workout_history}
            columns={
              isMobile ?
              [
                {
                  dataField: "derived__short_name",
                  text: "Workout Name",
                  sort: true,
                },
                {
                  dataField: "id",
                  text: "ID",
                  sort: true,
                  hidden: true,
                }
              ]
              :
            [
              {
                dataField: "id",
                text: "ID",
                sort: true,
                hidden: true,
              },
              {
                dataField: "derived__short_name",
                text: "Exercise",
                sort: true,
              },
              {
                dataField: "derived__status_memo",
                text: "Status",
                sort: true,
              },
              {
                dataField: "derived__workout_progress_pct",
                text: "Completed",
                sort: true,
              },
              {
                dataField: "recorded_enroll_date",
                text: "Date started",
                sort: true,
                formatter: (cellContent, row) => {
                  return <>{moment(cellContent).calendar(null,calendarStrings)}</>;
                }
              }
            ]
          }
            exportCSV
            search
          >
            {(props) => (
              <div>
                <CardHeader className="d-flex flex justify-content-between align-items-center">
                  <div>

                  <h6 className="card-subtitle text-muted">

                  </h6>
                  </div>


                  <div className="d-flex flex-row-reverse" >
                      <div style={{display: 'flex', 'justifyContent': 'center', 'alignItems': 'center'}} className="p-0 ml-1">
                        <MyExportCSV {...props.csvProps} />
                      </div>
                      <div style={{display: 'flex', 'justifyContent': 'center', 'alignItems': 'center'}} className="p-0 mt-3">
                      <SearchBar { ...props.searchProps } className="p-0 m-0"/>
                      </div>
                    </div>
                </CardHeader>
                <CardBody>
                  <BootstrapTable
                    {...props.baseProps}
                    keyField="id"
                    bootstrap4
                    bordered={false}
                    rowEvents={ {
                      style: { cursor: 'pointer'},
                      onClick: (e, row) => { this.setState({referrer: `/workouts/detail/${row.routine_xref}`}) }
                    }}
                    hover={true}
                    pagination={paginationFactory({
                      sizePerPage: 25,
                      sizePerPageList: [5, 10, 25, 50]
                    })}
                  />
                </CardBody>
              </div>
            )}
          </ToolkitProvider>
        </Card>

      </Container>

    );
  }
}


const mapStateToProps = (state) => ({
  general: state.general.isLoading,
});

export default connect(mapStateToProps)(WorkoutPrograms);
