import React from "react";
import store from "../../redux/store/index";
import { connect } from 'react-redux';
import SubscribeValidate from '../../config/subscribe_validate.js';
import {
  Container,
  Row,
  Col,
  CardImg,
} from "reactstrap";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
import {
  API_SUBSCRIBE_STRIPE_GET,
} from '../../redux/actions/API';
import axios from "axios";
import { keyConfig } from '../../redux/actions/auth';

import unsplash2 from "./pricing.jpg";
import PromoCards from './PromoCards'

class Thankyou extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "monthly"
    };
  }

  fetchNotifications = (sessionId) =>  {
    console.log(`${API_SUBSCRIBE_STRIPE_GET}${sessionId}`)
    axios.get(`${API_SUBSCRIBE_STRIPE_GET}${sessionId}`, keyConfig(store.getState)).then(res => {
      console.log(res.data)
      this.setState({

      });
    })
  }

  componentDidMount() {
    this.fetchNotifications(this.props.match.params.successID)
  }

  render() {
    return (

      <Container fluid className="p-0">
        <SubscribeValidate />

        <Row>
          <Col md="10" xl="10" className="mx-auto">
            <h1 className="text-center">Thank you for subscribing, {this.props.user_status.first_name}!</h1>
            <p className="lead text-center mb-4">
            We will be with you each step of the way! Keep lifting and keep growing!
            </p>




            <hr />

            <div className="text-center my-4">

            </div>

            <PromoCards />

                  <CardImg top width="100%" src={unsplash2} alt="Card image cap" />




          </Col>
        </Row>
      </Container>

    );
  }
}



const mapStateToProps = (state) => ({
  loginflag: state.auth.loginFlag,
  user_status: state.auth.user,
});

export default connect(mapStateToProps)(Thankyou);
