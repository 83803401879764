import React from "react";

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  Spinner
} from "reactstrap";






const Clients = () => (
  <Container fluid>



    <Card>
    <CardHeader>
      <CardTitle tag="h5">Exercise Dictionary</CardTitle>
      <h6 className="card-subtitle text-muted">
        Monitor your exercise performances
      </h6>
    </CardHeader>
    <CardBody>
      <div className="mb-2">
        <Spinner color="dark" type="grow" size="sm" className="mr-2" />
          <Spinner
            color="primary"
            type="grow"
            size="sm"
            className="mr-2"
          />
      </div>
    </CardBody>
    </Card>

  </Container>
);

export default Clients;
