import React from "react";
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Navbar,
  NavbarBrand,
  Table
  } from "reactstrap";
import {
  API_COPYRIGHT,
  API_COPYRIGHT_MOBILE,
} from '../../../redux/actions/API';
import { keyConfig } from '../../../redux/actions/auth';
import axios from "axios";
import store from "../../../redux/store/index";
import Loader from "../../../components/Loader.js"

const IntroOrig = () => {

    return (
    <section className="landing-intro pt-5 vh-50" style={{height:'125px'}}>
      <Container fluid>
        <Row>
          <Col md="8" className="mx-auto text-left mt-5">
            <h1 className="landing-intro-title my-4" style={{fontSize:'62px'}}>Open Source</h1>
            <h1 className="landing-intro-title my-4" style={{fontSize:'62px'}}>Notice</h1>
          </Col>
        </Row>
        <Row>
        </Row>
      </Container>
    </section>
  )};

const Plugins = (props) => (
    <Container className="pt-5 bg-light">
      <Row>
        <Col lg={10} xl={8} className="mx-auto">
          <h1 className="h3">Notices for Software Components On {props.type}</h1>
          <hr className="my-4" />
          <p className="mb-4 text-lg">
          Last updated on March 4, 2021
          </p>

          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Documentation / Website</th>
              </tr>
            </thead>

            <tbody>
            {props.data.map((a,b) =>
              <tr>
                <td>{a.name}</td>
                <td>
                  <a
                    href={a.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {a.link}
                  </a>
                </td>
              </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );

class CopyRight extends React.Component {

  fetchCopyRight = async () =>  {
    await axios.get(`${API_COPYRIGHT}`, keyConfig(store.getState)).then(res => {

      this.setState({
        api_copyright: res.data,
        api_copyright_loading: false
      });
    });
    await axios.get(`${API_COPYRIGHT_MOBILE}`, keyConfig(store.getState)).then(res => {
      this.setState({
        api_copyright_mobile: res.data,
        api_copyright_mobile_loading: false
      });
    });
  }

  constructor(props) {
      super(props)
      this.myRef = React.createRef()
      this.state = {
        count: 0,
        api_copyright: [],
        api_copyright_loading: true,
        api_copyright_mobile: [],
        api_copyright_mobile_loading: true
      }
  }

  componentDidMount() {
    this.fetchCopyRight();
  }

  renderNav = () => {
    return(
      <Navbar   expand="sm" className="absolute-top w-100 m-0 p-3" >
        <Container >
          <NavbarBrand className="font-weight-bold text-white" href="/" style={{'fontSize':'28px'}}>
            ironroom.
          </NavbarBrand>
        </Container>
      </Navbar>
    )
  }

  renderBody = () => {
    return(
      <Container className="bg-dark">
        <Row >
          <Plugins
            data = {this.state.api_copyright} type = {"Website"}
            />
            <Plugins
              data = {this.state.api_copyright_mobile} type = {"Mobile"}
              />

        </Row>
      </Container>
    )
  }

  render() {
    if (this.props.isAuthenticated) {
      return <Redirect to="/dashboard" />;
    }
    else if (this.state.api_copyright_loading || this.state.api_copyright_mobile_loading){
      return (<Loader />)
    }
    return (
      <div>
        {this.renderNav()}
        <IntroOrig />
        {this.renderBody()}
      </div>

    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isLoading: state.auth.isLoading,
});

export default connect(mapStateToProps)(CopyRight);
