import React from "react";
import { toastr } from "react-redux-toastr";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Form,
  FormGroup,
  Input,
  Label,
  Spinner
} from "reactstrap";
import { connect } from 'react-redux';
import Api from '../../../config/auth';



class ForgotPassword extends React.Component {

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit = async (e) => {
    e.preventDefault();
    await this.setState({isLoading: true})
    await Api.pwdreset(this.state.email, (success, data) => {
      if ( success) {
        console.log('success')
         this.setState({isSuccess: true, isLoading:false})
      } else {
        console.log(data)
        this.setState({isError: true, isLoading: false})
      }
    });
  };



  constructor(props) {
    super(props);
    this.state = {
      current: null,
      email: null,
      title: "",
      message: "New password has been saved.",
      type: "success",
      timeOut: 5000,
      showCloseButton: true,
      progressBar: true,
      position: "top-right",
      result: null,
      errors: [],
      success: [],
      myValueCurrent: '',
      myValuePwd1: '',
      myValuePwd2: '',
    };
  }



  showToastr = (val) => {
    const options = {
      timeOut: parseInt(this.state.timeOut),
      showCloseButton: this.state.showCloseButton,
      progressBar: this.state.progressBar,
      position: this.state.position,
    };

    const toastrInstance = toastr.success;

    toastrInstance(
      this.state.title,
      this.state.message,
      options
    );
  }

  renderEmailInput = () => {
    return(
      <FormGroup>
        <Label>E-Mail </Label>
        <Input required type="email" name="email" placeholder="Enter your email" onChange={this.onChange} />
      </FormGroup>
    )
  }





  render() {
    return(
      <Card>
        <CardHeader>
          <CardTitle tag="h5">Reset Password</CardTitle>
          <h6 className="card-subtitle text-muted">
            Forgot password? We can send you a reset link.
          </h6>
        </CardHeader>
        <CardBody>
          <Form onSubmit={this.onSubmit}>
            {this.renderEmailInput()}
            {this.state.isSuccess?
            <Button color="success">Submit</Button>
            :
            this.state.isError?
            <Button color="danger">Submit</Button>
            :
            this.state.isLoading?
            <Spinner color="secondary" />
            :
            <Button color="primary">Submit</Button>
          }
          </Form>
              {this.state.isSuccess ?
                <p className="pt-1 text-success"> An email has been sent with password reset instructions </p>
                :
                null
              }
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  theme: state.theme.currentTheme
});

export default connect(mapStateToProps, {  })(ForgotPassword);
