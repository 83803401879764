import React from "react";
import Chart from "react-apexcharts";
import { connect } from "react-redux";
import moment from "moment";

import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";

const AreaChart = ({ theme, ...props  }) => {
  const data = [
    {
      name: "Progression",
      type: "line",
      data: props.weight_progression.map((s,t)=>(s.weight ))
    },

  ];

  const options = {
    chart: {
      zoom: {
        enabled: true
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: [5, 7, 5],
      curve: "smooth",
      dashArray: [0, 8, 5]
    },
    markers: {
      size: 5,
      style: "hollow" // full, hollow, inverted
    },
    xaxis: {
      type: "datetime",
      categories: props.weight_progression.map((s,t)=>(moment(s.timestamp).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") )),
      datetimeFormatter: {
        year: 'yy',
        month: 'MMM \'yy',
        day: 'dd MMM',
        hour: 'HH:mm'
      },
    },
    tooltip: {
      x: {
          format: "dd-MMM-yyyy HH:mm"
      },
      y: [
        {
          title: {
            formatter: function(val) {
              return val + ' (' + props.weight_progression[0].measure_value + ')';
            }
          }
        },
      ]
    },
    colors: [
      theme.warning,
      theme.success,
      theme.primary,
      theme.danger,
      theme.info
    ]
  };

  return (
    <Card  className="flex-fill w-100">
    <CardHeader>
      <CardTitle tag="h5">Body Weight Progress</CardTitle>
      <h6 className="card-subtitle text-muted">

      </h6>
    </CardHeader>
      <CardBody>
        <div className="chart w-100">
          <Chart
          options={options}
          series={data}
          type="line"
          height="350" />
        </div>
      </CardBody>
    </Card>
  );
};

export default connect(store => ({
  theme: store.theme.currentTheme
}))(AreaChart);
