import React from "react";
import {
  Container,
  Row,
  Col,
} from "reactstrap";
import { connect } from 'react-redux';

import BlockedUsers from './BlockedUsers'
import Privacy from './Privacy'

import Navigation from '../Navigation';
import Header from './Header';


class Default extends React.Component {

  render() {
    return(
      <Container fluid>

      <Header />

        <Row>
          <Col md="3" xl="2">
            <Navigation activeName = {"privacy"}/>
          </Col>
          <Col md="9" xl="10">
            <Privacy />
            <BlockedUsers />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, {  })(Default);
