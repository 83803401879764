import React from "react";
import axios from "axios";
import { keyConfig } from '../../redux/actions/auth';
import store from "../../redux/store/index";
import { API_WORKOUTS_POPULAR } from '../../redux/actions/API'
import { Link } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Row,
} from "reactstrap";

import { exerciseTracker } from '../../redux/actions/lift_tracking';
import { connect } from 'react-redux';
import CreateWorkout from '../../components/Create/CreateWorkout.js'

class WorkoutNotFound extends React.Component {


  fetchData = () =>  {
    axios.get(`${API_WORKOUTS_POPULAR}`, keyConfig(store.getState)).then(res => {
      this.setState({
        workouts_popular: res.data
      });

    });
  }



  componentDidMount() {
    this.fetchData();
  }



  constructor(props) {
    super(props);
    this.state = {
      lift_tracking_input: [],
      workouts_popular: [],
      title: "",
      message: "",
      type: "success",
      timeOut: 5000,
      showCloseButton: true,
      progressBar: true,
      position: "top-right",
    };
  }




  render() {

    return (

      <Container fluid className="p-0">


        <Row>
          <Col md="10" xl="8" className="mx-auto">
            <h1 className="text-center">Record Lifts</h1>
            <p className="lead text-center mb-4">
              First join a workout and then you'll be able to log your progress here
            </p>

            <Row className="justify-content-center mt-3 mb-2">
              <Col xs="auto d-flex flex-row justify-content-center align-items-center">

        
                <Button tag={Link} to="/workouts/All" color="primary"  className="btn mr-1 mb-1" size="lg">
              Discover Workouts
                </Button>

                <CreateWorkout source={"record_custom"}/>



              </Col>
            </Row>

            <hr />


          </Col>
        </Row>
      </Container>


    );
  }
}


const mapStateToProps = (state) => ({
  user_id: state.auth.user.pk,
  user_status: state.auth.user,
  general: state.general.isLoading,
  theme: state.theme.currentTheme,
});
export default connect(mapStateToProps, { exerciseTracker })(WorkoutNotFound);
