import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { login, loginSocialFacebook } from '../../redux/actions/auth';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import {
  faFacebook,
} from "@fortawesome/free-brands-svg-icons";
import {
  Button,
  Spinner,
} from "reactstrap";

export class FacebookButton extends Component {


  state = {
    loader: false,
  };

  responseFacebook = async (response) => {
    await this.setState({loader: true})
    const result = await this.props.loginSocialFacebook(response.accessToken)
    if (result !== null && result.response) {
      this.setState({loader: false})
    }
  }

  renderMemo = () => {
    return(
      <div className="text-center mt-0">
        <p className="lead text-sm text-muted text-center">
          By signing up for you agree to the <Link to="/legal">Terms and Conditions</Link> and <Link to="/legal">Privacy Policy</Link>.
        </p>
      </div>
    )
  }

  render() {
    return(
      <FacebookLogin
        appId="3787878604614631"
        callback={this.responseFacebook}
        render={renderProps => (
          <Button
            onClick={renderProps.onClick}
            color="facebook" 
            size="lg"
            type="submit"
            className="mr-1 btn-block flex d-flex justify-content-center align-items-center"
            icon="facebook"
            >

          {this.state.loader?
            <Spinner color="secondary" />
            :

          <>
          <FontAwesomeIcon icon={faFacebook} className="align-middle float-left mr-3" />{" "}
          {this.props.source === "signin" ?
                <div>Login in using <b>Facebook</b> </div>
                :
                this.props.source === "landing" ?
                <div>  Continue with <b>Facebook</b> </div>
                :
            <div>  Sign Up with <b>Facebook</b> </div>
            }
            </>
          }
          </Button>
        )}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated
  };
}

export default connect(mapStateToProps, { login, loginSocialFacebook })(FacebookButton);
