import React, { useState }  from "react";
import {
  Button,
  Container,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardText,
  Modal, ModalHeader, ModalBody, ModalFooter, Spinner
} from "reactstrap";
import { connect } from 'react-redux';
import store from "../../../redux/store/index";
import axios from "axios";
import { keyConfig } from '../../../redux/actions/auth';
import {
  API_SUBSCRIBE_STRIPE_SUBSCRIBE_HISTORY,
  API_USER_PROFILES_SUBSCRIBED,
  API_SUBSCRIBE_STRIPE_GET_SUBSCRIBE_UPCOMING,
  API_SUBSCRIBE_STRIPE_SUBSCRIBE_DELETE,
  API_SUBSCRIBE_STRIPE_SUBSCRIBE_REFUND,
  API_SUBSCRIBE_STRIPE_REFUND_HISTORY,
  API_SUBSCRIBE_STRIPE_GET_CHARGE,
  API_SUBSCRIBE_STRIPE_GET_SUBSCRIBE
 } from '../../../redux/actions/API'
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import moment from "moment";
import { calendarStrings } from '../../../redux/actions/timeconfig';
import Subscribe from "../../../pages/pages/Subscribe.js"

const ModalRefund = (props) => {
  const {
    className
  } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const toggleRefund = async () => {
    await props.refundSubscriptionFromStripe(props.payment_intent)
    await setModal(!modal);
  }

  return (
    <div>
      <Button color="danger" onClick={toggle}>refund & cancel</Button>
      <Modal isOpen={modal} centered toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>Cancel Membership And Refund</ModalHeader>
        <ModalBody>
          Are you sure you want cancel your membership? <br/><br/>

          If so we will refund you fully and you will not be billed again in the future. <br/><br/>

          Thank you for lifting, you will always be welcomed back! Keep strong and keep growing!
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>Cancel</Button>
          <Button color="danger" onClick={toggleRefund}>Confirm Refund</Button>{' '}
        </ModalFooter>
      </Modal>
    </div>
  );
}

const CancelTrial = (props) => {
  const {
    className
  } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const toggleRefund = async () => {
    await props.cancelTrial(props.subscription_id)
    await setModal(!modal);
  }

  return (
    <div>
      <Button color="danger" onClick={toggle}>cancel membership</Button>
      <Modal isOpen={modal} centered toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>Cancel Membership</ModalHeader>
        <ModalBody>
          Are you sure you want cancel your upcoming membership? <br /><br />

          After your current preriod, all premium features will be cancelled and you will not be billed again in the future. <br /><br />

          Thank you for lifting, you will always be welcomed back! Keep strong, and keep growing!
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>Go back</Button>
          <Button color="danger" onClick={toggleRefund}>Confirm Cancel</Button>{' '}
        </ModalFooter>
      </Modal>
    </div>
  );
}

class Default extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      subscription_id: null,
      payment_intent: null,
      subscribe_history: [],
      subscribe_history_loading: true,
      subscribe_upcoming: [],
      subscribe_upcoming_loading: true,
      showRefund: false,
      refund_history_loading: true,
      currentSubscribed: false,
      currentSubscribed_loading: true,
      on_trial: false,

    };
  }

  fetchCharge = (chargeID) =>  {
    console.log('tt')
    console.log(`${API_SUBSCRIBE_STRIPE_GET_CHARGE}${chargeID}`)
    axios.get(`${API_SUBSCRIBE_STRIPE_GET_CHARGE}${chargeID}`, keyConfig(store.getState)).then(res => {
      console.log('++ ee')
      console.log(res.data)

    })
  }

  fetchSubscribedFromStripeHistory = (stripeID) =>  {
    axios.get(`${API_SUBSCRIBE_STRIPE_SUBSCRIBE_HISTORY}${stripeID}`, keyConfig(store.getState)).then(res => {
      this.refundHistory(res.data[0].payment_intent, moment.unix(res.data[0].lines.data[0].period.start + (60 * 60)).isAfter(moment()))
      this.setState({
        subscription_id: res.data[0].subscription,
        payment_intent: res.data[0].payment_intent,
        on_trial: res.data[0].charge === null? true : false,
        subscribe_history: res.data,
        subscribe_history_loading: false,
      })
    });

    axios.get(`${API_SUBSCRIBE_STRIPE_GET_SUBSCRIBE_UPCOMING}${stripeID}`, keyConfig(store.getState)).then(res => {
      console.log('eeee')
      console.log(res.data)
      this.setState({
        subscription_id: res.data.subscription,
        subscribe_upcoming:  [res.data],
        subscribe_upcoming_loading: false,
      })
    }).catch((err) => {
      console.log(err)
      console.log('ff')
      this.setState({
        subscribe_upcoming_loading: false,
        subscribe_upcoming: []
      });
    });

    axios.get(`${API_SUBSCRIBE_STRIPE_GET_SUBSCRIBE}${stripeID}`, keyConfig(store.getState)).then(res => {
      this.setState({currentSubscribed: true,currentSubscribed_loading:false})
    }).catch((err) => {
      this.setState({currentSubscribed: false,currentSubscribed_loading:false})
    });

  }

  deleteSubscriptionFromStripe = (subID) =>  {
    this.setState({subscribe_history_loading: true, currentSubscribed: false,currentSubscribed_loading:true})
    axios.get(`${API_SUBSCRIBE_STRIPE_SUBSCRIBE_DELETE}${subID}`, keyConfig(store.getState)).then(res => {
      this.setState({
        subscribe_upcoming:  [res.data],
        subscribe_upcoming_loading: false,
      })
      this.fetchSubscribed();
    });
  }

  refundSubscriptionFromStripe = (paymentID) =>  {
    this.setState({subscribe_history_loading: true})
    axios.get(`${API_SUBSCRIBE_STRIPE_SUBSCRIBE_REFUND}${paymentID}`, keyConfig(store.getState)).then(res => {
      this.deleteSubscriptionFromStripe(this.state.subscription_id);
    });
  }

  refundHistory = (paymentID, timelimit) =>  {
    console.log(`${API_SUBSCRIBE_STRIPE_REFUND_HISTORY}${paymentID}`)
    axios.get(`${API_SUBSCRIBE_STRIPE_REFUND_HISTORY}${paymentID}`, keyConfig(store.getState)).then(res => {
      console.log('++')
      console.log(res.data.data.length === 0 && timelimit? 'a' : 'b')
      console.log(timelimit)
      this.setState({
        showRefund: res.data.data.length === 0 && timelimit? true : res.data.data[0].status === "succeeded"? false : true,
        refund_history_loading:false,
      })
    }).catch((err) => {
      this.setState({
        showRefund: timelimit,
        refund_history_loading:false,
      });
    });


  }

  fetchSubscribed = () =>  {
    axios.get(`${API_USER_PROFILES_SUBSCRIBED}`, keyConfig(store.getState)).then(res => {
      if (res.data[0].stripe_id) {
        this.fetchSubscribedFromStripeHistory(res.data[0].stripe_id)
      }
      else if (!res.data[0].stripe_id) {
        this.setState({showSubscribe:true, showSubscribe_loading:false})
      }
    });
  }

  componentDidMount() {
    this.fetchSubscribed()
  }


  renderUpcoming = () => {
    return(
      <Card>
        <ToolkitProvider
          keyField="lines.data[0].period.start"
          data={this.state.subscribe_upcoming}
          columns={

          [
            {
              dataField: "lines.data[0].period.start",
              text: "Starts",
              sort: true,
              formatter: (cellContent, row) => {
                return <>{moment.unix(cellContent).calendar(null,calendarStrings)}</>;
              }
            },
            {
              dataField: "lines.data[0].period.end",
              text: "Ends",
              sort: true,
              formatter: (cellContent, row) => {
                return <>{moment.unix(cellContent).calendar(null,calendarStrings)}</>;
              }
            },
            {
              dataField: "lines.data[0].description",
              text: "Description",
              sort: true,
            },
            {
              dataField: "hosted_invoice_url",
              isDummyField: true,
              text: "Options",
              hidden: false,
              formatter: (cellContent, row) => {
                return <div>
                {this.state.on_trial?
                  <CancelTrial
                  subscription_id = {this.state.subscription_id}
                  cancelTrial = {this.deleteSubscriptionFromStripe}
                  />
                  :
               <Button className="mr-1" color="danger" onClick={()=> this.deleteSubscriptionFromStripe(this.state.subscription_id)}>  Cancel Membership </Button>
             }
                  </div>;
              }
            },
          ]
        }
        >
          {(props) => (
            <div>
              <CardHeader className="d-flex flex justify-content-between align-items-center">
                <div>
                <CardTitle tag="h5">My Ironroom Upcoming Payment</CardTitle>

                </div>



              </CardHeader>
              <CardBody>
                <BootstrapTable
                  {...props.baseProps}
                  keyField="lines.data[0].period.start"
                  bootstrap4
                  bordered={false}
                  rowEvents={ {
                    style: { cursor: 'pointer'},
                  }}
                  hover={true}
                />
              </CardBody>
            </div>
          )}
        </ToolkitProvider>
      </Card>
    )
  }


  render() {
    if (this.state.showSubscribe_loading) {
      return(
      <div className="flex d-flex justify-content-center align-items-center">
      <Spinner color="primary" />
      </div>
    )
    } else if (!this.state.showSubscribe_loading && this.state.showSubscribe) {
      return(
        <Container fluid>

        <Card>

            <CardBody>
              <CardTitle tag="h5">Subscriptions</CardTitle>
              <CardText>No Subscriptions Yet</CardText>

            </CardBody>
          </Card>


        </Container>
    )
    }
    else if(
      this.state.subscribe_history_loading ||
      this.state.subscribe_upcoming_loading ||
      this.state.refund_history_loading ||
      this.state.currentSubscribed_loading
    ){
      return(
        <div className="flex d-flex justify-content-center align-items-center">
        <Spinner color="primary" />
        </div>
      )
    }
    return(
      <Container fluid>

        <Card>
          <ToolkitProvider
            keyField="lines.data[0].period.start"
            data={this.state.subscribe_history}
            columns={

            [
              {
                dataField: "lines.data[0].period.start",
                text: "Starts",
                sort: true,
                formatter: (cellContent, row) => {
                  return <>{moment.unix(cellContent).calendar(null,calendarStrings)}</>;
                }
              },
              {
                dataField: "lines.data[0].period.end",
                text: "Ends",
                sort: true,
                formatter: (cellContent, row) => {
                  return <>{moment.unix(cellContent).calendar(null,calendarStrings)}</>;
                }
              },
              {
                dataField: "lines.data[0].description",
                text: "Description",
                sort: true,
              },
              {
                dataField: "hosted_invoice_url",
                isDummyField: true,
                text: "Options",
                hidden: false,
                formatter: (cellContent, row) => {
                  return <div>
                  {

                    row.charge === null && !this.state.currentSubscribed?

                    <a href={row.hosted_invoice_url} target="_blank" rel="noopener noreferrer">  <Button className="mr-1" color="info">  invoice  </Button> </a>

                    :


                    row.charge === null?

                    <a href={row.hosted_invoice_url} target="_blank" rel="noopener noreferrer">  <Button className="mr-1" color="info">  invoice  </Button> </a>



                    :

                    row.charge.refunded?
                    <a href={row.charge.receipt_url} target="_blank" rel="noopener noreferrer">  <Button className="mr-1" color="info">  refunded  </Button> </a>

                    :

                    this.state.showRefund && !row.charge.refunded?

                    <ModalRefund
                    payment_intent = {this.state.payment_intent}
                    refundSubscriptionFromStripe = {this.refundSubscriptionFromStripe}
                    />


                    :
                    <a href={row.charge.receipt_url} target="_blank" rel="noopener noreferrer">  <Button className="mr-1" color="primary">  invoice  </Button> </a>
                  }
                    </div>;
                }
              },
            ]
          }
            exportCSV
            search
          >
            {(props) => (
              <div>
                <CardHeader className="d-flex flex justify-content-between align-items-center">
                  <div>
                  <CardTitle tag="h5">My Ironroom Subscription History</CardTitle>

                  </div>



                </CardHeader>
                <CardBody>
                  <BootstrapTable
                    {...props.baseProps}
                    keyField="lines.data[0].period.start"
                    bootstrap4
                    bordered={false}
                    rowEvents={ {
                      style: { cursor: 'pointer'},
                      onClick: (e, row) => { this.setState({referrer: `/exercises/detail/${row.id}`}) }
                    }}
                    hover={true}
                    pagination={paginationFactory({
                      sizePerPage: 25,
                      sizePerPageList: [5, 10, 25, 50]
                    })}
                  />
                </CardBody>
              </div>
            )}
          </ToolkitProvider>
        </Card>


        {
        this.state.subscribe_upcoming.length === 0 ?

            <Subscribe type={'year'} freetrial={false} landing={true}/>

          :

          null}


        {this.state.subscribe_upcoming.length === 0?

           null:

          this.state.showRefund && this.state.on_trial?

              this.renderUpcoming()
                :

                !this.state.showRefund?

                this.renderUpcoming()

                :
                null

                }



      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  theme: state.theme.currentTheme
});

export default connect(mapStateToProps, {  })(Default);
