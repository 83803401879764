import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Col,
  Row,
} from "reactstrap";
import PatchWorkout from "../../components/Create/PatchWorkout.js"
import { ArrowLeft } from "react-feather";

const Header = (props) => {
  return (
    <Row className="mb-2 mb-xl-4  d-flex flex-row justify-content-between align-items-center">
      <Col xs="auto" className="d-none d-sm-block">
        <h3>{props.routine.routine}</h3>
      </Col>

      <Col xs="auto" className="ml-auto text-right mt-n1">




      {props.routine_owner === props.user_id?
        <div>
        <Button color="primary" className="shadow-sm mr-1" tag={Link} to="/myworkouts/">
          <ArrowLeft className="feather" /> My Workouts
        </Button>

        <PatchWorkout workoutID={props.workoutID} routine = {props.routine} fetchWorkouts = {props.fetchWorkouts}/>

        </div>
        :
        <Button color="primary" className="shadow-sm" tag={Link} to="/workouts/All">
          <ArrowLeft className="feather" /> Back to Workouts
        </Button>
      }

      </Col>
    </Row>
  );
};

export default Header;
