import React from "react";
import Chart from "react-apexcharts";
import { connect } from "react-redux";
import { Card, CardBody } from "reactstrap";
import { Badge, CardHeader, CardTitle } from "reactstrap";
import moment from "moment";

const AreaChart = ({ theme, ...props }) => {

  console.log(props.data)
  return (

      <Card className="flex-fill w-100">
      <CardHeader>
        <Badge color="primary" className="float-right">

        </Badge>
        <CardTitle tag="h5" className="mb-0">
          Time Series
        </CardTitle>
      </CardHeader>

          <CardBody>
            <div className="chart w-100">
              <Chart

                toggleseries= "progression"
                options=
                {
                  {
                    chart: {
                      zoom: {
                        enabled: true
                      },
                      type: 'area',
                      stacked: false,
                    },
                    dataLabels: {
                      enabled: false
                    },
                    stroke: {
                      width: [5, 7, 5],
                      curve: "smooth",
                      dashArray: [0, 8, 5]
                    },
                    markers: {
                      size: 5,
                      style: "hollow" // full, hollow, inverted
                    },
                    xaxis: {
                      type: "datetime",
                      categories: props.data.map((s,t)=>( moment(s.date_time_iso).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") )),
                      labels: {
                        format: 'MMM-yyyy',
                        },
                      datetimeFormatter: {
                        year: 'yy',
                        month: 'MMM \'yy',
                        day: 'dd MMM',
                        hour: 'HH:mm'
                      }
                    },
                    yaxis: {
                      showAlways: true,
                    },
                    tooltip: {
                      x: {
                        format: "dd-MMM-yyyy HH:mm"
                      },
                      y: [
                        {
                          title: {
                            formatter: function(val) {
                              return val + ' (' + props.measure_value + ')';
                            }
                          }
                        },
                      ]
                    },
                    grid: {
                      borderColor: "#f1f1f1"
                    },
                    colors: [
                      theme.primary,
                      theme.success,
                      theme.warning,
                      theme.danger,
                      theme.info
                    ]
                  }
                }

                series={[
                  {
                    name: "Progression",

                    data: props.data.map((s,t)=>(s.progression ))
                  },
                ]}
              type="line" height="350" />
            </div>
          </CardBody>
        </Card>



  );
};

export default connect(store => ({
  theme: store.theme.currentTheme
}))(AreaChart);
