import React from "react";
import {  withRouter} from 'react-router-dom';
import { connect } from "react-redux";
import store from "../../redux/store/index";
import ApiLiftTracker from '../../config/lift_tracking';
import {
  API_WORKOUTS_CREATE_QUICK,
  } from '../../redux/actions/API';
import axios from "axios";
import { keyConfig } from '../../redux/actions/auth';
import {
  Button,
  Spinner,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';



class PatchExercise extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      daytitle_loading: true,
      daytitle: null,
      routine_xref: null,
      loading: false,
    };
  }

  onSubmit = async (e) => {
    await this.setState({loading: true})
    await e.preventDefault();
    await ApiLiftTracker.workoutQuickPatch(
      this.props.workoutID,
      this.state.daytitle,
      (success, data) => {
      if ( success) {
        console.log('success')
        this.props.fetchDetail(this.state.routine_xref)
        this.props.toggle()
      } else {
        console.log('fail')
      }
    });
  };

  onDelete = async (e) => {
    await e.preventDefault();
    await ApiLiftTracker.workoutDelete(this.props.workoutID, (success, data) => {
      if ( success) {
        console.log('success')
        this.setState({id: 0})
      } else {
        console.log('fail')
      }
    });
  };

  onDeleteConfirm = () => {
    this.setState({confirm: true})
  }

  fetchData = async () =>  {


    await axios.get(`${API_WORKOUTS_CREATE_QUICK}${this.props.workoutID}/`, keyConfig(store.getState)).then(res => {
      console.log(res.data)
      this.setState({
          daytitle: res.data.day_title,
          daytitle_loading: false,
          routine_xref: res.data.routine_xref
      });
        this.fetchSubGroup()
    }).catch((err) => {
        this.setState({
          daytitle: null,
          daytitle_loading: false,
        });
      });




  }

  componentDidMount() {
    console.log(this.props.workoutID)
    this.fetchData()
  }

  onChange = (e) => {
    if(e.target.name === "name"){
      this.setState({daytitle: e.target.value})
    }
  }

  render() {
    if (this.state.daytitle_loading){
      return(
        null
      )
    }
    return (
      <div>
      <ModalBody>
          <Form>
            <FormGroup>
              <Label>Day Title</Label>
              <Input
                type="text"
                name="name"
                id="name"
                required
                placeholder="Day Title"
                pattern="[a-zA-Z]+"
                value={this.state.daytitle}
                onChange={this.onChange}
                />
            </FormGroup>
          </Form>
      </ModalBody>

      <ModalFooter className="modal-footer justify-content-end">

        <div>
        <Button color="secondary" onClick={this.props.toggle}>Cancel</Button>{' '}


        {this.state.daytitle === null?
          <Button style={{width:'110px'}} className="disabled" color="primary"> Update </Button>
            :
          this.state.daytitle.length === 0?
          <Button style={{width:'110px'}} className="disabled" color="primary"> Update </Button>
          :  
         this.state.daytitle.length > 0 && !this.state.loading?
         <Button style={{width:'110px'}} color="primary" onClick = {this.onSubmit}> Update </Button>
          :
          <Button style={{width:'110px'}} color="primary" className="disabled"> <Spinner color="light" size="sm" /> </Button>
        }

        </div>
      </ModalFooter>
      </div>

    );
  }
}

const mapStateToProps = (state) => ({
  user_status: state.auth.user,
  auth: state.auth,
  sidebar: store.sidebar,
});

export default connect(mapStateToProps, {  })(withRouter(PatchExercise));
