

import axios from 'axios';
import {
  API_USER,
  API_PWD_RESET,
  API_PWD_RESET_CONFIRM,
  API_EMAIL_VERIFY,
} from '../redux/actions/API';
import { keyConfig } from './key';
import Cookies from 'js-cookie';

const _patchUserFNLN = async (firstname, lastname, callback) => {
  const body = await JSON.stringify({ firstname, lastname});
  await axios.patch(`${API_USER}/`, body, keyConfig())
  .then(res => {
    callback(true, res.data);
  }).catch(error => {
    callback(false, error.response.data);
  })
};

const _onboardingComplete = async (onboarding_complete, callback) => {
  const body = await JSON.stringify({ onboarding_complete});
  await axios.patch(`${API_USER}/`, body, keyConfig())
  .then(res => {
    callback(true, res.data);
  }).catch(error => {
    callback(false, error.response.data);
  })
};

const _patchPhoto = async (avatar_image, callback) => {
  const body = await JSON.stringify({ avatar_image});
  await axios.patch(`${API_USER}/`, body, keyConfig())
  .then(res => {
    callback(true, res.data);
  }).catch(error => {
    callback(false, error.response.data);
  })
};

const _patchUserMeasurement = async (is_imperial, callback) => {
  const body = await JSON.stringify({ is_imperial });
  await axios.patch(`${API_USER}/`, body, keyConfig())
  .then(res => {
    callback(true, res.data);
  }).catch(error => {
    callback(false, error.response.data);
  })
};

const _patchUserMeasurement2= async (usa_imperial, callback) => {
  const body = await JSON.stringify({ usa_imperial });
  await axios.patch(`${API_USER}/`, body, keyConfig())
  .then(res => {
    callback(true, res.data);
  }).catch(error => {
    callback(false, error.response.data);
  })
};

const _patchUserCity = async (location_city, callback) => {
  const body = await JSON.stringify({ location_city });
  await axios.patch(`${API_USER}/`, body, keyConfig())
  .then(res => {
    callback(true, res.data);
  }).catch(error => {
    callback(false, error.response.data);
  })
};

const _loadUser = (data, callback) => {
  axios.get(`${API_USER}`, keyConfig(data.key)).then(res => {
    callback(true, res.data);
  }).catch(error => {
    callback(false, error.response.data);
  })
};

const _pwdreset = async (email, callback) => {

  const body = await JSON.stringify({ email });

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  await axios.post(`${API_PWD_RESET}`, body, config)
  .then(res => {
    callback(true, res.data);
  }).catch(error => {
    callback(false, error.response.data);
  })
};

const _pwdresetConfirm = async (new_password1,new_password2,uid,token, callback) => {

  const body = await JSON.stringify({ new_password1,new_password2,uid,token });

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  await axios.post(`${API_PWD_RESET_CONFIRM}`, body, config)
  .then(res => {
    callback(true, res.data);
  }).catch(error => {
    callback(false, error.response.data);
  })
};

const _emailVeirfy= async (id, callback) => {

  const csrftoken = Cookies.get('csrftoken') // Cookies from Django Domain
  const config = {
    headers: {
      'X-csrftoken': csrftoken
    },
  };

  await axios.get(`${API_EMAIL_VERIFY}${id}/`,  config)
  .then(res => {
    callback(true, res.data);
  }).catch(error => {
    callback(false, error.response.data);
  })
};

const _updateProfileHeading = async (heading, callback) => {

  // Request Body
  const body = await JSON.stringify({ heading});
  await axios.patch(`${API_USER}/`, body, keyConfig())
  .then(res => {
      callback(true, res.data);
    })
  .catch(error => {
      callback(false, error.response.data);
    });
};


const _patchUserHidden = async (hidden, callback) => {
  const body = await JSON.stringify({ hidden });
  await axios.patch(`${API_USER}/`, body, keyConfig())
  .then(res => {
    callback(true, res.data);
  }).catch(error => {
    callback(false, error.response.data);
  })
};

const dict = {
  loadUser: _loadUser,
  patchUserFNLN: _patchUserFNLN,
  patchUserMeasurement: _patchUserMeasurement,
  patchPhoto: _patchPhoto,
  patchUserCity: _patchUserCity,
  patchUserMeasurement2: _patchUserMeasurement2,
  onboardingComplete: _onboardingComplete,
  pwdreset: _pwdreset,
  pwdresetConfirm: _pwdresetConfirm,
  emailVeirfy: _emailVeirfy,
  updateProfileHeading: _updateProfileHeading,
  patchUserHidden: _patchUserHidden,
};

export default dict;
