import React from "react";
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Row,
  TabContent,
  TabPane,
  CardImg,
} from "reactstrap";
import Subscribe from "./Subscribe"
import unsplash2 from "./pricing.jpg";
import PromoCards from './PromoCards'

class Pricing extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "monthly"
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render() {
    return (
      <Container fluid className="p-0">

        <Row>
          <Col md="10" xl="10" className="mx-auto">
            <h1 className="text-center">Experience the best of ironroom.</h1>
            <p className="lead text-center mb-4">
            Unlock new features that help you reach your goals and have more fun.
            </p>

            <Row className="justify-content-center mt-3 mb-2">
              <Col xs="auto">
                <ButtonGroup>
                  <Button
                    color="dark"
                    outline
                    onClick={() => {
                      this.toggle("monthly");
                    }}
                    className={
                      this.state.activeTab === "monthly" ? "active" : ""
                    }
                  >
                    Annual billing
                  </Button>
                  <Button
                    color="dark"
                    outline
                    onClick={() => {
                      this.toggle("annual");
                    }}
                    className={
                      this.state.activeTab === "annual" ? "active" : ""
                    }
                  >
                    Monthly billing
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>

            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="monthly">
                <Row className="py-4">
                  <Col sm="12" className="mb-3 mb-md-0">
                    <Subscribe type={'year'} freetrial={false}/>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="annual">
                <Row className="py-4">
                  <Col sm="12" className="mb-3 mb-md-0">
                    <Subscribe type={'month'} freetrial={false}/>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>

            <hr />

            <div className="text-center my-4">

            </div>

            <PromoCards />

                  <CardImg top width="100%" src={unsplash2} alt="Card image cap" />



                  <Row className="py-4">

                    <Col sm="12" className="mb-3 mb-md-0">
                      <hr />
                      <Subscribe
                        type={'year'}
                        freetrial={10}
                        />
                    </Col>
                  </Row>

          </Col>
        </Row>
      </Container>
    );
  }
}

export default Pricing;
