import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Media,
  Badge,
  Form,
  UncontrolledCarousel,
} from "reactstrap";
import WorkoutCard from "./WorkoutCard"
import { connect } from 'react-redux';
import ProfilePhoto from '../../../components/Social/ProfilePhoto/ProfilePhoto'
import moment from "moment";
import Comments from '../Comments/Comments'
import LikeCommentsBar from '../LikeCommentsBar/LikeCommentsBar'
import LikeCommentsSummary from '../LikeCommentsSummary/LikeCommentsSummary'

const WithControls = (props) => {
  return (

    <Card className="mt-3">
        <UncontrolledCarousel
          items={props.photos}
          indicators={false}
          controls={false}
          autoPlay={false}
          interval={false}
        />
    </Card>

  );
};

class Timeline_Feed extends React.Component {

  onChange = async (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      comment_value: e.target.value,
      myValue: e.target.value
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      comment: null,
      comment_id: null,
      commentSpin_0: 0,
      openComment: 0,
    };
  }


  renderCardHeaderRight = (x) => {
    const userid = x.user_id_xref[0].pk
    const workout_id = x.id
    const workout_link = '/profile/' + userid +'/workout/' + workout_id
    const calendarStrings = {
        lastDay : '[Yesterday at] LT',
        sameDay : '[Today at] LT',
        nextDay : '[Tomorrow at] LT',
        lastWeek : '[Last] dddd [at] LT',
        nextWeek : 'dddd [at] LT',
        sameElse : 'llll',
    };
    const card_time = moment(x.timestamp).calendar(null,calendarStrings)
    if (x.event=== 1 ) {
      return(
        <div>
          <small className="float-right text-navy">
            {card_time}
          </small>

          {this.renderCardHeaderRightLogic(x)}

          <Badge color="info" className="float-right " tag={Link} to={workout_link}>
            {x.exercises[0].user_exercise_tracker_xref.workout_day_xref.day_title}
          </Badge>

          <Form tag={Link} to={workout_link}  className="text-dark">
           <strong >{x.exercises[0].user_exercise_tracker_xref.derived__part_of_day}</strong>
           </Form>
        </div>
      )
    }
    else if (x.event=== 2) {
      const album_link = '/profile/' + x.albums.user_album_xref.user_id_xref[0].pk + '/albums/' + x.albums.user_album_xref.id;
      return(
        <div>
          <small className="float-right text-navy">
            {card_time}
          </small>

          {this.renderCardHeaderRightLogic(x)}

          <Badge color="info" className="float-right" tag={Link} to={album_link}>
             Photo Album ({x.albums.user_album_xref.photos.length})
          </Badge>

          <Form tag={Link} to={album_link}  className="text-muted">
           <strong >{x.albums.user_album_xref.album_name_normal}</strong>
           </Form>

        </div>
      )
    }
  }

  renderCardHeaderRightLogic = (x) => {
    if (x.user_id_xref[0].pk === this.props.user_status.pk || x.user_id_xref[0].pk===this.props.member_id) {
      return(
        <Link to={'/profile/'+x.user_id_xref[0].pk+'/home' }   style={{color:"black",'textDcoration':'none'}}>
          {this.renderCardHeaderRightName(x)}
        </Link>
      )
    }
    return(
      <Link to={'/profile/'+x.user_id_xref[0].pk+'/home' }   style={{color:"black",'textDcoration':'none'}}>
        {this.renderCardHeaderRightName(x)}
      </Link>
    )
  }

  renderCardHeaderRightName= (x) => {
      return(
          <p className="mb-2"  style={{color:'black'}}>
            <strong>{x.user_id_xref[0].firstname_lastname}</strong>
          </p>
      )
  }

  renderCardBody = (x) => {
    if (x.event=== 1 ) {
      return(
        <WorkoutCard
        workout = {x.exercises[0].user_exercise_tracker_xref.workout_day_xref}
        derived__start_time_local = {x.exercises[0].user_exercise_tracker_xref.recorded_end_time} />
      )
    }
    else if (x.event=== 2 ) {
      return(


        <WithControls photos={x.albums.user_album_xref.photos.slice(0,5).map(({ photo, id, height }) => {return{src: photo, key: id, height: height, caption:"" }})} onClick={null}/>

      )
    }
  }


  renderProfilePhoto = (x) => {
      return(
        <div  className = "mr-3">
        <ProfilePhoto
          avatar_image={x.user_id_xref[0].avatar_image}
          avatar_color = {x.user_id_xref[0].avatar_color}
          avatar_letter = {x.user_id_xref[0].avatar_letter}
          avatar_size={"56px"}
          letter_size={"25px"}
          photo_change_flag = {this.props.photo_change_flag}
          active_user_id = {this.props.user_status.pk }
          user_id = {x.user_id_xref[0].pk}
          />
          </div>
      )
  }

  openCommentWindow = () => {
    if (this.state.openComment === 0) {
      this.setState({openComment: 1})
    }
  }

  render() {
    return (
      <Card className="flex-fill ">
        <CardBody >
          <Media className="mb-2">
            {this.renderProfilePhoto(this.props.x)}
            <Media body>
                {this.renderCardHeaderRight(this.props.x)}
            </Media>
          </Media>
          {this.renderCardBody(this.props.x)}
          <LikeCommentsSummary
            x= {this.props.x}
            handlerOpenCommentWindow = {this.openCommentWindow}
            />
          <LikeCommentsBar
            x= {this.props.x}
            handlerOpenCommentWindow = {this.openCommentWindow}
            callbackPageReload = {this.props.callbackPageReload}
            index = {this.props.index}
            source = {"timeline"}
            link = {
                this.props.x.event === 1? '/profile/' + this.props.x.user_id_xref[0].pk +'/workout/' + this.props.x.id
                :
                this.props.x.event === 2? '/profile/' + this.props.x.albums.user_album_xref.user_id_xref[0].pk + '/albums/' + this.props.x.albums.user_album_xref.id
                :
                null
              }
            flag_like_spinner_value = {this.props.flag_like_spinner_value}
            flag_like_spinner_handler = {this.props.flag_like_spinner_handler}
            />
          {this.state.openComment === 1?
            <div>

            <Comments
              x={this.props.x}
              callbackPageReload = {this.props.callbackPageReload}
              index = {this.props.index}
              flag_comment_spinner_value = {this.props.flag_comment_spinner_value}
              flag_comment_spinner_handler = {this.props.flag_comment_spinner_handler}
              source = {"timeline"}
              />
          </div>
          :
          null
        }
        </CardBody>
      </Card>
  );
 }
}

const mapStateToProps = (state) => ({
  user_status: state.auth.user,
  theme: state.theme.currentTheme
});

export default connect(mapStateToProps, {  })(Timeline_Feed);
