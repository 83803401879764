import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import 'aos/dist/aos.css';
import {
  Button,
  Col,
  Row,
  FormGroup,
  Label,
  Input,
  Form,
} from "reactstrap";
import { login } from '../../redux/actions/auth';

class LandingSignIn extends React.Component {

  state = {
    email: '',
    password: '',
    result: null,
    errors: []
  };

  componentDidUpdate() {
    if (this.state.result !== null && this.state.result.response) {
      this.setState({
        result: null,
        success: [],
        errors: Object.keys(this.state.result.response.data).map(field => {
            return {field, message: this.state.result.response.data[field]};
          }),
      });
    }
  }


  onSubmit = async (e) => {
    e.preventDefault();
    const result = await this.props.login(this.state.email, this.state.password);
    await this.setState({ result: result })
  };


  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }


  renderEmail = (email) => {
    if (this.state.errors.length > 0 ) {
      return(

        <FormGroup>


          <Input
            type="email"
            name="email"
            id="email"
            bsSize="lg"
            placeholder= "E-mail"
            onChange={this.onChange}
            required
            className="border-danger"

          />
            <Label  className="text-danger"> {this.state.errors[0].message[0]} </Label>
        </FormGroup>


      )
    }
    return(

        <FormGroup>


          <Input
            type="email"
            name="email"
            id="email"
            bsSize="lg"
            placeholder= "E-mail"
            onChange={this.onChange}
            required

          />

        </FormGroup>
    )
  }

  renderPwd = (password) => {
    if (this.state.errors.length > 0 ) {
      return(
        <FormGroup>

          <Input
            type="password"
            name="password"
            onChange={this.onChange}
            id="password"
            bsSize="lg"
            placeholder= "Password"
            required
            className="border-danger"
          />
            <Label tag={Link} to="/auth/sign-in"> more options </Label>
        </FormGroup>
      )
    }
    return(

        <FormGroup>

          <Input
            type="password"
            name="password"
            onChange={this.onChange}
            id="password"
            bsSize="lg"
            placeholder= "Password"
            required
          />
            <Label tag={Link} to="/auth/sign-in"> more options </Label>
        </FormGroup>
    )
  }

  render() {
    const { email, password } = this.state;
    return (



            <div className="p-0 m-0 d-flex justify-content-between">
              <Form onSubmit={this.onSubmit}>
              <Row form className="p-0 m-0 d-flex justify-content-between " >
                  <Col md={5}>

                    {this.renderEmail(email)}

                  </Col>
                  <Col md={5}>

                      {this.renderPwd(password)}


                  </Col>
                  <Col md={2} className="">
                    <FormGroup>

                      <Button
                      type="submit"
                      color="light"
                        size="lg"
                      >
                      Login </Button>
                    </FormGroup>
                  </Col>
              </Row>
              </Form>
            </div>




    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isLoading: state.auth.isLoading,
});

export default connect(mapStateToProps, { login })(LandingSignIn);
