import React from "react";
import { Redirect } from 'react-router-dom';
import {  withRouter} from 'react-router-dom';
import Select from 'react-select'
import { connect } from "react-redux";
import store from "../../redux/store/index";
import ApiLiftTracker from '../../config/lift_tracking';
import {
  API_WORKOUTS_VIEW_FULL_DETAIL, API_WORKOUTS_VIEW_FULL
  } from '../../redux/actions/API';
import axios from "axios";
import { keyConfig } from '../../redux/actions/auth';
import {
  Button,
  Row,
  Col,
  Spinner,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';

class AddExerciseBody extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      originalRowCount: null,
      full_quick:[],
      full_quick_loading: true,
      rowArray: [],
      rowCount: 0,
      selectedOption: null,
      exercise_count: null,

      loading: false,
      id: null,

      full_order: null
    };
  }

  onSubmit = async() => {
    this.setState({loading:true})
    await ApiLiftTracker.workoutDeleteFull(this.props.fullID,(success, data) => {
    if ( success) {
      console.log('success')
      this.getNewFull()
    } else {
      console.log('fail')
    }
  });
  }



  getNewFull = async () =>  {
    await axios.get(`${API_WORKOUTS_VIEW_FULL}${this.props.workout_day_id}`, keyConfig(store.getState)).then(res => {
      if(res.data.length===0){
        this.props.fetchWorkouts(this.props.match.params.workoutID)
        this.props.toggle()
      }
      else if (res.data.length > 0){
        this.refreshFull(res.data)
      }
    }).catch((err) => {
        console.log(err.response.data)
      });
  }


  refreshFull = async (full_data) => {
      var i;
      for (i = 0; i < full_data.length; i++) {
        const loop_i = await (i+1)
        const loop_defualt_i = await (i)
        await ApiLiftTracker.workoutPatchFull(full_data[i].id, loop_i, (success, data) => {
          if ( success) {
            console.log('success')
            if(loop_defualt_i === full_data.length - 1) {
              this.props.fetchWorkouts(this.props.match.params.workoutID)
              this.props.toggle()
            }
          } else {
            console.log('fail')
          }
        });
      }
  };

  assignValues = async (sets) => {
    await this.setState({rowCount:sets.length-1, originalRowCount:sets.length-1})
    await Object.keys(sets).map(a => {
        return this.setState({
          ['set_id_'+a] : sets[a].id,
          ['min_reps_'+a] : sets[a].rep_count,
          ['max_reps_'+a] : sets[a].rep_count_max,
          rowArray: this.state.rowArray.concat(Number(a))
        })
      })

  }

  fetchData = async () =>  {
    await axios.get(`${API_WORKOUTS_VIEW_FULL_DETAIL}${this.props.fullID}`, keyConfig(store.getState)).then(res => {
      this.assignValues(res.data.sets)
      this.setState({
          full_order: res.data.length + 1,
          full_quick: res.data,
          full_quick_loading: false
      });
    }).catch((err) => {
        console.log(err)
      });

    await axios.get(`${API_WORKOUTS_VIEW_FULL}${this.props.workout_day_id}`, keyConfig(store.getState)).then(res => {
      this.setState({
        exercise_count: res.data.length,
        full_list: res.data,
      })
    }).catch((err) => {
        console.log(err.response.data)
      });
  }

  componentDidMount() {
    this.fetchData()
  }

  onChange = (e) => {
    if(e.target.name === "max_reps"){
      this.setState({max_reps: e.target.value})
    }
    else if(e.target.name === "min_reps"){
      this.setState({min_reps: e.target.value})
    }
    else if(e.target.name === "ABC"){
      if(this.state.amayc) {
        this.setState({amayc: false})
      }
      else if(!this.state.amayc) {
        this.setState({amayc: true})
      }
    }
  }

  handleExerciseChange = (selectedOption: any,) => {
    this.setState({ selectedOption });
};

  addSet = async () => {
    await this.setState({rowCount: this.state.rowCount+1})
    await this.setState({
      ['min_reps_'+this.state.rowCount] : 8,
      ['max_reps_'+this.state.rowCount] : 12,
      ['ABC_'+this.state.rowCount] : false
    })
    await this.setState({ rowArray:  this.state.rowArray.concat([this.state.rowCount]) })
  }

  onChangeCheck = (b,name) => {
      if(this.state["ABC_"+b]) {
        this.setState({["ABC_"+b]: false})
      }
      else if(!this.state["ABC_"+b]) {
        this.setState({["ABC_"+b]: true})
      }
    }

  renderFormBody = (a,b) => {
    return(
      <FormGroup key={b}>
      <Row>

      <Col md="2">
        {b ===0?<Label>Set #</Label> : null}
        <p> {b+1} </p>
      </Col>

          <Col md="5">
            {b ===0?<Label>Minium Reps</Label>:null}

            <Input
              type="number"
              name={"min_reps_"+b}
              id={"min_reps_"+b}
              min="3"
              max="20"
              required
              disabled={true}
              value={this.state['min_reps_'+b]}
              onChange={(e) => this.setState({['min_reps_'+b] : e.target.value})}
              className={this.state["min_reps_"+b] !== null && this.state["min_reps_"+b] !== 0? " mb-2 " : "mb-2" }
              />
          </Col>

      <Col md="5">
        {b ===0?<Label>Maximum Reps</Label>:null}

        <Input
          type="number"
          name={"max_reps_"+b}
          id={"max_reps_"+b}
          min="8"
          max="30"
          required
          disabled={true}
          value={this.state['max_reps_'+b]}
          onChange={(e) => this.setState({['max_reps_'+b] : e.target.value})}
          className={this.state["max_reps_"+b] !== null && this.state["max_reps_"+b] !==0? " mb-2 " : "mb-2" }
          />
      </Col>


          </Row>
      </FormGroup>
    )
  }

  render() {
    if (
      this.state.full_quick_loading
    ) {
      return(
        <div className="flex d-flex justify-content-center align-items-center">
        <Spinner color="primary" />
        </div>
      )
    }
    else if (this.props.match.params.exerciseID && this.state.id !== null){
      return(
      <Redirect to={{pathname: "/exercises"}} />
      )
    }
    else if (this.state.id !== null){
      return(
      <Redirect to={{pathname: "/exercises/detail/" + this.state.id }} />
      )
    }
    return (
      <div>


      <ModalBody>


          <Form>

            <FormGroup>


              <Select


                defaultValue={{label:this.state.full_quick.exercise_name, value:0}}
                classNamePrefix="react-select"
                onChange={this.handleExerciseChange}
                placeholder= "Select An Exercise"
                openMenuOnClick={false}
                onInputChange={this.handleLocationInputChange}
                isDisabled={true}
                />


            </FormGroup>

            {this.state.rowArray.map((a,b) =>
              this.renderFormBody(a,b)
            )}


          </Form>


      </ModalBody>



      <ModalFooter className={this.state.rowCount >= 8 ? "modal-footer" : "modal-footer justify-end"}>


        <div>
        <Button color="secondary" onClick={this.props.toggle}>Cancel</Button>{' '}


        {this.state.loading?
          <Button style={{width:'110px'}} className="disabled" color="danger" > <Spinner color="light" size="sm" /> </Button>
          :
          <Button style={{width:'110px'}}  onClick = {this.onSubmit} color="danger" > Delete </Button>
        }

        </div>
      </ModalFooter>

      </div>

    );
  }
}

const mapStateToProps = (state) => ({
  user_status: state.auth.user,
  auth: state.auth,
  sidebar: store.sidebar,
});

export default connect(mapStateToProps, {  })(withRouter(AddExerciseBody));
