import React from "react";
import Chart from "react-apexcharts";
import { connect } from "react-redux";
import moment from "moment";

const AreaChart = ({ theme, ...props  }) => {
  const data = [
    {
      name: props.full.exercise_xref.exercise,
      data: props.data.map((s,t)=>(s.progression ))
    },

  ];

  const options = {
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: [5, 7, 5],
      curve: "smooth",
      dashArray: [0, 8, 5]
    },
    xaxis: {
      type: "datetime",
      categories: props.full.exercise_xref.graph_data.map((s,t)=>(   moment(s.date_time).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") ))
    },
    tooltip: {
      x: {
        format: "dd-MMM-yyyy HH:mm"
      },
      y: [
        {
          title: {
            formatter: function(val) {
              return val + ' (' + props.full.exercise_xref.measure_value + ')';
            }
          }
        },
      ]
    },
    colors: [
      theme.primary,
      theme.success,
      theme.warning,
      theme.danger,
      theme.info
    ]
  };

  return (



        <div className="chart w-100">
          <Chart options={options}
          series={data}
          type="area"
          height="350" />
        </div>


  );
};

export default connect(store => ({
  theme: store.theme.currentTheme
}))(AreaChart);
