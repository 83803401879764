import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Media,
  Input,
  InputGroup,
  InputGroupAddon,
  Form,
} from "reactstrap";
import { connect } from 'react-redux';
import { timelineComments, timelineCommentsReply } from '../../../redux/actions/social';
import { postPhotoReplyCommment, postPhotoCommment } from '../../../redux/actions/album';
import store from "../../../redux/store/index";
import ProfilePhoto from '../../../components/Social/ProfilePhoto/ProfilePhoto'
import ModalDelete from './ModalDelete';
import LoaderSpin from './LoaderSpin';
import TimeAgo from 'react-timeago'
import ApiSocial from '../../../config/social';

//source: timeline
//source: photo

async function PostPhotoComment(photoID, userid, comment, reload_flag, spinner_flag, flag_photo_scroll) {
  const result = await store.dispatch(postPhotoCommment(photoID, userid, comment));
  if (result.status === 200 || result.status === 201) {
   await flag_photo_scroll(1);
    await reload_flag(2);
    await spinner_flag(1);
  }
 }

async function PostPhotoReplyComment(photo_id, userid, comment, reload_flag, spinner_flag, flag_photo_scroll) {
   const result = await store.dispatch(postPhotoReplyCommment(photo_id, userid, comment));
   if (result.status === 200 || result.status === 201) {
    await flag_photo_scroll(1);
     await reload_flag(2);
     await spinner_flag(1);
   }
  }

async function SendComment(cardid, userid, changeflag, pageReloadNumber, comment) {
 const result = await store.dispatch(timelineComments(cardid, userid, comment));
 if (result.status === 200 || result.status === 201) {
     await changeflag(pageReloadNumber)
 }
}

async function SendCommentReply(comment_id, userid, changeflag, pageReloadNumber, comment) {
 const result = await store.dispatch(timelineCommentsReply(comment_id, userid, comment));
 if (result.status === 200 || result.status === 201) {
     await changeflag(pageReloadNumber)
 }
}

class Comments_Feed extends React.Component {


  onSendTimeline = async (e) => {
    e.preventDefault();
    SendComment(
      e.target.name.split('/')[0],
      this.props.user_status.pk,
      this.props.callbackPageReload,
      e.target.name.split('/')[1],
      this.state.comment_value,
    );
    this.setState({
      commentSpin_0: 1
    });
  };

  onSendPhoto = async (e) => {
    e.preventDefault();
    PostPhotoComment(
      this.props.photo_id,
      this.props.user_status.pk,
      this.state.myValue,
      this.props.callbackPageReload,
      this.props.flag_comment_spinner_handler,
      this.props.handlerLikeChangePhotosReload
    );
    this.setState({
      commentSpin_0: 1
    });
  };

  onSendReplyTimeline = async (e) => {
    e.preventDefault();
    SendCommentReply(
      e.target.name.split('/')[0],
      this.props.user_status.pk,
      this.props.callbackPageReload,
      e.target.name.split('/')[1],
      this.state.comment_value,
    );
    this.setState({
      ['commentSpin_' + e.target.name.split('/')[0]]: 1
    });
  };

  onSendReplyPhoto = async (e) => {
    e.preventDefault();
    PostPhotoReplyComment(
      e.target.name.split('/')[0],
      this.props.user_status.pk,
      this.state.comment_value,
      this.props.callbackPageReload,
      this.props.flag_comment_spinner_handler,
      this.props.handlerLikeChangePhotosReload
    );
    this.setState({
      ['commentSpin_' + e.target.name.split('/')[0]]: 1
    });
  };



  onDeleteComment = async (comment_id, page, time, type) =>{
    if(this.props.source === 'timeline' || this.props.source === 'timeline_detail') {
      this.onDeleteTimelineComment(comment_id, page, time, type)
    }
    else if (this.props.source === 'photo') {
      this.onDeleteCommentPhoto(comment_id, page, time, type)
    }
  }


  onDeleteTimelineComment = async (comment_id, page, time, type) => {
    if(type==='base') {
      await ApiSocial.timelineCommentsDelete(comment_id, (success, data) => {
      if ( success) {
        console.log('success')
        this.props.callbackPageReload(page)
      } else {
        console.log('fail')
      }
    });
    }
    else if(type==='reply'){
      await ApiSocial.timelineCommentsReplyDelete(comment_id, (success, data) => {
      if ( success) {
        console.log('success')
        this.props.callbackPageReload(page)
      } else {
        console.log('fail')
      }
    });
    }
  };



  onDeleteCommentPhoto = async (comment_id, page, time, type) => {
    if(type==='base') {
      await ApiSocial.photoCommentsDelete(comment_id, (success, data) => {
      if ( success) {
        console.log('success')
        this.props.callbackPageReload(2)
      } else {
        console.log('fail')
      }
    });
    }
    else if(type==='reply'){
      await ApiSocial.photoCommentsReplyDelete(comment_id, (success, data) => {
      if ( success) {
        console.log('success')
        this.props.callbackPageReload(2)
      } else {
        console.log('fail')
      }
    });
    }
  };

  onChange = async (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      comment_value: e.target.value,
      myValue: e.target.value
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      api_user_profiles: [],
      comment: null,
      comment_id: null,
      myValue_0: '',
      commentSpin_0: 0,
      commentBlockSize: 2,
      replyBlockSize: 2,
      photo_id: this.props.photo_id,
      x: this.props.x
    };
  }

  assignCommentMyValue = (a) => {
    var i;
    for (i = 0; i < a.length; i++) {
      const unique_id = a[i].id
      this.setState({
        ["myValue_" + unique_id]: '',
        ["commentReply_" + unique_id]: 0,
        ["commentSpin_" + unique_id]: 0
      })
    }
    this.setState({
      myValue_0:'',
      commentSpin_0: 0
    })
  }

  componentDidMount() {
    this.assignCommentMyValue(this.state.x.comments)
  }

  componentDidUpdate() {

    if (this.props.source!=="photo" && this.props.flag_comment_spinner_value === 2) {
      this.setState({
        x: this.props.x,
        commentSpin_0: 0,
        myValue_0: '',
      });
      this.assignCommentMyValue(this.props.x.comments)
      this.props.flag_comment_spinner_handler(0)
    }
    if (this.props.source==="photo" && this.props.flag_comment_spinner_value === 2 && this.props.x !== this.state.x)  {
      this.setState({
        x: this.props.x,
        commentSpin_0: 0,
        myValue_0: '',
      })
      this.assignCommentMyValue(this.props.x.comments)
      this.props.flag_comment_spinner_handler(0)
    }
    if (this.props.source==="photo" && this.props.flag_comment_spinner_value === 0 && this.props.photo_id !== this.state.photo_id)  {
      this.setState({
        x: this.props.x,
        commentSpin_0: 0,
        myValue_0: '',
        photo_id: this.props.photo_id,
      })
      this.assignCommentMyValue(this.props.x.comments)
      this.props.flag_comment_spinner_handler(0)
    }
  }

  renderCardBase = (x,a) => {
      return(
        <div>

          <InputGroup style={{'margin':'auto', marginTop: '10px', display: 'flex', alignItems: 'center'}}>

            <ProfilePhoto
              avatar_image={this.props.user_status.avatar_image}
              avatar_color = {this.props.user_status.avatar_color}
              avatar_letter = {this.props.user_status.avatar_letter}
              avatar_size={"36px"}
              letter_size={"18px"}
              active_user_id = {this.props.user_status.pk }
              user_id = {this.props.user_status.id}
              />


            <Input
              placeholder={x.comments.length === 0 ? "Be the first to comment " : "Add a comment" }
              value={this.state['myValue_' + a]}
              name={'myValue_' + a}
              className="ml-1 border-dark"
              onChange={this.onChange}
              style={{'borderRadius': '25px','paddingLeft': '20px','paddingRight': '20px'}}
            />

            <InputGroupAddon addonType="append" color="primary">
              <Form onSubmit={
                a===0 && this.props.source!=="photo"?
                this.onSendTimeline
                :
                a===0 && this.props.source==="photo"?
                this.onSendPhoto
                :
                this.props.source!=="photo"?
                this.onSendReplyTimeline
                :
                this.props.source==="photo"?
                this.onSendReplyPhoto
                :
                null
              }
                name={a===0? x.id + '/' + this.props.index : a + '/' + this.props.index }>
              {this.state['commentSpin_' +a]===0 && this.state['myValue_' + a]===''?
                null
                :
                this.state['commentSpin_' +a]===0?
              <Button className="btn-pill ml-1" color="light">Post</Button>
              :
              <Button className="btn-pill ml-1" disabled color="light"> <LoaderSpin/></Button> }
                </Form>
            </InputGroupAddon>


        </InputGroup>

          <hr className="mt-1"/>
          </div>
      )
  }

  renderCardCommentsAvatar = (a) => {
    return(
      <div className="pr-2">
      <Link to={"/profile/" + a.commenting_user_id_xref.pk + "/home"}   style={{color:"#696969",'textDcoration':'none'}}>
        <ProfilePhoto
          avatar_image={this.props.user_status.pk === a.commenting_user_id_xref.id? this.props.user_status.avatar_image : a.commenting_user_id_xref.avatar_image}
          avatar_color = {this.props.user_status.pk === a.commenting_user_id_xref.id? this.props.user_status.avatar_color : a.commenting_user_id_xref.avatar_color}
          avatar_letter = {this.props.user_status.pk === a.commenting_user_id_xref.id? this.props.user_status.avatar_letter : a.commenting_user_id_xref.avatar_letter}
          avatar_size={"36px"}
          letter_size={"18px"}
          active_user_id = {this.props.user_status.pk }
          user_id = {a.commenting_user_id_xref.id}
          />
        </Link>
      </div>
    )
  }

  renderCardCommentsBody = (a,x) => {
    return(
      <div className="border border-light rounded-bottom rounder-right p-2 mt-0" style={{'backgroundColor':'#f2f2f3'}}>

      <div className="float-right text-navy" style={{display:'flex', alignItems:'center'}}>

        <small className="float-right text-navy"><TimeAgo date={a.timestamp}/> </small>

        </div>

        <Link
        to={"/profile/" + a.commenting_user_id_xref.pk + "/home"}
        style={{'textDecoration':'none'}}>

          <strong className="text-dark">{a.commenting_user_id_xref.firstname_lastname}</strong>
        </Link>
        <br />

        <div className="mt-1">
          {a.comment}
        </div>
      </div>
    )
  }

  showReplyInput = (a,x) => {
    this.setState({['commentReply_' + a.id]: 1})
  }


  renderCommentLike = (a) => {
    if(a==="temp"){
      return(
        <>
      <small className="text-primary customComments" onClick={() => console.log('placeholder')}>like</small>
      <small className="text-dark">•</small>
      </>
    )
    }
    return(
      null
    )
  }

  renderCardCommentsBaseOptions = (a,x,type) => {
    return(
      <div>
        {this.renderCommentLike(0)}

        {type==='reply' ?
        null
        :
        <>
        <small className="ml-1 text-primary customComments" onClick={() => this.showReplyInput(a,x)}>reply</small>
        <small className="text-muted">{a.reply_comments.length > 1?' ('+a.reply_comments.length+' replies)': a.reply_comments.length===1?' (1 reply)':null}</small>
        </>
      }

        <small className="m-0 ml-1 p-0 text-muted">
            {this.props.user_status.pk === x.user_id_xref[0].pk || 
              this.props.user_status.pk === a.commenting_user_id_xref.pk ||
              this.props.user_status.pk === x.user_id_xref?
              this.renderCardCommentsDelete(a,x,type)
              :
              null
            }
        </small>
      </div>

    )
  }

  loadmoreComments = () => {
    this.setState({commentBlockSize: this.state.commentBlockSize + 3})
  }

  loadmoreReplies = () => {
    this.setState({replyBlockSize: this.state.replyBlockSize + 3})
  }

  renderCardComments = (x) => {
      return(
        <div>
          {x.comments.slice(0).reverse().slice(0, this.state.commentBlockSize).map((a,b) =>
          <div className="mb-3" key={b}>

        <Media style={{display:'flex', alignItems:'flex-start'}}>
        {this.renderCardCommentsAvatar(a)}

          <Media body>
          {this.renderCardCommentsBody(a,x)}
          {this.renderCardCommentsBaseOptions(a,x,'base')}


          {this.state['commentReply_' + a.id] === 0?
            null
            :
          this.renderCardBase(x,a.id)
        }

          {this.renderCardReplyComments(a.reply_comments,b,x)}



          </Media>
        </Media>
        <hr />

        {this.state.commentBlockSize < x.comment_count && b === this.state.commentBlockSize-1?
          <>

        <Button color="primary" block onClick={()=> this.loadmoreComments()}>
          Load more comments
        </Button>
        </>
        :
        null
      }

          </div>
      )}
        </div>

      )
  }

  renderCardReplyComments = (a,b,x) => {
    return(
      <div>
        {a.slice(0).reverse().slice(0, this.state.replyBlockSize).map((r,s) =>
          <div className="mb-1 mt-2" key={s}>
          <Media style={{display:'flex', alignItems:'flex-start'}}>
            {this.renderCardCommentsAvatar(r)}
            <Media body>
            {this.renderCardCommentsBody(r,x)}
            {this.renderCardCommentsBaseOptions(r,x,'reply')}

            {this.state.replyBlockSize < a.length && s===this.state.replyBlockSize-1?
            <small className="text-muted text-primary customComments" onClick={()=> this.loadmoreReplies()}>Load more replies </small>
            :
            null
          }

            </Media>
          </Media>
          </div>
        )}

      </div>
    )
  }

  renderCardCommentsDelete = (a,x,type) => {
  return(
    <ModalDelete
      a = {a}
      active_user_id = {this.props.user_status.pk }
      onDelete = {this.onDeleteComment}
      page = {this.props.index}
      time={x.current_time}
      type={type}
      />
  )
}

  render() {
    return (
      <div>
        {this.renderCardBase(this.props.x,0)}
        {this.renderCardComments(this.props.x)}
      </div>
    );
   }
}

const mapStateToProps = (state) => ({
  user_status: state.auth.user,
  theme: state.theme.currentTheme
});

export default connect(mapStateToProps, {  })(Comments_Feed);
