import React from "react";

import {
  Card,
  CardBody,
  Media,
} from "reactstrap";


const Howto = (props) => (





  <Card className="flex-fill w-100">
    <CardBody>
      <Media>

        <Media body>

          <p className="mb-2">
            <strong>Do's</strong>
          </p>

            <p dangerouslySetInnerHTML={ { __html: props.exercise_detail.dos } }></p>



        </Media>
      </Media>

    </CardBody>

    <CardBody>
      <Media>

        <Media body>

          <p className="mb-2">
            <strong>Dont's</strong>
          </p>

            <p dangerouslySetInnerHTML={ { __html: props.exercise_detail.donts } }></p>



        </Media>
      </Media>

    </CardBody>
  </Card>





);

export default Howto;
