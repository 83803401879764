import {
  ELEMENT_LOADING,
  ELEMENT_LOADED,
  LIFT_TRACKING,
  TIMELINE_YOU_ONLY,
  TIMELINE_ALBUM_ONLY,
  TIMELINE_MEMBER_ONLY,
  TIMELINE_YOU_AND_FOLLOWING,
  TIMELINE_YOU_ONLY_LAST,
  TIMELINE_ALBUM_ONLY_LAST,
  TIMELINE_MEMBER_ONLY_LAST,
  TIMELINE_YOU_AND_FOLLOWING_LAST,
} from '../actions/types';

const initialState = {
  isLoading: false,
  lift_tracking_input_data: [],
  timeline_you_only_data: [],
  timeline_you_and_following_data: [],
  timeline_member_only_data: [],
  timeline_album_only_data: [],
  timeline_you_only_last_data: null,
  timeline_you_and_following_last_data: null,
  timeline_member_only_last_data: null,
  timeline_album_only_last_data: null,
};

export default function general(state = initialState, action) {
  switch (action.type) {
    case ELEMENT_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case ELEMENT_LOADED:
      return {
        ...state,
        isLoading: false,
      };
    case LIFT_TRACKING:
      return {
        ...state,
        lift_tracking_input_data: action.payload,
      };
    case TIMELINE_YOU_ONLY:
      return {
        ...state,
        timeline_you_only_data: action.payload,
      };
    case TIMELINE_ALBUM_ONLY:
      return {
        ...state,
        timeline_album_only_data: action.payload,
      };
    case TIMELINE_MEMBER_ONLY:
      return {
        ...state,
        timeline_member_only_data: action.payload,
      };
    case TIMELINE_YOU_AND_FOLLOWING:
      return {
        ...state,
        timeline_you_and_following_data: action.payload,
      };
    case TIMELINE_YOU_ONLY_LAST:
      return {
        ...state,
        timeline_you_only_last_data: action.payload,
      };
    case TIMELINE_ALBUM_ONLY_LAST:
      return {
        ...state,
        timeline_album_only_last_data: action.payload,
      };
    case TIMELINE_MEMBER_ONLY_LAST:
      return {
        ...state,
        timeline_member_only_last_data: action.payload,
      };
    case TIMELINE_YOU_AND_FOLLOWING_LAST:
      return {
        ...state,
        timeline_you_and_following_last_data: action.payload,
      };
    default:
      return state;
  }
}
