import axios from 'axios';
import {
  API_USER_PROFILES_SUBSCRIBED,
  API_SUBSCRIBE_STRIPE_SUBSCRIBE_HISTORY,
  API_SUBSCRIBE_STRIPE_REFUND_TIME,
  API_SUBSCRIBE_PLAY_VALIDATE,
} from '../redux/actions/API';
import {keyConfig} from './key';
import moment from 'moment';


const _fetchSubscribedFromStripe = async (stripeID, callback) => {
  axios
    .get(
      `${API_SUBSCRIBE_STRIPE_SUBSCRIBE_HISTORY}${stripeID}`,
      await keyConfig(),
    )
    .then((res) => {
      callback(
        true,
        res.data[0].charge.refunded === true
          ? false
          : moment.unix(res.data[0].lines.data[0].period.end).isAfter(moment())
          ? true
          : false,
      );
    })
    .catch((err) => {
      console.log(err);
      callback(false, false);
    });
};

const _subscribe_status = async (callback) => {
  axios
    .get(`${API_USER_PROFILES_SUBSCRIBED}`, await keyConfig())
    .then((res) => {
      if (res.data[0].stripe_id) {
        _fetchSubscribedFromStripe(res.data[0].stripe_id, callback);
      } else if (!res.data[0].stripe_id) {
        callback(true, false);
      }
    })
    .catch((err) => {
      console.log(err);
      callback(false, false);
    });
};

const _refund_free = async (callback) => {
  axios
    .get(`${API_SUBSCRIBE_STRIPE_REFUND_TIME}`, await keyConfig())
    .then((res) => {
      if (res.data[0].allow_free_web) {
        callback(false, true);
      } else if (!res.data[0].allow_free) {
        _subscribe_status(callback);
      }
    })
    .catch((err) => {
      console.log(err);
      callback(false, true);
    });
};


const _playValidate = async (callback) => {
  const body = JSON.stringify({});
  axios
    .post(`${API_SUBSCRIBE_PLAY_VALIDATE}`, body, await keyConfig())
    .then((res) => {
      callback(true, res.data);
    })
    .catch((error) => {
      callback(false, error.response.data);
    });
};

const dict = {
  subscribe_status: _refund_free,
  playValidate: _playValidate
};

export default dict;
