import React from "react";
import Intro from "./Intro";
import Howto from "./Howto";
import Dosdonts from "./Dosdonts";
import Description from "./Description";
import LiftTable from "./LiftTable";
import Header from "./Header";
import Area from "./Area";
import Statistics from "./Statistics";
import axios from "axios";
import { keyConfig } from '../../../redux/actions/auth';
import store from "../../../redux/store/index";
import { connect } from 'react-redux';
import { API_EXERCISE_LIST,
  API_EXERCISE_TIMESERIES,
  API_LIFT_TRACKER_EXERCISE_LOG,
  API_EXERCISE_DEFAULT_IMAGE
 } from '../../../redux/actions/API'
import {
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";

const Default = (props) => (
  <Container fluid className="p-0">
    <Header exercise_detail = {props.exercise_detail} fetchDetail={props.fetchDetail} user={props.user}/>
    <Statistics
      exercise_last_lifted = {props.exercise_last_lifted}
      exercise_last_volume = {props.exercise_last_volume}
      exercise_detail = {props.exercise_detail}
      />
    <Row>
      <Col lg="8" className="d-flex">
        <Area data = {props.exercise_timeseries.graph_data} measure_value={props.exercise_timeseries_measure_value}/>
      </Col>
      <Col lg="4" className="d-flex">
        <Intro exercise_detail = {props.exercise_detail} exercises_default_image = {props.exercises_default_image}/>
      </Col>
    </Row>
    <Row>
      <Col lg="12" xl="12" className="d-flex">
        <LiftTable exercise_log = {props.exercise_log}/>
      </Col>
    </Row>
    {!props.my_exercise?
    <Row>
      <Col lg="6" xl="4" className="d-flex">
        <Description exercise_detail = {props.exercise_detail}/>
      </Col>
      <Col lg="6" xl="4" className="d-flex">
        <Howto exercise_detail = {props.exercise_detail}/>
      </Col>
      <Col lg="6" xl="4" className="d-flex">
        <Dosdonts exercise_detail = {props.exercise_detail}/>
      </Col>
    </Row>
    :
    null
  }
  </Container>
);

class ExercisesDetail extends React.Component {

  fetchDefaultImage = (exerciseID) =>  {
    axios.get(`${API_EXERCISE_DEFAULT_IMAGE}/`, keyConfig(store.getState)).then(res => {
      this.setState({
        exercises_default_image: res.data[0].default,
        exercises_default_image_loading: false,
      });
    });
  }

  fetchDetail = (exerciseID) =>  {
    axios.get(`${API_EXERCISE_LIST}/${exerciseID}/`, keyConfig(store.getState)).then(res => {
      this.setState({
        exercise_detail: res.data,
        exercise_detail_loading: false,
        my_exercise: res.data.user_id_xref === this.props.user_status.pk? true: false
      });
    });
  }

  fetchTimeSeries = (exerciseID) =>  {
    axios.get(`${API_EXERCISE_TIMESERIES}/${exerciseID}/`, keyConfig(store.getState)).then(res => {
      this.setState({
        exercise_timeseries: res.data,
        exercise_timeseries_loading: false,
        exercise_timeseries_measure_value: res.data.measure_value,
        exercise_last_volume: res.data.graph_data.length > 0? res.data.graph_data.reverse()[0] : null
      });
    });
  }

  fetchExerciseLog = (exerciseID) =>  {
    axios.get(`${API_LIFT_TRACKER_EXERCISE_LOG}?exercise_xref=${exerciseID}`, keyConfig(store.getState)).then(res => {
      this.setState({
        exercise_log: res.data,
        exercise_last_lifted: res.data.length > 0 ? res.data[0].timestamp : null,
        exercise_log_loading: false
      });
    }).catch((err) => {
      this.setState({
        exercise_log: null,
        exercise_log_loading: false
      });
    });


  }

  componentDidMount() {
    const exerciseID = this.props.match.params.exerciseID;
    this.fetchDefaultImage();
    this.fetchDetail(exerciseID);
    this.fetchTimeSeries(exerciseID);
    this.fetchExerciseLog(exerciseID);
  }

  constructor(props) {
    super(props);
    this.state = {
      exercise_detail: [],
      exercise_detail_loading: true,
      exercise_timeseries: [],
      exercise_timeseries_loading: true,
      exercise_timeseries_measure_value: null,
      exercise_youtube: [],
      exercise_youtube_loading: true,
      exercise_log: [],
      exercise_log_loading: true,
      exercise_last_lifted: null,
      exercises_default_image: [],
      exercises_default_image_loading: true,
      my_exercise: null,
    };
  }

  render() {
    if (this.state.exercise_detail_loading || this.state.exercise_timeseries_loading || this.state.exercises_default_image_loading) {
      return(
        <div className="flex d-flex justify-content-center align-items-center"> <Spinner color="primary" /> </div>
       )
    }
    return (
      <Default
        exercise_detail = {this.state.exercise_detail}
        exercise_log = {this.state.exercise_log}
        exercise_timeseries = {this.state.exercise_timeseries}
        exercise_timeseries_measure_value = {this.state.exercise_timeseries_measure_value}
        exercise_last_lifted = {this.state.exercise_last_lifted}
        exercise_last_volume = {this.state.exercise_last_volume}
        exercises_default_image = {this.state.exercises_default_image}
        fetchDetail = {this.fetchDetail}
        user = {this.props.user_status.pk}
        my_exercise = {this.state.my_exercise}
        />
    );
  }
}


const mapStateToProps = (state) => ({
    user_status: state.auth.user,
});

export default connect(mapStateToProps)(ExercisesDetail);
