import React from "react";
import { Link } from 'react-router-dom';
import {
  Button
} from "reactstrap";


const Status = (props) => (

  <div className="d-flex flex-column mt-5" >

      <h3 className="mb-0" style={{"justifyContent": "center", "textAlign": "center"}}>
        We cannot find what you are looking for here
      </h3>
      <br />

      <Button className="float-center" color="primary" size="lg" style={{alignSelf:'center'}} tag={Link} to="/timeline">
        Return to Timeline
        </Button>

</div>

);

export default Status;
