import React from "react";
import { connect } from "react-redux";

import Wrapper from "../components/Wrapper";
import SidebarMain from "../components/Sidebar/SidebarMain";
import Main from "../components/Main";
import Navbar from "../components/Navbar/Navbar";
import Content from "../components/Content";
import Footer from "../components/Footer";

const Dashboard = ({ sidebar, children }) => (
  <React.Fragment>
    <Wrapper>
      {!sidebar.isOnRight && <SidebarMain />}
      <Main>
        <Navbar />
        <Content>{children}</Content>
        <Footer />
      </Main>
      {sidebar.isOnRight && <SidebarMain />}
    </Wrapper>
  </React.Fragment>
);


const mapStateToProps = (state) => ({
  user_status: state.auth.user,
  sidebar: state.sidebar
});

export default connect(mapStateToProps, {  })(Dashboard);
