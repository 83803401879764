import React, { useState } from 'react';
import {  Redirect } from 'react-router-dom';
import {  withRouter} from 'react-router-dom';
import { connect } from "react-redux";
import store from "../../redux/store/index";
import ApiLiftTracker from '../../config/lift_tracking';
import {
  API_WORKOUTS_GOAL,
  API_WORKOUTS_TRAINING_LEVEL,
  API_WORKOUTS_CREATE,
  } from '../../redux/actions/API';
import axios from "axios";
import { keyConfig } from '../../redux/actions/auth';
import {
  Button,
  Spinner,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Modal,
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput,
} from 'reactstrap';



const ModalDelete = (props) => {
  const {
    className
  } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);


  return (
    <div>
      <Button color="danger" onClick={toggle}>Delete</Button>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>Modal title</ModalHeader>
        <ModalBody>
          Are you sure you want to delete your workout?
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>Cancel</Button>{' '}
          <Button color="danger" onClick={e => props.onDelete(e)}>Confirm</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}



class PatchExercise extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      api_detail_loading: true,
      loading: false,
      id: null,
      confirm: false,

      workout_name: this.props.routine.routine,
      description: null,
      active: true,

      api_goal: [],
      api_goal_loading: true,
      goal: null,


      api_training_level: [],
      api_training_levelloading: true,
      training_level: null,

    };
  }


  onSubmit = async (e) => {
    await this.setState({loading: true})
    await e.preventDefault();
    await ApiLiftTracker.workoutPatch(
      this.props.workoutID,
      this.state.workout_name,
      this.state.goal,
      this.state.training_level,
      this.state.description,
      true,
      (success, data) => {
      if ( success) {
        console.log('success')
        this.props.fetchDetail(this.props.workoutID)
        this.props.toggle()
      } else {
        console.log('fail')
      }
    });
  };

  onDelete = async (e) => {
    console.log('aaaaaaaaaa')
    await e.preventDefault();
    await ApiLiftTracker.workoutPatch(
      this.props.workoutID,
      this.state.workout_name,
      this.state.goal,
      this.state.training_level,
      this.state.description,
      false,
      (success, data) => {
      if ( success) {
        console.log('success')
        this.setState({id: 0})
      } else {
        console.log('fail')
      }
    });
  };



  onDeleteConfirm = () => {
    this.setState({confirm: true})
  }

  fetchData = async () =>  {


    await axios.get(`${API_WORKOUTS_CREATE}${this.props.workoutID}/`, keyConfig(store.getState)).then(res => {
      console.log(res.data)
      this.setState({
          goal: res.data.goal_xref,
          training_level: res.data.training_level_xref,
          api_detail_loading: false,
          description: res.data.description_body,
      });
        this.fetchSubGroup()
    }).catch((err) => {
        this.setState({
          api_detail: [],
          api_detail_loading: false,
        });
      });


    await axios.get(`${API_WORKOUTS_GOAL}`, keyConfig(store.getState)).then(res => {
      this.setState({
          api_goal: res.data,
          api_goal_loading: false,
      });
    }).catch((err) => {
        this.setState({
          api_goal: [],
          api_goal_loading: false,
        });
      });


    await axios.get(`${API_WORKOUTS_TRAINING_LEVEL}`, keyConfig(store.getState)).then(res => {
      this.setState({
          api_training_level: res.data,
          api_training_levelloading: false,
      });
    }).catch((err) => {
        this.setState({
          api_training_level: [],
          api_training_levelloading: false,
        });
      });


  }


  componentDidMount() {
    this.fetchData()
  }




  onChange = (e) => {
    if(e.target.name === "goal"){
      this.setState({goal: e.target.value})
    }
    else if(e.target.name === "training_level"){
      this.setState({training_level: e.target.value})
    }
    else if(e.target.name === "name"){
      this.setState({workout_name: e.target.value})
    }
    else if(e.target.name === "textarea"){
      this.setState({description: e.target.value})
    }
  }

  render() {
    if (
      this.state.api_goal_loading ||
      this.state.api_training_levelloading ||
      this.state.api_detail_loading
    ) {
      return(
        <div className="flex d-flex justify-content-center align-items-center">
        <Spinner color="primary" />
        </div>
      )
    }
    else if (this.state.id !== null){
      return(
      <Redirect to={{pathname: "/myworkouts/" }} />
      )
    }
    return (
      <div>
      <ModalBody>
          <Form>
            <FormGroup>
              <Label>Workout Name</Label>
              <Input
                type="text"
                name="name"
                id="name"
                required
                placeholder="Exercise Name"
                pattern="[a-zA-Z]+"
                value={this.state.workout_name}
                onChange={this.onChange}
                rows="4"
                className={this.state.workout_name !== null && this.state.workout_name.length >0? " mb-2 " : "mb-2" }
                />
            </FormGroup>

            <FormGroup>
              <Label>Goal</Label>

                <CustomInput
                  type="select"
                  id="goal"
                  name="goal"
                  value={this.state.goal}
                  className={this.state.goal > 0? " mb-2 " : "mb-2" }
                  onChange={this.onChange}
                >
                  {this.state.api_goal.map((a,b) =>
                  <option value={a.id}>{a.goal}</option>
                  )}
                </CustomInput>
            </FormGroup>

            <FormGroup>
              <Label>Training Level</Label>

                <CustomInput
                  type="select"
                  id="training_level"
                  name="training_level"
                  value={this.state.training_level}
                  className={this.state.training_level > 0? " mb-2 " : "mb-2" }
                  onChange={this.onChange}
                >

                  {this.state.api_training_level.map((a,b) =>
                  <option value={a.id}>{a.training_level}</option>
                  )}
                </CustomInput>
            </FormGroup>


            <FormGroup>
              <Label>Description (Optional)</Label>
              <Input
                type="textarea"
                name="textarea"
                id="textarea"
                rows="5"
                required
                placeholder="Write a description about your workout"
                value={this.state.description}
                onChange={this.onChange}
                />
            </FormGroup>




          </Form>
      </ModalBody>

      <ModalFooter className="modal-footer justify-content-between">
        <ModalDelete onDelete={this.onDelete}/>

        <div>
        <Button color="secondary" onClick={this.props.toggle}>Cancel</Button>{' '}

        {

          this.state.loading?
          <Button style={{width:'110px'}} color="primary" className="disabled"> <Spinner color="light" size="sm" /> </Button>

                  :

          (this.state.goal > 0 && this.state.training_level > 0 && this.state.workout_name !== null && this.state.workout_name.length >0 )?

          <Button style={{width:'110px'}} color="primary" onClick = {this.onSubmit}>Update </Button>

                    :

          <Button style={{width:'110px'}} color="primary" className="disabled"> Update </Button>
        }


        </div>
      </ModalFooter>
      </div>

    );
  }
}

const mapStateToProps = (state) => ({
  user_status: state.auth.user,
  auth: state.auth,
  sidebar: store.sidebar,
});

export default connect(mapStateToProps, {  })(withRouter(PatchExercise));
