import React, { useState } from "react";
import { Link } from 'react-router-dom';
import prettyNum from 'pretty-num';
import {
  Col,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  InputGroup,
  InputGroupAddon,
  Input,
} from "reactstrap";
import { connect } from 'react-redux';

const ModalWeight= (props) => {
  const {
    className
  } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const toggleSubmit = (e) => {
    props.submitBodyWeight(props.weight, props.recorded_start_time);
    setModal(!modal);
  }

  return (
    <div>
      <Button color="warning ml-2 mr-2" onClick={toggle}>Add Body Weight</Button>
      <Modal isOpen={modal} toggle={toggle} centered className={className}>
        <ModalHeader toggle={toggle}>Record Body Weight</ModalHeader>
        <ModalBody>
        <Card>
          <CardHeader>
            <CardTitle tag="h5" className="mb-0">
              Record Body Weight
            </CardTitle>
          </CardHeader>
          <CardBody>

          <InputGroup className="mb-1">
            <InputGroupAddon addonType="prepend" color="primary">
              <Button>{props.user_status.usa_imperial? 'Lbs' : 'Kg'}</Button>
            </InputGroupAddon>
            <Input
              type="number"
              onChange={props.onChange}
              min="10"
              required
              name="weight_input"
              placeholder="Record Weight..." />
          </InputGroup>

          </CardBody>
        </Card>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>Cancel</Button>{' '}
          <Button color="primary" onClick={toggleSubmit}>Submit</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_imperial: this.props.user_status.usa_imperial? 'true' : 'false',
      reload_flag: 0,
      weight_progression: [],
      weight_progression_loading: true,
      success: false,
      weight: null
    };
  }

  onChange = (e) => {
    this.setState({ weight: e.target.value });
  }

  render() {
    return (
      <Row className="mb-2 mb-xl-4">
        <Col xs="auto" className="d-none d-sm-block">
          <h3>{this.props.exercise_data.workout_day_xref.day_title} </h3>
          <span>{this.props.exercise_data.derived__part_of_day}</span>
        </Col>

        <Col xs="auto" className="ml-auto text-right mt-n1 flex d-flex justify-content-center align-items-center">
        {this.props.get_body_weight !== null?
          <Button color="warning ml-2 mr-2">
          Body Weight: {
            prettyNum(this.props.get_body_weight, {precision: 1, thousandsSeparator: ','})

            + ' ' + this.props.get_body_weight_measure_value
          }
          </Button>
          :
          this.props.this_user === this.props.user_data?
        <ModalWeight
        weight = {this.state.weight}
        submit = {this.state.submit}
        onChange = {this.onChange}
        onSubmit = {this.onSubmit}
        user_status = {this.props.user_status}
        recorded_start_time = {this.props.exercise_data.recorded_start_time}
        submitBodyWeight={this.props.submitBodyWeight}/>
        :
        null}
        <Button tag={Link} to={"/workouts/detail/" + this.props.exercise_data.workout_day_xref.routine_xref.id}>{this.props.exercise_data.workout_day_xref.routine_xref.routine} </Button>
        </Col>
      </Row>
  );
 }
}

const mapStateToProps = (state) => ({
  user_status: state.auth.user,
  general: state.general.isLoading,
  theme: state.theme.currentTheme
});

export default connect(mapStateToProps, {  })(Header);
