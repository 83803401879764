import React from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { keyConfig } from '../../redux/actions/auth';
import store from "../../redux/store/index";
import { posbodyWeight } from '../../redux/actions/social';
import {
  API_BODY_WEIGHT,
  API_ANALYTICS_WORKOUT_PROGRAMS,
} from '../../redux/actions/API'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  Row,
} from "reactstrap";
import Loading from "./Loading"
import Mixed from './Mixed'
import { connect } from 'react-redux';
import Header from './Header'
import ApiSocial from '../../config/social';
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";

const MyExportCSV = props => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div>
        <button className="btn btn-secondary mt-2" onClick={handleClick}>
          Export
        </button>
      </div>
    );
  };

const { SearchBar } = Search;

class BodyWeightTracking extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      referrer: null,
      start: moment().subtract(6, "days"),
      end: moment(),
      fetch: 0,
      range:"Last 7 Days",


      is_imperial: this.props.user_status.usa_imperial? 'true' : 'false',
      reload_flag: 0,
      weight_progression: [],
      weight_progression_loading: true,
      success: false,

      workout_programs_loading: true,
      workout_programs: []
    };
  }

  fetchAnalyticsStartEnd = async (start,end) =>  {

    await axios.get(`${API_BODY_WEIGHT}?day_start=${start}&day_end=${end}`, keyConfig(store.getState)).then(res => {
      console.log(res.data)
      this.setState({
        weight_progression: res.data,
        weight_progression_loading: false,
        });
      });

  }

  workoutPrograms = () =>  {
    axios.get(`${API_ANALYTICS_WORKOUT_PROGRAMS}`, keyConfig(store.getState)).then(res => {
      this.setState({
        workout_programs_recent: res.data.length > 0? '--' : null,
        workout_programs: res.data,
        workout_programs_loading: false,
      });
    });
  }



  componentDidMount() {
    this.fetchAnalyticsStartEnd(
      moment().subtract(6, 'days').startOf('day').utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      moment().subtract(0,'days').endOf('day').utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
    )
    this.workoutPrograms()
  }

  componentDidUpdate() {
    if (this.state.reload_flag === 1) {
      this.setState({reload_flag: 0})
    }
  }

  datefilter = async (start, end, range, type) => {
    console.log('riyaad')
    console.log(start)
    console.log(end)
    await this.setState({start:start, end:end, range:type==="date_range"? range : '--', changer:1, workout_programs_recent: type==="workout_range"? range : '--'})
    await this.fetchAnalyticsStartEnd(moment.utc(start).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"), moment().subtract(0,'days').endOf('day').utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"))
    await this.setState({changer:0})
  }

  datechange = async (start, end) => {
    await this.fetchAnalyticsStartEnd(moment.utc(start).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"), moment.utc(end).add(12, 'hours').format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"))
  }

  changeflag = (val) => {
    this.setState({
      reload_flag: val
    })
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }


  updateWeight = async () => {
    await ApiSocial.posbodyWeight(this.props.user_status.pk, this.state.is_imperial, this.state.weight_input,  (success, data) => {
      if ( success) {
        console.log('--success--')
        this.componentDidMount()
        this.setState({success:true})
      } else {
        console.log('fail')
      }
    });
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.updateWeight()
  }

  renderChart = () => {
    return(
      <Mixed weight_progression={this.state.weight_progression}/>
    )
  }

  renderForm = () => {
    return(
      <Card>
        <CardHeader>
          <CardTitle tag="h5" className="mb-0">
            Record Weight
          </CardTitle>
        </CardHeader>
        <CardBody>


        <InputGroup className="mb-3">
          <InputGroupAddon addonType="prepend" color="primary">
            <Button>{this.props.user_status.usa_imperial? 'Lbs' : 'Kg'}</Button>
          </InputGroupAddon>
          <Input
            type="number"
            onChange={this.onChange}
            min="0"
            name="weight_input"
            placeholder="Record Weight..." />
        </InputGroup>

        <Form onSubmit={this.onSubmit}>
          {this.state.success?
            <Button color="success" disabled className="mr-1 btn-block"  type="submit"> Saved!</Button>
            :
          <Button color="primary" className="mr-1 btn-block"  type="submit"> Save </Button>
        }
        </Form>

        </CardBody>
      </Card>
    )
  }

  renderProTip = () => {
    return(
      <Card>
        <CardBody className="">
          <div className="mb-1">
            <h3>Pro Tip</h3>
          </div>

          <p>Best practice is to record your weight first thing in the morning. Record at consistent times.</p>

            <hr className="my-2" />
        </CardBody>
        </Card>
    )
  }


  renderTable =() => {
    return(
      <Card>
        <ToolkitProvider
          keyField="name"
          data={this.state.weight_progression}
          columns={
              [
                {
                  dataField: "id",
                  text: "ID",
                  sort: true,
                  hidden: true,
                },
                {
                  dataField: "weight",
                  text: this.props.user_status.usa_imperial? "Weight (LB)" : "Weight (KG)",
                  sort: true,
                },
                {
                  dataField: "timestamp",
                  text: "Date",
                  sort: true,
                  formatter: (cellContent, row) => {
                    return moment(cellContent).format('YYYY-MM-DD HH:MM:SS')
                  }
                },
                {
                  dataField: "workout_session",
                  text: "Workout",
                  sort: true,
                  formatter: (cellContent, row) => {
                    return <div> {cellContent? cellContent : 'N/A'}</div>;
                  }
                },
              ]
        }
          exportCSV
          search
        >
          {(props) => (
            <div>
              <CardHeader className="d-flex flex justify-content-between align-items-center">
                <div>

                <h6 className="card-subtitle text-muted">

                </h6>
                </div>


                <div className="d-flex flex-row-reverse" >
                    <div style={{display: 'flex', 'justifyContent': 'center', 'alignItems': 'center'}} className="p-0 ml-1">
                      <MyExportCSV {...props.csvProps} />
                    </div>
                    <div style={{display: 'flex', 'justifyContent': 'center', 'alignItems': 'center'}} className="p-0 mt-3">
                    <SearchBar { ...props.searchProps } className="p-0 m-0"/>
                    </div>
                  </div>
              </CardHeader>
              <CardBody>
                <BootstrapTable
                  {...props.baseProps}
                  keyField="id"
                  bootstrap4
                  rowEvents={ {
                    style: { cursor: 'pointer'},
                    onClick: (e, row) => {
                      if (row.timeline_xref === null || !row.timeline_xref) {
                        console.log(row)
                      } else {
                        this.setState({referrer: `/profile/${this.props.user_status.pk}/workout/${row.timeline_xref}`})
                      }
                    }
                  }}
                  bordered={false}
                  hover={true}
                  pagination={paginationFactory({
                    sizePerPage: 25,
                    sizePerPageList: [5, 10, 25, 50]
                  })}
                />
              </CardBody>
            </div>
          )}
        </ToolkitProvider>
      </Card>
    )
  }

  render() {
    const {referrer} = this.state;
    if (
      this.props.user_status === null ||
      this.state.workout_programs_loading ||
      this.state.weight_progression_loading ) {
      return <Loading />
    }
    else if (referrer) {
      return <Redirect to={referrer} />;
    }
    return (
      <Container fluid>

      <Header
      datefilter = {this.datefilter}
      datechange = {this.datechange}
      start={this.state.start}
      end={this.state.end}
      range={this.state.range}
      changer={this.state.changer}
      workout_programs_recent = {this.state.workout_programs_recent}
      workout_programs = {this.state.workout_programs}
      handlerWorkoutSelected = {this.handlerWorkoutSelected}
      fetchAnalyticsStartEnd={this.fetchAnalyticsStartEnd}/>

        <Row>
          <Col lg="12">

            {this.renderChart()}
            {this.renderForm()}
            {this.renderTable()}
          </Col>

        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  user_id: state.auth.user.pk,
  user_status: state.auth.user,
  general: state.general.isLoading,
  theme: state.theme.currentTheme,
});
export default connect(mapStateToProps, { posbodyWeight })(BodyWeightTracking);
