import {
  ELEMENT_LOADING,
  ELEMENT_LOADED,
  LIFT_TRACKING,
  TIMELINE_YOU_ONLY,
  TIMELINE_ALBUM_ONLY,
  TIMELINE_MEMBER_ONLY,
  TIMELINE_YOU_AND_FOLLOWING,
  TIMELINE_YOU_ONLY_LAST,
  TIMELINE_ALBUM_ONLY_LAST,
  TIMELINE_MEMBER_ONLY_LAST,
  TIMELINE_YOU_AND_FOLLOWING_LAST,
} from './types';


// ELEMENT LOADING
export const elementLoading = () => (dispatch, getState) => {
  // element loading
  dispatch({ type: ELEMENT_LOADING });

};

// ELEMENT LOADED
export const elementLoaded = () => (dispatch, getState) => {
  // element loaded
  dispatch({ type: ELEMENT_LOADED });

};

// Lift Tracking Input
export const lifttracking = (data) => (dispatch, getState) => {
  // element loaded
  dispatch({ type: LIFT_TRACKING, payload: data });

};

// Timeline
export const timeline_you_only = (data) => (dispatch, getState) => {
  dispatch({ type: TIMELINE_YOU_ONLY, payload: data });
};

// Timeline
export const timeline_you_and_following = (data) => (dispatch, getState) => {
  dispatch({ type: TIMELINE_YOU_AND_FOLLOWING, payload: data });
};

// Timeline
export const timeline_member_only = (data) => (dispatch, getState) => {
  dispatch({ type: TIMELINE_MEMBER_ONLY, payload: data });
};

// Timeline
export const timeline_album_only = (data) => (dispatch, getState) => {
  dispatch({ type: TIMELINE_ALBUM_ONLY, payload: data });
};

export const timeline_you_only_last = (data) => (dispatch, getState) => {
  dispatch({ type: TIMELINE_YOU_ONLY_LAST, payload: data });
};

// Timeline
export const timeline_you_and_following_last = (data) => (dispatch, getState) => {
  dispatch({ type: TIMELINE_YOU_AND_FOLLOWING_LAST, payload: data });
};

// Timeline
export const timeline_member_only_last = (data) => (dispatch, getState) => {
  dispatch({ type: TIMELINE_MEMBER_ONLY_LAST, payload: data });
};

// Timeline
export const timeline_album_only_last_data = (data) => (dispatch, getState) => {
  dispatch({ type: TIMELINE_ALBUM_ONLY_LAST, payload: data });
};
