import React from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import Api from '../../config/auth';
import {
  Button,
  Spinner,
} from "reactstrap";



class VerifyEmail extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      done:null
    };
  }




  componentDidMount() {
    this.fetchWorkouts(this.props.match.params.verifyID)
  }

  fetchWorkouts = async (exerciseID) => {
    await Api.emailVeirfy(
        exerciseID,
        (success, data) => {
      if ( success) {
        console.log('success')
         console.log(data.length)
         if(data.length >= 20) {
           this.setState({done:true})
         }
         else if (data.length < 20){
           this.setState({done:false})
         }
      } else {
        console.log('fail')
        if(data.length >= 20) {
          this.setState({done:true})
        }
        else if (data.length < 20){
          this.setState({done:false})
        }

      }
    });
  };

  onChange = (e) => {
   this.setState({ [e.target.name]: e.target.value });
 }


  render() {
    return(

      <div className="w-100 py-5 px-md-5 px-xl-6 position-relative" >
      {this.state.done === null?

        <div className="text-center">
           <Spinner color="primary" />
        </div>


        :
        this.state.done === false?

        <div className="text-center">
          <h1 className="display-1 font-weight-bold">Hmmm!</h1>
          <p className="h1">Something went wrong</p>
          <p className="h2 font-weight-normal mt-3 mb-4">

          </p>
          <Link to="/dashboard">
            <Button color="primary" size="lg" tag={Link} to="/">
              Return to website
            </Button>
          </Link>
        </div>


        :
        <div className="text-center">
          <h1 className="display-1 font-weight-bold">Verified!</h1>
          <p className="h1">Thank you</p>
          <p className="h2 font-weight-normal mt-3 mb-4">
            Now keep lifiting..
          </p>
          <Link to="/dashboard">
            <Button color="primary" size="lg" tag={Link} to="/">
              Return to website
            </Button>
          </Link>
        </div>
      }
      </div>

    )
  }
}

const mapStateToProps = (state) => ({
  user_status: state.auth.user,
});

export default connect(mapStateToProps, {  })(VerifyEmail);
