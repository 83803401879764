import React from "react";
import { toastr } from "react-redux-toastr";
import {
  Card,
  Col,
  Container,
  Row,
} from "reactstrap";

import { exerciseTracker } from '../../../redux/actions/lift_tracking';
import { connect } from 'react-redux';
import AddAlbumModal from "./AddAlbumModal";

class AlbumNotFound extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      lift_tracking_input: [],
      workouts_popular: [],
      title: "",
      message: "",
      type: "success",
      timeOut: 5000,
      showCloseButton: true,
      progressBar: true,
      position: "top-right",
      button_spinner: 0,
    };
  }

  handlerButtonSpinner = (val) => {
    this.setState({
      button_spinner: val
    })
  }

  showToastr(name) {
    const options = {
      timeOut: 5000,
      showCloseButton: true,
      progressBar: true,
      position: "top-right"
    };

    const toastrInstance = toastr.success

    toastrInstance(
      "Show your progress",
      "Creating Photo Album " + name,
      options
    );
  }

  renderprimaryView = () => {
    return(
      <Container fluid className="p-0">
        <div className="h3 mb-3"></div>

        <Row>
          <Col md="10" xl="8" className="mx-auto">
            <h1 className="text-center">My Photo Albums</h1>
            <p className="lead text-center mb-4">
                It's not just the charts. Upload before and after pictures and share your progress
            </p>

            <Row className="justify-content-center mt-3 mb-2">
              <Col xs="auto">


              <AddAlbumModal
                album_name = {this.props.album_name}
                album_name_value = {this.props.album_name_value}
                onSubmit={this.props.onSubmit}
                location={"float-center"}
                size = {"lg"}
                color={"primary"}
                outline={"no"}
                button_spinner = {this.state.button_spinner}
                buttonSpinnerFunction = {this.handlerButtonSpinner}
                showToastr = {this.showToastr}
                album_name_function = {this.props.album_name_function}
                album_des_function = {this.props.album_des_function}
                post_to_timeline_function = {this.props.post_to_timeline_function}
                is_private_function = {this.props.is_private_function}
                />


              </Col>
            </Row>



            <hr />


          </Col>
        </Row>
      </Container>
    )
  }

  reternView = () => {
    if( this.props.source === "profile_filter") {
      return(
        <Card>
          <Row>
            <Col md="10" xl="8" className="mx-auto pt-3">
              <h1 className="text-center">No Albums Found</h1>
              <p className="lead text-center mb-4">
                  Progression is not just about the charts. Upload some before and after pictures and show your progress
              </p>

              <Row className="justify-content-center mt-3 mb-2">
                <Col xs="auto">


                <AddAlbumModal
                  album_name = {this.props.album_name}
                  album_name_value = {this.props.album_name_value}
                  onSubmit={this.props.onSubmit}
                  location={"float-center"}
                  size = {"lg"}
                  color={"primary"}
                  outline={"no"}
                  button_spinner = {this.state.button_spinner}
                  buttonSpinnerFunction = {this.handlerButtonSpinner}
                  showToastr = {this.showToastr}
                  album_name_function = {this.props.album_name_function}
                  album_des_function = {this.props.album_des_function}
                  post_to_timeline_function = {this.props.post_to_timeline_function}
                  is_private_function = {this.props.is_private_function}
                  />


                </Col>
              </Row>



              <hr />


            </Col>
          </Row>
        </Card>
      )
    }
    return(
      this.renderprimaryView()
    )
  }

  render() {

    return (

      <>
        {this.reternView()}
      </>





    );
  }
}

const mapStateToProps = (state) => ({
  theme: state.theme.currentTheme,
});
export default connect(mapStateToProps, { exerciseTracker })(AlbumNotFound);
