import axios from 'axios';
import {
  API_USER_LOCATION,
  API_BODY_WEIGHT,
  API_BODY_HEIGHT,
  API_TIMELINE_COMMENTS,
  API_TIMELINE_REPLY_COMMENTS,
  API_USER_PHOTOS_PHOTOS_COMMENTS,
  API_USER_PHOTOS_PHOTOS_REPLY_COMMENTS,
  API_USER_PROFILES_EXTRA,
  API_LIFT_TRACKER_WORKOUT_SESSION,
  API_LIFT_TRACKER_EXERCISE_LOG,
  API_NOTIFICATIONS_BELL,
  API_USER_PROFILES_MY_PROFILE
 } from '../redux/actions/API'
import { keyConfig, keyConfigPro } from './key'


// POST BIRTHDAY Month
const _postBirthdayGender = async (id, bmonth, byear, gender, callback) => {

  const body = await JSON.stringify({ bmonth,byear,gender });
  await axios.patch(`${API_USER_PROFILES_EXTRA}${id}/`, body, await keyConfig())
  .then(res => {
      callback(true, res.data);
    })
  .catch(error => {
      callback(false, error.response.data);
    })
};

//POST LOCATION
const _postLocation = async (user_id_xref, location_city, callback) => {

  const body = await JSON.stringify({user_id_xref, location_city  });
  await axios.post(`${API_USER_LOCATION}`, body, await keyConfig())
  .then(res => {
      callback(true, res.data);
    })
  .catch(error => {
      callback(false, error.response.data);
    })
};

//POST LOCATION
const _posbodyWeight = async (user_id_xref, usa_imperial, value, callback) => {

  const body = await JSON.stringify({user_id_xref, usa_imperial, value });
  console.log(body)
  await axios.post(`${API_BODY_WEIGHT}`, body, await keyConfig())
  .then(res => {
      callback(true, res.data);
    })
  .catch(error => {
    console.log(error)
      callback(false, error.response.data);
    })
};

//POST LOCATION
const _posbodyHeight = async (user_id_xref, usa_imperial, value, callback) => {

  const body = await JSON.stringify({user_id_xref, usa_imperial, value });
  await axios.post(`${API_BODY_HEIGHT}`, body, await keyConfig())
  .then(res => {
      callback(true, res.data);
    })
  .catch(error => {
      callback(false, error.response.data);
    })
};

// Remove Comment (Tineline)
const _timelineCommentsDelete = async (id, callback) => {

  // Request Body
  await axios.delete(`${API_TIMELINE_COMMENTS}${id}/`, await keyConfig())
  .then(res => {
      callback(true, res.data);
    })
  .catch(error => {
      callback(false, error.response.data);
    })
};

// Remove Comment Reply (Tineline)
const _timelineCommentsReplyDelete = async (id, callback) => {

  // Request Body
  await axios.delete(`${API_TIMELINE_REPLY_COMMENTS}${id}/`, await keyConfig())
  .then(res => {
      callback(true, res.data);
    })
  .catch(error => {
      callback(false, error.response.data);
    })
};

// Remove Comment (Photo)
const _photoCommentsDelete = async (id, callback) => {

  // Request Body
  await axios.delete(`${API_USER_PHOTOS_PHOTOS_COMMENTS}${id}/`, await keyConfig())
  .then(res => {
      callback(true, res.data);
    })
  .catch(error => {
      callback(false, error.response.data);
    })
};

// Remove Comment Reply (Photo)
const _photoCommentsReplyDelete = async (id, callback) => {

  // Request Body
  await axios.delete(`${API_USER_PHOTOS_PHOTOS_REPLY_COMMENTS}${id}/`, await keyConfig())
  .then(res => {
      callback(true, res.data);
    })
  .catch(error => {
      callback(false, error.response.data);
    })
};

// Post Workoutsession
const _postWorkoutSession = async (usa_imperial, session_day, user_id_xref, user_enrollment_history_xref, workout_day_xref, duration_xref, callback) => {

  // Request Body
  const body = await JSON.stringify({ usa_imperial, session_day, user_id_xref, user_enrollment_history_xref, workout_day_xref, duration_xref });
  await axios.post(`${API_LIFT_TRACKER_WORKOUT_SESSION}`, body, await keyConfig())
  .then(res => {
      callback(true, res.data);
    })
  .catch(error => {
      callback(false, error.response.data);
    })
};

// Post Workoutsession
const _patchWorkoutSession = async (id, is_active, recorded_start_time, callback) => {

  // Request Body
  const body = await JSON.stringify({ is_active, recorded_start_time  });
  await axios.patch(`${API_LIFT_TRACKER_WORKOUT_SESSION}${id}/`, body, await keyConfig())
  .then(res => {
      callback(true, res.data);
    })
  .catch(error => {
      callback(false, error.response.data);
    })
};


const _postWorkoutExerciseLog = async (
  workout_session_xref,
  exercise_xref,
  set_xref,
  weight,
  rep_count,
  weight_level,
  usa_imperial,
  callback) => {

  // Request Body
  const body = await JSON.stringify({
    workout_session_xref,
    exercise_xref,
    set_xref, weight, rep_count, weight_level, usa_imperial });
  await axios.post(`${API_LIFT_TRACKER_EXERCISE_LOG}`, body, await keyConfig())
  .then(res => {
      callback(true, res.data);
    })
  .catch(error => {
      callback(false, error.response.data);
    })
};

// Post Workoutsession
const _notificationBell = async (user_id_xref, event, callback) => {

  // Request Body
  const body = await JSON.stringify({user_id_xref, event  });
  await axios.patch(`${API_NOTIFICATIONS_BELL}${user_id_xref}/`, body, await keyConfig())
  .then(res => {
      callback(true, res.data);
    })
  .catch(error => {
      callback(false, error.response.data);
    })
};

// Post Body Weight
const _postBodyWeight = async (value, timestamp, usa_imperial, user_id_xref,workout_session_xref, callback) => {
  // Request Body
  const body = await JSON.stringify({value, timestamp, usa_imperial, user_id_xref,workout_session_xref});
  await axios.post(`${API_BODY_WEIGHT}`, body, await keyConfig())
  .then(res => {
      callback(true, res.data);
    })
  .catch(error => {
      callback(false, error.response.data);
    })
};




const _deleteUser = async (id, key, callback) => {

  await axios.delete(`${API_USER_PROFILES_MY_PROFILE}${id}/`, await keyConfigPro(key))
  .then(res => {
      callback(true, res.data);
    })
  .catch(error => {
      callback(false, error.response.data);
    })

};


const dict = {
  postBirthdayGender: _postBirthdayGender,
  postLocation:_postLocation,
  posbodyHeight: _posbodyHeight,
  posbodyWeight: _posbodyWeight,
  timelineCommentsDelete:_timelineCommentsDelete,
  timelineCommentsReplyDelete:_timelineCommentsReplyDelete,
  photoCommentsDelete:_photoCommentsDelete,
  photoCommentsReplyDelete: _photoCommentsReplyDelete,
  postWorkoutSession: _postWorkoutSession,
  postWorkoutExerciseLog:_postWorkoutExerciseLog,
  patchWorkoutSession :_patchWorkoutSession,
  notificationBell:_notificationBell,
  postBodyWeight:_postBodyWeight,
  deleteUser: _deleteUser
};

export default dict;
