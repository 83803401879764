import React from "react";
import { followUser } from "../../../redux/actions/social";
import { Link } from "react-router-dom";
import { Redirect } from 'react-router-dom';
import {
  Button,
  CardBody,
  Media,
} from "reactstrap";
import { connect } from 'react-redux';
import {
  API_BASE_MEDIA_URL
} from '../../../redux/actions/API';
import Gallery from "react-photo-gallery";

class FollowingCapped extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      cap: 3,
      photos: this.props.albums.map(({ user_id_xref, id, photos }) => {return{
        ida:user_id_xref,
        id:user_id_xref[0].pk + '/albums/' + id,
        src: photos[0].photo,
        width: photos[0].width,
        height: photos[0].height,
      }}),
      redirect: null,
      redirect2: null,
    };
  }

  renderAlbumAvatar = (x, user_id) => {
    return(
      <Media>
        <Link to={"/profile/" + user_id + "/albums/" + x.id}>
        <img
          src={API_BASE_MEDIA_URL + x.cover_image}
          height= '100vw'
           max-width= '21px'
          className=" mr-2"
          alt="Chris Wood"
        />
        </Link>
        <Media body>
          <p className="my-1">
            <strong>{x.album_name}</strong>
          </p>
          <span> sum </span>
        </Media>
      </Media>
      )
  }



  onClick = (event) => {
    this.setState({redirect: event.target.id})
  }

  renderAlbums = (input_data, cap, user_id) => {
      return(
        <div>
          <Gallery photos={this.state.photos.slice(0,this.state.cap)} direction={"row"} onClick={this.onClick}/>
        </div>
      )
  }

  renderSeeAllButton = (length,user_id,cap) => {
    if (length > cap) {
      return(
        <Button
          color="primary"
          className = ""
          block
          tag={Link}
          to={"/profile/" + user_id + "/albums"}
          >
          See All ({this.state.photos.length})
        </Button>
      )
    }
  }

  render() {
    if (this.state.redirect !==null) {
      return <Redirect to={'/profile/' + this.state.redirect} />;
    }
      return (
        <CardBody>
          {this.renderAlbums(this.props.albums, this.state.cap, this.props.user_id)}
          {this.renderSeeAllButton(this.props.albums_count, this.props.user_id, this.state.cap)}
        </CardBody>
      );
  }
}

const mapStateToProps = (state) => ({
  theme: state.theme.currentTheme,
});

export default connect(mapStateToProps, { followUser })(FollowingCapped);
