import React from "react";
import { Redirect } from "react-router-dom";
import {
  Card,
  CardBody,
  CardTitle,
  CardHeader,
} from "reactstrap";


import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";


import { connect } from 'react-redux';


const MyExportCSV = props => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div>
        <button className="btn btn-secondary mt-2" onClick={handleClick}>
          Export
        </button>
      </div>
    );
  };

const { SearchBar } = Search;

class LiftTable extends React.Component {



  constructor(props) {
    super(props);
    this.state = {
      referrer: null,
    };
  }

  render() {
    console.log(this.props.exercise_table)
    console.log(this.props.exercise_data)
    const {referrer} = this.state;
    if (referrer) {
      return <Redirect to={referrer} />;
    }
    return (

          <Card>
            <ToolkitProvider
              keyField="id"
              data={this.props.exercise_table[0].workout_session}
              columns={
              [
                {
                  dataField: "id",
                  text: "id",
                  sort: false,
                  hidden: true,
                },
                {
                  dataField: "exercise_name",
                  text: "Exercise",
                  sort: true,
                },
                {
                  dataField: "set_xref",
                  text: "Set",
                  sort: true,
                },
                {
                  dataField: "rep_count",
                  text: "Reps",
                  sort: true,
                },
                {
                  dataField: "weight_std",
                  text: this.props.user_status.usa_imperial? "Weight (LB)" : "Weight (KG)",
                  sort: true,
                  formatter: (cellContent, row) => {
                    return this.props.exercise_table[0].workout_session[0].exercise_modality === 5? '--' : cellContent
                  }
                },
              ]
            }
              exportCSV
              search
            >
              {(props) => (
                <div>
                  <CardHeader className="d-flex flex justify-content-between align-items-center">
                    <div>
                    <CardTitle tag="h5">Session Data</CardTitle>
                    </div>


                    <div className="d-flex flex-row-reverse" >
                        <div style={{display: 'flex', 'justifyContent': 'center', 'alignItems': 'center'}} className="p-0 ml-1">
                          <MyExportCSV {...props.csvProps} />
                        </div>
                        <div style={{display: 'flex', 'justifyContent': 'center', 'alignItems': 'center'}} className="p-0 mt-3">
                        <SearchBar { ...props.searchProps } className="p-0 m-0"/>
                        </div>
                      </div>
                  </CardHeader>
                  <CardBody>
                    <BootstrapTable
                      {...props.baseProps}
                      keyField="id"
                      bootstrap4
                      bordered={false}
                      rowEvents={ this.props.user_status.pk === this.props.active_profile? {
                        style: { cursor: 'pointer'},
                        onClick: (e, row) => { this.setState({referrer: `/exercises/detail/${row.exercise_xref}`}) }
                      } : null}
                      hover={this.props.user_status.pk === this.props.active_profile? true : null}
                      pagination={paginationFactory({
                        sizePerPage: 25,
                        sizePerPageList: [5, 10, 25, 50]
                      })}
                    />
                  </CardBody>
                </div>
              )}
            </ToolkitProvider>
          </Card>

    );
  }
}


const mapStateToProps = (state) => ({
  user_status: state.auth.user,
});

export default connect(mapStateToProps, {  })(LiftTable);
