import React, { Component } from 'react';
import { connect } from 'react-redux';
import { login, loginSocialGoogle } from '../../redux/actions/auth';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GoogleLogin from 'react-google-login';
import {
  faGoogle,
} from "@fortawesome/free-brands-svg-icons";
import {
  Button,
  Spinner,
} from "reactstrap";

export class SignIn extends Component {

  responseGoogle = async (response) => {
  await this.setState({loader: true})
  const result = await this.props.loginSocialGoogle(response.accessToken)
  if (result !== null && result.response) {
    this.setState({loader: false, message: result.response?.data?.non_field_errors[0]})
  {/*  console.log(Object.keys(state.auth.error).map(field => {
      return {field, message: state.auth.error[field]};
    })
  ) */}
  }
}


  state = {
    loader: false,
    message: false,
  };



  render() {
    return(
      <>
      <GoogleLogin
        clientId="881293883312-4s5r1o7990frmgivfb5960gscnhitv3q.apps.googleusercontent.com"
        render={renderProps => (
          <Button
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
            color="google" size="lg"
            type="submit"
            className="mr-1 btn-block flex d-flex justify-content-center align-items-center" >

          {this.state.loader?
            <Spinner color="secondary" />
            :

          <>
          <FontAwesomeIcon icon={faGoogle} className="align-middle float-left mr-3" />{" "}
          {this.props.source === "signin" ?
                <div>Login in using <b> Google</b> </div>
                :
                  this.props.source === "landing" ?
                    <div>  Continue with{" "} <b> Google</b> </div>
                    :
            <div>  Sign Up with{" "} <b> Google</b> </div>
            }
            </>
          }

          </Button>


        )}
        buttonText="Login"
        onSuccess={this.responseGoogle}
        onFailure={() => this.setState({loader:false})}
        cookiePolicy={'single_host_origin'}
      />
        {this.state.message?<p className="text-danger text-center">{this.state.message}</p> : null}
      </>
    )
  }
}

const mapStateToProps = state => {
  let errors = [];
  if (state.auth.error) {
    errors = Object.keys(state.auth.error).map(field => {
      return {field, message: state.auth.error[field]};
    });
  }
  return {
    errors,
    auth: state.auth,
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated
  };
}

export default connect(mapStateToProps, { login, loginSocialGoogle })(SignIn);
