import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { enableModernTheme } from "../../redux/actions/themeActions";
import AOS from 'aos';
import 'aos/dist/aos.css';
import LandingSingIn from './LandingSingIn'
import logo from '../../assets/img/brands/logo.svg'
import {
  Button,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  Col,
  Container,
  Row,
  Card,
  CardBody,
} from "reactstrap";
import lifterGirl from "./LifterGirl.jpg"
import FacebookButton from '../../pages/auth/FacebookButton.js'
import GoogleButton from '../../pages/auth/GoogleButton.js'
import AppleButton from '../../pages/auth/AppleButton.js'
AOS.init();




class Body extends React.Component {


  renderFooter = () => {
    const mystyle = {
      color: "gray",
    };
    return(
      <Nav className="d-flex p-2 justify-content-center text-wrap" style={{backgroundColor:'#0e1628'}}>

      {/*
        <NavItem>
          <NavLink tag={Link} to="/about" style={mystyle}>About</NavLink>
        </NavItem>
        */}

        {/*
        <NavItem>
          <NavLink href="#" style={mystyle}>Read Blog</NavLink>
        </NavItem>
        */}

        <NavItem>
          <NavLink
          style={mystyle}
          tag={Link}
          to="/auth/sign-in"
          >Log In</NavLink>
        </NavItem>

        <NavItem>
          <NavLink
          style={mystyle}
          tag={Link}
          to="/auth/sign-up"
          >Sign Up</NavLink>
        </NavItem>


        <NavItem>
          <NavLink href="https://play.google.com/store/apps/details?id=io.ironroom" target='_blank' style={mystyle}>Android App</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="https://apps.apple.com/us/app/ironroom/id1568748504" target='_blank' style={mystyle}>iPhone App</NavLink>
        </NavItem>


        <NavItem>
          <NavLink
          style={mystyle}
          tag={Link}
          to="/helpcenter"
          >Help Center</NavLink>
        </NavItem>


        <NavItem>
          <NavLink
          style={mystyle}
          tag={Link}
          to="/legal"
          >Terms of Use</NavLink>
        </NavItem>

        <NavItem>
          <NavLink
          tag={Link}
          to="/privacy"
          style={mystyle}
          >Privacy Policy</NavLink>
        </NavItem>

        <NavItem>
          <NavLink
          tag={Link}
          to="/copyright"
          style={mystyle}
          >Copyright</NavLink>
        </NavItem>

        <NavItem>
          <NavLink href="https://drive.google.com/drive/u/0/folders/1RkkZHKCyIMjs7rv4-yrZy42wrwG78zse" target="_blank" style={mystyle}>Brand Resources</NavLink>
        </NavItem>


        <NavItem>
          <NavLink href="#" style={mystyle}>
          <p className="mb-0">
            &copy; {new Date().getFullYear()} {" "}
            <span href="/" >
              Ironroom.
            </span>
          </p>
          </NavLink>
        </NavItem>
      </Nav>
    )
  }

  renderRight = () => {
    return(

      <Col md="6" className="d-flex flex-column justify-content-center align-items-center" style={{backgroundColor:'#0e1628'}}>


        <Navbar  light expand="sm" className="absolute-top w-100 m-0 p-3" style={{border:'none'}}>
          <Container>

            <Nav className="ml-auto mt-2" navbar>

            <LandingSingIn />

            </Nav>


          </Container>
        </Navbar>



        <div  style={{width:'55%'}}>


          <div className="text-left">
            <h1 className="h2 text-light mb-5" style={{fontSize:'32px'}}>The most kickass tracker for weight lifters</h1>
            <p className="lead text-light mt-3">
            Join ironroom today.
            </p>
          </div>


            <Card>
            <CardBody>
                  <div className="text-center mt-1">



                         <Button color="dark" size="lg" className=" btn-block" tag={Link} to="/auth/sign-up">
                           Sign up
                         </Button>

                         <Button color="dark" outline size="lg" className=" btn-block" tag={Link} to="/auth/sign-in">
                           Log in
                         </Button>


                    <div className="d-flex flex-row align-items-center mt-1 mb-1">
       <hr width="20%" size="8" align="right" color="#f8f9fa" className="mr-1"/> <div className="text-dark">OR</div>  <hr width="20%" size="8" align="left" color="#f8f9fa" className="ml-1"/>
       </div>

       <GoogleButton  source={"landing"}/>
       <AppleButton  source={"landing"}/>





                  </div>
                  </CardBody>
                  </Card>

          </div>



      </Col>

    )
  }


  renderLeft = () => {
    return(
      <Col md="6" className="" style={{backgroundColor:'#d3e2f0', "backgroundImage": `url(${lifterGirl})`, objectFit: 'cover', backgroundSize: 'cover', backgroundRepeat:'no-repeat' }} >


        <Navbar   expand="sm" className="absolute-top w-100 m-0 p-3" style={{border:'none'}}>
          <Container >

                <img src={logo} alt="React Logo" style={{'maxWidth':'30%','maxHeight':'30%'}}/>

          </Container>
        </Navbar>


        <section className="vh-100" style={{'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center','height': '200px', }}>



        </section>

      </Col>
    )
  }

  render () {
    return(

    <div className="vh-100 bg-dark d-flex flex-column justify-content-end ">

    <div className="m-0 p-0" style={{height: '100%', width: '100%', 'overflowX':'hidden', 'overflowY':'hidden'}}>
    <Row className="min-vh-100 d-flex ">
        {this.renderLeft()}
    {this.renderRight()}

    </Row>
    </div>



    {this.renderFooter()}

    </div>

    )
  }
}


const Landing = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(enableModernTheme());
  }, [dispatch]);

  return (
    <React.Fragment>
      <Body />
    </React.Fragment>
  )
}

export default connect()(Landing);
