import React from "react";
import { Pie } from "react-chartjs-2";
import { connect } from "react-redux";
import prettyNum, {PRECISION_SETTING} from 'pretty-num';
import {
  CardBody,
  Card,
  CardHeader,
  CardTitle,
  Table,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-solid-svg-icons";

const PieChart = ({ theme, ...props }) => {
  const data = {
    labels: props.api_muscle_group_labels,
    datasets: [
      {
        data: props.api_muscle_group_array,
        backgroundColor: [
          theme.primary,
          theme.warning,
          theme.danger,
          "#E8EAED",
          theme.info,
          theme.success,
        ],
        borderWidth: 5
      }
    ]
  };

  const options = {
    maintainAspectRatio: false,
    cutoutPercentage: 70,
    legend: {
      display: false
    }
  };

  return (
    <Card className="flex-fill w-100">
      <CardHeader>
        <div className="card-actions float-right">

        </div>
        <CardTitle tag="h5" className="mb-0">
          Muscle Group / Volume Moved
        </CardTitle>
      </CardHeader>
      <CardBody className="d-flex">
        <div className="align-self-center w-100">
          <div className="py-3">
            <div className="chart chart-xs">
              <Pie data={data} options={options} />
            </div>
          </div>

          <Table className="mb-0">
            <thead>
              <tr>
                <th>Muscle Group</th>
                <th className="text-right">Volume Moved ({props.api_muscle_group_measurement})</th>
              </tr>
            </thead>
            <tbody>
            {props.api_muscle_group.map((a,b) =>
              <tr key={b}>
                <td>
                  <FontAwesomeIcon icon={faSquare}
                  className={b===0? "text-primary" : b===1? "text-warning" : b===2? "text-danger" :  b===3? "#E8EAED" : b===4? "text-info" : b===5? "text-success" : null}
                  />{" "}
                  {a.muscle_group}
                </td>
                <td className="text-right">
                {prettyNum(a.volume_moved, {precision: 0, thousandsSeparator: ','})}
                </td>
              </tr>
              )}
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  );
};

export default connect(store => ({
  theme: store.theme.currentTheme
}))(PieChart);
