import React from "react";
import {
  Container,
  Row,
  Col,
} from "reactstrap";
import { connect } from 'react-redux';

import Deactivate from './Deactivate'
import Delete from './Delete'
import Header from './Header'
import Navigation from '../Navigation';


class Default extends React.Component {




  render() {
    return(
      <Container fluid style={{'overflow-y': 'auto'}}>
      <Header />

        <Row>
          <Col md="3" xl="2">
            <Navigation activeName = {"delete"}/>
          </Col>
          <Col md="9" xl="10">
            <Deactivate />
            <Delete />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, {  })(Default);
