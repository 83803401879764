import React from "react";
import TimelineLazy from "../../../components/Social/Timeline/TimelineLazy";
import { connect } from 'react-redux';

class Timeline extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "AllActivity"
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render() {
    return (
      <section className="flex-fill w-100">
      <TimelineLazy
      list = {"you_and_following"}
        />
      </section>
  );
 }
}

const mapStateToProps = (state) => ({
  user_status: state.auth.user,
  general: state.general.isLoading,
  theme: state.theme.currentTheme
});

export default connect(mapStateToProps, {  })(Timeline);
