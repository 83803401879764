import React, { useState } from "react";
import {  withRouter} from 'react-router-dom';
import { connect } from "react-redux";
import store from "../../redux/store/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWeightHanging,
} from "@fortawesome/free-solid-svg-icons";
import { Button, Modal, ModalHeader, DropdownItem } from 'reactstrap';
import CreateExerciseModalBody from './CreateExerciseModalBody'

const ModalExercise = (props) => {
  const {
    className
  } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <div>
    {props.source==="Nav"?

      <DropdownItem onClick={toggle}>
        <FontAwesomeIcon
          icon={faWeightHanging}
          fixedWidth
          className="mr-2 align-middle"
        />
        Create Exercise
      </DropdownItem>
      :
      <Button color="primary" className="shadow-sm mr-1" onClick={toggle}>
         Create Exercise
      </Button>
    }

      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>Create An Exercise</ModalHeader>

        <CreateExerciseModalBody toggle={toggle} showAll={props.showAll}/>


      </Modal>
    </div>
  );
}

class CreateExercise extends React.Component {



  render() {
    return (
      <ModalExercise source={this.props.source} showAll={this.props.showAll}/>
    );
  }
}

const mapStateToProps = (state) => ({
  user_status: state.auth.user,
  auth: state.auth,
  sidebar: store.sidebar,
});

export default connect(mapStateToProps, {  })(withRouter(CreateExercise));
