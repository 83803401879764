import React from "react";

import {
  Col,
  Row,
} from "reactstrap";


const Header = (props) => {
  return (
    <Row className="mb-2 mb-xl-4">
      <Col xs="auto" className="d-none d-sm-block">
        <h3>Subscriptions and Payments</h3>

      </Col>

      <Col xs="auto" className="ml-auto text-right mt-n1">

      </Col>
    </Row>
  );
};

export default Header;
