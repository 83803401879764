export const GET_LEADS = 'GET_LEADS';
export const DELETE_LEAD = 'DELETE_LEAD';
export const ADD_LEAD = 'ADD_LEAD';
export const GET_ERRORS = 'GET_ERRORS';
export const CREATE_MESSAGE = 'CREATE_MESSAGE';
export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const CLEAR_POSTS = 'CLEAR_POSTS';
export const ELEMENT_LOADING = 'ELEMENT_LOADING';
export const ELEMENT_LOADED = 'ELEMENT_LOADED';
export const ENROLL_SUCCESS = 'ENROLL_SUCCESS';
export const ENROLL_FAIL = 'ENROLL_FAIL';
export const ENROLL_COMPLETE_SUCCESS = 'ENROLL_COMPLETE_SUCCESS';
export const ENROLL_COMPLETE_FAIL = 'ENROLL_COMPLETE_FAIL';
export const ENROLL_RESUME_SUCCESS = 'ENROLL_RESUME_SUCCESS';
export const ENROLL_RESUME_FAIL = 'ENROLL_RESUME_FAIL';
export const LOGIN_FLAG = 'LOGIN_FLAG';
export const FOLLOW_SUCCESS = 'FOLLOW_SUCCESS';
export const FOLLOW_FAIL = 'FOLLOW_FAIL';
export const OPEN_ADD_CHAT_POPUP = "OPEN_ADD_CHAT_POPUP";
export const CLOSE_ADD_CHAT_POPUP = "CLOSE_ADD_CHAT_POPUP";
export const ADD_MESSAGE = "ADD_MESSAGE";
export const SET_MESSAGES = "SET_MESSAGES";
export const GET_CHATS_SUCCESS = "GET_CHATS_SUCCESS";
export const GET_PROFILE_PHOTO = "GET_PROFILE_PHOTO";
export const GET_PROFILE_PHOTO_FAIL = "GET_PROFILE_PHOTO_FAIL";
export const CHANGE_NAME_SUCCESS = "CHANGE_NAME_SUCCESS";
export const CHANGE_NAME_FAIL = "CHANGE_NAME_FAIL";
export const GET_CHANGE_NAME_SUCCESS = "GET_CHANGE_NAME_SUCCESS";
export const GET_CHANGE_NAME_FAIL = "GET_CHANGE_NAME_FAIL";
export const CHANGE_HEADING_SUCCESS = "CHANGE_NAME_SUCCESS";
export const CHANGE_HEADING_FAIL = "CHANGE_NAME_FAIL";
export const LIFT_TRACKING = "LIFT_TRACKING";
export const TIMELINE_YOU_ONLY = "TIMELINE_YOU_ONLY";
export const TIMELINE_YOU_AND_FOLLOWING = "TIMELINE_YOU_AND_FOLLOWING";
export const TIMELINE_MEMBER_ONLY = "TIMELINE_MEMBER_ONLY";
export const TIMELINE_ALBUM_ONLY = "TIMELINE_ALBUM_ONLY";
export const TIMELINE_YOU_ONLY_LAST = "TIMELINE_YOU_ONLY_LAST";
export const TIMELINE_YOU_AND_FOLLOWING_LAST = "TIMELINE_YOU_AND_FOLLOWING_LAST";
export const TIMELINE_MEMBER_ONLY_LAST = "TIMELINE_MEMBER_ONLY_LAST";
export const TIMELINE_ALBUM_ONLY_LAST = "TIMELINE_ALBUM_ONLY_LAST";
