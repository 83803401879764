import React from "react";
import {
  Spinner,
} from "reactstrap";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
import {
  API_SUBSCRIBE_STRIPE_GET,
} from '../../redux/actions/API';
import axios from "axios";

// Setup config with key - helper function

const keyConfig = (tokenID) => {
  // Get key from state
  const key = tokenID

  // Headers
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // If key, add to headers config
  if (key) {
    config.headers['Authorization'] = `Token ${key}`;
  }

  return config;
};

class SubscribeOpenSuccess extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      activeTab: "monthly",
      route: false
    };
  }

  fetchNotifications = (sessionId,tokenID) =>  {
    console.log(tokenID)
    console.log(`${API_SUBSCRIBE_STRIPE_GET}${sessionId}`)
    axios.get(`${API_SUBSCRIBE_STRIPE_GET}${sessionId}&mobile=1&token=${tokenID}&success=1`, keyConfig(tokenID)).then(res => {
      console.log(res.data)
      console.log('sup')
    })
  }

  componentDidMount() {
    this.fetchNotifications(this.props.match.params.checkoutID, this.props.match.params.tokenID)
  }

  render() {

    return(
      <div className="w-100 py-5 px-md-5 px-xl-6 position-relative" >

        <div className="text-center">
          <p className="h1">Thank you</p>
          <p className="h2 font-weight-normal mt-3 mb-4">
            Now keep lifiting..
          </p>

            <Spinner color="dark" size="md" />

        </div>

      </div>
    )
  }
}

export default SubscribeOpenSuccess;
