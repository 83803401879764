import React from "react";
import { Line } from "react-chartjs-2";
import { connect } from "react-redux";


import { Card, CardBody, CardHeader, CardTitle, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown} from "reactstrap";

const LineChart = ({ theme, ...props }) => {

  const data = {
    labels: props.volume_series_label,
    datasets: [
      {
        label: "Volume (" + props.measure_type + ")",
        fill: true,
        backgroundColor: "transparent",
        borderColor: theme.primary,
        data: props.volume_series_data
      },
    ]
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    tooltips: {
      intersect: false
    },
    hover: {
      intersect: true
    },
    plugins: {
      filler: {
        propagate: false
      }
    },
    scales: {
      xAxes: [
        {
          reverse: true,
          gridLines: {
            color: "rgba(0,0,0,0.05)"
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            stepSize: 500
          },
          display: true,
          borderDash: [5, 5],
          gridLines: {
            color: "rgba(0,0,0,0)",
            fontColor: "#fff"
          }
        }
      ]
    }
  };

  return (
    <>
    {props.volume_series_loading || props.api_muscle_group_loading?
      null
      :
    <Card className="flex-fill w-100">
      <CardHeader className="flex d-flex justify-content-between align-items-center">

    <div className="flex d-flex justify-content-start align-items-center">
        <CardTitle tag="h5" className="mb-0">
           Volume By {props.source==="MG"? "Muscle Group" : "Exercise"}

        </CardTitle>

        <UncontrolledDropdown className="d-inline mr-2 ml-2">
        <DropdownToggle caret color="light" className="bg-white shadow-sm " size="md" style={{height:'100%'}}>
        {props.lineGraphSelect === 100? 'Total' : props.lineGraphSelect}
        </DropdownToggle>
        <DropdownMenu right>

        <DropdownItem onClick={() => props.toggleLineGraphMuscleGroup(100,100)}>Total</DropdownItem>

        {props.api_muscle_group.map((a,b) =>

          <DropdownItem key={b} onClick={() => props.toggleLineGraphMuscleGroup(a.id,b)}>{a.muscle_group}</DropdownItem>
          )}


        </DropdownMenu>
        </UncontrolledDropdown>
        </div>

        {props.volume_series.length === 0?
              <p className="text-muted float-right"> no data </p>
          : null}


      </CardHeader>
      <CardBody>
        <div className="chart chart-md">
          <Line data={data} options={options} />
        </div>
      </CardBody>
    </Card>
  }
    </>
  );
};

export default connect(store => ({
  theme: store.theme.currentTheme
}))(LineChart);
