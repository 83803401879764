import React from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import Api from '../../config/auth';
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner
} from "reactstrap";




class ResetPassword extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      new_password1: null,
      new_password2: null,
      uid: null,
      token: null,
    };
  }


  componentDidMount() {
    this.setState({
      uid: this.props.match.params.uID,
      token: this.props.match.params.tokenID
    })
  }

  onSubmit = async (e) => {
    e.preventDefault();
    await this.setState({isLoading: true})
    await Api.pwdresetConfirm(
        this.state.new_password1,
        this.state.new_password2,
        this.state.uid,
        this.state.token,
        (success, data) => {
      if ( success) {
        console.log('success')
         this.setState({isSuccess: true, isLoading:false})
      } else {
        this.setState({
          isLoading: false,
          isError: true,
          errors: Object.keys(data).map(field => {
              return {field, message: data[field]};
            }),
        });

      }
    });
  };

  onChange = (e) => {
   this.setState({ [e.target.name]: e.target.value });
 }


  render() {
    return(

      <div className="w-100 py-5 px-md-5 px-xl-6 position-relative" >
        <div className="text-center mt-4">
          <h1 className="h2">Reset password</h1>
          <p className="lead">Enter your email to reset your password.</p>
        </div>

        <Card>
          <CardBody>
            <div className="m-sm-4 ">
              <Form>
                <FormGroup>
                  <Label>Password</Label>
                  <Input
                    bsSize="lg"
                    type="password"
                    name="new_password1"
                    onChange={this.onChange}
                    disabled={this.state.isSuccess? true: false}
                    className={this.state.isError? "border-danger" : null}
                    placeholder="Enter your password"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Confirm Password</Label>
                  <Input
                    bsSize="lg"
                    type="password"
                    name="new_password2"
                    onChange={this.onChange}
                    disabled={this.state.isSuccess? true: false}
                    className={this.state.isError? "border-danger" : null}
                    placeholder="Confirm your password"
                  />
                </FormGroup>



                <div className="text-center mt-3 flex d-flex align-items-center justify-content-center">

                {this.state.isSuccess?


                  <Button tag={Link} to="/" color="success" className="mr-1 mb-1" style={{width:'175px'}}>
                  Return to Site
                  </Button>
                    :


                    <Button color="primary" size="lg" onClick={this.onSubmit} style={{width:'175px'}}>
                      {this.state.isLoading? <Spinner color="secondary" /> : "Reset password" }
                    </Button>


                  }

                </div>


              </Form>
            </div>
          </CardBody>
            {this.state.isSuccess?
                <span className="text-success" style={{"alignSelf":"center"}}> Your password has been reset successfully </span>
                :
              this.state.isError?
            <span className="text-danger" style={{"alignSelf":"center"}}>   {this.state.errors[0].message[0]  }
            </span>
              :
              null
              }
        </Card>
      </div>


    )
  }
}

const mapStateToProps = (state) => ({
  user_status: state.auth.user,
});

export default connect(mapStateToProps, {  })(ResetPassword);
