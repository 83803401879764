import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Collapse,
} from "reactstrap";
import { connect } from 'react-redux';


import Navigation from './Navigation';


const FaqQuestion = ({ onClick, isOpen, question, answer }) => (
  <Card className="border mb-3">
    <CardHeader className="cursor-pointer" onClick={onClick}>
      <h6 className="mb-0">{ question }</h6>
    </CardHeader>
    <Collapse isOpen={isOpen}>
      <CardBody className="py-3">
        { answer }
      </CardBody>
    </Collapse>
  </Card>
)

const Faq = () => {
  const [activeQuestion, setActiveQuestion] = useState(0);

  return (
      <div>
        <div className="mb-5 text-center">
          <h2>Frequently Asked Questions</h2>
          <p className="text-muted font-size-lg">Here are some of the answers you might be looking for.</p>
        </div>

        <Row>
          <Col md={9} lg={8} className="mx-auto">
            <FaqQuestion
              onClick={() => setActiveQuestion(0)}
              isOpen={activeQuestion === 0}
              question="Is there any support included?"
              answer={<>You have access to the Software's online support services via email for six (6) months from the purchase date. Please contact us at <a href="mailto:info@bootlab.io">info@bootlab.io</a> for any questions.</>}
            />
            <FaqQuestion
              onClick={() => setActiveQuestion(1)}
              isOpen={activeQuestion === 1}
              question="How do I get a receipt for my purchase?"
              answer={<>You'll receive an email from Bootstrap themes once your purchase is complete. More info can be found <a href="https://themes.zendesk.com/hc/en-us/articles/360000011052-How-do-I-get-a-receipt-for-my-purchase-" rel="noopener noreferrer" target="_blank">here</a>.</>}
            />
            <FaqQuestion
              onClick={() => setActiveQuestion(2)}
              isOpen={activeQuestion === 2}
              question="What methods of payments are supported?"
              answer={<>You can purchase the themes on Bootstrap Themes via any major credit/debit card (via Stripe) or with your Paypal account. We don't support cryptocurrencies or invoicing at this time.</>}
            />
          </Col>
        </Row>
      </div>
  )
};


class Default extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      section: "workouts",
    };
  }

  sectionCallback = (val) => {
    this.setState({section:val})
  }

  render() {
    return(
      <Container fluid>


        <Row>
          <Col md="3" xl="2">
            <Navigation activeName = {this.state.section} sectionCallback={this.sectionCallback}/>
          </Col>
          <Col md="9" xl="10">


          {this.state.section === "workouts"?


          <Faq />


          :
          <h1> test2 </h1>
        }




          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, {  })(Default);
