import React, { useState }from "react";
import { Link } from 'react-router-dom';
import moment from "moment";
import { connect } from 'react-redux';
import Subscribe from "../pages/pages/Subscribe.js"
import SubscribeValidate from '../config/subscribe_validate.js';



import { Button, Modal, ModalHeader, ModalBody, ModalFooter,  } from 'reactstrap';

const ModalExample = (props) => {

  const [modal] = useState(true);

  return (
    <div>
      <Modal isOpen={modal} centered size="md" >
        <ModalHeader >Analyze Your Training</ModalHeader>
        <ModalBody>
          Quickly scan your past activities to spot training patterns and trends.
        </ModalBody>
        <ModalFooter>
          <Button color="primary" tag={Link} to="/pages/pricing">Learn More</Button>{' '}
          <Button color="secondary" tag={Link} to="/pages/pricing">Subscribe</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

class SubscribeModal extends React.Component {



  constructor(props) {
    super(props);
    this.state = {
      timeNow: moment().subtract(0,'days').endOf('day').format('dddd, MMMM Do, YYYY h:mm:ss A'),
      is_subscribed: false,
      newsubscriber:true,
    };
  }

  render() {
    if (this.props.user_status.sstatus === true) {
      return(
        null
      )
    }
    else if (this.props.source === "Navbar") {
      return(
        <>
        <Button className="flex d-flex justify-content-center align-items-center mr-1" tag={Link} to="/pages/pricing">  <div>Subscribe</div> </Button>
        </>
      )
    }
    else if (this.props.source === "landing") {
      return(
        <>
        <SubscribeValidate />
        <Subscribe type={'year'} freetrial={false} landing={true}/>
        </>
      )
    }
      return (
        <>
        <SubscribeValidate />
        <ModalExample />
        </>

  );
 }
}

const mapStateToProps = (state) => ({
  user_status: state.auth.user,
  theme: state.theme.currentTheme
});

export default connect(mapStateToProps, {  })(SubscribeModal);
