import React from "react";
import { Link } from "react-router-dom";
import { API_BASE_MEDIA_URL } from '../../../redux/actions/API'
import {
  Card,
  CardImg,
  CardTitle,
  CardBody,
  CardHeader,
} from "reactstrap";
import TimeAgo from 'react-timeago'

const Albums = (props) => (

  <Card>
    <CardHeader>
      <CardTitle tag="h5" className="d-flex flex justify-content-between">
      <div>
      {props.album_details.album_name_normal}
      <span className="text-muted"> ({props.album_details.photos.length} items)</span>
      </div>
      <span className="text-muted"><TimeAgo date={props.album_details.recorded_time} /></span>
      </CardTitle>
      <h6 className="card-subtitle">

          {props.album_details.description && props.album_details.description.length > 20?
            props.album_details.description.substring(0,20 - 3) + "..."
            :
          props.album_details.description?
            props.album_details.description
            :
          null
      }
      </h6>
    </CardHeader>
    <CardBody className="pt-0">
      <Link to={props.source === "profile" ? props.url : "/albums/" + props.album_details.id} >

      <CardImg
      style = {{'maxWidth': '100%', 'maxHeight': '100%', 'height': '350px', 'width': '500px', 'objectFit': 'cover', cursor:'pointer'}}
        top
        src={API_BASE_MEDIA_URL + props.album_details.cover_image}
        alt="image" />
        
      </Link>
    </CardBody>
  </Card>



);

export default Albums;
