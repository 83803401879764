import React from "react";
import {
  Button,
  ButtonGroup,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Form,
  UncontrolledButtonDropdown,
} from "reactstrap";
import { connect } from 'react-redux';
import LoaderSpin from "./LoaderSpin"
import store from "../../../redux/store/index";
import { unfollowUser, blockUser } from "../../../redux/actions/social";
import {
  Settings,
} from "react-feather";


async function UnFollowUser(a,b,c,d,e,z, index) {
  const result = await store.dispatch(unfollowUser(a,b,c,d,e));
  if (result.status === 200 || result.status === 201) {
      await z.change_flag(index)
    }
  }

async function BlockUser(y,z,a) {
  await store.dispatch(blockUser(y,z));
  try {
    await a.change_flag('1','2');
  }
  catch(err) {
    console.log('fail')
  }
 }

class UnFollowButton extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loadSpin: 0,
      flag_spinner: 0
    };
  }



  componentDidUpdate() {
    if (this.state.flag_spinner !== this.props.flag_spinner) {
      this.props.handlerLoadSpinner(0)
      this.setState({
        loadSpin:0,
        flag_spinner: this.props.flag_spinner
      })
    }
  }



  onSubmit = async (e) => {
    e.preventDefault();
    UnFollowUser(
      this.props.followingid,"true",
      this.props.currentTime,
      this.props.myid,
      this.props.user_id,
      this.props,
      this.props.index )
    this.setState({
      loadSpin: 1
    });
  };

  onSubmitSettings = async (e) => {
    e.preventDefault();
  };

  onBlock = async (e) => {
    BlockUser(this.props.myid,this.props.user_id, this.props)
  }


  render() {
    return (
      <ButtonGroup>

        <Form onSubmit={this.onSubmit}>
        {this.state.loadSpin===0?
          <Button className= "custombutton mr-1" outline color="primary" size={this.props.size==="md"? "md" : this.props.size==="lg" ? "lg" : "sm"}> <span>Following! </span> </Button>
          :
          <Button className= " mr-1" outline color="primary" size={this.props.size==="md"? "md" : this.props.size==="lg" ? "lg" : "sm"} disabled> <LoaderSpin/></Button>
        }
        </Form>


        {this.props.source === "athletes" ?
        null
        :
        <Form onSubmit={this.onSubmitSettings}>
          <UncontrolledButtonDropdown  className="mr-1 " size={this.props.size==="md"? "md" : this.props.size==="lg" ? "lg" : "sm"} outline>
            <DropdownToggle  color="primary" outline>
              <Settings width={16} height={16} />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => {this.onBlock();}}>Block Athlete</DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </Form>
      }

      </ButtonGroup>

    );
  }
}

const mapStateToProps = (state) => ({
  theme: state.theme.currentTheme,
});
export default connect(mapStateToProps, {  })(UnFollowButton);
