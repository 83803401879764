import React, {useState} from "react";
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import StickyBox from "react-sticky-box";
import lifterGirl from "./help.jpeg"
import {
  Container,
  Row,
  Col,
  Navbar,
  NavbarBrand,
  Button,
  Card,
  CardHeader,
  Collapse,
  CardBody,
  } from "reactstrap";
import { API_HELP_CENTER} from '../../../redux/actions/API';
import { keyConfig } from '../../../redux/actions/auth';
import axios from "axios";
import store from "../../../redux/store/index";
import Loader from "../../../components/Loader.js"


const FaqQuestion = ({ onClick, isOpen, question, answer }) => (
  <Card className="border mb-3">
    <CardHeader className="cursor-pointer" onClick={onClick}>
      <h6 className="mb-0">{ question }</h6>
    </CardHeader>
    <Collapse isOpen={isOpen}>
      <CardBody className="py-3">
        { answer }
      </CardBody>
    </Collapse>
  </Card>
)


const Faq = (props) => {
  const [activeQuestion, setActiveQuestion] = useState(0);

  return (
    <div>

                  {props.data.items.map((x,y) =>

        <FaqQuestion
          onClick={() => setActiveQuestion(y)}
          isOpen={activeQuestion === y}
          question={x.question}
          answer={
            <div dangerouslySetInnerHTML={ {
              __html: x.answer
            } }>
            </div>
          }
        />

      )}

        </div>
  )
};




const IntroOrig = () => {

    return (

    <section className="mb-5 pt-7 pb-5 vh-50 d-flex flex justify-content-center align-items-center landing-bg text-white overflow-hidden bg-dark" style={{backgroundColor:'#d3e2f0', "backgroundImage": `url(${lifterGirl})`, objectFit: 'cover', backgroundSize: 'cover', backgroundRepeat:'no-repeat' }}>
      <Container fluid>
        <Row>
          <Col md="8" className="mx-auto text-left mt-5">
            <h1 className="landing-intro-title my-4" style={{fontSize:'62px'}}>Help Center</h1>
          </Col>
        </Row>
        <Row>
        </Row>
      </Container>
    </section>
  )};




class Legal extends React.Component {

  fetchPrivacy = async () =>  {
    console.log(`${API_HELP_CENTER}`)
    await axios.get(`${API_HELP_CENTER}`, keyConfig(store.getState)).then(res => {
      res.data.map((a,b) => {return(this[`${b}_ref`] = React.createRef())});
      this.setState({
        api_helpcenter: res.data,
        api_helpcenter_loading: false
      });
    });


  }

  constructor(props) {
      super(props)
      this.myRef = React.createRef()
      this.state = {
        count: 0,
        api_helpcenter: [],
        api_helpcenter_loading: true
      }
  }

  componentDidMount() {
    this.fetchPrivacy();
  }

  scrollToMyRef = (b) => window.scrollTo({ behavior: 'smooth', top: this[`${b}_ref`].current.offsetTop })

  renderNav = () => {
    return(
      <Navbar   expand="sm" className="absolute-top w-100 m-0 p-3" >
        <Container >
          <NavbarBrand className="font-weight-bold text-white" href="/" style={{'fontSize':'28px'}}>
            ironroom.
          </NavbarBrand>
        </Container>
      </Navbar>
    )
  }

  renderSideBar = () => {
    return(
      <Col md="4" className="mx-auto text-left bh-50 bg-white">
        <StickyBox>
          <Container className="text-center d-flex pt-5 flex-column justify-content-start align-items-start">

          {this.state.api_helpcenter.map((a,b) =>
            <Button
              onClick={() => window.scrollTo({ behavior: 'smooth', top: this[`${b}_ref`].current.offsetTop })}
              key={b}
              color="link"
              size="lg"
              target="_blank"
              className="btn-pill text-dark"
            >
               {
                 a.section.length > 34?

                 `${a.section.substring(0, 35)}...`

                 :

                 a.section

               }
            </Button>
            )}

          </Container>
        </StickyBox>
      </Col>
    )
  }

  renderRight = () => {
    return(
      <Col md="12" className="mx-auto text-left pt-5 bg-white pr-5">

      {this.state.api_helpcenter.map((a,b) =>

            <div ref={this[`${b}_ref`]} key={b}>
              <h1> {a.section} </h1>


                <Faq data = {a}/>




            </div>

          )}

      </Col>
    )
  }

  renderBody = () => {
    return(
      <Container>
        <Row >
          {this.renderRight()}
        </Row>
      </Container>
    )
  }

  render() {
    if (this.props.isAuthenticated) {
      return <Redirect to="/dashboard" />;
    }
    else if (this.state.api_helpcenter_loading){
      return (<Loader />)
    }
    return (
      <div>
        {this.renderNav()}
        <IntroOrig />
        {this.renderBody()}
      </div>

    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isLoading: state.auth.isLoading,
});

export default connect(mapStateToProps)(Legal);
