import React from "react";
import {
  Container,
  Row,
  Col,
} from "reactstrap";
import { connect } from 'react-redux';
import Header from './Header'
import PasswordChange from './PasswordChange'
import ForgotPassword from './ForgotPassword'

import Navigation from '../Navigation';


class Default extends React.Component {

  render() {
    return(
      <Container fluid>

      <Header />

        <Row>
          <Col md="3" xl="2">
            <Navigation activeName = {"password"}/>
          </Col>
          <Col md="9" xl="10">
            <PasswordChange />
            <ForgotPassword />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, {  })(Default);
