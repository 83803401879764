import React from "react";
import { Col, Row, Progress } from "reactstrap";


const ProgressBar = (props) => (
  <Row className="mb-3">
      <Col md="12" xl>
    <Progress
    color={
      props.exercise_table[0].percentage  === 100?
      "success"
      :
      "primary"
    }
    value={props.exercise_table[0].percentage}>
    {props.exercise_table[0].percentage + '%'}
    </Progress>
    </Col>
  </Row>
);

export default ProgressBar;
