import React from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';

import {
  Card,
  CardBody,
  CardHeader,
  Table,
  CardText,
  CardTitle,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import WorkoutDetailGraph from "../../../components/Social/Graph/Area";
import ProfilePhoto from '../../../components/Social/ProfilePhoto/ProfilePhoto';
import ModalDelete from '../../../components/Social/Timeline/ModalDelete';
import ModalLikes from '../../../components/Social/Timeline/ModalLikes';

import store from "../../../redux/store/index";
import { timelineLikes, timelineComments, timelineCommentsDelete } from '../../../redux/actions/social';

async function SendLike(cardid, userid, changeflag) {
  await store.dispatch(timelineLikes(cardid, userid));
  await changeflag(1)
 }

async function SendComment(cardid, userid, comment, changeflag) {
 await store.dispatch(timelineComments(cardid, userid, comment));
 await changeflag(1)
 //await spinvalue(0)
}

async function DeleteComment(cardid, changeflag, time) {
  await store.dispatch(timelineCommentsDelete(cardid, "true", time));
  await changeflag(1)
 }

class WorkoutLineChart extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      myValue: '',
      commentSpin: 0,
      initialComments: this.props.comments,
      likeSpin: 0,
      initialLikes: this.props.likes,
      split: 0,
      openComment: 0,
    };
  }

  componentDidUpdate() {
    if (this.state.initialComments !== this.props.comments) {
      this.setState({
        initialComments: this.props.comments,
        commentSpin: 0,
        myValue: '',
      });
    }
    if (this.state.initialLikes !== this.props.likes) {
      this.setState({
        initialLikes: this.props.likes,
        likeSpin: 0,
      });
    }
  }

  onDeleteComment = async (comment_id, page, time) => {
    //e.preventDefault();
    DeleteComment(
      comment_id,
      this.props.workoutdetail_change_flag,
      time
    );
  };

  onLike = async (e) => {
    e.preventDefault();
    SendLike(
      this.props.timeline_id,
      this.props.user_status.pk,
      this.props.workoutdetail_change_flag,
    );
    this.setState({
      likeSpin: 1
    });
  };

  onChange = async (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      comment_value: e.target.value,
      myValue: e.target.value
    });
  }

  onSend = async (e) => {
    e.preventDefault();
    SendComment(
      this.props.timeline_id,
      this.props.user_status.pk,
      this.state.comment_value,
      this.props.workoutdetail_change_flag,
    );
    this.setState({
      commentSpin: 1
    });
  };

  renderCardCommentsDelete = (a,x) => {
    return(
      <ModalDelete
        a = {a}
        active_user_id = {this.props.user_status.pk }
        onDelete = {this.onDeleteComment}
        page = {Math.ceil((this.props.index+1) /4,1)}
        time={this.props.data.current_time}
        />
    )
  }

  renderHeaderTalbe = () => {
    return(
      <Table size="sm" className="my-2 text-left">
        <tbody>
          <tr>
            <th>Time</th>
            <td>{this.props.exercise_data.derived__historic_datetime}</td>
          </tr>
          <tr>
            <th>Workout</th>
            <Link to={"/workouts/detail/" + this.props.exercise_data.workout_day_xref.routine_xref.id}>
            <td >{this.props.exercise_data.workout_day_xref.routine_xref.routine}</td>
            </Link>
          </tr>
          <tr>
            <th>Session</th>
            <td>{this.props.exercise_data.workout_day_xref.day_title}</td>
          </tr>
          <tr>
            <th>progres</th>
            <td>pending</td>
          </tr>
        </tbody>
      </Table>
    )
  }

  openCommentWindow = () => {
    if (this.state.openComment === 0) {
      this.setState({openComment: 1})
    }
  }

  renderMainHeaderNew = () => {
      return(
        <>




          <div className=" d-flex flex-row justify-content-start">
            <ProfilePhoto
              avatar_image={this.props.user_data.avatar_image}
              avatar_color = {this.props.user_data.avatar_color}
              avatar_letter = {this.props.user_data.avatar_letter}
              avatar_size={"128px"}
              letter_size={"56px"}
              />


              <div className="ml-3 d-flex flex-column justify-content-start">
              <CardHeader tag="h3" className="m-0 p-0 pt-2 text-strong text-left">
                {this.props.exercise_data.derived__part_of_day}
              </CardHeader>

              <CardText>
                  <p>{this.props.exercise_data.derived__historic_datetime}</p>
              </CardText>
              </div>



            </div>







        </>
      )
  }

  renderCardBaseLikedBy = () => {
    if (this.props.data.like_count === 1) {
      return (
      <small className="float-left text-navy">
        Liked by {this.props.data.likes.map((a,b) => <Link key={b} to={'/profile/'+a.liking_user_id_xref.id+'/home' }> {a.liking_user_id_xref.first_name +' '+ a.liking_user_id_xref.last_name} </Link>)}
      </small>
      )
    }
    else if (this.props.data.like_count === 2) {
      return (
      <small className="float-left text-navy">
        Liked by
        <Link to={'/profile/'+this.props.data.likes[0].liking_user_id_xref.id+'/home' }>
          {' ' + this.props.data.likes[0].liking_user_id_xref.first_name +' '+this.props.data.likes[0].liking_user_id_xref.last_name+ ' '}
        </Link>
        and
        <Link to={'/profile/'+this.props.data.likes[1].liking_user_id_xref.id+'/home' }>
          {' '+ this.props.data.likes[1].liking_user_id_xref.first_name +' ' +this.props.data.likes[1].liking_user_id_xref.last_name}
        </Link>
      </small>
      )
    }
    else if (this.props.data.like_count > 2) {
      return (
      <small className="float-left text-navy">
        Liked by
        <Link to={'/profile/'+this.props.data.likes[0].liking_user_id_xref.id+'/home' }>
          {' ' + this.props.data.likes[0].liking_user_id_xref.like_display_name},
        </Link>
        <Link to={'/profile/'+this.props.data.likes[1].liking_user_id_xref.id+'/home' }>
          {' '+ this.props.data.likes[1].liking_user_id_xref.like_display_name},
        </Link>
        {' '}and <ModalLikes likes = {this.props.data.likes} count={this.props.data.like_count-2} word = {this.props.data.like_count ===3 ? "other" : "others"} />
      </small>
      )
    }
}

  splitData = async (y) => {
    await this.setState({split: y})
  }

  render() {
    return (
      <Card className="flex-fill w-100">
        <CardHeader>

          <UncontrolledDropdown className="d-inline float-right">
            <DropdownToggle caret color="light" className="bg-white shadow-sm">
              {this.props.graph_data.slice(this.state.split,this.state.split+1)[0].exercise_xref.exercise}
            </DropdownToggle>
            <DropdownMenu right>

              {this.props.graph_data.map((x,y) =>


                <DropdownItem
                  action="true"
                  active={
                    x.exercise_xref.exercise ===
                    this.props.graph_data.slice(this.state.split,this.state.split+1)[0].exercise_xref.exercise}
                  onClick={() => this.splitData(y)} style={{cursor: 'pointer', borderLeft: 'none', borderRight: 'none', borderTop:'none'}}
                  key={y}
                  >
                  {x.exercise_xref.exercise}

                </DropdownItem>

              )}

            </DropdownMenu>
          </UncontrolledDropdown>
          <CardTitle tag="h5" className="mb-0">
            Lift Progress
          </CardTitle>
        </CardHeader>
        <CardBody>
          <div className="chart chart-lg">
              <WorkoutDetailGraph
              data = {this.props.graph_data.slice(this.state.split,this.state.split+1)}
              source={'workout_sub_detail'}
              measure_value={this.props.measure_value}
              />
          </div>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  user_status: state.auth.user,
});

export default connect(mapStateToProps, {  })(WorkoutLineChart);
