import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
} from "reactstrap";
import { connect } from 'react-redux';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import ModalLikes from './ModalLikes';

class Timeline_Feed extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      openComment: 0,
    };
  }

  openCommentWindow = () => {
    this.props.handlerOpenCommentWindow()
    if (this.state.openComment === 0) {
      this.setState({openComment: 1})
    }
  }

  renderLikeSummary = (x) => {
    if(x.like_count ===0 ) {
      return(
        <>
          0
        </>
      )
    }
    else if (x.like_count === 1) {
      return(
        x.likes.slice(-1).map((a,b) => <Link key={b} to={'/profile/'+a.liking_user_id_xref.pk+'/home' }> {a.liking_user_id_xref.firstname_lastname} </Link>)
      )
    }
    else if (x.like_count > 1) {
      return(
        <>
        {x.likes.slice(-1).map((a,b) => <div key={b}><Link  to={'/profile/'+a.liking_user_id_xref.pk+'/home' }> {a.liking_user_id_xref.firstname_lastname + "  "} </Link> <span> {"‎  "}  and </span> </div>)}
          <ModalLikes likes = {x.likes} count={x.like_count-1} word = {x.like_count ===2 ? "other" : "others"} />
        </>
      )
    }
  }

  renderLikeSummarParent = (x) => {
    return(
      <div className="d-flex flex justify-content-between align-items-center m-0 p-0">
        <FontAwesomeIcon icon={faThumbsUp} fixedWidth />
        {this.renderLikeSummary(x)}
      </div>
    )
  }

  renderCommentsSumary = (x) => {
    const comment_count_sum = x.comment_count + x.comment_count_reply
    if (comment_count_sum > 1) {
      return(
        <>
          • {comment_count_sum} comments
        </>
      )
    }
      else if (comment_count_sum === 1) {
        return(
          <>  • 1 comment </>
        )
      }
      else if (comment_count_sum < 1) {
        return(
        <>  • 0 comments </>
        )
      }
    }

  renderKudosCommentsBar = (x) => {
    return(
      <div className="d-flex flex justify-content-start align-items-center">

      <Button color="white" className="btn btn-pill m-0 p-0" size="sm" disabled>
            {this.renderLikeSummarParent(x)}
      </Button>

        <small onClick={() => this.openCommentWindow()} className="customComments m-0 p-0">
          {this.renderCommentsSumary(x)}
        </small>
      </div>
    )
  }

  render() {
    return (
      <div>
        {this.renderKudosCommentsBar(this.props.x)}
      </div>
    );
   }
}

const mapStateToProps = (state) => ({
  user_status: state.auth.user,
  theme: state.theme.currentTheme
});

export default connect(mapStateToProps, {  })(Timeline_Feed);
