import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Spinner,
} from "reactstrap";
import { API_BASE_URL } from '../../redux/actions/API'
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe("pk_test_51IiCoXA7VgKP3PFMA2JRukWBN6wBA0bBxtPnZdGSdsET4Pd02Wn44MUYbjiLdCj1JdZQTm0u68T6Exih4WF2fQMm00gzeVMIDK");


class PricingCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      test: 'month',
      source: 'mobile',
    };
  }

  componentDidMount () {
    console.log('**')
    console.log(this.props.original.match.params.tokenID)
    console.log(`${API_BASE_URL}/stripe/?type=${this.state.test}?&freetrial=${this.state.freetrial}?source="mobile"`)
    this.props.handleClick(this.props.original.match.params.typeID, this.props.original.match.params.tokenID, this.state.source, this.props.original.match.params.freeTrial)
  }

  click = async(e) => {
    await this.setState({loader:true})
    await this.props.handleClick(e,this.props.type)
  }

  render() {
    return (
      <div className="d-flex flex justify-content-center align-items-center mt-5">
        <Spinner color="dark" />
      </div>
    );
  }
}

const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
);

export default function App(props) {
  const [message, setMessage] = useState("");

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setMessage("Order placed! You will receive an email confirmation.");
    }

    if (query.get("canceled")) {
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, []);

  const handleClick = async (type,tokenID, source, freetrial) => {

    const stripe = await stripePromise;
    const key = await tokenID

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${key}`
      },
      method: "POST"
    };

    const response = await fetch(`${API_BASE_URL}/stripe/?type=${type}&source=${source}&token=${tokenID}&freetrial=${freetrial}`, config);

    const session = await response.json();

    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (result.error) {
      console.log(result.error)
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    }
  };

  return message ? (
    <Message message={message} />
  ) : (
    <PricingCard handleClick={handleClick} type={props.type} original={props}/>
  );
}
