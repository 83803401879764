import React from "react";
import prettyNum, {PRECISION_SETTING} from 'pretty-num';
import {
  Card,
  CardHeader,
  CardTitle,
  Table,
  Progress
} from "reactstrap";


const Languages = (props) => (
  <Card className="flex-fill w-100">
    <CardHeader>
      <div className="card-actions float-right">
      {props.api_volume_moved === 0 ?
        <p className="text-muted"> no data </p>
        :
        null
      }
      </div>
      <CardTitle tag="h5" className="mb-0">
        Relative Muscle Group
      </CardTitle>
    </CardHeader>
    <Table striped className="my-0">
      <thead>
        <tr>
          <th>Muscle Group</th>
          <th className="text-left">Volume ({props.api_muscle_group_measurement})</th>
          <th className="d-none d-xl-table-cell w-48">% Weight</th>
        </tr>
      </thead>
      <tbody>
      {props.api_muscle_group.map((a,b) =>
          <tr key={b}>
            <td>{a.muscle_group}</td>
            <td className="text-left">
            {prettyNum(a.volume_moved, {precision: 0, thousandsSeparator: ','})}
            </td>
            <td className="d-none d-xl-table-cell">
              <Progress color="info" value={(props.api_volume_moved)*100 === 0? null : (a.volume_moved / props.api_volume_moved)*100}>
              {(Math.round((a.volume_moved / props.api_volume_moved)* 1000) / 10).toFixed(1)}%
              </Progress>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  </Card>
);

export default Languages;
