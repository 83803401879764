import React from "react";
import { Link } from "react-router-dom";
import Loader from "./Loader";
import { connect } from 'react-redux';
import store from "../../../redux/store/index";
import {
  API_USER,
  API_ONBOARDING_IMAGES,
} from '../../../redux/actions/API';
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Button,
  CardHeader,
  CardTitle,
  CustomInput,
} from "reactstrap";
import axios from "axios";
import { keyConfig } from '../../../redux/actions/auth';
import { loadUser } from '../../../redux/actions/auth';
import LayoutPicture from '../LayoutPicture';
import Api from '../../../config/auth';
import { postuserIP } from '../../../redux/actions/social';
import AOS from 'aos';
import 'aos/dist/aos.css';
import unsplash from "../../../assets/img/photos/1.jpg"
AOS.init();
async function PostIPAddress(userid, ip_address) {
  await store.dispatch(postuserIP(userid, ip_address));
 }



class Step2 extends React.Component {

  fetchInitial = () =>  {
    axios.get(`${API_USER}/`, keyConfig(store.getState)).then(res => {
      this.setState({
        api_first_name : res.data.first_name,
        api_last_name : res.data.last_name,
        api_name_heading_loading: false,
      });
    })
    axios.get(`${API_ONBOARDING_IMAGES}${"2"}`, keyConfig(store.getState)).then(res => {
      this.setState({
        background_photo: res.data[Math.floor(Math.random() * res.data.length)].cover_image,
        background_photo_loading: false
      });
    });
  }

  getGeoInfo = async () => {
    const publicIp = await require('public-ip');
    const ipv4 = await publicIp.v4()
    await PostIPAddress(this.props.user_status.pk, ipv4)
  };

  componentDidMount = async() => {
    if (this.props.user_status === null) {
      await store.dispatch(loadUser());
      await this.setState({flag_userloaded: 1});
    }
    else if (this.props.user_status !== null) {
      this.fetchInitial();
      this.getGeoInfo();
    }
    document.body.style.overflow = 'overflow';
  }

  componentDidUpdate() {
    if (this.state.form_change_flag !== 0 && (this.state.api_first_name === "" || this.state.api_last_name === "")) {
      this.fetchInitial();
    }
    else if (this.props.user_status !== null && this.state.flag_userloaded === 1) {
      this.setState({flag_userloaded: 0});
      this.fetchInitial();
      this.getGeoInfo();
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      api_name_heading_loading: true,
      api_first_name: null,
      api_last_name: null,
      background_photo: [],
      background_photo_loading: true,
      form_change_flag: 0,
      flag_userloaded: 0,
      firstName: '',
      lastName: '',
    };
  }

  onSubmit = async (e) => {
    e.preventDefault();
    await Api.patchUserFNLN(this.state.firstName, this.state.lastName, (success, data) => {
      if ( success) {
        console.log('success')
        this.fetchInitial();
      } else {
        console.log('fail')
      }
    });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  renderLeft = () => {
    return(
      <Col md="4" lg="6" xl="7"  className="d-none d-md-block" data-aos="fade-right" data-aos-delay="100">
        <LayoutPicture background_photo={unsplash} />
      </Col>
    )
  }

  renderRight = () => {
    return(
      <Col md="8" lg="6" xl="5" className="vh-100 d-flex align-items-center justify-content-center customBackgroundAuth m-0 p-0" data-aos="fade-left" data-aos-delay="100">
        <div className="">
          <div className="row float-right text-right mr-5 pr-5">
            <p>2/4</p>
          </div>

          <div className="w-100 py-5 px-md-5 px-xl-6 position-relative" >
          <div className="text-center mt-4" >
            <h1 className="h2">Making Lifting Awesome...</h1>

            <p className="lead text-center">
            Because those who lift know it.
            </p>
          </div>





                                <Form onSubmit={this.onSubmit}>
                                <Card >

                                  <CardBody >










                                          <CardHeader className="pl-0">
                                            <CardTitle tag="h5" className="mb-0 p-0">
                                              Which workouts have you done before?
                                            </CardTitle>
                                          </CardHeader>


                                            <CustomInput
                                              type="checkbox"
                                              id="squats"
                                              label="Sqauts"
                                              className="mb-2"
                                            />
                                            <CustomInput
                                              type="checkbox"
                                              id="benchpress"
                                              label="Bench Press"
                                              className="mb-2"
                                            />
                                            <CustomInput
                                              type="checkbox"
                                              id="deadlifts"
                                              label="Deadlifts"
                                              className="mb-2"
                                            />
                                            <CustomInput
                                              type="checkbox"
                                              id="overheadpress"
                                              label="Overhead Press"
                                              className="mb-2"
                                            />

                                            <CustomInput
                                              type="checkbox"
                                              id="barbellrow"
                                              label="Barbell Row"
                                              className="mb-2"
                                            />


                                                                  <Button color="primary" className="float-right" tag={Link} to="/onboarding/step3">Next</Button>

                                      </CardBody>



                                    </Card>



                                          </Form>







          </div>

        </div>

      </Col>
    )
  }

  render() {
    if ( this.props.user_status === null ||
      this.state.api_name_heading_loading === true ||
      this.state.background_photo_loading === true
    ) {
      return < Loader />;
    }
    return (

      <div className="container-fullwidth m-0 p-0" style={{height: '100%', width: '100%', 'overflowX':'hidden', 'overflowY':'auto'}}>
      <Row className="min-vh-100">
        {this.renderLeft()}
        {this.renderRight()}
      </Row>
</div>


    );
  }
}

const mapStateToProps = (state) => ({
  user_status: state.auth.user,
});

export default connect(mapStateToProps, {  })(Step2);
