import React, {useState} from "react";
import { Redirect } from 'react-router-dom';
import axios from "axios";
import { keyConfig } from '../../redux/actions/auth';
import store from "../../redux/store/index";
import {
  API_LIFT_TRACKER_INPUT,
  API_LIFT_TRACKER_WORKOUT_FORM,
  API_TIMELINE_EXERCISE_ALT,
 } from '../../redux/actions/API'
import { Link } from "react-router-dom";
import removeTrailingZeros from 'remove-trailing-zeros'
import { loadUser } from '../../redux/actions/auth';
import { lifttracking } from '../../redux/actions/general';
import {
  Button,
  Spinner,
  Alert,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  UncontrolledTooltip,
  Row,
  CustomInput,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  CardSubtitle
} from "reactstrap";
import { exerciseTracker, exerciseTrackerOneRepMax } from '../../redux/actions/lift_tracking';
import Loading from "./Loading"
import WorkoutNotFound from "./WorkoutNotFound"
import WorkoutCompleted from "./WorkoutCompleted"
import { connect } from 'react-redux';
import ApiSocial from '../../config/social';
import ApiLiftTracking from '../../config/lift_tracking';
import { Filter, RefreshCw } from "react-feather";
import Timer from 'react-compound-timer'

const OneRepMaxAlert = (props) => {
  const [visible, setVisible] = useState(true);

  const onDismiss = () => setVisible(false);

  return (
    <Alert color="info" isOpen={visible} toggle={onDismiss}>
      <div className="alert-message">
        <h4 className="alert-heading">First record your one rep max! Be safe and know your limits. Your following workout will be based on %'s of your one rep max</h4>
        <hr />
      </div>
    </Alert>
  );
}

const buttonWidth = {
  width: "50px",
};

class LiftTracking extends React.Component {

  patchWorkoutSession = async () => {
    await ApiSocial.patchWorkoutSession(
        this.state.workout_session_id,
        "true",
        this.state.lift_tracking_input[0].derived__time_now,
        (success, data) => {
      if ( success) {
        console.log('success')
        this.postProcessWorkout()
      } else {
        console.log('fail')
      }
    });
  };

  patchTimelineID = async (timelineID, timestamp) => {
    await ApiLiftTracking.timelinePatch(
        timelineID,
        timestamp,
        (success, data) => {
      if ( success) {
        console.log('success')
        console.log(data)
      } else {
        console.log('fail')
        console.log(data)
      }
    });
  };

  postProcessWorkout = async () =>{
    await this.setState({post_process_load: true, post_process_redirect: false})
    await this.fetchTimelineID()
  }

  fetchTimelineID = async () => {
      await axios.get(`${API_TIMELINE_EXERCISE_ALT}?user_exercise_tracker_xref=${this.state.workout_session_id}`, keyConfig(store.getState)).then(res => {
          this.patchTimelineID(res.data[0].timeline_xref, this.state.lift_tracking_input[0].derived__time_now)
          this.setState({post_process_redirect: true, post_process_timelineid: res.data[0].timeline_xref})
      }).catch((err) => {
        console.log(err)
      });
    }

  postgetWorkoutSession = async (lift_tracking_input) => {
    await ApiSocial.postWorkoutSession(
        this.props.user_status.usa_imperial,
        lift_tracking_input.derived__current_session_day,
        this.props.user_status.pk,
        lift_tracking_input.routine_xref.enroll_history[0].id,
        lift_tracking_input.id,
        lift_tracking_input.derived__current_workout_week,
        (success, data) => {
      if ( success) {
        console.log('success')
        this.setState({workout_session_id: data.id})
      } else {
        console.log('fail')
      }
    });
  };

  postgetWorkoutExerciseLog = async (exercise_xref, set_xref, weight, rep_count, weight_level) => {
    await ApiSocial.postWorkoutExerciseLog(
        this.state.workout_session_id,
        exercise_xref,
        set_xref,
        weight,
        rep_count,
        weight_level,
        this.props.user_status.usa_imperial,
        (success, data) => {
      if ( success) {
        console.log('success')
      } else {
        console.log('fail')
        this.setState({finishSaveSpin: false})
      }
    });
  };

  initialVariable = async (data) => {
    try{
      await data.full_quick.map((a,b) => {
      if (a.derived__set_count > 3){
        this.setState({
          vertical_form: true
        })
      }
      a.full_set.map((x,y) => {
        if (data.routine_xref.training_max_routine || data.routine_xref.training_max_exercise) {
          this.setState({
            ['weight'+a.order+x.order] : x.derived__suggested_weight,
            ['weight_name'+a.order+x.order] : x.derived__suggested_weight + ' ' + this.state.measure_value
          })
        }
        return this.setState({
          ['rep'+a.order+x.order] : x.rep_count,
          ['rep_name'+a.order+x.order] : x.rep_count_max === null || x.rep_count_max === 999 ? x.rep_count + ' Reps' : x.rep_count_max > 0 && x.rep_count_max < 999? x.rep_count_max + ' Reps' : null
        })
      })
      return this.setState({
        ['const_weight'+a.order] : removeTrailingZeros(a.derived__constant_weight_value),
        ['const_weight_name'+a.order] : removeTrailingZeros(a.derived__constant_weight_value) + ' ' + this.state.measure_value
      })
    })
      await this.setState({lift_tracking_input_loading: false})
    } catch (e) {
      this.setState({lift_tracking_input_loading: false})
    }
  }

  fetchData = async () =>  {
    await axios.get(`${API_LIFT_TRACKER_INPUT}`, keyConfig(store.getState)).then(res => {
      console.log(res.data)
      this.props.lifttracking(res.data)
      if (res.data[0] && res.data[0].workout_session) {
        if (res.data[0] && (res.data[0].workout_session.length === 0 ||
          res.data[0].workout_session[0].user_enrollment_history_xref !== res.data[0].routine_xref.enroll_history[0].id)){
          this.postgetWorkoutSession(res.data[0])
        }
        else if (res.data[0] && (res.data[0].workout_session.length > 0 ||
          res.data[0].workout_session[0].user_enrollment_history_xref === res.data[0].routine_xref.enroll_history[0].id)){
          this.setState({workout_session_id:res.data[0].workout_session[0].id})
        }
      }
      this.setState({
        lift_tracking_input: res.data,
      });
      try {
        this.initialVariable(res.data[0])
      } catch (e) {
        this.setState({lift_tracking_input_loading: false})
      }
      });

  }

  fetchWorkoutForm = async () =>  {
    await axios.get(`${API_LIFT_TRACKER_WORKOUT_FORM}`, keyConfig(store.getState)).then(res => {
      this.setState({
        api_lift_tracking_form: res.data,
        api_lift_tracking_form_loading: false,
        form_type:res.data[0].id_alt,
        form_type_name:res.data[0].form_name,
      });
    }
  )}

  componentDidMount() {
    if (this.props.user_status === null) {
      store.dispatch(loadUser());
    }
    this.fetchData();
    this.fetchWorkoutForm();
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  onChangeExpressDrop = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name.replace("_name", "")]: e.target.value.split(" ")[0]
    });
  }

  onChangeExpressDropMax = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name.replace("_name", "")]: e.target.value.split(" ")[0]
    });
  }



  onSubmit = (e) => {
    e.preventDefault();
    var i;
    var k;
    for (i = 1; i < this.state.lift_tracking_input[0].full_quick.length+1; i++) {
      for (k = 1; k < this.state.lift_tracking_input[0].full_quick[i-1].full_set.length+1; k++) {
        this.postgetWorkoutExerciseLog(
          this['exercise'+i+k].props.value,
          this['set'+i+k].props.value,
          this.state['weight'+i],
          this.state['rep'+i+k],
          this.weight_level.props.value,
        );
      }
    }
    this.props.history.push('/');
  };

  onSubmitFreeFormAll = async (e) => {
    await this.setState({finishSaveSpin: true})
    e.preventDefault();
    var i;
    var k;
    for (i = 1; i < this.state.lift_tracking_input[0].full_quick.length+1; i++) {
      for (k = 1; k < this.state.lift_tracking_input[0].full_quick[i-1].full_set.length+1; k++) {
        this.postgetWorkoutExerciseLog(
          this['exercise'+i+k].props.value,
          this['set'+i+k].props.value,
          this.state['weight'+i+k],
          this.state['rep'+i+k],
          this.weight_level.props.value,
        );
      }
      if (i === this.state.lift_tracking_input[0].full_quick.length){
        this.patchWorkoutSession()
      }
    }
  };

  onSubmitExpressFormConst = async (e) => {
    await this.setState({finishSaveSpin: true})
    e.preventDefault();
    var i;
    var k;
    for (i = 1; i < this.state.lift_tracking_input[0].full_quick.length+1; i++) {
      for (k = 1; k < this.state.lift_tracking_input[0].full_quick[i-1].full_set.length+1; k++) {
        this.postgetWorkoutExerciseLog(
          this['exercise'+i+k].props.value,
          this['set'+i+k].props.value,
          this.state['const_weight'+i],
          this.state['rep'+i+k],
          this.weight_level.props.value,
        );
      }
      if (i === this.state.lift_tracking_input[0].full_quick.length){
        this.patchWorkoutSession()
      }
    }
  };

  onSubmitOneRepMax = async (e) => {
    await this.setState({finishSaveSpin: true})
    e.preventDefault();
    var i;
    for (i = 0; i < this.state.lift_tracking_input[0].full_quick.length; i++) {
      this.props.exerciseTrackerOneRepMax(
        this.state['weight'+i],
        this.props.user_status.pk,
        this.state.lift_tracking_input[0].routine_xref.enroll_history[0].id,
        this['exercise'+i].props.value
      )
    }
    this.props.history.push('/timeline');
  }


  //1: Express
  //2: Open

  constructor(props) {
    super(props);
    this.state = {
      lift_tracking_input: this.props.lift_tracking_input_data,
      finishSaveSpin: false,
      lift_tracking_input_loading: this.props?.lift_tracking_input_data?.length > 0? false : true,
      vertical_form: false,
      form_type: null,
      form_type_name: null,
      api_lift_tracking_form: [],
      api_lift_tracking_form_loading: true,
      disableButton: false,
      post_process_load: false,
      post_process_timelineid: null,
      measure_value: this.props.user_status.usa_imperial? 'LBs' : 'KGs'
    };
  }

  handleFormTypeChange = (val1,val2) => {
    this.setState({
      form_type: val1,
      form_type_name: val2
    })
  };

  renderFreeFormSets = (x,s,t) => {
    return(
      <div>
        <InputGroup className="mb-3">
          <InputGroupAddon addonType="prepend" color="primary">
            <React.Fragment>
              <Button style={buttonWidth} color="secondary" id={"UncontrolledTooltip" +x.order+s.order} disabled>{s.order}</Button>
              <UncontrolledTooltip target={"UncontrolledTooltip" + +x.order+s.order} placement="top" >
                Set {s.order}
              </UncontrolledTooltip>
            </React.Fragment>
          </InputGroupAddon>
          <Input
            type="number"
            min="0"
            required
            className="form-control"
            placeholder={s.derived__rep_count_message}
            name={'rep'+x.order+s.order}
            onChange={this.onChange}/>
        </InputGroup>
        <Input type="hidden" value={x.exercise_xref.id} ref={(input) => { this["exercise" + x.order + s.order] = input }}/>
        <Input type="hidden" value={s.order} ref={(input) => { this['set'+x.order+s.order] = input }}/>
      </div>
    )
  }

  renderFreeFormWeights = (x,s) => {
    const modality = x.exercise_xref.modality_xref
    const is_imperial = this.props.user_status.usa_imperial
    return(
      <div>
        <InputGroup className="mb-3">
          <InputGroupAddon addonType="prepend" color="primary">
            <React.Fragment>
              <Button style={buttonWidth} onClick={this.popOver} color="warning" id={"UncontrolledTooltip" +x.order+"weight"} >W </Button>
              <UncontrolledTooltip target={"UncontrolledTooltip" + +x.order+"weight"} placement="top" >
                {x.derived__weight_message}
              </UncontrolledTooltip>
            </React.Fragment>
          </InputGroupAddon>
          <Input
            type="number"
            required
            min={modality === 4 && is_imperial? "45" : modality === 4 && !is_imperial? "20" : "0"}
            className="form-control"
            placeholder={x.derived__weight_message}
            name={'const_weight'+x.order}
            onChange={this.onChange}/>
          <Input type="hidden" value={this.state.lift_tracking_input[0].derived__one_rep_max_flag === "false"? this.state.lift_tracking_input[0].full_quick[0].full_set[0].weight_level : 'temp'}ref={(input) => { this.weight_level = input }}/>
          <Input type="hidden" value={this.state.lift_tracking_input[0].derived__current_session_day} ref={(input) => { this.session_day = input }}/>
          <Input type="hidden" value={this.state.lift_tracking_input[0].derived__time_now} ref={(input) => { this.recorded_start_time = input }}/>
          <Input type="hidden" value={this.props.user_id} ref={(input) => { this.user_id_xref = input }}/>
          <Input type="hidden" value={this.state.lift_tracking_input[0].routine_xref.enroll_history[0].id} ref={(input) => { this.user_enrollment_history_xref = input }}/>
          <Input type="hidden" value={this.state.lift_tracking_input[0].id} ref={(input) => { this.workout_day_xref = input }}/>
          <Input type="hidden" value={this.state.lift_tracking_input[0].derived__current_workout_week} ref={(input) => { this.duration_xref = input }}/>
        </InputGroup>
      </div>
    )
  }

  renderCardTitleExerciseName = (x,c) => {
    console.log(x)
    const max_exercise = this.state.lift_tracking_input[0].routine_xref.training_max_exercise
    const max_routine = this.state.lift_tracking_input[0].routine_xref.training_max_routine
    return(
      <div>
      <CardTitle className="mb-0" tag={Link} to={"/exercises/detail/" + x.exercise_xref.id}>
        <strong>{x.exercise_xref.exercise}</strong>
      </CardTitle>
      <CardSubtitle tag="h6" className="mt-1 text-muted">
      {
        c==='on' && x.derived__one_rep_max_value === null && (max_exercise || max_routine) ?
            "Set Your One Rep Max"
          :
       max_exercise || max_routine?
        "Your One-Rep-Max: "  + x.derived__one_rep_max_value + x.measure_value
        :
        null
      }
      </CardSubtitle>
      </div>
    )
  }

  renderFreeFormHorizontal = () => {
    return(
      <Form onSubmit={this.onSubmitExpressFormConst}>
      {this.state.lift_tracking_input[0].full_quick.map((x,y)=>
        <Card key={y}>
          <CardHeader>
            {this.renderCardTitleExerciseName(x)}
          </CardHeader>
          <CardBody>
            <Row>

              {x.full_set.map((s,t)=>
                <Col lg="3" key={t}>
                  {this.renderFreeFormSets(x,s,t)}
                </Col>
              )}
                <Col lg="3">
                  {this.renderFreeFormWeights(x)}
                </Col>

            </Row>
          </CardBody>
        </Card>
      )}

      {this.state.finishSaveSpin?
        <div className="flex d-flex justify-content-center align-items-center w100">
        <Spinner color="primary" />
        </div>
        :
      <Button  color="primary" className="mr-1 btn-block"  type="submit"> Finish and Save </Button>
    }
      </Form>
    )
  }

  renderFreeFormVertical = () => {
    return(
      <Form onSubmit={this.onSubmitExpressFormConst} className="customLiftTrackingVertical">
      {this.state.lift_tracking_input[0].full_quick.map((x,y)=>
        <Card key={y}>
          <CardHeader>
            {this.renderCardTitleExerciseName(x)}
          </CardHeader>
          <CardBody>
            <Row>

              {x.full_set.map((s,t)=>
                <Col lg="12" key={t}>
                  {this.renderFreeFormSets(x,s,t)}
                </Col>
              )}
              <Col lg="12">
                  {this.renderFreeFormWeights(x)}
              </Col>

            </Row>
          </CardBody>
        </Card>
      )}
      {this.state.finishSaveSpin?
        <div className="flex d-flex justify-content-center align-items-center w100">
        <Spinner color="primary" />
        </div>
        :
      <Button  color="primary" className="mr-1 btn-block"  type="submit"> Finish and Save </Button>
    }
      </Form>
    )
  }

  renderFreeFormWeightsAll = (x,s,t,w) => {
    const modality = x.exercise_xref.modality_xref
    const is_imperial = this.props.user_status.usa_imperial
    return(
      <div>
        <InputGroup className="mb-3">
          <InputGroupAddon addonType="prepend" color="primary">
            <React.Fragment>
              <Button style={buttonWidth} color="warning" id={"UncontrolledTooltip" +x.order+s.order+"weight"} disabled>W {w === 'y'? s.derived__suggested_weight : null}</Button>
              <UncontrolledTooltip target={"UncontrolledTooltip" + +x.order+s.order+"weight"} placement="top" >
                Enter Weight (Constant accross all sets)
              </UncontrolledTooltip>
            </React.Fragment>
          </InputGroupAddon>
          <Input
            type="number"
            min={modality === 4 && is_imperial? "45" : modality === 4 && !is_imperial? "20" : "0"}
            className="form-control"
            placeholder={s.derived__weight_message}
            name={'weight'+x.order+s.order}
            onChange={this.onChange}/>
          <Input type="hidden" value={this.state.lift_tracking_input[0].derived__one_rep_max_flag === "false"? this.state.lift_tracking_input[0].full_quick[0].full_set[0].weight_level : null}ref={(input) => { this.weight_level = input }}/>
          <Input type="hidden" value={this.state.lift_tracking_input[0].derived__current_session_day} ref={(input) => { this.session_day = input }}/>
          <Input type="hidden" value={this.state.lift_tracking_input[0].derived__time_now} ref={(input) => { this.recorded_start_time = input }}/>
          <Input type="hidden" value={this.props.user_id} ref={(input) => { this.user_id_xref = input }}/>
          <Input type="hidden" value={this.state.lift_tracking_input[0].routine_xref.enroll_history[0].id} ref={(input) => { this.user_enrollment_history_xref = input }}/>
          <Input type="hidden" value={this.state.lift_tracking_input[0].id} ref={(input) => { this.workout_day_xref = input }}/>
          <Input type="hidden" value={this.state.lift_tracking_input[0].derived__current_workout_week} ref={(input) => { this.duration_xref = input }}/>
        </InputGroup>
      </div>
    )
  }

  renderFreeFormAll = () => {
    return(
      <Form onSubmit={this.onSubmitFreeFormAll}>
        {this.state.lift_tracking_input[0].full_quick.map((x,y)=>
          <Card key={y}>
            <CardHeader>
              {this.renderCardTitleExerciseName(x,this.state.lift_tracking_input[0])}
            </CardHeader>
            <CardBody>
              {x.full_set.map((s,t)=>
                <Row key={t}>
                  <Col lg="6" >
                    {this.renderFreeFormSets(x,s,t)}
                  </Col>

                  <Col lg="6">
                    {this.renderFreeFormWeightsAll(x,s,t)}
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>
        )}
        {this.state.finishSaveSpin?
          <div className="flex d-flex justify-content-center align-items-center w100">
          <Spinner color="primary" />
          </div>
          :
        <Button  color="primary" className="mr-1 btn-block"  type="submit"> Finish and Save </Button>
      }
      </Form>
    )
  }

  renderExpressFormDropSets = (x,s) => {
    return(
      <CustomInput type="select" id={'rep_name'+x.order+s.order} name={'rep_name'+x.order+s.order} onChange={this.onChangeExpressDrop} value={this.state['rep_name'+x.order+s.order]}>

        {s.rep_count_max === null?
          Array.from(Array(s.rep_count+1), (e, i) => {return <option key={i}>{s.rep_count-i} Reps</option>})
          :
          s.rep_count_max === 999?
          Array.from(Array(25+1), (e, i) => {return <option key={i}>{25-i} Reps</option>})
          :
          s.rep_count_max > 0 && s.rep_count_max < 999?
          Array.from(Array(s.rep_count_max+1), (e, i) => {return <option key={i}>{s.rep_count_max-i} Reps</option>})
          :
          null
        }
      </CustomInput>
    )
  }

  renderExpressFormDropWeights = (x,s) => {
    console.log('PPP')
    console.log(this.state['weight_name'+x.order+s.order])
    return(
      <CustomInput
      type="select"
      id={'weight_name'+x.order+s.order}
      name={'weight_name'+x.order+s.order}
      onChange={this.onChangeExpressDropMax}
      value={this.state['weight_name'+x.order+s.order]}>


      {x.derived__weight_options.map(({key},b) => {
        return <option key={b}>{key + ' ' + this.state.measure_value}</option>

      })}

      </CustomInput>
    )
  }

  popOver = (e) => {
    e.preventDefault();
  }

  renderExpressFormSets = (x,s,t) => {
    return(
      <div>
        <InputGroup className="mb-3">
          <InputGroupAddon addonType="prepend" color="primary">
            <React.Fragment>
              <Button
                style={buttonWidth}
                 color="secondary"
                 id={"UncontrolledTooltip" +x.order+s.order}
                 onClick={this.popOver} >{s.order} {s.rep_count_max === 999? '*' : '   '}
                 </Button>
              <UncontrolledTooltip target={"UncontrolledTooltip" + +x.order+s.order} placement="top" >
              {s.rep_count_max === 999?
                'Set' + s.order + ' (Do as many reps as you can)'
                :
                'Set' + s.order
              }
              </UncontrolledTooltip>
            </React.Fragment>
          </InputGroupAddon>
          {this.renderExpressFormDropSets(x,s)}
        </InputGroup>
        <Input type="hidden" value={x.exercise_xref.id} ref={(input) => { this["exercise" + x.order + s.order] = input }}/>
        <Input type="hidden" value={s.order} ref={(input) => { this['set'+x.order+s.order] = input }}/>
      </div>
    )
  }

  renderExpressFormWeightsConst = (x) => {
    return(
      <div>
        <InputGroup className="mb-3">
          <InputGroupAddon addonType="prepend" color="primary">
            <React.Fragment>
              <Button style={buttonWidth} onClick={this.popOver} color="warning" id={"UncontrolledTooltip" +x.order+"weight"} >W </Button>
              <UncontrolledTooltip target={"UncontrolledTooltip" + +x.order+"weight"} placement="top" >
                {x.derived__weight_message}
              </UncontrolledTooltip>
            </React.Fragment>
          </InputGroupAddon>

          {this.renderExpressFormDropWeightsConst(x)}


          <Input type="hidden" value={this.state.lift_tracking_input[0].derived__one_rep_max_flag === "false"? this.state.lift_tracking_input[0].full_quick[0].full_set[0].weight_level : 'temp'}ref={(input) => { this.weight_level = input }}/>
          <Input type="hidden" value={this.state.lift_tracking_input[0].derived__current_session_day} ref={(input) => { this.session_day = input }}/>
          <Input type="hidden" value={this.state.lift_tracking_input[0].derived__time_now} ref={(input) => { this.recorded_start_time = input }}/>
          <Input type="hidden" value={this.props.user_id} ref={(input) => { this.user_id_xref = input }}/>
          <Input type="hidden" value={this.state.lift_tracking_input[0].routine_xref.enroll_history[0].id} ref={(input) => { this.user_enrollment_history_xref = input }}/>
          <Input type="hidden" value={this.state.lift_tracking_input[0].id} ref={(input) => { this.workout_day_xref = input }}/>
          <Input type="hidden" value={this.state.lift_tracking_input[0].derived__current_workout_week} ref={(input) => { this.duration_xref = input }}/>
        </InputGroup>
      </div>
    )
  }

  renderExpressFormDropWeightsConst = (x,s) => {

    return(
      <CustomInput
        type="select"
        id={'const_weight_name'+x.order}  name={'const_weight_name'+x.order} onChange={this.onChangeExpressDrop} value={this.state['const_weight_name'+x.order]}>

      {x.derived__weight_options.map((a,b) =>
        <option> {a.key + ' ' + this.state.measure_value} </option>
      )}
      </CustomInput>
    )
  }

  renderExpressFormHorizontal = () => {
    return(
      <Form onSubmit={this.onSubmitExpressFormConst}>
      {this.state.lift_tracking_input[0].full_quick.map((x,y)=>
        <Card key={y}>
          <CardHeader>
            {this.renderCardTitleExerciseName(x)}
          </CardHeader>
          <CardBody>
            <Row>
              {x.full_set.map((s,t)=>
                <Col lg="3" key={t}>
                  {this.renderExpressFormSets(x,s,t)}
                </Col>
              )}
              <Col lg="3">
                  {this.renderExpressFormWeightsConst(x)}
              </Col>
            </Row>
          </CardBody>
        </Card>
      )}
      <Button color="primary" className="mr-1 btn-block"  type="submit"> Finish and Save </Button>
      </Form>
    )
  }

  renderExpressFormVertical = () => {
    return(
      <Form onSubmit={this.onSubmitExpressFormConst} className="customLiftTrackingVertical">
      {this.state.lift_tracking_input[0].full_quick.map((x,y)=>
        <Card key={y}>
          <CardHeader>
            {this.renderCardTitleExerciseName(x)}
          </CardHeader>
          <CardBody>
            <Row>
              {x.full_set.map((s,t)=>
                <Col lg="12" key={t}>
                {this.renderExpressFormSets(x,s,t)}
                </Col>
              )}
              <Col lg="12">
                {this.renderExpressFormWeightsConst(x)}
              </Col>
            </Row>
          </CardBody>
        </Card>
      )}
      <Button color="primary" className="mr-1 btn-block"  type="submit"> Finish and Save </Button>
      </Form>
    )
  }

  renderExpressFormWeightsAll = (x,s,t,w) => {
    return(
      <div>
        <InputGroup className="mb-3">
          <InputGroupAddon addonType="prepend" color="primary">
            <React.Fragment>
              <Button style={buttonWidth} color="warning" id={"UncontrolledTooltip" +x.order+s.order+"weight"} disabled>W</Button>
              <UncontrolledTooltip target={"UncontrolledTooltip" + +x.order+s.order+"weight"} placement="top" >
                Enter Weight (Constant accross all sets)
              </UncontrolledTooltip>
            </React.Fragment>
          </InputGroupAddon>
          {this.renderExpressFormDropWeights(x,s,t)}


          <Input type="hidden" value={this.state.lift_tracking_input[0].derived__one_rep_max_flag === "false"? this.state.lift_tracking_input[0].full_quick[0].full_set[0].weight_level : 'temp aa'}ref={(input) => { this.weight_level = input }}/>
          <Input type="hidden" value={this.state.lift_tracking_input[0].derived__current_session_day} ref={(input) => { this.session_day = input }}/>
          <Input type="hidden" value={this.state.lift_tracking_input[0].derived__time_now} ref={(input) => { this.recorded_start_time = input }}/>
          <Input type="hidden" value={this.props.user_id} ref={(input) => { this.user_id_xref = input }}/>
          <Input type="hidden" value={this.state.lift_tracking_input[0].routine_xref.enroll_history[0].id} ref={(input) => { this.user_enrollment_history_xref = input }}/>
          <Input type="hidden" value={this.state.lift_tracking_input[0].id} ref={(input) => { this.workout_day_xref = input }}/>
          <Input type="hidden" value={this.state.lift_tracking_input[0].derived__current_workout_week} ref={(input) => { this.duration_xref = input }}/>
        </InputGroup>
      </div>
    )
  }

  renderSetOneRepMax = () => {
    const is_imperial = this.props.user_status.usa_imperial
    return(
      <div>
        <OneRepMaxAlert />


        <Form onSubmit={this.onSubmitOneRepMax}>
        {this.state.lift_tracking_input[0].full_quick.map((x,y)=>
          <Card key={y}>
            <CardHeader>
              {this.renderCardTitleExerciseName(x,'on')}
            </CardHeader>
            <CardBody>
              {x.full_set.map((s,t)=>
                <Row key={t}>
                  <Col lg="6" >
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend" color="primary">
                        <React.Fragment>
                          <Button style={buttonWidth} color="secondary" id={"UncontrolledTooltip" +x.order+s.order} disabled>1</Button>
                          <UncontrolledTooltip target={"UncontrolledTooltip" + +x.order+s.order} placement="top" >
                            Set {s.order}
                          </UncontrolledTooltip>
                        </React.Fragment>
                      </InputGroupAddon>

                      <CustomInput type="select">
                        <option>1</option>
                      </CustomInput>


                    </InputGroup>
                    <Input type="hidden" value={x.exercise_xref.id} ref={(input) => { this['exercise' + y] = input }}/>

                  </Col>

                  <Col lg="6">

                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend" color="primary">
                          <React.Fragment>
                            <Button style={buttonWidth} color="warning" id={"UncontrolledTooltip" +x.order+s.order+"weight"} disabled>W </Button>
                            <UncontrolledTooltip target={"UncontrolledTooltip" + +x.order+s.order+"weight"} placement="top" >
                              Enter Weight (Constant accross all sets)
                            </UncontrolledTooltip>
                          </React.Fragment>
                        </InputGroupAddon>
                        <Input
                          type="number"
                          min={x.exercise_xref.modality_xref === 4 && is_imperial? "45" : x.exercise_xref.modality_xref === 4 && !is_imperial? "20" : "0"}
                          className="form-control"
                          placeholder={x.derived__weight_message}
                          name={'weight'+ y}
                          onChange={this.onChange}/>
                        <Input type="hidden" value={this.state.lift_tracking_input[0].derived__one_rep_max_flag === "false"? this.state.lift_tracking_input[0].full_quick[0].full_set[0].weight_level : 'temp'}ref={(input) => { this.weight_level = input }}/>
                        <Input type="hidden" value={this.state.lift_tracking_input[0].derived__current_session_day} ref={(input) => { this.session_day = input }}/>
                        <Input type="hidden" value={this.state.lift_tracking_input[0].derived__time_now} ref={(input) => { this.recorded_start_time = input }}/>
                        <Input type="hidden" value={this.props.user_id} ref={(input) => { this.user_id_xref = input }}/>
                        <Input type="hidden" value={this.state.lift_tracking_input[0].routine_xref.enroll_history[0].id} ref={(input) => { this.user_enrollment_history_xref = input }}/>
                        <Input type="hidden" value={this.state.lift_tracking_input[0].id} ref={(input) => { this.workout_day_xref = input }}/>
                        <Input type="hidden" value={this.state.lift_tracking_input[0].derived__current_workout_week} ref={(input) => { this.duration_xref = input }}/>
                      </InputGroup>

                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>
        )}
        <Button color="primary" className="mr-1 btn-block"  type="submit"> Finish and Save </Button>
        </Form>
        </div>
    )
  }

  renderCardTitleExerciseNameOneRepMax = (x) => {
    return(
      <CardTitle tag="h5" className="mb-0">
        {x.exercise_xref.exercise} <span className="text-muted"> (one rep max: {x.derived__one_rep_max_value}) </span>
      </CardTitle>
    )
  }

  renderExpressFormTrainingMaxExercise = () => {
    return(
      <Form onSubmit={this.onSubmitFreeFormAll}>
      {this.state.lift_tracking_input[0].full_quick.map((x,y)=>
        <Card key={y}>
          <CardHeader>
            {this.renderCardTitleExerciseNameOneRepMax(x)}
          </CardHeader>
          <CardBody>
            {x.full_set.map((s,t)=>
              <Row>
                <Col lg="6" key={t}>
                  {this.renderExpressFormSets(x,s,t)}
                </Col>

                <Col lg="6">
                  {this.renderExpressFormWeightsAll(x,s,t,'y')}
                </Col>
              </Row>
            )}
          </CardBody>
        </Card>
      )}
      <Button color="primary" className="mr-1 btn-block"  type="submit"> Finish and Save </Button>
      </Form>
    )
  }

  renderFreeFormTrainingMaxExercise = () => {

    return(
      <Form onSubmit={this.onSubmitFreeFormAll}>
      {this.state.lift_tracking_input[0].full_quick.map((x,y)=>
        <Card key={y}>
          <CardHeader>
            {this.renderCardTitleExerciseNameOneRepMax(x)}
          </CardHeader>
          <CardBody>
            {x.full_set.map((s,t)=>
              <Row  key={t}>
                <Col lg="6">
                  {this.renderExpressFormSets(x,s,t)}
                </Col>

                <Col lg="6">
                  {this.renderFreeFormWeightsAll(x,s,t,'y')}
                </Col>
              </Row>
            )}
          </CardBody>
        </Card>
      )}
      <Button color="primary" className="mr-1 btn-block"  type="submit"> Finish and Save </Button>
      </Form>
    )
  }

  renderExpressFormTrainingMaxRoutine = () => {
    return(
      <Form onSubmit={this.onSubmitFreeFormAll}>
      {this.state.lift_tracking_input[0].full_quick.map((x,y)=>
        <Card key={y}>
          <CardHeader>
            {this.renderCardTitleExerciseNameOneRepMax(x)}
          </CardHeader>
          <CardBody>
            {x.full_set.map((s,t)=>
              <Row key={t}>
                <Col lg="6" >
                  {this.renderExpressFormSets(x,s,t)}
                </Col>

                <Col lg="6">
                  {this.renderFreeFormWeightsAll(x,s,t,'y')}
                </Col>
              </Row>
            )}
          </CardBody>
        </Card>
      )}
      <Button color="primary" className="mr-1 btn-block"  type="submit"> Finish and Save </Button>
      </Form>
    )
  }

  renderHeader = () => {
    return(
      <Row className="mb-2 mb-xl-4">
        <Col xs="auto" className="d-none d-sm-block">
          <h3>{this.state.lift_tracking_input[0].day_title}</h3>
          <CardTitle tag={Link} target="_blank" to={"/workouts/detail/" + this.state.lift_tracking_input[0].routine_xref.id}>
          {this.state.lift_tracking_input[0].routine_xref.short_name}
          </CardTitle>
        </Col>

        <Col xs="auto" className="ml-auto text-right mt-n1">

          <Timer
            initialTime={0}
            checkpoints={[
                {
                    time: 60000,
                    callback: () => this.setState({disableButton: true}),
                }
            ]}
            onReset={() => this.fetchData()}>
              {({ start, resume, pause, stop, reset, timerState }) => (

                      <React.Fragment>

          <UncontrolledDropdown className="d-inline mr-2">
            <DropdownToggle caret color="light" className="bg-white shadow-sm">
              <Filter className="feather align-middle mt-n1 " /> {this.state.form_type_name}
            </DropdownToggle>
            <DropdownMenu left="true">
              {this.state.api_lift_tracking_form.map((a,b) =>
                <div key={b}>
                  <DropdownItem onClick={() => this.handleFormTypeChange(a.id_alt, a.form_name)}>{a.form_name}</DropdownItem>
                </div>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>

          <Button type="submit" onClick={reset} disabled={this.state.disableButton} color="primary" className="shadow-sm mr-1">
            <RefreshCw className="feather" />
          </Button>


          <Button disabled={this.state.disableButton} color="primary" className="shadow-sm mr-0">

                <Timer.Hours />h : <Timer.Minutes />m : <Timer.Seconds />s

          </Button>

        </React.Fragment>
    )}
          </Timer>

        </Col>
      </Row>
    )
  }
//#3 Basic
//#2 basic (cons weight)
  render() {
    if (this.state.post_process_redirect && this.state.post_process_timelineid !==null){
      return(
      <Redirect to={{pathname: '/profile/' + this.props.user_status.pk + '/workout/' + this.state.post_process_timelineid }} />
      )
    }
    else if (this.state.post_process_load){
      return(
        null
      )
    }
    else if (this.props.user_status === null || this.state.lift_tracking_input_loading || this.state.api_lift_tracking_form_loading) {
      return <Loading />
    }
    else if (this.state.lift_tracking_input.length === 0) {
      return <WorkoutNotFound />
    }
    else if (this.state.lift_tracking_input != null  && this.state.lift_tracking_input[0].routine_xref.enroll_history[0].completed_workout) {
      return <WorkoutCompleted
      workout = {this.state.lift_tracking_input[0].routine_xref.routine}
      cover_image = {this.state.lift_tracking_input[0].routine_xref.cover_image}
      />
    }
    return (
      <Container fluid>
        {this.renderHeader()}
          <section>
            {
              this.state.lift_tracking_input[0].derived__one_rep_max_flag === "true"?
              this.renderSetOneRepMax()
              :
              this.state.lift_tracking_input[0].routine_xref.training_max_exercise === true && (this.state.form_type === 1 || this.state.form_type === 2)?
              this.renderExpressFormTrainingMaxExercise()
              :
              this.state.lift_tracking_input[0].routine_xref.training_max_routine === true && (this.state.form_type === 1 || this.state.form_type === 2)?
              this.renderExpressFormTrainingMaxExercise()
              :
              this.state.form_type === 1 && this.state.vertical_form===false?
              this.renderExpressFormHorizontal()
              :
              this.state.form_type === 1 && this.state.vertical_form===true?
              this.renderExpressFormVertical()
              :
              this.state.form_type === 2 && this.state.vertical_form===false?
              this.renderFreeFormHorizontal()
              :
              this.state.form_type === 2 && this.state.vertical_form===true?
              this.renderFreeFormVertical()
              :
              this.state.form_type === 3 ?
              this.renderFreeFormAll()
              :
              null
            }
          </section>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  user_id: state.auth.user.pk,
  user_status: state.auth.user,
  general: state.general.isLoading,
  lift_tracking_input_data: state.general.lift_tracking_input_data,
  theme: state.theme.currentTheme,
});
export default connect(mapStateToProps, { exerciseTracker, exerciseTrackerOneRepMax, lifttracking })(LiftTracking);
