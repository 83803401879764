import React from "react";
import { Redirect } from "react-router-dom";
import { keyConfig } from '../../redux/actions/auth';
import axios from "axios";
import store from "../../redux/store/index";
import { API_EXERCISE_LIST_IRONROOM, API_EXERCISE_LIST_CUSTOM } from '../../redux/actions/API';
import Loading from './Loading'
import HeaderList from './HeaderList'
import {
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  Container,
} from "reactstrap";
import {
  isMobile
} from "react-device-detect";

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";

import { connect } from 'react-redux';


const MyExportCSV = props => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div>
        <button className="btn btn-secondary mt-2" onClick={handleClick}>
          Export
        </button>
      </div>
    );
  };

const { SearchBar } = Search;

class ExerciseList extends React.Component {

  fetchExercises = () =>  {
    axios.get(`${API_EXERCISE_LIST_IRONROOM}`, keyConfig(store.getState)).then(res => {
      this.setState({
        exercises_detail: res.data,
        exercises_detail_loading: false,
      });
    });
  }

  fetchExercisesCustom = () =>  {
    axios.get(`${API_EXERCISE_LIST_CUSTOM}`, keyConfig(store.getState)).then(res => {
      this.setState({
        exercises_detail_custom: res.data,
        exercises_detail_custom_loading: false,
      });
    });
  }

  componentDidMount() {
    this.fetchExercises();
    this.fetchExercisesCustom();
  }

  constructor(props) {
    super(props);
    this.state = {
      exercises_detail: [],
      referrer: null,
      exercises_detail_custom: [],
      exercises_detail_custom_loading: true,
      exercises_detail_loading: true,
    };
  }




  render() {
    const {referrer} = this.state;
    if (this.state.exercises_detail_custom_loading || this.state.exercises_detail_loading ) {
      return <Loading />
    }
    else if (referrer) {
      return <Redirect to={referrer} />;
    }
    return (

      <Container fluid>

        <HeaderList path={this.props.match.path}/>

        {this.state.exercises_detail_custom.length === 0 ?
          null
          :

        <Card>
          <ToolkitProvider
            keyField="name"
            data={this.state.exercises_detail_custom}
            columns={
              isMobile ?
              [
                {
                  dataField: "exercise",
                  text: "Exercise",
                  sort: true,
                }
              ]
              :
            [
              {
                dataField: "exercise",
                text: "Exercise",
                sort: true,
              },
              {
                dataField: "body_group_xref",
                text: "Body Group",
                sort: true,
              },
              {
                dataField: "muscle_group_xref",
                text: "Body Part",
                sort: true,
              },
              {
                dataField: "push_pull_xref",
                text: "Type",
                sort: true,
              },
              {
                dataField: "modality_xref.modality",
                text: "Modality",
                sort: true,
              },
              {
                dataField: "training_level_xref",
                text: "Level",
                sort: true,
              },
            ]
          }
            exportCSV
            search
          >
            {(props) => (
              <div>
                <CardHeader className="d-flex flex justify-content-between align-items-center">
                  <div>
                  <CardTitle tag="h5">My Custom Exercises</CardTitle>
                  </div>


                  <div className="d-flex flex-row-reverse" >
                      <div style={{display: 'flex', 'justifyContent': 'center', 'alignItems': 'center'}} className="p-0 ml-1">
                        <MyExportCSV {...props.csvProps} />
                      </div>
                      <div style={{display: 'flex', 'justifyContent': 'center', 'alignItems': 'center'}} className="p-0 mt-3">
                      <SearchBar { ...props.searchProps } className="p-0 m-0"/>
                      </div>
                    </div>
                </CardHeader>
                <CardBody>
                  <BootstrapTable
                    {...props.baseProps}
                    keyField="exercise"
                    bootstrap4
                    bordered={false}
                    rowEvents={ {
                      style: { cursor: 'pointer'},
                      onClick: (e, row) => { this.setState({referrer: `/exercises/detail/${row.id}`}) }
                    }}
                    hover={true}
                    pagination={paginationFactory({
                      sizePerPage: 5,
                      sizePerPageList: [5, 10, 25, 50]
                    })}
                  />
                </CardBody>
              </div>
            )}
          </ToolkitProvider>
        </Card>

      }

        <Card>
          <ToolkitProvider
            keyField="name"
            data={this.state.exercises_detail}
            columns={
              isMobile ?
              [
                {
                  dataField: "exercise",
                  text: "Exercise",
                  sort: true,
                }
              ]
              :
            [
              {
                dataField: "exercise",
                text: "Exercise",
                sort: true,
              },
              {
                dataField: "body_group_xref",
                text: "Body Group",
                sort: true,
              },
              {
                dataField: "muscle_group_xref",
                text: "Body Part",
                sort: true,
              },
              {
                dataField: "push_pull_xref",
                text: "Type",
                sort: true,
              },
              {
                dataField: "modality_xref.modality",
                text: "Modality",
                sort: true,
              },
              {
                dataField: "training_level_xref",
                text: "Level",
                sort: true,
              },
            ]
          }
            exportCSV
            search
          >
            {(props) => (
              <div>
                <CardHeader className="d-flex flex justify-content-between align-items-center">
                  <div>
                  <CardTitle tag="h5">My Ironroom Exercises</CardTitle>

                  </div>


                  <div className="d-flex flex-row-reverse" >
                      <div style={{display: 'flex', 'justifyContent': 'center', 'alignItems': 'center'}} className="p-0 ml-1">
                        <MyExportCSV {...props.csvProps} />
                      </div>
                      <div style={{display: 'flex', 'justifyContent': 'center', 'alignItems': 'center'}} className="p-0 mt-3">
                      <SearchBar { ...props.searchProps } className="p-0 m-0"/>
                      </div>
                    </div>
                </CardHeader>
                <CardBody>
                  <BootstrapTable
                    {...props.baseProps}
                    keyField="exercise"
                    bootstrap4
                    bordered={false}
                    rowEvents={ {
                      style: { cursor: 'pointer'},
                      onClick: (e, row) => { this.setState({referrer: `/exercises/detail/${row.id}`}) }
                    }}
                    hover={true}
                    pagination={paginationFactory({
                      sizePerPage: 25,
                      sizePerPageList: [5, 10, 25, 50]
                    })}
                  />
                </CardBody>
              </div>
            )}
          </ToolkitProvider>
        </Card>

      </Container>

    );
  }
}


const mapStateToProps = (state) => ({
  general: state.general.isLoading,
});

export default connect(mapStateToProps)(ExerciseList);
