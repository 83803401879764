import React, { useState } from "react";
import {  withRouter} from 'react-router-dom';
import { connect } from "react-redux";
import store from "../../redux/store/index";
import { Button, Modal, ModalHeader,} from 'reactstrap';
import DeleteFullSetModalBody from './DeleteFullSetModalBody'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes
} from "@fortawesome/free-solid-svg-icons";

const ModalExercise = (props) => {
  const {
    className
  } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <>

      <Button color="danger" className="shadow-sm mr-1" onClick={toggle}>
         <FontAwesomeIcon icon={faTimes} />
      </Button>



      <Modal isOpen={modal} toggle={toggle} className={className} size="lg">
        <ModalHeader toggle={toggle}>Delete Exercise Set</ModalHeader>

        <DeleteFullSetModalBody toggle={toggle} fullID = {props.fullID} fetchWorkouts = {props.fetchWorkouts} routine = {props.routine} workout_day_id={props.workout_day_id}/>


      </Modal>
    </>
  );
}

class DeleteFullSet extends React.Component {

  componentDidMount() {

  }

  render() {
    return (
      <ModalExercise fullID = {this.props.fullID} fetchWorkouts={this.props.fetchWorkouts} routine = {this.props.routine} workout_day_id={this.props.workout_day_id}/>
    );
  }
}

const mapStateToProps = (state) => ({
  user_status: state.auth.user,
  auth: state.auth,
  sidebar: store.sidebar,
});

export default connect(mapStateToProps, {  })(withRouter(DeleteFullSet));
