import React from "react";
import { Link } from "react-router-dom";
import {
  Col,
  Button,
  Row,
} from "reactstrap";
import { ArrowLeft } from "react-feather";
import PatchExercise from "../../../components/Create/PatchExercise.js"

const Header = (props) => {
  return (
    <Row className="mb-2 mb-xl-4">
      <Col xs="auto" className="d-none d-sm-block">
        <h3>{props.exercise_detail.exercise}</h3>
      </Col>

      <Col xs="auto" className="ml-auto text-right mt-n1">
      <Button color="primary" className="shadow-sm mr-1" tag={Link} to="/exercises">
        <ArrowLeft className="feather" /> Back to List
      </Button>

      {props.exercise_detail.user_id_xref === props.user ?
      <PatchExercise exerciseID = { props.exercise_detail.id} fetchDetail = {props.fetchDetail}/>
      :
      null
    }


      </Col>
    </Row>
  );
};

export default Header;
