import React from "react";
import Select from 'react-select'
import {  withRouter} from 'react-router-dom';
import { connect } from "react-redux";
import { toggleSidebar } from "../../redux/actions/sidebarActions";
import { logout } from "../../redux/actions/auth";
import {
  timeline_you_and_following_last,
  timeline_member_only_last,
  timeline_you_and_following,
  timeline_member_only,
} from "../../redux/actions/general";
import store from "../../redux/store/index";
import { Link } from 'react-router-dom';
import { loadUser } from '../../redux/actions/auth';
import {
  SOCKET_URL,
  API_TIMELINE_YOU_AND_FOLLOWING_ID,
  API_TIMELINE_ALL_USER_ID,
  API_TIMELINE_ALL_USER_NORM_ID,
  API_TIMELINE_ALL_USER,
  API_TIMELINE_YOU_AND_FOLLOWING
} from '../../redux/actions/API'
import { toastr } from "react-redux-toastr";
import CreateExercise from '../Create/CreateExercise.js'
import CreateWorkout from '../Create/CreateWorkout.js'
import SubscribeModal from "../../components/SubscribeModal.js"
import ProfilePhoto from '../../components/Social/ProfilePhoto/ProfilePhoto'
import {
  Row,
  Col,
  Collapse,
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ListGroupItem,
} from "reactstrap";
import {
  User,
  Settings,
  PlusCircle,
  ChevronDown,
  PieChart,
} from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faEnvelopeOpen,
  faBell,
  faThumbsUp,
  faComment
} from "@fortawesome/free-solid-svg-icons";
import {API_DISCOVER_PROFILES } from '../../redux/actions/API';
import axios from "axios";
import { keyConfig } from '../../redux/actions/auth';
import APISocial from '../../config/social';



const NavbarDropdown = ({children, count, header, footer, icon, active, indicator, user_pk, fetchMessages, turnOffNotificationsBell}) => (
  <UncontrolledDropdown nav inNavbar className="ml-lg-1" active={active} onClick={() => turnOffNotificationsBell(user_pk, fetchMessages)}>
    <DropdownToggle tag={Link} to="/notifications" nav className="nav-icon dropdown-toggle position-relative">
      <FontAwesomeIcon icon={icon} className="align-middle"  />
      {indicator ? <span className="indicatorCustom" /> : ""}
    </DropdownToggle>

  </UncontrolledDropdown>
);

const NavbarDropdownItem = ({ icon, title, description, time, spacing, link }) => (
  <ListGroupItem tag={Link} to={link}>
    <Row noGutters className="align-items-center">
      <Col xs={2}>{icon}</Col>
      <Col xs={10} className={spacing ? "pl-2" : null}>
        <div className="text-dark">{title}</div>
        <div className="text-muted small mt-1">{description}</div>
        <div className="text-muted small mt-1">{time}</div>
      </Col>
    </Row>
  </ListGroupItem>
);

const NavbarToggle = connect(store => ({
  app: store.app
}))(({ dispatch }) => {
  return (
    <span
      className="sidebar-toggle d-flex mr-2"
      onClick={() => {
        dispatch(toggleSidebar());
      }}
    >
      <i className="hamburger align-self-center" />
    </span>
  );
});

const NavbarDropdowns = connect(store => ({
  sidebar: store.sidebar
}))(({ sidebar, dispatch, ...props }) => {
  return (
    <Collapse navbar>
      <Nav className={!sidebar.isOnRight ? "ml-auto" : "mr-auto"} navbar >

      {props.showSubscribe?
        <SubscribeModal source="Navbar" />
        :
        null}



      <UncontrolledDropdown nav inNavbar className="ml-lg-1">
        <DropdownToggle nav>
          <PlusCircle size={22} className="align-middle text-dark"/>
        </DropdownToggle>
        <DropdownMenu right>



      <CreateWorkout source={"Nav"} />
      <CreateExercise source={"Nav"} showAll={props.showAllExercises}/>


        </DropdownMenu>
      </UncontrolledDropdown>


      <NavbarDropdown
        header="Recent Notifications"
        footer="Show all notifications"
        icon={faBell}
        count={props.new_notification_count}
        indicator = {props.display_notification_flag}
        user_pk = {props.user_pk}
        fetchMessages = {props.fetchMessages}
        turnOffNotificationsBell = {props.turnOffNotificationsBell}
      >
        {props.notifications1 ? props.notifications1.map((item, key) => {
          let icon = (
            <FontAwesomeIcon icon={faEnvelopeOpen} className="text-warning" />
          );

          if (item.event === 1) {
            icon = <FontAwesomeIcon icon={faUser} className="text-danger" />;
          }

          if (item.event === 2) {
            icon = (
              <FontAwesomeIcon icon={faThumbsUp} className="text-primary" />
            );
          }

          if (item.event === 3) {
            icon = (
              <FontAwesomeIcon icon={faComment} className="text-success" />
            );
          }

          return (
            <NavbarDropdownItem
              key={key}
              icon={icon}
              title={item.title}
              description={item.memo}
              time={item.timestamp}
              link={item.event === 1? "/profile/" + item.user_id + "/home":
                    item.event === 2? "/profile/" + props.user_pk + "/workout/" + item.link_id:
                    item.event === 3? "/profile/" + props.user_pk + "/workout/" + item.link_id:
                null}



            />
          );
        }) : null}
      </NavbarDropdown>






      <UncontrolledDropdown nav inNavbar>


      <span className="d-inline-block d-sm-none">
        <DropdownToggle nav caret>
          <Settings size={18} className="align-middle" />
        </DropdownToggle>
      </span>


      <span className="d-none d-sm-inline-block">
        <DropdownToggle nav caret={props.avatar_image === null || !props.avatar_image || props.avatar_image==="None" || props.avatar_image===""? false: true}>


        {props.avatar_image === null || !props.avatar_image || props.avatar_image==="None" || props.avatar_image===""?

<div>
          <div className="d-flex flex justify-content-center align-items-center">



          <span
          className="avatar img-fluid rounded-circle mr-1"
          style={{
            "background":props.avatar_color,
            "width": '28px',
            "height": '28px',
            "display": "flex",
            "alignItems": "center",
            "justifyContent": "center",
            "border":"none",
            "borderRadius": "50%",
            "fontFamily": "sans-serif",
            "color": "#fff",
            "fontSize": '14px',
            "marginLeft": "auto",
            "marginRight": "auto",
          }}
          >
          {props.avatar_letter}
          </span>

      <span className="text-dark ml-1">{props.first_name + ' ' + props.last_name }<ChevronDown size={12} className="align-middle text-dark"/></span>

</div>
</div>

:

<>
  <img
    src={props.avatar_image}
    className="avatar img-fluid rounded-circle mr-1"
    style={{width:'28px', height:'28px'}}
    alt="Chris Wood"
  />
  <span className="text-dark">{props.first_name + ' ' + props.last_name}</span>
</>




      }


        </DropdownToggle>
        </span>


        <DropdownMenu right>
          <DropdownItem tag={Link} to={'/profile/' + props.user_pk  + '/home'}>
            <User size={18} className="align-middle mr-2" />
            My Profile
          </DropdownItem>
          <DropdownItem  tag={Link} to={'/myprogression/analytics'}>
            <PieChart size={18} className="align-middle mr-2"/>
            Analytics
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem tag={Link} to={'/settings/profile'}>
            Settings & Privacy</DropdownItem>

          <DropdownItem
          onClick={() => {
            dispatch(logout());
          }}>
          Sign out</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>






      </Nav>
    </Collapse>
  );
});

const customStyles = {
  control: (base, state) => ({
    ...base,
    fontFamily: 'Arial, Helvetica, sans-serif',
    fontSize: 14,
    border: state.isFocused ? 0 : 0,
    boxShadow: state.isFocused ? 0 : 0,
    cursor: 'text',
    borderRadius: 0,
    width: '250px',
    background: '#f8f9fa'

  }),



  option: (styles, { isFocused }) => {
    return {
      ...styles,
      cursor: 'pointer',
      backgroundColor: isFocused ? '#e9ecef' : 'white',
      color: isFocused ? 'black' : 'black',
      lineHeight: 2,
    }
  },

  input: styles => ({
    ...styles,
    color: 'black',
    fontFamily: 'Arial, Helvetica, sans-serif',
  }),

  menu: styles => ({
    ...styles,
    marginTop: 0,
    boxShadow: 'none',
    borderRadius: 0,
  }),

  singleValue: styles => ({
    ...styles,
    color: 'green',
  }),
}

class Navbar_Main extends React.Component {


  turnOffNotificationsBell = async (user_id) => {
    await APISocial.notificationBell(
        user_id,
        "false",
        (success, data) => {
      if ( success) {
        console.log('success')
        console.log(data)
        this.fetchMessages(1)
      } else {
        console.log('fail')
        console.log(data)
        this.fetchMessages(1)
      }
    });
  };


  fetchInitial = () =>  {
    axios.get(`${API_DISCOVER_PROFILES}?search=${this.state.search_value}`, keyConfig(store.getState)).then(res => {
      this.setState({
        isSearchLoading: false,
        user_profiles_api: res.data,
        user_profiles_search_api: res.data.results.map(({ id,firstname_lastname, avatar_image, avatar_color, avatar_letter, location_city_std }) => {return{
          value: firstname_lastname,
          label:  <div className="flex d-flex justify-content-start align-items-center">
                  <ProfilePhoto
                  avatar_image={avatar_image}
                  avatar_color = {avatar_color}
                  avatar_letter = {avatar_letter}
                  avatar_size={"36px"}
                  letter_size={"18px"}
                  active_user_id = {id }
                  user_id = {id}

                  />&nbsp; &nbsp;
                  <div className="d-flex flex-column justify-content-start align-items-start m-0 p-0">
                    <p className="m-0 p-0">{firstname_lastname} </p>
                    <small className="m-0 p-0">{location_city_std} </small>
                  </div>
                  </div>,
          id: id
        }}),
      });
    });
  }

  componentDidMount() {
    if (this.props.user_status == null) {
      store.dispatch(loadUser());
    }
    if (this.props.user_status !== null && this.props.auth !== null) {
      this.fetchInitial();
      if(process.env.REACT_APP_STAGE !=='mac'){
        this.waitForSocketConnection();
      }
    }
    if (this.props.match.path === "/pages/thankyou/:successID") {
      this.setState({showSubscribe:false})
    }
    this.fetchTimeLineLatest();
  }

  fetchTimeLineLatest =  () =>  {
    axios.get(`${API_TIMELINE_YOU_AND_FOLLOWING_ID}?page=${1}`, keyConfig(store.getState)).then(res => {
      this.props.timeline_you_and_following_last(res.data?.results[0]?.id)
      if (!this.props.timeline_you_and_following_data.find((data) => data.id.includes(res.data?.results[0]?.id))) {
       axios.get(`${API_TIMELINE_YOU_AND_FOLLOWING}?page=${1}`, keyConfig(store.getState)).then(res => {
         axios.get(`${API_TIMELINE_YOU_AND_FOLLOWING}?page=${2}`, keyConfig(store.getState)).then(res2 => {
           this.props.timeline_you_and_following(res.data.results.concat(res2.data.results))
          })
        })
      }
    })
    
  }


   componentWillUnmount() {
     this.disconnect();
}



    componentDidUpdate() {
      if (this.state.search_value !== this.state.search_value_before) {
        this.setState({search_value_before: this.state.search_value})
        this.fetchInitial()
        if (!this.state.isSearchLoading) {
          this.setState({isSearchLoading:true})
        }
      }
      if (this.state.socketState === 1 && this.state.fetchInitial === 0 && this.props.user_status) {
        this.setState({
          fetchInitial: 1,
        });
        this.fetchMessages();
      }
      if (this.state.display_notification_trigger === 1) {
        this.setState({
          display_notification_trigger: 0,
        });
        this.fetchMessages();
      }
    }


    sendMessage(data) {
      try {
        this.socketRef.send(JSON.stringify({ ...data }));
      }
      catch(err) {
        console.log(err.message);
      }
    }



    fetchMessages() {
      this.sendMessage({
        command: "fetch_latest",
        user_id: this.props.user_status.pk
      });
    }






    waitForSocketConnection(callback) {
      const component = this;
      setTimeout(function() {
        if (component.state.socketState === 1) {
          console.log("Connection is made");
          //callback();
          return;
        } else {
          console.log("wait for connection...");
          component.connect();
        }
      }, 500);
    }


     connect(chatUrl) {
       const path = `${SOCKET_URL}/ws/notifications/${this.props.auth.key}/`;
       this.socketRef = new WebSocket(path);
       //this.socketRef.close();
       if (this.socketRef.readyState === 1) {
         return;
       }
       this.socketRef.onopen = () => {
         console.log('WebSocket open');
         this.setState({
           socketState: 1,
         });
       };
       this.socketRef.onmessage = e => {
         this.socketNewMessage(e.data);
         //console.log(e.data)
       };
       this.socketRef.onerror = e => {
         console.log(e.message);
       };
       this.socketRef.onclose = () => {
         console.log("WebSocket closed let's reopen");
         this.waitForSocketConnection();
       };
     }


     messageTrigger = (type,parsedData) => {
       if (type === "user_gossippx") {
         this.setState({
           notifications: parsedData.messages,
           new_notification_count: parsedData.new_notification_count,
           display_notification_flag: parsedData.display_notification_flag
         });
         if (this.state.display_notification_initial === 0) {
           this.setState({
             display_notification_initial: 1
           })
         }
         else if (parsedData.display_notification_flag && this.state.display_notification_initial ===1) {
            this.showToastr(parsedData.messages[0].memo)
         }
       }
     }

     socketNewMessage(data) {
       const parsedData = JSON.parse(data);
       this.messageTrigger(parsedData.type,parsedData)
     }



  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      showSubscribe: true,
      timeline_api:[],
      user_profiles_api : [],
      user_profiles_search_api: [],
      workouts_popular: null,
      orig_status: null,
      api_timeline_loading: true,
      api_profiles_loading: true,
      api_user_profile_detail_loading: true,
      current_member_id: null,
      selectedOption: null,
      search_value: null,
      search_value_before: null,
      isSearchLoading: true,
      socketRef: null,
      socketState: null,
      fetchInitial: 0,
      notifications: [],
      new_notification_count: null,
      display_notification_flag: null,
      display_notification_trigger: 0,
      display_notification_initial: 0,
      title: "",
      message: "",
      type: "info",
      timeOut: 5000,
      showCloseButton: true,
      progressBar: true,
      position: "bottom-right",



    };
  }


  disconnect() {
    try{this.socketRef.close()} catch(e) { console.error(e); }
  }


  showToastr(memo) {
    const options = {
      timeOut: parseInt(this.state.timeOut),
      showCloseButton: this.state.showCloseButton,
      progressBar: this.state.progressBar,
      position: this.state.position
    };

    const toastrInstance =
      this.state.type === "info"
        ? toastr.info
        : this.state.type === "warning"
        ? toastr.warning
        : this.state.type === "error"
        ? toastr.error
        : toastr.success;

    toastrInstance(
      this.state.title,
      this.state.message || memo,
      options
    );
  }




  notificationBell = (val) => {
    this.setState({
      display_notification_trigger: val
    })
}


  handleChange = (selectedOption) => {
    this.setState({ selectedOption })
   // code to make something happen after selecting an option
   this.props.history.push(`/profile/${selectedOption.id}/home`);
}

  handleInputChange = (inputValue: any, actionMeta: any) => {
   this.setState({ search_value: inputValue });
 };


  onKeyDown = e => {
  if (e.keyCode === 13) {
    // do stuff
    this.props.history.push(`/athletes/search/${this.state.search_value}`);
  }
};

  render() {

    if (this.props.user_status == null) {
        return null
      }

    return (
      <Navbar expand className="navbar-theme">
        <React.Fragment>
          <NavbarToggle />


          {this.props.user_status.hidden? null :
          <Select
            value={this.state.selectedOption}
            options={this.state.user_profiles_search_api}
            onChange={this.handleChange}
            placeholder= "Search athletes to follow..."
            openMenuOnClick={false}
            styles={customStyles}
            components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
            onKeyDown={this.onKeyDown}
            onInputChange={this.handleInputChange}
            noOptionsMessage={() => null}
            isLoading={this.state.isSearchLoading}
          />
        }


          <NavbarDropdowns
            email_lower = {this.props.user_status.email_lower}
            avatar_letter = {this.props.user_status.avatar_letter}
            avatar_color = {this.props.user_status.avatar_color}
            avatar_image= {this.props.user_status.avatar_image}
            username = {this.props.user_status.username}
            first_name = {this.props.user_status.first_name}
            last_name = {this.props.user_status.last_name}
            user_pk = {this.props.user_status.pk}
            notifications1 = {this.state.notifications}
            new_notification_count = {this.state.new_notification_count}
            display_notification_flag = {this.state.display_notification_flag}
            fetchMessages = {this.notificationBell}
            showAllExercises = {this.props.match.path === "/exercises"? "No" : null}
            showSubscribe = {this.state.showSubscribe}
            turnOffNotificationsBell={this.turnOffNotificationsBell}
            />
        </React.Fragment>
      </Navbar>
    );
  }
}

const mapStateToProps = (state) => ({
  user_status: state.auth.user,
  auth: state.auth,
  sidebar: store.sidebar,
  timeline_you_and_following_data: state.general.timeline_you_and_following_data,
  timeline_member_only_data: state.general.timeline_member_only_data,
});

export default connect(mapStateToProps, { timeline_you_and_following_last, timeline_member_only_last, timeline_you_and_following, timeline_member_only })(withRouter(Navbar_Main));
