import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Button,
  Spinner,
  Card,
  CardHeader,
  CardImg,
  CardText,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import axios from "axios";
import { keyConfig } from '../../redux/actions/auth';
import { lifttracking } from '../../redux/actions/general';
import store from "../../redux/store/index";
import iOS from "./button-app.png"
import play from "./button-play.png"
import {
  API_LIFT_TRACKER_INPUT,
  API_EXERCISE_TRACKER,
  API_TIMELINE_GETTING_STARTED,
 }
  from '../../redux/actions/API'
import {
  User,
  Lock,
  GitBranch,
  GitMerge,
} from "react-feather";
import Popular from '../../pages/dashboards/Default/Popular.js'
import CreateWorkout from "../../components/Create/CreateWorkout.js"

class Default extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      api_dashboard_lti: this.props.lift_tracking_input_data,
      api_dashboard_lti_loading: this.props?.lift_tracking_input_data?.length > 0? false : true,
      api_dashboard_history: [],
      api_dashboard_history_loading: true,

      api_photos: [],
      api_photos_loading: true,
    };
  }

  fetchNextLiftData = () =>  {
    axios.get(`${API_LIFT_TRACKER_INPUT}`, keyConfig(store.getState)).then(res => {
      this.props.lifttracking(res.data)
      this.setState({
        api_dashboard_lti: res.data,
        api_dashboard_lti_loading: false
      });
    }).catch((err) => {
      this.setState({
        api_dashboard_lti_loading: false
      });
    })
  }

  fetchHistoricLiftData = () =>  {
    axios.get(`${API_EXERCISE_TRACKER}`, keyConfig(store.getState)).then(res => {
      this.setState({
        api_dashboard_history: res.data,
        api_dashboard_history_loading: false
      });
    }).catch((err) => {
      this.setState({
        api_dashboard_history_loading: false
      });
    })
  }

  fetchPhotos = () =>  {
    axios.get(`${API_TIMELINE_GETTING_STARTED}`, keyConfig(store.getState)).then(res => {
      this.setState({
        api_photos: res.data,
        api_photos_loading: false,
      });
    })
  }

  componentDidMount() {
    this.fetchNextLiftData()
    this.fetchHistoricLiftData()
    this.fetchPhotos()
  }

  renderCardForDashboard = () => {
    return(
      <div>
        <Card>
          {this.renderHeaderImage()}
          <ListGroup flush>
          {this.renderListDiscoverWorkoutsDashboard()}
          </ListGroup>
        </Card>
        {this.renderCardGetApp2()}
      </div>
    )
  }

  renderCardForTimeline = () => {
    return(
      <div>
        <Card>
          {this.renderHeaderImage()}
          <ListGroup flush>
          {this.renderGettingStarted()}
          {this.renderTimelineListSequence()}
          </ListGroup>
        </Card>
      </div>
    )
  }

  renderTimelineListSequence = () => {
    if (this.state.api_dashboard_lti_loading || this.state.api_dashboard_history_loading) {
      return(
        <div className="d-flex flex justify-content-center align-items-center">
      <Spinner color="primary p-2" />
      </div>
    )
    }
    else if (this.state.api_dashboard_lti.length === 0 && this.state.api_dashboard_history.length === 0) {
      return(
        <>
      {this.renderListDiscoverWorkouts()}
      {this.renderListCreateWorkouts()}
      {this.renderListFindFriends()}
      {this.renderListPrivacy()}
      </>
      )
    }
    else if (this.state.api_dashboard_lti.length > 0  && this.state.api_dashboard_history.length === 0) {
      return(
        <>
      {this.renderListLogFirst()}
      {this.renderListFindFriends()}
      {this.renderListPrivacy()}
      </>
      )
    }
  }

  renderCardGetApp = () => {
    return(
      <Card>

        <ListGroupItem>
      <CardHeader className="m-0 p-0">
        <h2 className="mb-0" style={{"justifyContent": "left", "textAlign": "left"}}>
          Get Our App
        </h2>
      </CardHeader>
      <CardHeader className="m-0 p-0">
        <CardText>
        Record, analyze and share activities right from your phone. Our free mobile app keeps you connected with friends and ready for the next workout.
        </CardText>
      </CardHeader>
      <div className="mt-3 float-right">
      <a href="https://apps.apple.com/us/app/ironroom/id1568748504" target='_blank' className="d-inline-block mr-2">
        <CardImg src={iOS} className="img-fluid" alt="..." style={{'maxWidth': '125px'}}/>
      </a>

      <a href="https://play.google.com/store/apps/details?id=io.ironroom" target='_blank' className="d-inline-block">
        <CardImg src={play} className="img-fluid" alt="..." style={{'maxWidth': '125px'}}/>
      </a>


      </div>
        </ListGroupItem>





      </Card>
    )
  }

  renderCardSubscribe = () => {
    return(
      <Card>

        <ListGroupItem>
      <CardHeader className="m-0 p-0">
        <h2 className="mb-0" style={{"justifyContent": "left", "textAlign": "left"}}>
          First Month On Us
        </h2>
      </CardHeader>
      <CardHeader className="m-0 p-0">
        <CardText>
        Tracking and logging and bing part of the community is always free. Deeper analytics and details. Keep Rocking and Keep Lifting
        </CardText>
      </CardHeader>
        </ListGroupItem>
        <ListGroupItem  className="d-flex flex-row align-items-start justify-content-start">

        <div>
          <Lock width={44} height={44} className="mr-3" style={{border:"1px solid black", "borderRadius": "50%", padding: "10px"}} />
          </div>


            <div className="d-flex flex-column justify-content-between align-items-start">
            <div>
             <h4>Deeper Analytics!</h4>
            <span> Join and enroll in a workout that's right log your first activity. Moving ahead you can track your progress, share, grow and learn. </span>
            </div>
            <div><Button className="btn mt-3" color="danger" size="md"> Upgrade </Button> </div>
            </div>



        </ListGroupItem>


      </Card>

    )
  }

  renderCardGetApp2 = () => {
    return(
      <Card>


      <ListGroupItem>
        <CardHeader className="pl-0">
      <h2 className="mb-0">
        Get Our App
      </h2>
        </CardHeader>

      <CardText style={{"justifyContent": "left", "textAlign": "left"}}>
      Record, analyze and share activities right from your phone. Our free mobile app keeps you connected with friends and ready for the next workout.
      </CardText>


      <div>
      <a href="#!" className="d-inline-block mr-1">
        <CardImg src={iOS} className="img-fluid" alt="..." style={{'maxWidth': '105px'}}/>
      </a>

      <a href="#!" className="d-inline-block">
        <CardImg src={play} className="img-fluid" alt="..." style={{'maxWidth': '105px'}}/>
      </a>



      </div>

      </ListGroupItem>



      </Card>
    )
  }

  renderHeaderImage = () => {
    if (this.state.api_photos_loading) {
      return (
        <div className="d-flex flex justify-content-center align-items-center">
      <Spinner color="primary p-2" />
      </div>
      )
    }
    if (this.props.source==="timeline_youonly") {
      return(
        <CardImg top width="100%" height="50%" src={this.state.api_photos[1].cover_image} alt="Card image cap" style={{height:'250px', objectFit: 'cover', backgroundSize: 'cover', backgroundRepeat:'no-repeat' }}/>
      )
    }
    return(
      <CardImg top width="100%" height="50%" src={this.state.api_photos[0].cover_image} alt="Card image cap" style={{height:'250px', objectFit: 'cover', backgroundSize: 'cover', backgroundRepeat:'no-repeat' }}/>
    )
  }

  renderGettingStarted = () => {
    return(
      <ListGroupItem>
    <CardHeader className="m-0 p-0">
      <h2 className="mb-0" style={{"justifyContent": "left", "textAlign": "left"}}>
        Things You Can Do
      </h2>
    </CardHeader>
    <CardHeader className="m-0 p-0">
      <CardText>
      To get started, we've detailed some things you can do to make the most of ironroom.
      </CardText>
    </CardHeader>
      </ListGroupItem>
    )
  }

  renderListDiscoverWorkouts = () => {
    return(
      <ListGroupItem  className="d-flex flex-row align-items-start justify-content-start" style={{border:'none'}} >

      <div>
        <GitBranch width={44} height={44} className="mr-3" style={{border:"1px solid black", "borderRadius": "50%", padding: "10px"}} />
        </div>


          <div className="d-flex flex-column justify-content-between align-items-start">
          <div>
           <h4>Discover Workouts</h4>
          <span> Join and log a workout that's right for you. </span>
          </div>
          <div>
            <Button style={{width: '150px' }}className="btn mt-3 mr-3" tag={Link} to="/workouts/All" color="primary" size="md"> Discover Workouts </Button>

           </div>
          </div>



      </ListGroupItem>
    )
  }





  renderListCreateWorkouts = () => {
    return(
      <ListGroupItem  className="d-flex flex-row align-items-start justify-content-start" style={{border:'none'}} >

      <div>
        <GitMerge width={44} height={44} className="mr-3" style={{border:"1px solid black", "borderRadius": "50%", padding: "10px"}} />
        </div>


          <div className="d-flex flex-column justify-content-between align-items-start">
          <div>
           <h4>Create Your Own Workouts</h4>
          <span> Create and track your own custom workout</span>
          </div>
          <div>

          <CreateWorkout source="getting_started" />


           </div>
          </div>



      </ListGroupItem>
    )
  }

  renderListDiscoverWorkoutsDashboard = () => {
    return(
      <ListGroupItem  className="d-flex flex-row align-items-start justify-content-start" style={{border:'none'}} >

      <div>
        <User width={44} height={44} className="mr-3" style={{border:"1px solid black", "borderRadius": "50%", padding: "10px"}} />
        </div>


          <div className="d-flex flex-column justify-content-between align-items-start">
          <div>
           <h4>Discover Workouts</h4>
          <span> Join a workout routine that's right for you, and log your first activity. You workout progression will be displayed here. </span>
          </div>
          <div>
            <Button style={{width: '150px' }}className="btn mt-3 mr-3" tag={Link} to="/workouts/Popular" color="primary" size="md"> Discover Workouts </Button>

           </div>
          </div>



      </ListGroupItem>
    )
  }

  renderListLogFirst = () => {
    return(
      <ListGroupItem  className="d-flex flex-row align-items-start justify-content-start" style={{border:'none'}}>

      <div>
        <User width={44} height={44} className="mr-3" style={{border:"1px solid black", "borderRadius": "50%", padding: "10px"}} />
        </div>


          <div className="d-flex flex-column justify-content-between align-items-start">
          <div>
           <h4>Log Your First Workout!</h4>
          <span> you are all set to record your first gym session on ironroom, <strong> {this.state.api_dashboard_lti[0].day_title}! </strong> Moving ahead you can track your progress, share, grow and learn.</span>
          </div>
          <div><Button style={{width:'150px'}} className="btn mt-3" tag={Link} to="/lift_tracking" color="primary" size="md"> Record Workout </Button> </div>
          </div>



      </ListGroupItem>
    )
  }

  renderListPrivacy = () => {
    return(
      <ListGroupItem  className="d-flex flex-row align-items-start justify-content-start" style={{border:'none'}}>

      <div>
        <Lock width={44} height={44} className="mr-3" style={{border:"1px solid black", "borderRadius": "50%", padding: "10px"}} />
        </div>


          <div className="d-flex flex-column justify-content-between align-items-start">
          <div>
           <h4>Settings and Privacy</h4>
          <span> Keep your settings and privacy up to date </span>
          </div>
          <div><Button tag={Link} to="/settings/privacy" className="btn mt-3" color="primary" size="md" style={{width:'150px'}}> Review Settings </Button> </div>
          </div>



      </ListGroupItem>
    )
  }

  renderListFindFriends = () => {
    return(
      <ListGroupItem  className="d-flex flex-row align-items-start justify-content-start" style={{border:'none'}}>

      <div>
        <User width={44} height={44} className="mr-3" style={{border:"1px solid black", "borderRadius": "50%", padding: "10px"}} />
        </div>


          <div className="d-flex flex-column justify-content-between align-items-start">
          <div>
           <h4>Find Friends.</h4>
          <span> Share your progression and growth with friends, classmates and colleagues </span>
          </div>
          <div><Button tag={Link} to="/athletes" className="btn mt-3" color="primary" size="md" style={{width:'150px'}}> Find Athletes </Button> </div>
          </div>



      </ListGroupItem>
    )
  }

  renderCardForTimeLineRight = () => {
    return(
      <div>
        {this.renderCardGetApp2()}
        <Popular />
      </div>
    )
  }


  render() {
    if (this.props.source==="dashboard"){
      return(
        this.renderCardForDashboard()
      )
    }
    else if (this.props.source==="timeline_right"){
      return(
        this.renderCardForTimeLineRight()
      )
    }
    else if (this.props.source==="timeline_andfollowers" || this.props.source==="timeline_youonly"){
      return(
        this.renderCardForTimeline()
      )
    }
    return (
        this.renderCardForTimeline()
  );
 }
}

const mapStateToProps = (state) => ({
  user_status: state.auth.user,
  theme: state.theme.currentTheme,
  lift_tracking_input_data: state.general.lift_tracking_input_data,
});

export default connect(mapStateToProps, { lifttracking })(Default);
