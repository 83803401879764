import React from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import store from "../../redux/store/index";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../components/Loader";
import Following from './Following'
import {
  API_DISCOVER_PROFILES,
 } from '../../redux/actions/API';
import axios from "axios";
import { keyConfig } from '../../redux/actions/auth';
import {
  Spinner,
  Button,
} from "reactstrap";


class TimelineLazy extends React.Component {


  fetchData = async () =>  {
    if (this.props.list === "discover") {
      await axios.get(`${API_DISCOVER_PROFILES}?page=${this.state.pageNumber}`, keyConfig(store.getState)).then(res => {
        this.setState({
          api_discover_profiles: this.state.api_discover_profiles.concat(res.data.results),
          api_discover_profiles_loading: false,
          pageNumber: this.state.pageNumber + 1
        });
      }).catch((err) => {
        this.setState({
          hasMore: false,
          api_discover_profiles_loading: false,
        });
      });
    }
  }


  fetchDataSearch = async (searchvalue) =>  {

    if (this.props.list === "discover") {
      await axios.get(`${API_DISCOVER_PROFILES}?search=${searchvalue}&page=${this.state.pageNumber}`, keyConfig(store.getState)).then(res => {
        if (res.data.count === 0 ) {
          this.setState({
            hasMore: false,
            api_discover_profiles_loading: false,
          });
        }
        this.setState({
          api_discover_profiles: this.state.api_discover_profiles.concat(res.data.results),
          api_discover_profiles_loading: false,
          pageNumber: this.state.pageNumber + 1,
          search_count: res.data.count
        });
      }).catch((err) => {
        this.setState({
          hasMore: false,
          api_discover_profiles_loading: false,
        });
      });
    }
  }


  componentDidMount() {
    if (this.props.search_value) {
      this.fetchDataSearch(this.props.search_value);
    }
    else if (!this.props.search_value) {
      this.fetchData();
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      hasMore: true,
      pageNumber: 1,
      capBeforeInfiniteScroll: 10,
      flag_follow_change: null,
      api_discover_profiles:[],
      api_discover_profiles_loading: true,
      search_count: null
    };
  }


  renderFollowingCard = (x,y) => {
    return(
      <div key={y}>
          <Following
      x={x}
      index={y}
      member_id = {this.props.member_id}
      />
      </div>

    )
  }


  renderDiscover = (a) =>{
    return(
    <div>
      <h4> <strong> No Results </strong> </h4>
      <Button color="primary" size="lg" tag={Link} to="/athletes" > Discover Athletes </Button>
      </div>
    )
  }

  renderLazy = () => {

    return(
      <InfiniteScroll
  dataLength={this.state.api_discover_profiles.length} //This is important field to render the next data
  next={this.props.search_value && this.state.hasMore? this.fetchDataSearch(this.props.search_value) : this.props.search_value && !this.state.hasMore? null : this.fetchData}
  hasMore={this.state.hasMore}
  loader={<h4><div><Spinner color="primary" /></div></h4>}
  scrollableTarget="scrollableDiv"
  endMessage={
    <p style={{ textAlign: 'center' }}>
      <b>{
        this.props.user_status.hidden? "Your profile is set to hidden, nothing to show you" :
        this.props.search_value && this.state.search_count === 0? this.renderDiscover() :
        this.props.search_value && this.state.search_count !== 0? "That's All" : "You have seen it all!"
      }</b>
      </p>
  }
  >

        {this.state.api_discover_profiles.map((x,y)=>


      this.renderFollowingCard(x,y,)


      )}

      </InfiniteScroll>
    )
  }



  render() {
    if (this.state.api_discover_profiles_loading === true) {
      return <Loader />
    }
    return (
      <>
        {this.renderLazy(this.state.pageNumber)}
      </>

    );
   }
  }

const mapStateToProps = (state) => ({
  user_status: state.auth.user,
  theme: state.theme.currentTheme
});

export default connect(mapStateToProps, {  })(TimelineLazy);
