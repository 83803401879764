// local, dev, prod
let PRO_ENV = process.env.REACT_APP_STAGE

let UI_URL = null
let HOST_URL = null
let MEDIA_URL = null
let WEB_SOCKET_URL = null
let STRIPE_PUB = null

if (PRO_ENV==='local' || PRO_ENV==='mac') {
  UI_URL = "http://localhost:3000";
  HOST_URL = "http://localhost:8000/api/v1";
  MEDIA_URL = 'https://stocktok-prod.nyc3.digitaloceanspaces.com/media/ironroom_debug/';
  WEB_SOCKET_URL = "ws://localhost:8001";
  STRIPE_PUB = "pk_test_51IiCoXA7VgKP3PFMA2JRukWBN6wBA0bBxtPnZdGSdsET4Pd02Wn44MUYbjiLdCj1JdZQTm0u68T6Exih4WF2fQMm00gzeVMIDK"
}

if (PRO_ENV==='dev' || PRO_ENV==='mac-dev') {
    UI_URL = "https://ironroom-42bdc-dev.web.app/";
    HOST_URL = "https://dev.ironroom.io/api/v1";
    MEDIA_URL = 'https://stocktok-prod.nyc3.digitaloceanspaces.com/media/ironroom_debug/';
    WEB_SOCKET_URL = "wss://dev.ironroom.io";
    STRIPE_PUB = "pk_test_51IiCoXA7VgKP3PFMA2JRukWBN6wBA0bBxtPnZdGSdsET4Pd02Wn44MUYbjiLdCj1JdZQTm0u68T6Exih4WF2fQMm00gzeVMIDK"
}

if (PRO_ENV==='staging' || PRO_ENV==='mac-staging') {
    UI_URL = "https://ironroom-42dbc-staging.web.app/";
    HOST_URL = "https://staging.ironroom.io/api/v1";
    MEDIA_URL = 'https://stocktok-prod.nyc3.digitaloceanspaces.com/media/ironroom_debug/';
    WEB_SOCKET_URL = "wss://staging.ironroom.io";
    STRIPE_PUB = "pk_test_51IiCoXA7VgKP3PFMA2JRukWBN6wBA0bBxtPnZdGSdsET4Pd02Wn44MUYbjiLdCj1JdZQTm0u68T6Exih4WF2fQMm00gzeVMIDK"
}

if (PRO_ENV==='production') {
    UI_URL = "https://ironroom.io/";
    HOST_URL = "https://prod.ironroom.io/api/v1";
    MEDIA_URL = 'https://stocktok-prod.nyc3.digitaloceanspaces.com/media/ironroom_prod/';
    WEB_SOCKET_URL = "wss://prod.ironroom.io";
    STRIPE_PUB = "pk_live_51IiCoXA7VgKP3PFMD4BxwXEObohEN1dbsWjRtCV3jeGKV1Y5XGNX27OLKndnPb1jvEoT3wGKiIcAT8LuKQ9Q2HbC00fpnGxqlZ"
}





// BASE URL
export const API_UI_URL = UI_URL;
export const API_BASE_URL = HOST_URL;
export const API_BASE_MEDIA_URL = MEDIA_URL;
export const SOCKET_URL = WEB_SOCKET_URL;
export const STRIPE_PUB_KEY = STRIPE_PUB;


// AUTHENTICATION
export const API_USER = `${API_BASE_URL}/rest-auth/user`;
export const API_LOGIN = `${API_BASE_URL}/rest-auth/login/`;
export const API_REGISTER = `${API_BASE_URL}/rest-auth/registration/`;
export const API_LOGOUT = `${API_BASE_URL}/rest-auth/logout/`;
export const API_CHANGE_PWD = `${API_BASE_URL}/rest-auth/password/change/`;
export const API_PWD_RESET = `${API_BASE_URL}/rest-auth/password/reset/`;
export const API_PWD_RESET_CONFIRM = `${API_BASE_URL}/rest-auth/password/reset/confirm/`;
export const API_EMAIL_VERIFY = `${API_BASE_URL}/rest-auth/registration/account-confirm-email/`;
export const API_EMAIL_READ= `${API_BASE_URL}/email_verify/`;
export const API_GOOGLE= `881293883312-q8td7up58h7ac7d1gumb3fh5dnn84e18.apps.googleusercontent.com`;
export const API_GOOGLE_SIGNUP= `${API_BASE_URL}/rest-auth/google_signup/`;
export const API_GOOGLE_LOGIN= `${API_BASE_URL}/rest-auth/google_login/`;
export const API_FACEBOOK_LOGIN= `${API_BASE_URL}/rest-auth/facebook_login/`;
export const API_FACEBOOK_SIGNUP= `${API_BASE_URL}/rest-auth/facebook_signup/`;
export const API_APPLE_LOGIN= `${API_BASE_URL}/rest-auth/apple_login/`;







// USER
export const API_USER_AVATAR = `${API_BASE_URL}/user_avatar/`;

// EXERCISES
export const API_EXERCISE_LIST = `${API_BASE_URL}/exercises_detail`;
export const API_EXERCISE_LIST_CUSTOM = `${API_BASE_URL}/exercises_detail_custom`;
export const API_EXERCISE_LIST_CUSTOM_PATCH = `${API_BASE_URL}/exercises_detail_custom_patch`;
export const API_EXERCISE_LIST_IRONROOM = `${API_BASE_URL}/exercises_detail_ironroom`;
export const API_EXERCISE_CREATE = `${API_BASE_URL}/exercises_create/`;
export const API_EXERCISE_TIMESERIES = `${API_BASE_URL}/exercises_timeseries`;
export const API_EXERCISE_YOUTUBE = `${API_BASE_URL}/exercises_youtube/?exercise=`;
export const API_EXERCISE_BODY_GROUP = `${API_BASE_URL}/exercises_body_group`;
export const API_EXERCISE_MUSCLE_GROUP = `${API_BASE_URL}/exercises_muscle_group`;
export const API_EXERCISE_MUSCLE_SUB_GROUP = `${API_BASE_URL}/exercises_muscle_sub_group`;
export const API_EXERCISE_PUSH_PULL = `${API_BASE_URL}/exercises_push_pull`;
export const API_EXERCISE_TRAINING_LEVEL = `${API_BASE_URL}/exercises_training_level`;
export const API_EXERCISE_MODALITY = `${API_BASE_URL}/exercises_modality`;
export const API_EXERCISE_JOINT = `${API_BASE_URL}/exercises_joint`;
export const API_EXERCISE_DEFAULT_IMAGE = `${API_BASE_URL}/exercises_default_image`;



// ENROLLMENT HISTORY
export const API_ENROLLMENT_HISTORY = `${API_BASE_URL}/enrollment_history/`;
export const API_ENROLLMENT_HISTORY_RESUME_DATE = `${API_BASE_URL}/enrollment_history_resume_date/ `;

// WORKOUTS
export const API_WORKOUTS_DETAIL = `${API_BASE_URL}/workouts_detail/`;
export const API_WORKOUTS_CUSTOM_LIST = `${API_BASE_URL}/workouts_custom_list/`;
export const API_WORKOUTS_CATEGORY = `${API_BASE_URL}/workouts_category/`;
export const API_WORKOUTS_CATEGORY_PUBLIC = `${API_BASE_URL}/workouts_category_public/`;
export const API_WORKOUTS_POPULAR = `${API_BASE_URL}/dashboard_popular_workouts/`;
export const API_WORKOUTS_CREATE = `${API_BASE_URL}/workouts_create/`;
export const API_WORKOUTS_CREATE_QUICK = `${API_BASE_URL}/workouts_quick_create/`;
export const API_WORKOUTS_CREATE_FULL = `${API_BASE_URL}/workouts_full_create/`;
export const API_WORKOUTS_VIEW_FULL = `${API_BASE_URL}/workouts_full_view/?workout_day_xref=`;
export const API_WORKOUTS_VIEW_FULL_DETAIL = `${API_BASE_URL}/workouts_full_view/`;
export const API_WORKOUTS_CREATE_FULL_SET = `${API_BASE_URL}/workouts_full_set_create/`;


export const API_WORKOUTS_TRAINING_LEVEL = `${API_BASE_URL}/workouts_training_level/`;
export const API_WORKOUTS_GOAL = `${API_BASE_URL}/workouts_goal/`;
export const API_WORKOUTS_DAYS = `${API_BASE_URL}/workouts_days/`;
export const API_WORKOUTS_WEEKS = `${API_BASE_URL}/workouts_weeks/`;
export const API_WORKOUTS_SETS = `${API_BASE_URL}/workouts_sets/`;
export const API_WORKOUTS_DEFAULT_IMAGE = `${API_BASE_URL}/workouts_default_image/`;


// DASHBOARD
export const API_DASHBOARD_GRAPH = `${API_BASE_URL}/dashboard_graph/`;
export const API_DASHBOARD_TABLE = `${API_BASE_URL}/dashboard_table/`;
export const API_DASHBOARD_TYCD = `${API_BASE_URL}/dashboard_things_you_can_do/`;
export const API_DASHBOARD_PW = `${API_BASE_URL}/dashboard_popular_workouts/`;

// LIFT TRACKER
export const API_LIFT_TRACKER_INPUT = `${API_BASE_URL}/lift_tracker_input/`;
export const API_EXERCISE_TRACKER = `${API_BASE_URL}/exercise_tracker/`;
export const API_ONE_REP_MAX_TRACKER = `${API_BASE_URL}/exercise_one_rep_max_tracker_all/`;
export const API_LIFT_TRACKER_WORKOUT_SESSION = `${API_BASE_URL}/lift_tracker_workout_sesion/`;
export const API_LIFT_TRACKER_WORKOUT_SESSION_TABLE = `${API_BASE_URL}/lift_tracker_workout_sesion_table/`;
export const API_LIFT_TRACKER_EXERCISE_LOG = `${API_BASE_URL}/lift_tracker_workout_exercise_log/`;
export const API_LIFT_TRACKER_WORKOUT_FORM = `${API_BASE_URL}/workout_form/`;

// LANDING
export const API_LANDING_INSTAGRAM_PUBLIC = `${API_BASE_URL}/instagram_public/`;
export const API_LEGAL = `${API_BASE_URL}/legal/`;
export const API_PRIVACY = `${API_BASE_URL}/privacy/`;
export const API_COPYRIGHT = `${API_BASE_URL}/copyright/`;
export const API_COPYRIGHT_MOBILE = `${API_BASE_URL}/copyright_mobile/`;
export const API_HELP_CENTER = `${API_BASE_URL}/help_center/`;

// REPORTS
export const API_REPORTS_LIFT_HISTORY = `${API_BASE_URL}/reports_lift_history/`;

// TIMELINE
export const API_TIMELINE = `${API_BASE_URL}/timeline/`;
export const API_TIMELINE_ALL_USER = `${API_BASE_URL}/timeline_all_user/?user_id_xref=`;
export const API_TIMELINE_ALL_USER_ID = `${API_BASE_URL}/timeline_all_user_id/?user_id_xref=`;
export const API_TIMELINE_ALL_USER_NORM = `${API_BASE_URL}/timeline_all_user/`;
export const API_TIMELINE_ALL_USER_NORM_ID = `${API_BASE_URL}/timeline_all_user_id/`;
export const API_TIMELINE_YOU_AND_FOLLOWING = `${API_BASE_URL}/timeline_you_and_following/`;
export const API_TIMELINE_YOU_AND_FOLLOWING_ID = `${API_BASE_URL}/timeline_you_and_following_id/`;
export const API_TIMELINE_LIKES = `${API_BASE_URL}/timeline_likes/`;
export const API_TIMELINE_COMMENTS = `${API_BASE_URL}/timeline_comments/`;
export const API_TIMELINE_REPLY_COMMENTS = `${API_BASE_URL}/timeline_reply_comments/`;
export const API_TIMELINE_EVENT_COUNTS = `${API_BASE_URL}/timeline_event_counts/`;
export const API_TIMELINE_GETTING_STARTED = `${API_BASE_URL}/timeline_gettings_started/`;
export const API_TIMELINE_EXERCISE_ALT = `${API_BASE_URL}/timeline_exercise_alt/`;



// PROFILES
export const API_DISCOVER_PROFILES = `${API_BASE_URL}/discover_profiles/`;
export const API_USER_PROFILES = `${API_BASE_URL}/user_profiles/`;
export const API_USER_PROFILES_MY_PROFILE = `${API_BASE_URL}/user_profiles_my_profile/`;
export const API_USER_PROFILES_EXTRA = `${API_BASE_URL}/user_profiles_extra/`;
export const API_USER_PROFILES_SUBSCRIBED = `${API_BASE_URL}/user_profiles_subscribed/`;
export const API_USER_PROFILES_FILTER = `${API_BASE_URL}/user_profiles/?id=`;
export const API_USER_PROFILES_SEARCH = `${API_BASE_URL}/user_profiles/?search=`;

// PROFILES: AVATAR PHOTO
export const API_USER_AVATAR_PHOTO = `${API_BASE_URL}/user_avatar_photo/`;
export const API_USER_AVATAR_PHOTO_MASTER = `${API_BASE_URL}/user_avatar_photo_master/`;

// PROFILES: FIRSTNAME LASTNAME
export const API_USER_FIRSTNAME_LASTNAME = `${API_BASE_URL}/user_firstname_lastname/`;
export const API_USER_HEADING = `${API_BASE_URL}/user_heading/`;

// PROFILES: BIRTHDAY
export const API_USER_BIRTHDAY = `${API_BASE_URL}/user_birthday/`;
export const API_BIRTHDAY_MONTH_CHOICES = `${API_BASE_URL}/birthday_month/`;
export const API_BIRTHDAY_YEAR_CHOICES = `${API_BASE_URL}/birthday_year/`;

// PROFILES: GENDER
export const API_USER_GENDER = `${API_BASE_URL}/user_gender/`;
export const API_GENDER_CHOICES = `${API_BASE_URL}/gender_list/`;

//PROFILES: LOCATION
export const API_USER_LOCATION = `${API_BASE_URL}/user_location/`;
export const API_USER_LOCATION_MASTER = `${API_BASE_URL}/user_location_master/`;
export const API_LOCATION_CITY = `${API_BASE_URL}/location_city/`;
export const API_LOCATION_REGION = `${API_BASE_URL}/location_region/`;
export const API_LOCATION_COUTNRY = `${API_BASE_URL}/location_country/`;

//PROFILES: IP
export const API_IP_SIGNUP= `${API_BASE_URL}/ip_signup/`;


//ALBBUMS
export const API_USER_PHOTOS_ALBUMS = `${API_BASE_URL}/albums/`;
export const API_USER_PHOTOS_ALBUMS_EMPTY = `${API_BASE_URL}/albums_no_photo/`;
export const API_USER_PHOTOS_ALBUMS_MASTER = `${API_BASE_URL}/albums_master/?user_id_xref=`;
export const API_USER_PHOTOS_ALBUMS_MASTER_NORMAL = `${API_BASE_URL}/albums_master/`;
export const API_USER_PHOTOS_PHOTOS = `${API_BASE_URL}/photos/`;
export const API_USER_PHOTOS_PHOTOS_MASTER = `${API_BASE_URL}/photos_master/`;
export const API_USER_PHOTOS_PHOTOS_LIKES = `${API_BASE_URL}/photos_likes/`;
export const API_USER_PHOTOS_PHOTOS_COMMENTS = `${API_BASE_URL}/photos_comments/`;
export const API_USER_PHOTOS_PHOTOS_REPLY_COMMENTS = `${API_BASE_URL}/photos_reply_comments/`;


//FOLLOWING
export const API_USER_FOLLOWING = `${API_BASE_URL}/user_following/?user_id_xref=`;
export const API_USER_FOLLOWERS = `${API_BASE_URL}/user_followers/?followed_user=`;
export const API_USER_FOLLOWING_MASTER = `${API_BASE_URL}/user_following_master/`;
export const API_USER_FOLLOWING_MASTER_FILTER_OPEN = `${API_BASE_URL}/user_following_master_filter/`;

//BLOCKING
export const API_USER_BLOCK_MASTER = `${API_BASE_URL}/user_blocked/`;

//CHATS
export const API_CHATS = `${API_BASE_URL}/chat/`;
export const API_CHATS_RD = `${API_BASE_URL}/chat_rd/`;

//ONBOARDING IMAGES
export const API_ONBOARDING_IMAGES = `${API_BASE_URL}/onboarding_images/?onboarding_step=`;


//NOTIFICATIONS
export const API_NOTIFICATIONS_BELL = `${API_BASE_URL}/notification_flag/`;
export const API_NOTIFICATIONS = `${API_BASE_URL}/notifications/`;


//BODY TRACKING
export const API_BODY_WEIGHT = `${API_BASE_URL}/body_weight/`;
export const API_BODY_HEIGHT = `${API_BASE_URL}/body_height/`;


//ANALYTICS
export const API_ANALYTICS_REP_COUNT = `${API_BASE_URL}/rep_count/?`;
export const API_ANALYTICS_SESSION_COUNT = `${API_BASE_URL}/session_count/?`;
export const API_ANALYTICS_EXERCISE_COUNT = `${API_BASE_URL}/exercise_count/?`;
export const API_ANALYTICS_VOLUME_MOVED = `${API_BASE_URL}/volume_moved/?`;
export const API_ANALYTICS_AVERAGE_TIME = `${API_BASE_URL}/average_time/?`;
export const API_ANALYTICS_TOTAL_TIME = `${API_BASE_URL}/total_time/?`;
export const API_ANALYTICS_EXERCISE_TABLE = `${API_BASE_URL}/exercise_table/?`;
export const API_ANALYTICS_MUSCLE_GROUP = `${API_BASE_URL}/muscle_group/?`;
export const API_ANALYTICS_THIS_WEEK = `${API_BASE_URL}/this_week/?`;
export const API_ANALYTICS_WORKOUT_PROGRAMS = `${API_BASE_URL}/workout_programs/?`;
export const API_ANALYTICS_VOLUME_TIMESERIES = `${API_BASE_URL}/volume_timeseries/?`;
export const API_ANALYTICS_VOLUME_HEAT_MAP = `${API_BASE_URL}/heat_map/?`;


//PAYMENT
export const API_SUBSCRIBE_STRIPE = `${API_BASE_URL}/stripe/`;
export const API_SUBSCRIBE_STRIPE_GET = `${API_BASE_URL}/stripe_get/?session_id=`;
export const API_SUBSCRIBE_STRIPE_BASE = `https://api.stripe.com/`;
export const API_SUBSCRIBE_STRIPE_GET_SUBSCRIBE = `${API_BASE_URL}/stripe_get_subscribe/?customer_id=`;
export const API_SUBSCRIBE_STRIPE_GET_SUBSCRIBE_UPCOMING = `${API_BASE_URL}/stripe_get_subscribe_upcoming/?customer_id=`;
export const API_SUBSCRIBE_STRIPE_SUBSCRIBE_HISTORY = `${API_BASE_URL}/stripe_get_subscribe_history/?customer_id=`;
export const API_SUBSCRIBE_STRIPE_SUBSCRIBE_DELETE = `${API_BASE_URL}/stripe_get_subscribe_delete/?sub_id=`;
export const API_SUBSCRIBE_STRIPE_SUBSCRIBE_REFUND = `${API_BASE_URL}/stripe_get_subscribe_refund/?payment_id=`;
export const API_SUBSCRIBE_STRIPE_REFUND_HISTORY = `${API_BASE_URL}/stripe_get_refund_history/?payment_id=`;
export const API_SUBSCRIBE_STRIPE_GET_CHARGE = `${API_BASE_URL}/stripe_get_charge/?charge_id=`;
export const API_SUBSCRIBE_STRIPE_REFUND_TIME = `${API_BASE_URL}/refund_time`;
export const API_SUBSCRIBE_PLAY_VALIDATE = `${API_BASE_URL}/play_validate/`;
