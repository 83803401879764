import React from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import store from "../../../redux/store/index";
import axios from "axios";
import { keyConfig } from '../../../redux/actions/auth';
import {
  Card,
  Table,
  CardText,
  CardBody,
  CardHeader,
  CardTitle,
} from "reactstrap";

import {
  API_TIMELINE_ALL_USER_NORM,
} from '../../../redux/actions/API';


import ProfilePhoto from '../../../components/Social/ProfilePhoto/ProfilePhoto';
import ModalLikes from '../../../components/Social/Timeline/ModalLikes';
import LikeCommentsBar from '../../../components/Social/LikeCommentsBar/LikeCommentsBar';
import LikeCommentsSummary from '../../../components/Social/LikeCommentsSummary/LikeCommentsSummary';
import Comments from '../../../components/Social/Comments/Comments';


class Feed extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      flag_comment_spinner_value: 0,
      flag_like_spinner_value: 0,
      reload_page_value: 0,

      myValue: '',
      commentSpin: 0,
      initialComments: this.props.comments,
      likeSpin: 0,
      initialLikes: this.props.likes,
      split: 0,
      openComment: 0,
    };
  }


  fetchTimelineID = () =>  {
    axios.get(`${API_TIMELINE_ALL_USER_NORM}${this.props.timeline_id}`, keyConfig(store.getState)).then(res => {

        this.setState({
          data: res.data,
          flag_comment_spinner_value: 2,
          flag_like_spinner_value: 2,
        });

    });
  }



componentDidUpdate() {
  if(this.state.reload_page_value !==0) {
    this.fetchTimelineID()
    this.setState({reload_page_value:0})
  }
}




  onChange = async (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      comment_value: e.target.value,
      myValue: e.target.value
    });
  }


  renderHeaderTalbe = () => {
    return(
      <Table size="sm" className="my-2 text-left">
        <tbody>
          <tr>
            <th>Time</th>
            <td>{this.props.exercise_data.derived__historic_datetime}</td>
          </tr>
          <tr>
            <th>Workout</th>
            <Link to={"/workouts/detail/" + this.props.exercise_data.workout_day_xref.routine_xref.id}>
            <td >{this.props.exercise_data.workout_day_xref.routine_xref.routine}</td>
            </Link>
          </tr>
          <tr>
            <th>Session</th>
            <td>{this.props.exercise_data.workout_day_xref.day_title}</td>
          </tr>
          <tr>
            <th>progres</th>
            <td>pending</td>
          </tr>
        </tbody>
      </Table>
    )
  }


  openCommentWindow = () => {
    if (this.state.openComment === 0) {
      this.setState({openComment: 1})
    }
  }



  renderMainHeaderNew = () => {
      return(
        <>




          <div className=" d-flex flex-row justify-content-start">
            <ProfilePhoto
              avatar_image={this.props.user_data.avatar_image}
              avatar_color = {this.props.user_data.avatar_color}
              avatar_letter = {this.props.user_data.avatar_letter}
              avatar_size={"128px"}
              letter_size={"56px"}
              />


              <div className="ml-3 d-flex flex-column justify-content-start">
              <CardHeader tag="h3" className="m-0 p-0 pt-2 text-strong text-left">
                {this.props.exercise_data.derived__part_of_day}
              </CardHeader>

              <CardText>
                  <p>{this.props.exercise_data.derived__historic_datetime}</p>
              </CardText>
              </div>



            </div>







        </>
      )
  }

  renderCardBaseLikedBy = () => {
    if (this.props.data.like_count === 1) {
      return (
      <small className="float-left text-navy">
        Liked by {this.props.data.likes.map((a,b) => <Link key={b} to={'/profile/'+a.liking_user_id_xref.id+'/home' }> {a.liking_user_id_xref.first_name +' '+ a.liking_user_id_xref.last_name} </Link>)}
      </small>
      )
    }
    else if (this.props.data.like_count === 2) {
      return (
      <small className="float-left text-navy">
        Liked by
        <Link to={'/profile/'+this.props.data.likes[0].liking_user_id_xref.id+'/home' }>
          {' ' + this.props.data.likes[0].liking_user_id_xref.first_name +' '+this.props.data.likes[0].liking_user_id_xref.last_name+ ' '}
        </Link>
        and
        <Link to={'/profile/'+this.props.data.likes[1].liking_user_id_xref.id+'/home' }>
          {' '+ this.props.data.likes[1].liking_user_id_xref.first_name +' ' +this.props.data.likes[1].liking_user_id_xref.last_name}
        </Link>
      </small>
      )
    }
    else if (this.props.data.like_count > 2) {
      return (
      <small className="float-left text-navy">
        Liked by
        <Link to={'/profile/'+this.props.data.likes[0].liking_user_id_xref.id+'/home' }>
          {' ' + this.props.data.likes[0].liking_user_id_xref.like_display_name},
        </Link>
        <Link to={'/profile/'+this.props.data.likes[1].liking_user_id_xref.id+'/home' }>
          {' '+ this.props.data.likes[1].liking_user_id_xref.like_display_name},
        </Link>
        {' '}and <ModalLikes likes = {this.props.data.likes} count={this.props.data.like_count-2} word = {this.props.data.like_count ===3 ? "other" : "others"} />
      </small>
      )
    }
}



handlerReload = (val) => {
  this.setState({
    reload_page_value: val
  })
}

handlerCommentSpinner = (val) => {
  this.setState({
    flag_comment_spinner_value: val
  })
}



handlerLikeSpinner = (val) => {
  this.setState({
    flag_like_spinner_value: val
  })
}

  render() {
    return (
      <>

      <Card className="flex-fill w-100">
        <CardHeader>

        <CardTitle tag="h5" className="mb-0">
          Discussion
        </CardTitle>


        </CardHeader>
        <hr className="p-0 m-0"/>

        <CardHeader className="bg-light d-flex flex-row justify-content-between align-items-center pb-0 pt-1">

        <div className="float-right flex d-flex justify-content-center align-items-center">
        <LikeCommentsBar
          x={this.state.data}
          handlerOpenCommentWindow = {this.openCommentWindow}
          callbackPageReload = {this.handlerReload}
          index = {1}
          source = {"timeline_detail"}
          flag_like_spinner_value = {this.props.flag_like_spinner_value}
          flag_like_spinner_handler = {this.handlerLikeSpinner}
          />

          <div className="ml-2">
          <LikeCommentsSummary
            x={this.state.data}
            handlerOpenCommentWindow = {this.openCommentWindow}
            />
            </div>



          </div>


          </CardHeader>

        <CardBody className="mt-0 pt-1">

        <Comments
          x={this.state.data}
          callbackPageReload = {this.handlerReload}
          index = {1}
          flag_comment_spinner_value = {this.state.flag_comment_spinner_value}
          flag_comment_spinner_handler = {this.handlerCommentSpinner}
          source = {"timeline_detail"}
          />


        </CardBody>
      </Card>


      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user_status: state.auth.user,
});

export default connect(mapStateToProps, {  })(Feed);
