import React from "react";
import { Redirect } from 'react-router-dom';
import {  withRouter} from 'react-router-dom';
import Select from 'react-select'
import { connect } from "react-redux";
import store from "../../redux/store/index";
import ApiLiftTracker from '../../config/lift_tracking';
import {
  API_EXERCISE_LIST,
  API_WORKOUTS_VIEW_FULL
  } from '../../redux/actions/API';
import axios from "axios";
import { keyConfig } from '../../redux/actions/auth';
import {
  Button,
  Row,
  Col,
  Spinner,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput,
} from 'reactstrap';

class AddExerciseBody extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      rowArray: [0],
      rowCount: 0,
      selectedOption: null,

      loading: false,
      id: null,

      weight_level_0: 60,
      min_reps_0: 8,
      max_reps_0: 12,
      AMRAC0: false,

      api_exercise_list: [],
      api_exercise_list_loading: true,

      full_order: null
    };
  }





  createFullSet = async (dataFull) => {
    var i
    for (i = 0; i < this.state.rowCount+1; i++) {
      console.log(this.state["min_reps_"+i])
      await ApiLiftTracker.workoutFullSetCreate(
      dataFull.id,
      i+1, //set_xref
      this.state["min_reps_"+i]? this.state["min_reps_"+i] : 8,
      this.state["AMRAC"+i]? 999: this.state["max_reps_"+i] < this.state["min_reps_"+i]? null: !this.state["AMRAC"+i] && this.state["max_reps_"+i] === 999? null: this.state["max_reps_"+i] === ""? null:this.state["max_reps_"+i],
      !this.props.workouts_detail[0].training_max_exercise? null: this.state["weight_level_"+i]? this.state["weight_level_"+i] : 50,
      i+1,  //order
      (success, data) => {
      if ( success) {
        console.log('success')
      } else {
        console.log('fail')
      }
    });
      if (i === this.state.rowCount){
        this.props.fetchWorkouts(this.props.match.params.workoutID)
        this.props.toggle()
    }
    }
  }

  onSubmit = async (e) => {
    await e.preventDefault();
    await this.setState({loading:true})
    await ApiLiftTracker.workoutFullCreate(
      this.props.match.params.workoutID,
      this.props.workout_id,
      this.state.selectedOption.id,
      this.state.full_order, // order
      (success, data) => {
      if ( success) {
        console.log('success')
        this.createFullSet(data)

      } else {
        console.log('fail')
      }
    });
  };

  fetchExercise = async () =>  {

      await axios.get(`${API_EXERCISE_LIST}`, keyConfig(store.getState)).then(res => {
        this.setState({
            api_exercise_list: res.data.map(({ id,exercise }) => {return{value: exercise, label: exercise, id: id }}),
            api_exercise_list_loading: false,
        });
      }).catch((err) => {
          this.setState({
            api_exercise_list: [],
            api_exercise_list_loading: false,
          });
        });

    }

  fetchData = async () =>  {
    console.log(`${API_WORKOUTS_VIEW_FULL}${this.props.workout_id}`)
    await axios.get(`${API_WORKOUTS_VIEW_FULL}${this.props.workout_id}`, keyConfig(store.getState)).then(res => {
      console.log('aa')
      console.log(res.data)
      console.log('aa')
      this.setState({
          full_order: res.data.length + 1
      });
    }).catch((err) => {
        console.log(err)
      });
  }

  componentDidMount() {
    console.log(this.props.match.params.workoutID)
    console.log(this.props.workout_id)
    console.log('ee')
    console.log(this.props.workouts_detail[0].training_max_exercise)
    console.log('ee')
    this.fetchExercise()
    this.fetchData()
  }

  onChange = (e) => {
    if(e.target.name === "max_reps"){
      this.setState({max_reps: e.target.value})
    }
    else if(e.target.name === "min_reps"){
      this.setState({min_reps: e.target.value})
    }
    else if(e.target.name === "ABC"){
      if(this.state.amayc) {
        this.setState({amayc: false})
      }
      else if(!this.state.amayc) {
        this.setState({amayc: true})
      }
    }
  }

  handleExerciseChange = (selectedOption: any,) => {
    this.setState({ selectedOption });
    console.log(selectedOption)
};

  addSet = async () => {
    await this.setState({rowCount: this.state.rowCount+1})
    await this.setState({
      ['weight_level_'+this.state.rowCount] : 60,
      ['min_reps_'+this.state.rowCount] : 8,
      ['max_reps_'+this.state.rowCount] : 12,
      ['AMRAC'+this.state.rowCount] : false
    })
    await this.setState({ rowArray:  this.state.rowArray.concat([this.state.rowCount]) })
    await console.log(this.state.rowArray)
  }

  onChangeCheck = (b,name) => {
    console.log(b)
    console.log(name)
    console.log(this.state["AMRAC"+b])
      if(this.state["AMRAC"+b]) {
        this.setState({["AMRAC"+b]: false})
      }
      else if(!this.state["AMRAC"+b]) {
        this.setState({["AMRAC"+b]: true})
      }
    }

  renderSet = (a,b,c) => {
    return(
      <Col md={c}>
        {a ===0?<Label>Set #</Label> : null}
        <p> {b+1} </p>
      </Col>
    )
  }

  renderWeightLevel = (a,b,c) => {
    return(
      <Col md={c}>
        {a ===0?<Label>Weight Level (%)</Label>:null}

        <Input
          type="number"
          name={"weight_level_"+b}
          id={"weight_level_"+b}
          min="10"
          max="90"
          required
          value={this.state['weight_level_'+b]}
          onChange={(e) => this.setState({['weight_level_'+b] : e.target.value < 0 || e.target.value ===null ? 10 : e.target.value > 100? 100 : e.target.value})}
          className={this.state["weight_level_"+b] !== null && this.state["weight_level_"+b] !== 0? "border-success mb-2 " : "mb-2" }
          />
      </Col>
    )
  }

  renderMinRep = (a,b,c) => {
    return(
      <Col md={c}>
        {a ===0?<Label>Minium Reps</Label>:null}

        <Input
          type="number"
          name={"min_reps_"+b}
          id={"min_reps_"+b}
          min="3"
          max="20"
          required
          value={this.state['min_reps_'+b]}
          onChange={(e) => this.setState({['min_reps_'+b] : e.target.value <0? 2 : e.target.value > 100? 100 : e.target.value})}
          className={this.state["min_reps_"+b] !== null && this.state["min_reps_"+b] !== 0? "border-success mb-2 " : "mb-2" }
          />
      </Col>
    )
  }

  renderMaxRep = (a,b,c) => {
    return(
      <Col md={c}>
        {a ===0?<Label>Maximum Reps (Optional)</Label>:null}

        <Input
          type="number"
          name={"max_reps_"+b}
          id={"max_reps_"+b}
          min="8"
          max="30"
          disabled={this.state["AMRAC"+b]? true: false}
          value={this.state["AMRAC"+b]? 999 : this.state['max_reps_'+b]}
          onChange={(e) => this.setState({['max_reps_'+b] : e.target.value < 0? 2: e.target.value>100? 100 : e.target.value})}
          className="mb-2"
          />
      </Col>
    )
  }

  renderAMRC = (a,b,c) => {
    return(



                <Col md={c}>
            {a ===0?<Label>As Many Reps As You Can?</Label>:null}


            <FormGroup>
              <CustomInput
                checked={this.state["AMRAC"+b]}
                type="checkbox"
                name={"AMRAC"+b}
                id={"AMRAC"+b}
                label=""
                onChange={e => this.onChangeCheck(b,e.target.name)}
                />
            </FormGroup>


              </Col>
    )
  }

  renderFormBody = (a,b) => {
    return(
      <FormGroup key={b}>
      {this.props.workouts_detail[0].training_max_exercise?
      <Row>

      {this.renderSet(a,b,1)}
      {this.renderWeightLevel(a,b,2)}
      {this.renderMinRep(a,b,3)}
      {this.renderMaxRep(a,b,3)}
      {this.renderAMRC(a,b,3)}

          </Row>

          :

          <Row>

          {this.renderSet(a,b,1)}
          {this.renderMinRep(a,b,4)}
          {this.renderMaxRep(a,b,4)}
          {this.renderAMRC(a,b,3)}

              </Row>
            }
      </FormGroup>
    )
  }

  render() {
    if (
      this.state.api_muscle_group_loading ||
      this.state.api_muscle_sub_group_loading
    ) {
      return(
        <div className="flex d-flex justify-content-center align-items-center">
        <Spinner color="primary" />
        </div>
      )
    }
    else if (this.props.match.params.exerciseID && this.state.id !== null){
      return(
      <Redirect to={{pathname: "/exercises"}} />
      )
    }
    else if (this.state.id !== null){
      return(
      <Redirect to={{pathname: "/exercises/detail/" + this.state.id }} />
      )
    }
    return (
      <div>
        <ModalBody>
            <Form>

              <FormGroup>
                <Label>Exercise Name</Label>

                <Select


                  value={this.state.selectedOption}
                  classNamePrefix="react-select"
                  options={this.state.api_exercise_list}
                  onChange={this.handleExerciseChange}
                  placeholder= "Select An Exercise"
                  openMenuOnClick={false}
                  onInputChange={this.handleLocationInputChange}
                  />


              </FormGroup>

              {this.state.rowArray.map((a,b) =>
                this.renderFormBody(a,b)
              )}


            </Form>
        </ModalBody>

        <ModalFooter className={this.state.rowCount >= 8 ? "modal-footer" : "modal-footer justify-content-between"}>

        {this.state.rowCount >= 8 ? null :
          <Button color="secondary" onClick={this.addSet} className="float-left" >Add Set</Button>}

          <div>
          <Button color="secondary" onClick={this.props.toggle}>Cancel</Button>{' '}

          {this.state.selectedOption === null?
            <Button style={{width:'110px'}} className="disabled" color="primary" > Create </Button>
            :
            this.state.loading?
            <Button style={{width:'110px'}} className="disabled" color="primary" > <Spinner color="light" size="sm" /> Create </Button>
            :
            (this.state.min_reps_0 !== null && this.state.min_reps_0 > 0) ?
            <Button style={{width:'110px'}} color="primary" onClick = {this.onSubmit}> Create </Button>
            :
            <Button style={{width:'110px'}} className="disabled" color="primary" > Create </Button>
          }
          </div>
        </ModalFooter>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user_status: state.auth.user,
  auth: state.auth,
  sidebar: store.sidebar,
});

export default connect(mapStateToProps, {  })(withRouter(AddExerciseBody));
