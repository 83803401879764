import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
} from "reactstrap";
import { connect } from 'react-redux';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp, faCommentAlt } from "@fortawesome/free-regular-svg-icons";
import { timelineLikes } from '../../../redux/actions/social';
import { postPhotoLike } from '../../../redux/actions/album';
import store from "../../../redux/store/index";
import LoaderSpin from './LoaderSpin';




async function SendLike(cardid, userid, changeflag, pageReloadNumber) {
  const result = await store.dispatch(timelineLikes(cardid, userid));
  if (result.status === 200 || result.status === 201) {
      await changeflag(pageReloadNumber)
  }
 }


 async function PostPhotoLike(photoID, userid, reload_flag, spinner_flag, flag_photo_scroll) {
   const result = await store.dispatch(postPhotoLike(photoID, userid));
   if (result.status === 200 || result.status === 201) {
    await flag_photo_scroll(1);
     await reload_flag(2);
     await spinner_flag(1);
   }
  }


class Timeline_Feed extends React.Component {


  onLike = async (cardid, indexid) => {
    if (this.props.source === "photo") {
      PostPhotoLike(
        this.props.x.id,
        this.props.user_status.pk,
        this.props.callbackPageReload,
        this.props.flag_like_spinner_handler,
        this.props.handlerLikeChangePhotosReload
      );
    }
    else {
      SendLike(
      cardid,
      this.props.user_status.pk,
      this.props.callbackPageReload,
      indexid
    );
    }
    this.setState({
      likeSpin: 1
    });
  };



  constructor(props) {
    super(props);
    this.state = {
      api_user_profiles: [],
      comment: null,
      comment_id: null,
      myValue_0: '',
      commentSpin_0: 0,
      openComment: 0,
      likeSpin: 0,
    };
  }


  componentDidUpdate() {
    if (this.props.flag_like_spinner_value === 2) {
      this.setState({
        likeSpin: 0,
      });
      this.props.flag_like_spinner_handler(0)
    }
  }


  openCommentWindow = () => {
    this.props.handlerOpenCommentWindow()
    if (this.state.openComment === 0) {
      this.setState({openComment: 1})
    }
  }


  renderKudosButton = (x) => {
    return(
    <>
      {( (this.props.source !== "photo" && x.you_liked === true) || (this.props.source==="photo" && this.props.you_liked === true) || (this.props.source==="photo" && x.you_liked===true))?

        <Button color="primary" outline className="btn btn-pill" size="sm" disabled>
          <FontAwesomeIcon icon={faThumbsUp} fixedWidth /> Kudos
        </Button>
        :
      this.state.likeSpin===0?
        <Button color="primary" outline className="btn btn-pill" size="sm" onClick={() => this.onLike(x.id,this.props.index)} >
          <FontAwesomeIcon icon={faThumbsUp} fixedWidth /> Kudos
        </Button>
        :
        <Button color="primary" outline disabled className="btn btn-pill" size="sm"> <LoaderSpin/></Button>
      }
    </>
    )
  }


  rendercommentButton = (x) => {
    if (this.props.source==="timeline_detail"){
      return(
        null
      )
    }
    return(
      <Button color="primary" outline className="btn btn-pill ml-1" size="sm" onClick={() => this.openCommentWindow()}>
        <FontAwesomeIcon icon={faCommentAlt} fixedWidth /> Comment
      </Button>
    )
  }



  renderOtherButton = (x) => {
    if(this.props.source==="timeline") {
      return(
        <Button color="primary" outline className="btn btn-pill ml-1 float-right" size="sm" tag={Link} to={this.props.link}>
            View
        </Button>
      )
    }
    else if(this.props.source2==="photo_modal") {
      return(
        <Button color="primary" outline className="btn btn-pill ml-1 float-right" size="sm" tag={Link}
          to={'/profile/' + x.user_id_xref + '/photos_alt/' + x.id }>
            View
        </Button>
      )
    }
  }

  renderKudosCommentTabs = (x) => {
    return(
      <div>
        {this.props.source !=="timeline_detail"?
        <hr className="mt-0 mb-1"/>
        :
        null
        }

        {this.renderKudosButton(x)}
        {this.rendercommentButton(x)}
        {this.renderOtherButton(x)}
      </div>

    )
  }


  render() {
    return (
      <div>
        {this.renderKudosCommentTabs(this.props.x)}
      </div>
    );
   }
}

const mapStateToProps = (state) => ({
  user_status: state.auth.user,
  theme: state.theme.currentTheme
});

export default connect(mapStateToProps, {  })(Timeline_Feed);
