import React from "react";
import { Link } from "react-router-dom";
import Loader from "./Loader";
import { connect } from 'react-redux';
import store from "../../../redux/store/index";
import TimelineFeed from './TimelineFeed'
import StatusNewYouAndFollowers from '../StatusNewYouAndFollowers'
import {
  API_TIMELINE,
  API_TIMELINE_YOU_AND_FOLLOWING,
  API_TIMELINE_ALL_USER,
  API_TIMELINE_ALL_USER_NORM,
 } from '../../../redux/actions/API';
import axios from "axios";
import { keyConfig } from '../../../redux/actions/auth';
import {
  timeline_you_only,
  timeline_you_and_following,
  timeline_member_only,
  timeline_album_only
} from '../../../redux/actions/general';
import InfiniteScroll from "react-infinite-scroll-component";
import {  Spinner, Card, CardBody, Button, CardImg } from "reactstrap";
import AlbumsNotFound from "../../../pages/albums/Album/Index";
import empty_gym from "./empty_gym.jpg";
import SubscribeModal from "../../SubscribeModal"

class TimelineLazy extends React.Component {

  fetchData = async () =>  {
    if (this.props.list === "you_only") {
      await axios.get(`${API_TIMELINE}?page=${this.state.pageNumber}`, keyConfig(store.getState)).then(res => {
        if(this.state.pageNumber === 2) {this.setState({api_timeline_loading:false})}
        if (!this.state.api_timeline.find((data) => data.id.includes(res.data.results[0].id))) {
          this.props.timeline_you_only(this.state.api_timeline.concat(res.data.results))
          this.setState({
            api_timeline: this.state.api_timeline.concat(res.data.results),
            api_timeline_loading: false,
            pageNumber: this.state.pageNumber + 1
          });
        }
      }).catch((err) => {
        this.setState({
          hasMore: false,
          api_timeline_loading: false,
        });
      });
    }
    else if (this.props.list === "you_and_following") {
      await axios.get(`${API_TIMELINE_YOU_AND_FOLLOWING}?page=${this.state.pageNumber}`, keyConfig(store.getState)).then(res => {
        if(this.state.pageNumber === 2) {this.setState({api_timeline_loading:false})}
        if (!this.state.api_timeline.find((data) => data.id.includes(res.data.results[0].id))) {
          this.props.timeline_you_and_following(this.state.api_timeline.concat(res.data.results))
          this.setState({
            api_timeline: this.state.api_timeline.concat(res.data.results),
            pageNumber: this.state.pageNumber + 1,
            api_timeline_loading: false
          });
        }

      }).catch((err) => {
        this.setState({
          hasMore: false,
          api_timeline_loading: false,
        });
      });
    }
    else if (this.props.list === "member_only" && this.state.postType===0) {
      await axios.get(`${API_TIMELINE_ALL_USER}${this.props.member_id}&page=${this.state.pageNumber}`, keyConfig(store.getState)).then(res => {
        if (!this.state.api_timeline.find((data) => data.id.includes(res.data.results[0].id))) {
          if (this.props.user_status.pk === this.props.member_id) {
            this.props.timeline_member_only(this.state.api_timeline.concat(res.data.results))
          }
          this.setState({
            api_timeline: this.state.api_timeline.concat(res.data.results),
            pageNumber: this.state.pageNumber + 1,
            api_timeline_loading:false
          });
        }

      }).catch((err) => {
        console.log(err)
        this.setState({
          hasMore: false,
          api_timeline_loading: false,
        });
      });
    }
    else if (this.props.list === "member_only" && this.state.postType!==0) {
      await axios.get(`${API_TIMELINE_ALL_USER}${this.props.member_id}&event=${this.state.postType}&page=${this.state.pageNumber}`, keyConfig(store.getState)).then(res => {
        if (!this.state.api_timeline.find((data) => data.id.includes(res.data.results[0].id))) {
          if (this.props.user_status.pk === this.props.member_id) {
            this.props.timeline_member_only(this.state.api_timeline.concat(res.data.results))
          }
          this.setState({
            api_timeline: this.state.api_timeline.concat(res.data.results),
            pageNumber: this.state.pageNumber + 1,
            api_timeline_loading: false,
          });
        }
      }).catch((err) => {
        console.log(err)
        this.setState({
          hasMore: false,
          api_timeline_loading: false,
        });
      });
    }
    else if (this.props.list === "album_only") {
      await axios.get(`${API_TIMELINE_ALL_USER_NORM}${this.props.timelinealbumid}`, keyConfig(store.getState)).then(res => {
        if(this.state.pageNumber === 2) {this.setState({api_timeline_loading:false})}
        if (!this.state.api_timeline.find((data) => data.id.includes(res.data.results[0].id))) {
          this.props.timeline_album_only(this.state.api_timeline.concat(res.data))
          this.setState({
            api_timeline: this.state.api_timeline.concat(res.data),
            api_timeline_loading: false,
            hasMore: false,
          });
        }

      }).catch((err) => {
        console.log(err)
        this.setState({
          hasMore: false,
          api_timeline_loading: false,
        });
      });
    }
  }

  fetchTimelineReloadLike = async (page,index) =>  {
    if (this.props.list === "you_only") {
      await axios.get(`${API_TIMELINE}${this.state.api_timeline[index].id}`, keyConfig(store.getState)).then(res => {
        this.state.api_timeline.splice(index,1,res.data.results[0])
        this.props.timeline_you_only(this.state.api_timeline.splice(index,1,res.data.results[0]))
        this.setState({
          flag_comment_spinner_value: 2,
          flag_like_spinner_value: 2,
          reload_page_value: null,
        });
      });
    }
    else if (this.props.list === "you_and_following") {
      await axios.get(`${API_TIMELINE_YOU_AND_FOLLOWING}${this.state.api_timeline[index].id}`, keyConfig(store.getState)).then(res => {
        this.state.api_timeline.splice(index,1,res.data.results[0])
        this.props.timeline_you_only(this.state.api_timeline.splice(index,1,res.data.results[0]))
        this.setState({
          flag_comment_spinner_value: 2,
          flag_like_spinner_value: 2,
          reload_page_value: null,
        });
      });
    }
    else if (this.props.list === "member_only" && this.state.postType===0) {
      await axios.get(`${API_TIMELINE_ALL_USER_NORM}${this.state.api_timeline[index].id}`, keyConfig(store.getState)).then(res => {
        this.state.api_timeline.splice(index,1,res.data.results[0])
        this.props.timeline_you_only(this.state.api_timeline.splice(index,1,res.data.results[0]))
        this.setState({
          flag_comment_spinner_value: 2,
          flag_like_spinner_value: 2,
          reload_page_value: null,
        });
      });
    }
    else if (this.props.list === "member_only" && this.state.postType!==0) {
      await axios.get(`${API_TIMELINE_ALL_USER_NORM}${this.state.api_timeline[index].id}`, keyConfig(store.getState)).then(res => {
        this.state.api_timeline.splice(index,1,res.data.results[0])
        this.props.timeline_you_only(this.state.api_timeline.splice(index,1,res.data.results[0]))
        this.setState({
          flag_comment_spinner_value: 2,
          flag_like_spinner_value: 2,
          reload_page_value: null,
        });
      });
    }
    else if (this.props.list === "album_only") {
      await axios.get(`${API_TIMELINE_ALL_USER_NORM}${this.props.timelinealbumid}`, keyConfig(store.getState)).then(res => {
        if(this.state.pageNumber === 2) {this.setState({api_timeline_loading:false})}
        this.state.api_timeline.splice(index,1,res.data)
        this.props.timeline_you_only(this.state.api_timeline.splice(index,1,res.data.results[0]))
        this.setState({
          flag_comment_spinner_value: 2,
          flag_like_spinner_value: 2,
          reload_page_value: null,
        });
      })
    }
  }

  handlerCommentSpinner = (val) => {
    this.setState({
      flag_comment_spinner_value: val
    })
  }

  handlerLikeSpinner = (val) => {
    this.setState({
      flag_like_spinner_value: val
    })
  }

  handlerReload = (val) => {
    this.setState({
      reload_page_value: val
    })
  }

  componentDidMount() {
    this.fetchData()
  }

  reloadfilter = async () => {
    await this.setState({ api_timeline: [], api_timeline_loading:true, postType: this.props.postType, pageNumber: 1,hasMore: true,})
    await this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (this.state.reload_page_value !==null ) {
      this.fetchTimelineReloadLike(parseInt(this.state.reload_page_value) + 1,parseInt(this.state.reload_page_value));
      this.setState({
        reload_page_value: null,
      });
    }
    else if (this.props.list === "member_only" && this.props.postType !== this.state.postType ) {
      this.reloadfilter()
    }
    else if (this.state.pageNumber === 2 && this.state.second_load === false) {
      this.setState({second_load: true})
      this.fetchData()
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      second_load: false,
      postType: 0,
      hasMore: true,
      photo_change_flag: 0,
      reload_page_value: null,
      flag_lazy_change: 0,
      lazy_page_number: 1,
      flag_comment_spinner_value: 0,
      flag_like_spinner_value: 0,
      api_timeline:this.props.list === "you_only"? this.props.timeline_you_only_data :
        this.props.list === "you_and_following" && (this.props.timeline_you_and_following_data.find((data) => data.id.includes(this.props.timeline_you_and_following_last_data)))? this.props.timeline_you_and_following_data :
        this.props.list === "member_only" && (this.props.user_status.pk !== this.props.member_id)? [] :
        this.props.list === "member_only" && (this.props.timeline_member_only_data.find((data) => data.id.includes(this.props.timeline_member_only_last_data)))? this.props.timeline_member_only_data :
        this.props.list === "album_only"? this.props.timeline_album_only_data : [],

      api_timeline_loading:this.props.list === "you_only" && this.props.timeline_you_only_data.length > 0?  false:
        this.props.list === "you_and_following" && (this.props.timeline_you_and_following_data.find((data) => data.id.includes(this.props.timeline_you_and_following_last_data))) && this.props.timeline_you_and_following_data.length > 0?  false:
        this.props.list === "member_only" && (this.props.user_status.pk !== this.props.member_id)? true :
        this.props.list === "member_only" && (this.props.timeline_member_only_data.find((data) => data.id.includes(this.props.timeline_member_only_last_data))) && this.props.timeline_member_only_last_data.length > 0?  false:
        this.props.list === "album_only" && this.props.timeline_album_only_data.length > 0?  false: true,

      pageNumber:this.props.list === "you_only"? this.props.timeline_you_only_data.length + 1 :
        this.props.list === "you_and_following" && (this.props.timeline_you_and_following_data.find((data) => data.id.includes(this.props.timeline_you_and_following_last_data)))? this.props.timeline_you_and_following_data.length + 1:
        this.props.list === "member_only" && (this.props.user_status.pk !== this.props.member_id)? 1 :
        this.props.list === "member_only" && (this.props.timeline_member_only_data.find((data) => data.id.includes(this.props.timeline_member_only_last_data)))? this.props.timeline_member_only_data.length + 1 :
        this.props.list === "album_only"? this.props.timeline_album_only_data.length + 1 : 1,
      api_user_following : [],
      api_user_following_loading: true,
      api_user_followers : [],
      api_user_followers_loading: true,
      api_user_profiles: [],
      api_user_profiles_loading: true,
      api_albums_master: [],
      api_albums_master_loading: true,
      member_id: this.props.member_id,
    };
  }

  renderTimelineFeed = (x,y) => {
    return(
      <TimelineFeed
        x={x}
        index={y}
        member_id = {this.props.member_id}
        callbackPageReload = {this.handlerReload}
        flag_comment_spinner_handler={this.handlerCommentSpinner}
        flag_comment_spinner_value = {this.state.flag_comment_spinner_value}
        flag_like_spinner_handler={this.handlerLikeSpinner}
        flag_like_spinner_value = {this.state.flag_like_spinner_value}
        />
    )
  }

  renderData = () => {
    if (this.state.api_timeline.length === 0 && this.props.list === "you_only") {
      return (
        <div>
        <StatusNewYouAndFollowers source={"timeline_youonly"}/>
        <SubscribeModal source="landing" />
        </div>
      )
    }
    else if (this.state.api_timeline.length === 0 && this.props.list === "you_and_following") {
        return (
              <div>
        <StatusNewYouAndFollowers source={"timeline_andfollowers"}/>
        <SubscribeModal source="landing" />
        </div>
        )
    }
    else if (this.state.api_timeline.length === 0 && (this.props.list === "member_only" && this.state.postType === 2) && this.props.user_status.pk === this.props.member_id) {
        return (
        <AlbumsNotFound source={"profile_filter"}/>
        )
    }
    else if (this.state.api_timeline.length === 0 && (this.props.list === "member_only" && this.state.postType === 2) && this.props.user_status.pk !== this.props.member_id) {
        return (

          <Card>
            <CardBody className="text-center">
              <CardImg top width="100%" src={empty_gym} alt="EmptyGym" />

                  <h3 className="mt-2"> We are waiting for {this.props.member_first_name} to post an album! </h3>

                                <Button className="mt-1" size="md" color="primary" onClick={() => this.props.postTypeUnFilter(0,'All')}> Reset </Button>

            </CardBody>
        </Card>



        )
    }
    else if (this.state.api_timeline.length === 0 && this.props.list === "member_only" && this.state.postType !== 2) {
        return (
          <Card>
            <CardBody className="text-center">
              <CardImg top width="100%" src={empty_gym} alt="EmptyGym" />
              {this.props.user_status.pk === this.props.member_id ?
                <>
                  <h3 className="mt-2"> We are waiting on your first lift </h3>
                  <Button color="primary" tag={Link} to="/workouts/All"> Discover Workouts </Button>
                </>
                  :
                  <h3 className="mt-2"> We are waiting on {this.props.member_first_name + "'s"} first lift! </h3>
                }


            </CardBody>
        </Card>
        )
    }
    return(
      <InfiniteScroll
        dataLength={this.state.api_timeline.length} //This is important field to render the next data
        next={this.fetchData}
        hasMore={this.state.hasMore}
        scrollThreshold={-100}
        endMessage={
          <p style={{ textAlign: 'center' }}>
            <b>{this.props.list !== "album_only"? "You have seen it all!" : null }</b>
            </p>
        }
        loader={<Card className="d-flex flex justify-content-center align-items-center h-100 p-3"><Spinner color="primary" /></Card>}
        >

        {this.state.api_timeline.sort((a, b) => -a.timestamp.localeCompare(b.timestamp)).map((x,y)=>
          <div key={y}>
            {this.renderTimelineFeed(x,y)}
          </div>
        )}
      </InfiniteScroll>
    )
  }

  render() {
    if (this.state.api_timeline_loading === true) {
      return <Loader />
    }

    return (
      <div>
          {this.renderData()}
      </div>
  );
 }
}

const mapStateToProps = (state) => ({
  user_status: state.auth.user,
  theme: state.theme.currentTheme,
  timeline_you_only_data: state.general.timeline_you_only_data,
  timeline_you_and_following_data: state.general.timeline_you_and_following_data,
  timeline_member_only_data: state.general.timeline_member_only_data,
  timeline_album_only_data: state.general.timeline_album_only_data,
  timeline_you_and_following_last_data: state.general.timeline_you_and_following_last_data,
  timeline_member_only_last_data: state.general.timeline_member_only_last_data,
});

export default connect(mapStateToProps, { timeline_you_only, timeline_album_only, timeline_you_and_following, timeline_member_only  })(TimelineLazy);
