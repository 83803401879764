import React from "react";
import { Col, Card, CardBody, Row, Spinner, Media } from "reactstrap";
import { ShoppingCart, Repeat, Clock, Clipboard, Anchor, Watch} from "react-feather";
import { connect } from 'react-redux';
import prettyNum from 'pretty-num';

const prettyMilliseconds = require('pretty-ms');

class Statistics extends React.Component {

  renderStats = () => {
    return(
      <div className="w-100 d-flex flex-column justify-content-between align-items-between bg-done p-0 m-0">
      <Row className="flex d-flex justify-content-between align-items-between ">
        <Col sm="6">
          <Card className="flex-fill">
            <CardBody className="py-4">


              <Media>
                <div className="d-inline-block mt-2 mr-3">
                  <Clipboard className="feather-lg text-primary" />
                </div>
                <Media body>
                  <h1 className="display-5 mt-1 mb-3">{this.props.api_session_count}</h1>
                  <div className="mb-0">Sessions</div>
                </Media>
              </Media>
            </CardBody>
        </Card>
        </Col>
        <Col sm="6">
        <Card className="flex-fill">
          <CardBody className="py-4">
            <Media>
              <div className="d-inline-block mt-2 mr-3">
                <Anchor className="feather-lg text-primary" />
              </div>
              <Media body>
                <h1 className="display-5 mt-1 mb-3">{this.props.api_exercise_count}</h1>
                <div className="mb-0">Exercises</div>
              </Media>
            </Media>
          </CardBody>
          </Card>
        </Col>
        </Row>
        <Row className="flex d-flex justify-content-between align-items-between">
        <Col sm="6">
          <Card className="flex-fill">
            <CardBody className="py-4">
              <Media>
                <div className="d-inline-block mt-2 mr-3">
                  <Repeat className="feather-lg text-primary" />
                </div>
                <Media body>
                  <h1 className="display-5 mt-1 mb-3">{this.props.api_rep_count}</h1>
                  <div className="mb-0">Repetitions</div>
                </Media>
              </Media>
            </CardBody>
        </Card>
        </Col>
        <Col sm="6">
        <Card className="flex-fill">
          <CardBody className="py-4">
            <Media>
              <div className="d-inline-block mt-2 mr-3">
                <ShoppingCart className="feather-lg text-primary" />
              </div>
              <Media body>
                <h1 className="display-5 mt-1 mb-3">
                {prettyNum(this.props.api_volume_moved, {precision: 2, thousandsSeparator: ','})}
                </h1>
                <div className="mb-0">{this.props.api_volume_moved_measurement} Moved</div>
              </Media>
            </Media>
          </CardBody>
          </Card>
        </Col>
        </Row>
        <Row className="flex d-flex justify-content-between align-items-between">
        <Col sm="6">
          <Card className="flex-fill">
            <CardBody className="py-4">
              <Media>
                <div className="d-inline-block mt-2 mr-3">
                  <Watch className="feather-lg text-primary" />
                </div>
                <Media body>
                  <h1 className="display-5 mt-1 mb-3">{this.props.api_average_time ===0? '0s' : prettyMilliseconds(this.props.api_average_time*1000)}</h1>
                  <div className="mb-0">Average Time</div>
                </Media>
              </Media>
            </CardBody>
        </Card>
        </Col>
        <Col sm="6">
        <Card className="flex-fill">
          <CardBody className="py-4">
            <Media>
              <div className="d-inline-block mt-2 mr-3">
                <Clock className="feather-lg text-primary" />
              </div>
              <Media body>
                <h1 className="display-5 mt-1 mb-3">{this.props.api_total_time ===0? '0s' : prettyMilliseconds(this.props.api_total_time*1000)}</h1>
                <div className="mb-0">Total Time</div>
              </Media>
            </Media>
          </CardBody>
          </Card>
        </Col>
      </Row>
      </div>
    )
  }

  render() {
    if(this.props.api_rep_count_loading ||
      this.props.api_average_time_loading ||
      this.props.api_total_time_loading ||
      this.props.api_session_count_loading ||
      this.props.api_exercise_count_loading ||
      this.props.api_volume_moved_measurement_loading
    ){
      return(
        <div className="w-100 flex d-flex justify-content-center align-items-center">
        <Spinner />
        </div>
      )
    }

    return (
      < >
        {this.renderStats()}
      </>
  );
 }
}

const mapStateToProps = (state) => ({
  user_status: state.auth.user,
  theme: state.theme.currentTheme
});

export default connect(mapStateToProps, {  })(Statistics);
