import React from "react";
import store from "../../redux/store/index";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Media,
  FormGroup,
  Input
} from "reactstrap";
import UnfollowButton from "../../components/Social/Buttons/UnfollowButton"
import FollowButton from "../../components/Social/Buttons/FollowButton"
import { connect } from 'react-redux';
import ProfilePhoto from '../../components/Social/ProfilePhoto/ProfilePhoto'
import {
  API_DISCOVER_PROFILES,
 } from '../../redux/actions/API';
import axios from "axios";
import { keyConfig } from '../../redux/actions/auth';


class Following extends React.Component {

  onSubmit = (e) => {
    e.preventDefault();
  };

  constructor(props) {
    super(props);
    this.state = {
      index: this.props.index %  20,
      flag_spinner: 0,
      pageInput: Math.floor(this.props.index/20)+1,
      x: this.props.x,
      blocked: false,
      flag_follow_change: null,
    };
  }


  handlerClickFollowButtonChange = (val1,val2) => {
    if(val2){
      this.setState({blocked:true})
    }
    else if(!val2){
      this.setState({flag_follow_change: val1});
    }
  }



  handlerLoadSpinner = (val) => {
    this.setState({
      flag_spinner: val
    })
  }


  fetchTimelineReloadFollow = async (page,index) =>  {
    await axios.get(`${API_DISCOVER_PROFILES}?page=${this.state.pageInput}`, keyConfig(store.getState)).then(res => {
        this.setState({
          flag_spinner:0,
          x: res.data.results[this.state.index]
        })
      });
  }



  componentDidUpdate(prevProps) {
    if (this.state.flag_follow_change !==null ) {
      this.fetchTimelineReloadFollow();
      this.setState({
        flag_follow_change: null
      });
    }


  }


  renderButton = (input_data, input_key) => {
    if (this.props.change_flag_value === 1) {
      return(
        <h2> LOADING </h2>
      )
    }

    else if (input_data.derived__is_following === "true") {
      return(
        <FormGroup onSubmit={this.onSubmit} key={input_key} >
          <Input type="hidden" value={Math.ceil((this.props.index+1) /20,1)} ref={(input) => { this.lazy_page = Math.ceil((this.props.index+1) /20,1) }}/>
          <UnfollowButton
            followingid = {input_data.derived__following_id}
            currentTime = {input_data.derived__current_time}
            myid = {store.getState().auth.user.pk}
            user_id = {input_data.id}
            change_flag = {this.handlerClickFollowButtonChange}
            size = "md"
            index = {this.props.index}
            handlerLoadSpinner = {this.handlerLoadSpinner}
            flag_spinner = {this.state.flag_spinner}
            source={"athletes"}
          />
        </FormGroup>
      )
    }

    else if (input_data.derived__is_following !== "true") {
      return (
        <FormGroup onSubmit={this.onSubmit} key={input_key} >
          <Input type="hidden" value={Math.ceil((this.props.index+1) /20,1)} ref={(input) => { this.lazy_page = Math.ceil((this.props.index+1) /20,1) }}/>
          <FollowButton
            myid = {store.getState().auth.user.pk}
            user_id = {input_data.id}
            change_flag = {this.handlerClickFollowButtonChange}
            size = "md"
            index = {this.props.index}
            handlerLoadSpinner = {this.handlerLoadSpinner}
            flag_spinner = {this.state.flag_spinner}
            source={"athletes"}
            />
        </FormGroup>
      )
    }
  }

  render() {
    return (
      <>
      {!this.state.blocked?
      <Card className="flex-fill mb-2">

        <CardBody className="mb-0 p-2">


              <Media>



              <ProfilePhoto
                avatar_image= {this.state.x.avatar_image}
                avatar_letter = {this.state.x.avatar_letter}
                avatar_color = {this.state.x.avatar_color}
                avatar_size={"56px"}
                letter_size={"25px"}
                />




                <Media body   className="ml-2 d-flex flex-row justify-content-between align-items-center ">
                <div>
                <Link to={"/profile/" + this.state.x.id + '/home'}>
                    <p className="my-1" style={{color:"black"}}>
                      <strong>{this.state.x.firstname_lastname} </strong>
                    </p>
                  </Link>
                      {' ' + this.state.x.location_city_std === null? '' : this.state.x.location_city_std}
                  </div>


                  <div className="ml-2 d-flex flex-column justify-content-between align-items-center">
                  { this.renderButton(this.state.x,this.props.index) }
                  </div>


                </Media>
              </Media>


        </CardBody>

      </Card>
      :
      <Card className="flex-fill mb-2">

        <CardBody className="mb-0 p-2 d-flex flex justify-content-center align-items-center">

          <strong> Profile Blocked Successfully</strong>


        </CardBody>

      </Card>
    }
    </>
    );
  }
}

const mapStateToProps = (state) => ({
  user_id: state.auth.user.pk,
  user_status: state.auth.user,
  general: state.general.isLoading,
  theme: state.theme.currentTheme,
});
export default connect(mapStateToProps, {  })(Following);
