import React from "react";
import LoaderSpin from "./LoaderSpin"
import {
  Button,
  ButtonGroup,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Form,
  UncontrolledButtonDropdown,
} from "reactstrap";
import { connect } from 'react-redux';
import store from "../../../redux/store/index";
import { followUser, blockUser } from "../../../redux/actions/social";
import {
  Settings,
} from "react-feather";



async function FollowUser(y,z,a, index) {
  const result = await store.dispatch(followUser(y,z));
  if (result.status === 200 || result.status === 201) {
      await a.change_flag(index)
    }
  }




 async function BlockUser(y,z,a) {
   await store.dispatch(blockUser(y,z));
   try {
     await a.change_flag('1','2');
   }
   catch(err) {
     console.log('fail')
   }
  }


class Following extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      loadSpin: 0,
      flag_spinner: 0
    };
  }

  componentDidUpdate() {
    if (this.state.flag_spinner !== this.props.flag_spinner) {
      this.props.handlerLoadSpinner(0)
      this.setState({
        flag_spinner: this.props.flag_spinner
      })
    }
  }

  onSubmit = async (e) => {
    e.preventDefault();
    FollowUser(this.props.myid,this.props.user_id, this.props, this.props.index)
    this.setState({
      loadSpin: 1
    });
  };

  onSubmitSettings = async (e) => {
    e.preventDefault();
  };

  onBlock = async (e) => {
    BlockUser(this.props.myid,this.props.user_id, this.props)
  }



  render() {
    return (
      <ButtonGroup>

      <Form onSubmit={this.onSubmit}>
        {this.state.loadSpin===0?
          <Button className= " mr-1 " color="primary" size={this.props.size==="md"? "md" : this.props.size==="lg"? "lg" : "sm"} > Follow </Button>
          :
          <Button className= " mr-1 " color="primary" size={this.props.size==="md"? "md" : this.props.size==="lg"? "lg" : "sm"} disabled> <LoaderSpin/></Button>
        }
        </Form>

        {this.props.source === "athletes" ? 
        null
        :
        <Form onSubmit={this.onSubmitSettings}>
          <UncontrolledButtonDropdown  className="mr-1 " size={this.props.size==="md"? "md" : this.props.size==="lg" ? "lg" : "sm"} outline="true">
            <DropdownToggle  color="primary" >
              <Settings width={16} height={16} />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => {this.onBlock();}}>Block Athlete</DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </Form>}

        </ButtonGroup>
    );
  }
}

const mapStateToProps = (state) => ({
  theme: state.theme.currentTheme,
});
export default connect(mapStateToProps, {  })(Following);
