import React from "react";
import { Link } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
  CardImg,
} from "reactstrap";



const WorkoutNotFound = (props) => (
  <div>

  {props.source==="sidebar"?

  <Row>
    <Col>
      <Card className="bg-dark">

          <CardBody className="mb-0">
          <div className="text-center text-light">
            <h1 className="display-5 font-weight-bold text-light">Champ!</h1>
            <p className="h2 font-weight-normal mt-3 mb-2 text-light">

            </p>

            <div className="d-flex flex justify-content-center align-items-center">
              <Button tag={Link} to="/workouts/All" color="primary" outline className="btn-pill mr-0 mb-1" size="lg">
                Keep On Lifiting
              </Button>

              </div>

          </div>
          </CardBody>


      </Card>
    </Col>
  </Row>


  :



  <Container fluid>


  <Row>
      <Col className="bg-dark">
        <Card className="bg-dark">
        <CardHeader className="bg-dark">
          <CardTitle tag="h5" className="mb-0">
          <div className="text-center">
            <h1 className="display-1 font-weight-bold text-light">Champ!</h1>
            <p className="h2 font-weight-normal mt-3 mb-4 text-light">
               {props.workout}...is done
            </p>



              <div className="d-flex flex justify-content-center align-items-center">
                <Button tag={Link} to="/workouts/All" color="primary" outline className="btn-pill mr-1 mb-1" size="lg">
                  Keep On Lifiting
                </Button>

                <Button tag={Link} to="/myprogression/analytics" color="primary" outline className="btn-pill ml-1 mr-1 mb-1" size="lg">
                  Training Analytics
                </Button>

                <Button tag={Link} to="/albums" color="primary" outline className="btn-pill ml-1 mb-1" size="lg">
                  Share Pictures
                </Button>

                </div>

          </div>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <div className="my-2">&nbsp;</div>
        </CardBody>
      </Card>
      <CardImg top src={props.cover_image} alt={props.workout} className="img-fluid"/>
    </Col>
  </Row>


    <Row >


      <Col md="6" lg="4">

      </Col>

    </Row>
  </Container>
}
  </div>


);

export default WorkoutNotFound;
