import React from "react";
import Loader from "../../../components/Loader";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Form,
  FormGroup,
  CustomInput,
} from "reactstrap";
import { connect } from 'react-redux';
import store from "../../../redux/store/index";
import { loadUser } from '../../../redux/actions/auth';
import { loadUserSocial } from '../../../redux/actions/auth';
import ApiAuth from '../../../config/auth';


class Privacy extends React.Component {

  componentDidMount() {
      if (this.props.user_status === null) {
        store.dispatch(loadUser());
      }
    }


  onChange = (e) => {
    console.log('TT')
    console.log(e.target.name)
    if ( e.target.name === "customRadio2") {
      this.setState({ is_hidden: true })
    }
    else if ( e.target.name === "customRadio1") {
      this.setState({ is_hidden: false })
    }
  }


  updateMeasurement = async (e) => {
      await ApiAuth.patchUserHidden(this.state.is_hidden,(success, data) => {
        if ( success) {
          console.log('success')
          store.dispatch(loadUserSocial());
          this.setState({sucess: true})
        } else {
          console.log('fail')
        }
      });
    };



  onSubmit = (e) => {
    e.preventDefault();
    this.updateMeasurement()
  }



  constructor(props) {
    super(props);
    this.state = {
      is_hidden: this.props.user_status.hidden,
      sucess: false
    };
  }

  render() {
    if ( this.props.user_status === null) {
      return <Loader />
    }
    else if ( this.state.change_flag === 1) {
      return <Loader />
    }

    return(

      <Card>
        <CardHeader className="mb-0 mr-sm-0 mb-sm-0">
          <CardTitle tag="h5">Profile Privacy:
          </CardTitle>
        </CardHeader>
        <CardBody className="mt-0 pt-0">
          <Form onSubmit={this.onSubmit}>

          <FormGroup>
          <CustomInput
            type="radio"
            id="exampleCustomRadio1"
            name="customRadio1"
            label="Open Profile (you can search and view others; and anybody can search and view you)"
            className="mb-2"
            checked={this.state.is_hidden === false}
            onChange={this.onChange}
          />
          <CustomInput
            type="radio"
            id="exampleCustomRadio2"
            name="customRadio2"
            label="Hidden Profile (you cannot search and view others; and nobody can search and view you)"
            className="mb-2"
            checked={this.state.is_hidden === true}
            onChange={this.onChange}
          />
          </FormGroup>


            {this.state.sucess?
            <Button color="success" >Saved</Button>
            :
            <Button color="primary" >Update</Button>
          }

          </Form>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  user_status: state.auth.user,
  theme: state.theme.currentTheme
});

export default connect(mapStateToProps, {  })(Privacy);
