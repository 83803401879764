import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import lifterGirl from "./LifterGirl.jpg"
import empty from "./Empty.jpeg"
import group from "./Group.jpeg"

import {
  Button,
  Col,
  Container,
  Row,
  Navbar,
  NavbarBrand
} from "reactstrap";




const Header = () => (
  <Navbar dark expand="xs" className="absolute-top w-100 py-2">
    <Container>
      <NavbarBrand className="font-weight-bold" href="/">
       About
      </NavbarBrand>
      <Button
        tag={Link}
        to="/"
        size="lg"
        color="primary"
        target="_blank"
        rel="noreferrer noopener"
        className="my-2 ml-2 btn-pill"
      >
        Go to ironroom.io
      </Button>
    </Container>
  </Navbar>
);

const Intro = () => (
  <section className="mb-5 pt-7 pb-5 vh-90 d-flex flex justify-content-center align-items-center landing-bg text-white overflow-hidden bg-dark" style={{backgroundColor:'#d3e2f0', "backgroundImage": `url(${lifterGirl})`, objectFit: 'cover', backgroundSize: 'cover', backgroundRepeat:'no-repeat' }}>
    <Container className="py-4">
      <Row>
        <Col xl={11} className="mx-auto">
          <Row>
            <Col md={12} xl={8} className="mx-auto text-center">
              <div className="d-block my-4">
                <h1 className="display-4 font-weight-bold mb-3 text-white">

Ironroom is what’s happening and where people are lifting right now.

                </h1>
                <p className="lead font-weight-light mb-3 landing-text">

                </p>

                <div className="my-4">

                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  </section>
);

const Navigation = () => (

  <Container>
      <Row>
          <Col xl="8" lg="10" className="mx-auto" >
              <p className="lead mb-5" > As am hastily invited settled at limited civilly fortune me. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo. </p>
          </Col>
      </Row>

          <Row>
              <Col xl="10" className="mx-auto d-flex flex justify-content-center align-items-center">
                  <img src={empty} alt="" className="img-fluid mb-5" style={{objectFit: 'cover', backgroundSize: 'cover', backgroundRepeat:'no-repeat'}}/>
              </Col>
          </Row>

          <Row>
              <Col xl="8" lg="10" className="mx-auto">
                    <p className="lead mb-5" > Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo. Quisque sit amet est et sapien ullamcorper pharetra. Vestibulum erat wisi, condimentum sed, commodo vitae, ornare sit amet, wisi. Aenean fermentum, elit eget tincidunt condimentum, eros ipsum rutrum orci, sagittis tempus lacus enim ac dui. Donec non enim in turpis pulvinar facilisis. Ut felis. Praesent dapibus, neque id cursus faucibus, tortor neque egestas augue, eu vulputate magna eros eu erat. Aliquam erat volutpat. Nam dui mi, tincidunt quis, accumsan porttitor, facilisis luctus, metus </p>
              </Col>
          </Row>


          <Row>
              <Col xl="10" className="mx-auto d-flex flex justify-content-center align-items-center">
                  <img src={group} alt="" className="img-fluid mb-5" style={{objectFit: 'cover', backgroundSize: 'cover', backgroundRepeat:'no-repeat'}}/>
              </Col>
          </Row>

  </Container>

);




const Footer = () => (
  <section className="py-5">
    <Container className="text-center">
      <Row>
        <Col lg={6} className="mx-auto">
          <h2 className="mb-3">

          </h2>
          <Button
            tag={Link}
            to="/"
            rel="noreferrer noopener"
            className="align-middle btn btn-dark btn-lg mt-n1"
          >
            Get Lifting
          </Button>
        </Col>
      </Row>
    </Container>
  </section>
);

const Landing = () => {

  return (
    <React.Fragment>
      <Header />
      <Intro />
      <Navigation />
      <Footer />
    </React.Fragment>
  )
}


export default connect()(Landing);
