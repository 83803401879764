import React from 'react';
import {connect} from 'react-redux';
import APISubscribe from './subscribe';
import { loadUserSocial } from '../redux/actions/auth';
import store from "../redux/store/index";

class SubscribeValidate extends React.Component {
  componentDidMount() {
    this.validateToken();
  }


  validateToken = async () => {
    await APISubscribe.playValidate((success, data) => {
      if (success) {
        console.log('validate success');
        store.dispatch(loadUserSocial())
      } else {
        console.log('validate fail');
      }
    });
  };

  render() {
    return null;
  }
}

function mapStateToProps(state) {
  const {auth} = state;
  return {
    user: auth.key,
    user_status: auth.user_status,
  };
}

export default connect(mapStateToProps, {loadUserSocial})(SubscribeValidate);
