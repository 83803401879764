import React, { useState } from "react";
import {  withRouter} from 'react-router-dom';
import { connect } from "react-redux";
import store from "../../redux/store/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDumbbell,
} from "@fortawesome/free-solid-svg-icons";
import { DropdownItem, Button, Modal, ModalHeader } from 'reactstrap';
import CreateWorkoutModalBody from './CreateWorkoutModalBody'




const NestedModalWorkout = (props) => {
  const {
    className
  } = props;

  const [modal, setModal] = useState(false);
  const [nestedModal, setNestedModal] = useState(false);
  const [ setCloseAll] = useState(false);

  const toggle = () => setModal(!modal);
  const toggleNested = () => {
    setNestedModal(!nestedModal);
    setCloseAll(false);
  }


  return (
    <div>
    {props.source==="Nav"?

      <DropdownItem onClick={toggle}>
        <FontAwesomeIcon
          icon={faDumbbell}
          fixedWidth
          className="mr-2 align-middle"
        />
        Create Workout
      </DropdownItem>
      :

      props.source==="getting_started"?
      <Button onClick={toggle} style={{width: '150px' }}className="btn mt-3 mr-3" color="primary" size="md"> Create Workout </Button>
      :

        props.source==="workout_list"?
      <Button color="primary" className="shadow-sm" onClick={toggle}>
         Create Custom Workout
      </Button>

      :

      props.source==="record_custom"?
      <Button  color="primary"  className="btn mb-1" size="lg" onClick={toggle}>
         Create Custom Workout
      </Button>

    :

      null
    }

      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>Create A Workout</ModalHeader>
        <CreateWorkoutModalBody toggle={toggle} toggleNested={toggleNested}/>
      </Modal>
    </div>
  );
}


class CreateWorkout extends React.Component {

  componentDidMount() {

  }

  render() {
    return (
      <NestedModalWorkout source={this.props.source}/>
    );
  }
}

const mapStateToProps = (state) => ({
  user_status: state.auth.user,
  auth: state.auth,
  sidebar: store.sidebar,
});

export default connect(mapStateToProps, {  })(withRouter(CreateWorkout));
