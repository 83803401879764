import React from "react";
import { Col, Card, CardBody, Media, Row } from "reactstrap";
import prettyNum from 'pretty-num';
import { calendarStrings } from '../../../redux/actions/timeconfig';
import moment from "moment";
import TimeAgo from 'react-timeago'

const Statistics = (props) => (
  <Row>
    <Col md="6" xl>
      <Card className="flex-fill">
        <CardBody className="py-4">
          <Media>
            <div className="d-inline-block mt-2 mr-3">

            </div>
            <Media body>
              <h4 className="mb-2">{moment(props.exercise_data.recorded_start_time).calendar(null,calendarStrings)}</h4>
              <span className="badge badge-dark float-left"><TimeAgo date={props.exercise_data.recorded_start_time}  /></span>
            </Media>
          </Media>
        </CardBody>
      </Card>
    </Col>
    <Col md="6" xl>
      <Card className="flex-fill">
        <CardBody className="py-4">
          <Media>
            <div className="d-inline-block mt-2 mr-3">

            </div>
            <Media body>
              <h4 className="mb-2">{ moment.duration( moment(props.exercise_data.recorded_end_time).diff(moment(props.exercise_data.recorded_start_time)) ).humanize()}</h4>
              <span className="badge badge-dark float-left">Time Lifting</span>
            </Media>
          </Media>
        </CardBody>
      </Card>
    </Col>
    <Col md="6" xl>
      <Card className="flex-fill">
        <CardBody className="py-4">
          <Media>
            <div className="d-inline-block mt-2 mr-3">

            </div>
            <Media body>
              <h4 className="mb-2">

              {prettyNum(props.exercise_data.total_volume, {precision: 0, thousandsSeparator: ','})}

              {' ' + props.exercise_data.measure_value}</h4>
              <span className="badge badge-dark float-left">Total Volume Moved</span>
            </Media>
          </Media>
        </CardBody>
      </Card>
    </Col>
    <Col md="6" xl>
      <Card className="flex-fill">
        <CardBody className="py-4">
          <Media>
            <div className="d-inline-block mt-2 mr-3">

            </div>
            <Media body>
              <h4 className="mb-2">{props.exercise_data.workout_day_xref.full_quick.length}</h4>
              <span className="badge badge-dark float-left">Exercises Lifted</span>
            </Media>
          </Media>
        </CardBody>
      </Card>
    </Col>
    <Col md="6" xl className="d-none d-xxl-flex">
      <Card className="flex-fill">
        <CardBody className="py-4">
          <Media>
            <div className="d-inline-block mt-2 mr-3">

            </div>
            <Media body>
              <h4 className="mb-2">{props.exercise_table[0].session_day +"/" + props.exercise_table[0].expected_workouts}</h4>
              <span className="badge badge-dark float-left">Sessions Day</span>
            </Media>
          </Media>
        </CardBody>
      </Card>
    </Col>
  </Row>
);

export default Statistics;
