import React from "react";
import {
  Card,
  CardBody,
  CardImg,
} from "reactstrap";
import { connect } from 'react-redux';
import LikeCommentsBar from '../../components/Social/LikeCommentsBar/LikeCommentsBar';
import LikeCommentsSummary from '../../components/Social/LikeCommentsSummary/LikeCommentsSummary';
import Comments from '../../components/Social/Comments/Comments';


class PhotoDetail extends React.Component {





  componentDidMount() {
    document.body.style.overflow = 'unset';
  }

  componentDidUpdate() {
    if (this.state.data !== this.props.data) {
      this.setState({
        data: this.props.data,
        flag_comment_spinner_value: 2,
        flag_like_spinner_value: 2,
      })
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      openComment: 0,
      data: this.props.data
    };
  }





  callbackPageReload = (val) => {
    this.props.fetchAlbumPhotoDetails(this.state.data.id)
  }


  flag_like_spinner_handler = (val) => {
    this.setState({
      flag_like_spinner_value: val
    })
  }


  handlerLikeChangePhotosReload = (val) => {
      console.log(val)
  }


  openCommentWindow = () => {
    if (this.state.openComment === 0) {
      this.setState({openComment: 1})
    }
  }

  render() {

      return       (
        <Card className="float-center">
          <CardBody className="">
            <CardImg top width="100%" src={this.state.data.photo} alt="Card image cap" />

            <LikeCommentsSummary
              x= {this.state.data}
              handlerOpenCommentWindow = {this.openCommentWindow}
              />

              <LikeCommentsBar
                x= {this.state.data}
                handlerOpenCommentWindow = {this.openCommentWindow}
                callbackPageReload = {this.callbackPageReload}
                index = {null}
                source = {"photo"}
                link = {null}
                flag_like_spinner_value = {this.state.flag_like_spinner_value}
                flag_like_spinner_handler = {this.flag_like_spinner_handler}
                handlerLikeChangePhotosReload = {this.handlerLikeChangePhotosReload}
                />


                {this.state.openComment === 1?
                  <div>

                  <Comments
                    x={this.state.data}
                    callbackPageReload = {this.callbackPageReload}
                    index = {null}
                    flag_comment_spinner_value = {this.state.flag_like_spinner_value}
                    flag_comment_spinner_handler = {this.flag_like_spinner_handler}
                    handlerLikeChangePhotosReload = {this.handlerLikeChangePhotosReload}
                    source = {"photo"}
                    photo_id={this.state.data.id}
                    />
                </div>
                :
                null
              }



          </CardBody>
        </Card>
          )
        }
 }

const mapStateToProps = (state) => ({
  user_status: state.auth.user,
  theme: state.theme.currentTheme
});

export default connect(mapStateToProps, {  })(PhotoDetail);
