import React from "react";
import Select from 'react-select'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { connect } from 'react-redux';
import store from "../../../redux/store/index";
import { loadUser } from '../../../redux/actions/auth';
import ApiSocial from '../../../config/social';
import axios from "axios";
import { keyConfig } from '../../../redux/actions/auth';
import {
  API_BIRTHDAY_MONTH_CHOICES,
  API_BIRTHDAY_YEAR_CHOICES,
  API_GENDER_CHOICES,
  API_USER_PROFILES_EXTRA,
  API_EMAIL_READ
} from '../../../redux/actions/API';
import Loader from "../../../components/Loader";



class Private extends React.Component {

  fetchInitial = () =>  {



    axios.get(`${API_USER_PROFILES_EXTRA}${this.props.user_status.pk}/`, keyConfig(store.getState)).then(res => {
        this.setState({
          birthMonth: res.data.bmonth_normal,
          birthYear: res.data.byear_normal,
          gender: res.data.gender_normal,

          birthMonthNumber: res.data.bmonth,
          birthYearNumber: res.data.byear,
          genderNumber: res.data.gender,

          api_birthday_loading: false,
        });
      }).catch((err) => {
        this.setState({
          birthMonth: null,
          birthYear: null,
          birthMonthNumber: null,
          birthYearNumber: null,
          gender: null,
          genderNumber: null,
          api_birthday_loading: false,
        });
    });

    axios.get(`${API_BIRTHDAY_MONTH_CHOICES}`, keyConfig(store.getState)).then(res => {
      this.setState({
        optionsMonth: res.data.map(({ id,month }) => {return{value: id, label: month, id: id }}),
      });
    });
    axios.get(`${API_BIRTHDAY_YEAR_CHOICES}`, keyConfig(store.getState)).then(res => {
      this.setState({
        optionsYear: res.data.map(({ id,year }) => {return{value: id, label: year, id: id }}),
      });
    });
    axios.get(`${API_GENDER_CHOICES}`, keyConfig(store.getState)).then(res => {
      this.setState({
        optionsGender: res.data.map(({ id,gender }) => {return{value: id, label: gender, id: id }}),
      });
    });
    axios.get(`${API_EMAIL_READ}`, keyConfig(store.getState)).then(res => {
      console.log(res.data)
      this.setState({
        useremail: res.data.length ===0? null : res.data[0].email,
        useremail_verified: res.data.length ===0? null : res.data[0].verified
      });
    });
  }

    componentDidMount() {
      if (this.props.user_status === null) {
        store.dispatch(loadUser());
      }
      this.fetchInitial();
    }

    constructor(props) {
      super(props);
      this.state = {
        useremail: null,
        api_birthday_loading: true,

        birthMonth: null,
        birthYear: null,
        birthMonthNumber: null,
        birthYearNumber: null,
        optionsMonth: [],
        optionsYear: [],

        gender: null,
        genderNumber: null,
        optionsGender: [],
        selectedGender: null,

        saved1: null,
        loading: 0

      };
    }

    componentDidUpdate() {
          if (this.state.loading ===1 && this.state.saved1 !== null) {
            this.setState({loading:0})
          }
        }

    onSubmit = async (e) => {
            e.preventDefault();
            await ApiSocial.postBirthdayGender(this.props.user_status.pk, this.state.birthMonthNumber, this.state.birthYearNumber, this.state.genderNumber, (success, data) => {
              if ( success) {
                console.log('success')
                this.setState({saved1: 1})
              } else {
                console.log('fail')
              }
            });
          };

  onChange = (e) => {
     this.setState({ [e.target.name]: e.target.value });
   }

   handlerBirthMonthChange = (val) => {
     this.setState({
       birthMonth: val.label,
       birthMonthNumber: val.id
     })
   }

   handleGenderChange = (val) => {
     this.setState({
       genderMonth: val.label,
       genderNumber: val.id
     });
   };

   handlerBirthYearChange = (val) => {
     this.setState({
       birthYear: val.label,
       birthYearNumber: val.id
     })
   }

    render() {
      if (this.state.api_birthday_loading) {
        return(
          < Loader />
        )
      }
      return(
        <Card>
          <CardHeader>

            <CardTitle tag="h5" className="mb-0">
              Private info
            </CardTitle>
          </CardHeader>
          <CardBody>
            <Form>

            <FormGroup>
              <Label for="gender">Email</Label>

              <p> {this.state.useremail} {this.state.useremail_verified? '(verified)' : '(unverified)'} </p>


            </FormGroup>

              <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="firstName">Birthday Month</Label>

                  <Select
                    styles={{// Fixes the overlapping problem of the component
                      menu: provided => ({ ...provided, zIndex: 9999 })
                    }}
                    classNamePrefix="react-select"
                    options={this.state.optionsMonth}
                    onChange={this.handlerBirthMonthChange}
                    value={this.state.birthMonth}
                    placeholder={this.state.birthMonth? this.state.birthMonth : "January"}

                  />

                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="lastName">Birthday Year</Label>

                  <Select
                    styles={{// Fixes the overlapping problem of the component
                      menu: provided => ({ ...provided, zIndex: 9999 })
                    }}
                    classNamePrefix="react-select"
                    options={this.state.optionsYear}
                    onChange={this.handlerBirthYearChange}
                    placeholder={this.state.birthYear? this.state.birthYear : "1995"}

                  />


                </FormGroup>
              </Col>
              </Row>
              <FormGroup>
                <Label for="gender">Gender</Label>

                <Select
                  styles={{// Fixes the overlapping problem of the component
                    menu: provided => ({ ...provided, zIndex: 9999 })
                  }}
                  classNamePrefix="react-select"
                  options={this.state.optionsGender}
                  onChange={this.handleGenderChange}
                  placeholder={this.state.gender? this.state.gender : "Other"}
                  size="lg"
                />


              </FormGroup>


              <Button color={this.state.saved1 ===null ? 'primary' : 'success'} onClick={this.onSubmit} style={{width: "120px"}}>
              {this.state.loading === 1 ?
                <Spinner color="info" size="sm" />
                :
                (this.state.saved1 !==null ) ?
                "Saved"
                :
                "Save changes"
              }
                 </Button>

            </Form>
          </CardBody>
        </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  user_status: state.auth.user,
  theme: state.theme.currentTheme
});

export default connect(mapStateToProps, {  })(Private);
