
import axios from 'axios';
import {
  API_EXERCISE_TRACKER,
  API_ONE_REP_MAX_TRACKER,
  API_EXERCISE_CREATE,
  API_EXERCISE_LIST_CUSTOM_PATCH,
  API_WORKOUTS_CREATE,
  API_WORKOUTS_CREATE_QUICK,
  API_WORKOUTS_CREATE_FULL,
  API_WORKOUTS_CREATE_FULL_SET,
  API_WORKOUTS_VIEW_FULL_DETAIL,
  API_TIMELINE,
} from '../redux/actions/API';
import { keyConfig } from './key'

// ENROLL USER IN WORKOUT
const _exerciseTracker = async (
    rep_count,
    weight,
    weight_level,
    session_day,
    recorded_start_time,
    user_id_xref,
    user_enrollment_history_xref,
    workout_day_xref,
    duration_xref,
    exercise_xref,
    set_xref,
    callback
  ) => {

  // Request Body
  const body = await JSON.stringify({
    rep_count, weight, weight_level, session_day, recorded_start_time, user_id_xref, user_enrollment_history_xref,
      workout_day_xref, duration_xref, exercise_xref, set_xref });
  await axios.post(`${API_EXERCISE_TRACKER}`, body,await keyConfig())
  .then(res => {
      callback(true, res.data);
    })
  .catch(error => {
    console.log(error.response.data)
      callback(false, error.response.data);
    })
};

// ONE REP MAX TRACKER
const _exerciseTrackerOneRepMax = async (
  weight,
  user_id_xref,
  user_enrollment_history_xref,
  exercise_xref
  ) => {

  // Request Body
  const body = JSON.stringify({ weight, user_id_xref, user_enrollment_history_xref, exercise_xref});

  axios.post(`${API_ONE_REP_MAX_TRACKER}`, body, await await keyConfig())

};

// CREATE EXERCISE
const _exerciseCreate = async (
      exercise,
      muscle_group_xref,
      muscle_sub_group_xref,
      training_level_xref,
      body_group_xref,
      push_pull_xref,
      modality_xref,
      joint_xref,
      user_id_xref,
      active,
      callback,
  ) => {
  // Request Body
  const body = await JSON.stringify(
    {
        exercise,
        muscle_group_xref,
        muscle_sub_group_xref,
        training_level_xref,
        body_group_xref,
        push_pull_xref,
        modality_xref,
        joint_xref,
        user_id_xref,
        active,
    }
  );
  await axios.post(`${API_EXERCISE_CREATE}`, body, await keyConfig())
  .then(res => {
      callback(true, res.data);
    })
  .catch(error => {
      callback(false, error.response.data);
    })
};

// PATCH EXERCISE
const _exercisePatch = async (
      id,
      exercise,
      muscle_group_xref,
      muscle_sub_group_xref,
      training_level_xref,
      body_group_xref,
      push_pull_xref,
      modality_xref,
      joint_xref,
      user_id_xref,
      callback,
  ) => {
  // Request Body
  const body = await JSON.stringify(
    {
        exercise,
        muscle_group_xref,
        muscle_sub_group_xref,
        training_level_xref,
        body_group_xref,
        push_pull_xref,
        modality_xref,
        joint_xref,
        user_id_xref,
    }
  );
   await axios.patch(`${API_EXERCISE_LIST_CUSTOM_PATCH}/${id}/`, body, await keyConfig())
  .then(res => {
      callback(true, res.data);
    })
  .catch(error => {
      callback(false, error.response.data);
    })
};

// PATCH EXERCISE
const _exerciseDelete = async (id, callback) => {

   await axios.delete(`${API_EXERCISE_LIST_CUSTOM_PATCH}/${id}/`, await keyConfig())
  .then(res => {
      callback(true, res.data);
    })
  .catch(error => {
      callback(false, error.response.data);
    })
};

// CREATE WORKOUT
const _workoutCreate = async (
      routine,
      goal_xref,
      training_level_xref,
      duration_weeks,
      days_per_week,
      user_id_xref,
      is_A_B_C_programme,
      training_max_exercise,
      callback,
  ) => {
  // Request Body
  const body = await JSON.stringify(
    {
      routine,
      goal_xref,
      training_level_xref,
      duration_weeks,
      days_per_week,
      user_id_xref,
      is_A_B_C_programme,
      training_max_exercise,
    }
  );
   await axios.post(`${API_WORKOUTS_CREATE}`, body, await keyConfig())
  .then(res => {
      callback(true, res.data);
    })
  .catch(error => {
      callback(false, error.response.data);
    })
};

// CREATE EXERCISE QUICK
const _workoutQuickCreate = async (routine_xref, calandar_day_xref, day_title, order, callback) => {
  // Request Body
  const body = await JSON.stringify({routine_xref, calandar_day_xref, day_title, order});
  await axios.post(`${API_WORKOUTS_CREATE_QUICK}`, body, await keyConfig())
  .then(res => {
      callback(true, res.data);
    })
  .catch(error => {
      callback(false, error.response.data);
    })
};

// CREATE EXERCISE QUICK UPDATE
const _workoutQuickPatch = async (id, day_title, callback) => {
  // Request Body
  const body = await JSON.stringify({day_title});
  await axios.patch(`${API_WORKOUTS_CREATE_QUICK}${id}/`, body, await keyConfig())
  .then(res => {
      callback(true, res.data);
    })
  .catch(error => {
      callback(false, error.response.data);
    })
};

// CREATE EXERCISE FULL
const _workoutFullCreate = async (routine_xref, workout_day_xref, exercise_xref, order, callback) => {
  // Request Body
  const body = await JSON.stringify({routine_xref, workout_day_xref, exercise_xref, order});
  await axios.post(`${API_WORKOUTS_CREATE_FULL}`, body, await keyConfig())
  .then(res => {
      callback(true, res.data);
    })
  .catch(error => {
      callback(false, error.response.data);
    })
};

// CREATE EXERCISE FULL SET
const _workoutFullSetCreate = async (workout_routines_full_xref, set_xref, rep_count, rep_count_max, weight_level, order, callback) => {
  // Request Body
  const body = await JSON.stringify({workout_routines_full_xref, set_xref, rep_count, rep_count_max, weight_level, order});
  await axios.post(`${API_WORKOUTS_CREATE_FULL_SET}`, body, await keyConfig())
  .then(res => {
      callback(true, res.data);
    })
  .catch(error => {
      callback(false, error.response.data);
    })
};

// CREATE EXERCISE FULL SET PATCH
const _workoutFullSetPatch= async (id, workout_routines_full_xref, set_xref, rep_count, rep_count_max, weight_level, order, callback) => {
  // Request Body
  const body = await JSON.stringify({workout_routines_full_xref, set_xref, rep_count, rep_count_max, weight_level, order});
  await axios.patch(`${API_WORKOUTS_CREATE_FULL_SET}${id}/`, body, await keyConfig())
  .then(res => {
      callback(true, res.data);
    })
  .catch(error => {
      callback(false, error.response.data);
    })
};

// DELETE WORKOUT
const _workoutDelete = async (id, callback) => {
  // Request Body

  await axios.delete(`${API_WORKOUTS_CREATE}${id}/`, await keyConfig())
  .then(res => {
      callback(true, res.data);
    })
  .catch(error => {
      callback(false, error.response.data);
    })
};

// DELETE WORKOUT FULL
const _workoutDeleteFull = async (id, callback) => {
  // Request Body

  await axios.delete(`${API_WORKOUTS_VIEW_FULL_DETAIL}${id}/`, await keyConfig())
  .then(res => {
      callback(true, res.data);
    })
  .catch(error => {
      callback(false, error.response.data);
    })
};


// PATCH WORKOUT FULL
const _workoutPatchFull = async (id, order, callback) => {
  // Request Body
  const body = await JSON.stringify({order});
  await axios.patch(`${API_WORKOUTS_VIEW_FULL_DETAIL}${id}/`, body, await keyConfig())
  .then(res => {
      callback(true, res.data);
    })
  .catch(error => {
      callback(false, error.response.data);
    })
};

// PATCH WORKOUT
const _workoutPatch = async (
      id,
      routine,
      goal_xref,
      training_level_xref,
      description_body,
      active,
      callback,
  ) => {
  // Request Body
  const body = await JSON.stringify({ routine, goal_xref, training_level_xref, description_body, active});
  await axios.patch(`${API_WORKOUTS_CREATE}${id}/`, body, await keyConfig())
  .then(res => {
      callback(true, res.data);
    })
  .catch(error => {
      callback(false, error.response.data);
    })
};


// PATCH Timeline
const _timelinePatch = async (id,timestamp, callback,) => {
  // Request Body
  const body = await JSON.stringify({timestamp });
  await axios.patch(`${API_TIMELINE}${id}/`, body, await keyConfig())
  .then(res => {
      callback(true, res.data);
    })
  .catch(error => {
      callback(false, error.response.data);
    })
};




const dict = {
  exerciseTracker: _exerciseTracker,
  exerciseTrackerOneRepMax: _exerciseTrackerOneRepMax,
  exerciseCreate:_exerciseCreate,
  exercisePatch:_exercisePatch,
  exerciseDelete: _exerciseDelete,
  workoutCreate:_workoutCreate,
  workoutQuickCreate:_workoutQuickCreate,
  workoutFullCreate:_workoutFullCreate,
  workoutFullSetCreate:_workoutFullSetCreate,
  workoutFullSetPatch:_workoutFullSetPatch,
  workoutDelete:_workoutDelete,
  workoutPatch:_workoutPatch,
  workoutQuickPatch:_workoutQuickPatch,
  workoutDeleteFull:_workoutDeleteFull,
  workoutPatchFull:_workoutPatchFull,
  timelinePatch:_timelinePatch
};

export default dict;
