import React, { useState }  from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Redirect } from 'react-router-dom';
import WorkoutsDetailLoading from './WorkoutsDetailLoading'
import HeaderDetail from './HeaderDetail'
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddExercise from '../../components/Create/AddExercise.js'
import PatchQuick from '../../components/Create/PatchQuick'
import {
  faExclamation,
  faExclamationTriangle,
  faTimes,
  faEye
} from "@fortawesome/free-solid-svg-icons";
import { faSmile } from "@fortawesome/free-regular-svg-icons";
import {
  ButtonGroup,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardImg,
  Col,
  Container,
  Form,
  Input,
  Row,
  Table,
  Spinner,
} from "reactstrap";
import { keyConfig } from '../../redux/actions/auth';
import store from "../../redux/store/index";
import { enrollUser, completeEnroll, resumeEnroll } from "../../redux/actions/workouts";
import {
  API_WORKOUTS_DETAIL,
  API_WORKOUTS_DEFAULT_IMAGE,
  API_LIFT_TRACKER_WORKOUT_SESSION,
  API_ENROLLMENT_HISTORY
 } from '../../redux/actions/API'
import { connect } from 'react-redux';
import PatchFullSet from "../../components/Create/PatchFullSet.js"
import DeleteFullSet from "../../components/Create/DeleteFullSet.js"
import { Alert, Modal, ModalHeader, ModalBody, ModalFooter, Progress } from 'reactstrap';
import iOS from "../../assets/img/buttons/button-app.png"
import play from "../../assets/img/buttons/button-play.png"

const ModalStartOver = (props) => {
  const {
    className
  } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <div className={props.group && !props.placement==='side'? 'pr-3 pl-3' : null}>

      {props.group?

      <Button color="warning" className={props.placement==='side' ? 'btn-block' : 'btn-block'} onClick={toggle} type="submit"><FontAwesomeIcon icon={faExclamation} /> Start Over </Button>

                            :

      <Button color="warning" className="btn-block"  onClick={toggle}><FontAwesomeIcon icon={faExclamation} /> Start Over </Button>

    }
      <Modal centered={true} isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>Start Over</ModalHeader>
        <ModalBody>
          Are you sure you want to start over? Your historic performace data will still be available, we will just start you off from day 1 again. Keep lifting and keep safe!
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>Cancel</Button>{' '}
          <Button color="warning" onClick={props.action}>Start Over</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

const ModalEnd = (props) => {
  const {
    className
  } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <div className={props.group && !props.placement==='side'? 'pr-3 pl-3' : null}>

      {props.group?

      <Button color="danger" className="btn-block"  onClick={toggle} type="submit"><FontAwesomeIcon icon={faTimes} /> End Workout</Button>
                            :

      null

    }
      <Modal centered={true} isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>End Tracking</ModalHeader>
        <ModalBody>
          Are you sure you want to leave this workout? All your historic performace data will still be avaible. Next time you join again, you'll start from Day 1. Keep lifting and keep safe!
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>Cancel</Button>{' '}
          <Button color="danger" onClick={props.action}>End</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

const MyExportCSV = props => {
  const handleClick = () => {
    props.onExport();
  };
  return (
    <div>
    {props.api_user === props.this_user?
      <div className="flex d-flex justify-content-center align-items-center">
      < AddExercise workout_id = {props.workout_id} fetchWorkouts={props.fetchWorkouts} workouts_detail={props.workouts_detail}/>
      < PatchQuick workout_id = {props.workout_id} fetchWorkouts={props.fetchWorkouts} workouts_detail={props.workouts_detail}/>
      </div>
      :
      <div>
        <button className="btn btn-secondary mt-2" onClick={handleClick}>
          Export
        </button>
      </div>
    }
    </div>
  );
};

class WorkoutsDetail extends React.Component {

  fetchEnrollHistory = () =>  {
    axios.get(`${API_ENROLLMENT_HISTORY}`, keyConfig(store.getState)).then(res => {
      this.setState({
        workout_current_name: res.data.length > 0? res.data[0].routine_name : null,
        enroll_length: res.data.length
      });
    });
  }

  fetchDefaultImage = () =>  {
    axios.get(`${API_WORKOUTS_DEFAULT_IMAGE}`, keyConfig(store.getState)).then(res => {
      this.setState({
        workouts_default_image: res.data[0].default,
        workouts_default_image_loading: false,
      });
    });
  }

  fetchWorkoutSession = () =>  {
    axios.get(`${API_LIFT_TRACKER_WORKOUT_SESSION}?workout=${this.props.match.params.workoutID}&active=${1}`, keyConfig(store.getState)).then(res => {
      this.setState({
        show_reset: res.data.length > 0? true: false,
        show_reset_loading: false,
      });
    });
  }

  FullQuick = async (data) => {
    await this.setState({
      errors: Object.keys(data).map(a => {
          return {a, message: data[a].full_quick.length > 0? 1: 0};
        }),
    });
    await this.setState({fullquicklength: Object.values(this.state.errors).reduce((r, { message }) => r + message, 0) === data.length? data.length : 0 })
  }

  fetchWorkouts = (workoutID) =>  {
    axios.get(`${API_WORKOUTS_DETAIL}${workoutID}/`, keyConfig(store.getState)).then(res => {
      this.FullQuick(res.data.quick)
      this.setState({
        workouts_cat: [res.data],
        workout_cat_loading: false,
        workout_complete: res.data.enroll_history[0]? res.data.enroll_history[0].derived__workout_progress_int : null,
        workout_complete_pct: res.data.enroll_history[0]? res.data.enroll_history[0].derived__workout_progress_pct : null,
        workout_complete_actual: res.data.enroll_history[0]? res.data.enroll_history[0].derived__total_days_completed : null,
        workout_complete_total: res.data.enroll_history[0]? res.data.enroll_history[0].derived__total_expected_days : null,
      });
    });
  }

  onSubmit_enroll = async (e) => {
    e.preventDefault();
    const result = await this.props.enrollUser(this.routineInput.props.value,this.userIDInput.props.value,this.currentTimeInput.props.value);
    if (result.status === 200 || result.status === 201) {
      this.setState({join: true})
    }
    else if (result.status === 400 || result.state === 500) {
      this.props.history.push('/');
    }
  };

  onSubmit__start_over = (e) => {
    e.preventDefault();
    this.props.completeEnroll(this.routineInput.props.value,this.userIDInput.props.value,this.enrollTimeInput.props.value,this.currentTimeInput.props.value,this.endedWorkoutInput.props.value, this.enrollID.props.value);
    this.props.enrollUser(this.routineInput.props.value,this.userIDInput.props.value,this.currentTimeInput.props.value);
    this.props.history.push('/');
  };

  onSubmit__resume = async (e) => {
    e.preventDefault();

    await this.props.resumeEnroll(
      this.enrollID.props.value,
      this.userIDInput.props.value,
      this.currentTimeInput.props.value);
    await this.setState({groupload: true})
    await this.setState({hide_resume: true})
    await this.setState({resumed:true})
    await this.fetchWorkouts(this.props.match.params.workoutID);
  };

  onSubmit__end = (e) => {
    e.preventDefault();
    this.props.completeEnroll(this.routineInput.props.value,this.userIDInput.props.value,this.enrollTimeInput.props.value,this.currentTimeInput.props.value,this.endedWorkoutInput.props.value, this.enrollID.props.value);
    this.props.history.push('/workouts/Popular');
  };

  renderWorkoutCompleteCard = (x) => {
    if(this.state.workout_complete===null){
      return(
        null
      )
    }
    return(
      <div className="mt-3">
      <Card>
        <CardHeader className="m-0 pt-2 pb-0">
      <CardTitle> {this.state.workout_complete_actual + '/' + this.state.workout_complete_total} Workout Days Completed </CardTitle>

        </CardHeader>
        <CardBody className="m-0 pt-2 pb-2">
              <Progress value={this.state.workout_complete} className="m-0">{this.state.workout_complete_pct}</Progress>

              {x.enroll_history[0].derived__status_memo === "In Progress"?

              <Button size="md" color="secondary" className="btn-pill btn-block mt-2" tag={Link} to={'/lift_tracking'} outline> Record Lifts Now </Button>
              :
              null}

              </CardBody>
      </Card>
      </div>

    )
  }

  componentDidMount() {
    this.fetchWorkouts(this.props.match.params.workoutID,0);
    this.fetchDefaultImage();
    this.fetchWorkoutSession();
    this.fetchEnrollHistory();
  }

  constructor(props) {
    super(props);
    this.state = {
      groupload: false,
      enroll_length: 0,
      workout_current_name: null,
      join: false,
      resumed: false,
      hide_resume: false,
      show_reset: false,
      show_reset_loading: true,
      workouts_default_image: [],
      workouts_default_image_loading:true,
      workouts_cat: [],
      referrer: null,
      routine_xref: null,
      user_id_xref: null,
      recorded_enroll_date: null,
      recorded_end_date: null,
      ended_workout: null,
      workout_cat_loading: true,
    };

  }

  renderButtons = (x,k,placement) => {
    if (this.state.join){
      return(
        null
      )
    }
    return(
      <>

      {x.enroll_history.length === 0 || x.enroll_history[0].completed_workout ?
        <Card>
          <Form onSubmit={this.onSubmit_enroll}>
            <Input type="hidden" name="routine_xref" value={x.id} ref={(input) => { this.routineInput = input }} />
            <Input type="hidden" name="user_id_xref" value={this.props.user_id } ref={(input) => { this.userIDInput = input }} />
            <Input type="hidden" name="recorded_enroll_date" value={x.derived__time_now} ref={(input) => { this.currentTimeInput = input }} />
            {this.state.fullquicklength === 0?
              <React.Fragment>
              <Button color="primary"  active={false} disabled={true} id="UncontrolledTooltip" className="mr-1 btn-block">{x.enroll_history.length === 0? "JOIN" : "Join and Lift Again!" } </Button>


                <div className="border-info text-sm text-muted text-center m-2 mt-1">
                <FontAwesomeIcon icon={faExclamationTriangle} />{'  '}
                  Before you can join and track your workout, each workout day needs atleast one exercise
                </div>



              </React.Fragment>
              :
            <Button color="primary" className="mr-1 btn-block"  type="submit">{x.enroll_history.length === 0? "JOIN" : "Join and Lift Again!" } </Button>
          }
          </Form>
        </Card>
      :
      <div>
      {this.state.show_reset?
        <>
          {x.enroll_history.map((u,v) =>
            <div key={v}>
              {u.derived__total_days_completed === "0"?
              null
              :
              u.derived__status === "current" && u.derived__total_days_completed !== "0"?
                <div >
                  {placement==='side'?
                  <Card>
                  <Input type="hidden" name="routine_xref" value={x.id} ref={(input) => { this.routineInput = input }} />
                  <Input type="hidden" name="user_id_xref" value={this.props.user_id } ref={(input) => { this.userIDInput = input }} />
                  <Input type="hidden" name="recorded_enroll_date" value={u.recorded_enroll_date} ref={(input) => { this.enrollTimeInput = input }} />
                  <Input type="hidden" name="recorded_end_date" value={x.derived__time_now} ref={(input) => { this.currentTimeInput = input }} />
                  <Input type="hidden" name="ended_workout" value="true" ref={(input) => { this.endedWorkoutInput = input }} />

                  <Input type="hidden" name="recorded_enroll_date_new" value={u.id} ref={(input) => { this.enrollID = input }} />
                  <ModalStartOver action={this.onSubmit__start_over}/>
                  </Card>
                  :null}

                </div>
              :
              this.state.groupload?
                <Spinner color="primary" />
                :

                <ButtonGroup className={placement==='side'? 'd-flex flex-column bg-light align-items-center' : 'd-flex flex-row bg-light align-items-center'}>
                  <Input type="hidden" name="enrollID" value={u.id} ref={(input) => { this.enrollID = input }} />
                  <Input type="hidden" name="user_id_xref" value={this.props.user_id } ref={(input) => { this.userIDInput = input }} />
                  <Input type="hidden" name="recorded_enroll_date_curret" value={x.derived__time_now} ref={(input) => { this.currentTimeInput = input }} />
                  <Input type="hidden" name="routine_xref" value={x.id} ref={(input) => { this.routineInput = input }} />
                  <Input type="hidden" name="recorded_enroll_date" value={u.recorded_enroll_date} ref={(input) => { this.enrollTimeInput = input }} />
                  <Input type="hidden" name="ended_workout" value="true" ref={(input) => { this.endedWorkoutInput = input }} />


                  {
                    placement==='side' ?
                  <>
                  <Form onSubmit={this.onSubmit__resume} className="d-flex flex-column align-items-stretch" style={{width:'100%'}}>
                    <Button color="primary" className=""  type="submit"><FontAwesomeIcon icon={faSmile} /> Resume Workout </Button>
                  </Form>

                  <div className="d-flex flex-column align-items-stretch mt-1 mb-1 mr-3 ml-3" style={{width:'100%'}}>
                    <ModalStartOver placement={placement} group={true} action={this.onSubmit__start_over}/>
                  </div>

                  <div  className="d-flex flex-column  align-items-stretch" style={{width:'100%'}}>
                    <ModalEnd placement={placement} group={true} action={this.onSubmit__end}/>
                  </div>
                  </>
                  :
                  null}


                </ButtonGroup>
              }
            </div>
            )}
        </>
        :
        <>
        {this.state.hide_resume === false && x.enroll_history[0].derived__status === "paused" && placement==='side' && !this.state.show_reset?
        <ButtonGroup className={placement==='side'? 'd-flex flex-column bg-light align-items-center' : 'd-flex flex-row bg-light align-items-center'}>
          <Input type="hidden" name="enrollID" value={x.enroll_history[0].id} ref={(input) => { this.enrollID = input }} />
          <Input type="hidden" name="user_id_xref" value={this.props.user_id } ref={(input) => { this.userIDInput = input }} />
          <Input type="hidden" name="recorded_enroll_date_curret" value={x.derived__time_now} ref={(input) => { this.currentTimeInput = input }} />
          <Input type="hidden" name="routine_xref" value={x.id} ref={(input) => { this.routineInput = input }} />
          <Input type="hidden" name="recorded_enroll_date" value={x.enroll_history[0].recorded_enroll_date} ref={(input) => { this.enrollTimeInput = input }} />
          <Input type="hidden" name="ended_workout" value="true" ref={(input) => { this.endedWorkoutInput = input }} />

          <Form onSubmit={this.onSubmit__resume} className="d-flex flex-column align-items-stretch" style={{width:'100%'}}>
            <Button color="primary" className=""  type="submit"><FontAwesomeIcon icon={faSmile} /> Resume Workout </Button>
          </Form>

        </ButtonGroup>
        :
        null
      }

        </>
      }
        </div>
      }
      </>
    )
  }

  renderAlert = () => {
    if (this.state.join) {
      return(
        <div>

        <Card>

          <CardBody>
            <Alert color="primary">
              <div className="alert-message">
                <h4 className="alert-heading">You can now head over to the record screen and begin logging your performances</h4>
                <p>

                </p>
                <hr />
                <div className="btn-list">

                <Button size="lg" color="secondary" className="btn-pill btn-block mt-1 mb-1" tag={Link} to={'/lift_tracking'} > Record Lifts </Button>

                </div>
              </div>
            </Alert>

            {this.state.enroll_length > 0? null :
            <div className="flex d-flex justify-content-between aligin-items-center">
             <CardImg src={iOS} className="img-fluid" alt="..." style={{'maxWidth': '48%'}}/>
              <CardImg src={play} className="img-fluid" alt="..." style={{'maxWidth': '48%'}}/>
            </div> }
          </CardBody>
        </Card>


      </div>
      )
    }
    else if (this.state.resumed){
      return(
        <div>

        <Card>

          <CardBody>
            <Alert color="success">
              <div className="alert-message">
                <h4 className="alert-heading">{' '}</h4>
                <p>
                  We paused your other workout, <b>{this.state.workout_current_name === null? '' : this.state.workout_current_name}</b>. You can now head to the the gym and track {this.state.workouts_cat[0].routine}
                </p>
                <hr />
                <div className="btn-list">

                <Button size="lg" color="secondary" className="btn-pill btn-block mt-1 mb-1" tag={Link} to={'/lift_tracking'} > Record Lifts Now </Button>

                </div>
              </div>
            </Alert>


          </CardBody>
        </Card>


      </div>
      )
    }
  }

  render() {
    const {referrer} = this.state;
    if (this.state.workout_cat_loading || this.state.workouts_default_image_loading || this.state.show_reset_loading) {
      return <WorkoutsDetailLoading />

    }
    else if (referrer) {
      return <Redirect to={referrer} />;
    }
    return (
      <Container fluid>

      <HeaderDetail
        fetchWorkouts = {this.fetchWorkouts}
        workoutID = {this.props.match.params.workoutID}
        routine={this.state.workouts_cat[0]}
        routine_owner={this.state.workouts_cat[0].user_id_xref}
        user_id={this.props.user_id}/>

      {this.state.workouts_cat.map((x,k) =>
        <Row key={k}>

          <Col xs="12" className="col-xxl-8">


              {this.renderAlert()}
                  {this.renderButtons(x,k,0)}




            <Card>
              <CardHeader>
                <CardTitle tag="h5" className="mb-0">
                  Description
                </CardTitle>
              </CardHeader>
              <CardBody>


                <div dangerouslySetInnerHTML={ { __html: x.description_body } }></div>



              </CardBody>
            </Card>



            {x.quick.map((y,t) =>
              <Card key={t}>
                <ToolkitProvider
                  keyField="order"
                  data={y.full_quick}
                  columns={
                  [
                    {
                      dataField: "exercise_xref.exercise",
                      text: "Exercise",
                      sort: true,
                    },
                    {
                      dataField: "derived__set_count",
                      text: "Sets",
                      sort: true
                    },
                    {
                      dataField: "derived__rep_count",
                      text: "Reps",
                      sort: true
                    },
                    {
                      dataField: "options",
                      isDummyField: true,
                      text: "Options",
                      hidden: this.state.workouts_cat[0].user_id_xref === this.props.user_id? false : true,
                      formatter: (cellContent, row) => {
                        return <div>
                          <PatchFullSet fullID = {row.id} workoutID={this.props.match.params.workoutID} fetchWorkouts={this.fetchWorkouts} workouts_detail = {this.state.workouts_cat} routine={this.state.workouts_cat[0].routine}/>
                          <Button className="mr-1" tag={Link} to={"/exercises/detail/" + row.exercise_xref.id} color="primary"> <FontAwesomeIcon icon={faEye} /> </Button>
                          <DeleteFullSet fullID = {row.id} workoutID={this.props.match.params.workoutID} fetchWorkouts={this.fetchWorkouts} workout_day_id = {row.workout_day_xref}/>
                          </div>;
                      }
                    },
                  ]
                }
                  exportCSV
                >
                  {(props) => (
                    <div>
                      <CardHeader>
                        <div className="float-right pull-right">
                          <MyExportCSV {...props.csvProps} workout_id = {y.id} fetchWorkouts={this.fetchWorkouts} workouts_detail = {this.state.workouts_cat}  api_user = {this.state.workouts_cat[0].user_id_xref} this_user ={this.props.user_id}/>
                        </div>
                        <CardTitle tag="h5">{t === 0 ? "The Program" : ""}</CardTitle>

                        <h6 className="card-subtitle text-muted">
                          {y.derived__day_title}
                        </h6>


                      </CardHeader>
                      <CardBody>
                        <BootstrapTable
                          {...props.baseProps}
                          keyField = "order"
                          bootstrap4
                          bordered={false}
                          hover={true}
                          rowEvents={ {
                            style: { cursor: this.state.workouts_cat[0].user_id_xref !== this.props.user_id ? 'pointer' : null},
                            onClick: (e, row) => { if (this.state.workouts_cat[0].user_id_xref !== this.props.user_id) { this.setState({referrer: `/exercises/detail/${row.exercise_xref.id}`}) } }
                          }}

                        />
                      </CardBody>
                    </div>
                  )}
                </ToolkitProvider>
              </Card>
            )}



          </Col>

          <Col xs="12" className="col-xxl-4">
            <Card>
              <CardHeader>
                <CardTitle tag="h5" className="mb-0">
                  Summary
                </CardTitle>
              </CardHeader>

              <CardBody>


                  <CardImg top src={x.cover_image ? x.cover_image : x.default_cover_image? x.default_cover_image : this.state.workouts_default_image} alt="Workout Image" />



                <Table size="sm" className="my-2">
                  <tbody>
                  <tr>
                    <th>Level</th>
                    <td>{x.training_level_xref}</td>
                  </tr>
                  <tr>
                    <th>Days per week</th>
                    <td>{x.days_per_week} days </td>
                  </tr>
                  <tr>
                    <th>Duration</th>
                    <td>{x.duration_weeks} Weeks</td>
                  </tr>
                  {x.author === 'None' ? null :
                    <tr>
                      <th>Author</th>
                      {x.source ?
                      <td><a href={x.source} target="blank">{x.author}</a></td>
                      :
                      <td>{x.author}</td>
                    }
                    </tr>
                  }
                  <tr>
                    <th>One Rep Max?</th>
                    <td>
                    {
                      x.training_max_exercise?  "Yes" : "No"
                    }
                    </td>
                  </tr>
                  <tr>
                    <th>Status</th>
                    <td>
                    {
                      x.enroll_history.length === 0 ?
                      <span className="badge badge-info">Never Lifted</span>
                      :
                      <span>
                        {x.enroll_history.map((u,v) =>
                          <span key={v}>

                          {u.derived__status_memo === "In Progress" ?
                          <div>
                          <span className="badge badge-primary">{u.derived__status_memo}</span>
                          </div>
                          :
                          u.derived__status_memo === "Paused" ?
                          <div>
                          <span className="badge badge-dark">{u.derived__status_memo}</span>
                          </div>
                          :
                          u.derived__status_memo === "Completed" ?
                          <div>
                          <span className="badge badge-success">{u.derived__status_memo}</span>
                          </div>
                          :
                          null
                        }


                        </span>

                        )}</span>
                    }
                    </td>
                  </tr>
                  </tbody>
                </Table>

              </CardBody>

            </Card>
                  {this.renderWorkoutCompleteCard(x)}
                  {this.renderButtons(x,k,'side')}
          </Col>
        </Row>
      )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  user_id: state.auth.user.pk,
  general: state.general.isLoading,
});

export default connect(mapStateToProps, { enrollUser, completeEnroll, resumeEnroll })(WorkoutsDetail);
