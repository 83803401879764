import React from "react";

import {
  Card,
  CardHeader,
  CardTitle,
  ListGroupItem,
  ListGroup

} from "reactstrap";



const pages = [
  {
    name: "workouts",
    value: "Workouts"
  },
  {
    name: "general",
    value: "General"
  },
  {
    name: "password",
    value: "Password"
  },
  {
    name: "privacy",
    value: "Privacy and safety"
  },
  {
    name: "subscriptions",
    value: "Subscriptions and Payments"
  },
  {
    name: "delete",
    value: "Deactivate or Delete"
  }
];

const Navigation = (props) => (
  <Card>
    <CardHeader>
      <CardTitle tag="h5" className="mb-0">
        Help Sections
      </CardTitle>
    </CardHeader>
    <ListGroup flush>


    {pages.map((x,y) =>
    <div>
      {props.activeName === x.name ?
        <ListGroupItem  action active onClick = {() => props.sectionCallback(x.name)}>
          {x.value}
        </ListGroupItem>
        :
        <ListGroupItem action onClick = {() => props.sectionCallback(x.name)}>
          {x.value}
        </ListGroupItem>
      }
      </div>
      )}

    </ListGroup>
  </Card>
);

export default Navigation;
