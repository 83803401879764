import store from "../redux/store/index";


export const keyConfig = () => {
  // Get key from state
  const key = store.getState().auth.key;

  // Headers
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // If key, add to headers config
  if (key) {
    config.headers['Authorization'] = `Token ${key}`;
  }

  return config;
};



export const keyConfigPro = (key) => {
  // Get key from state


  // Headers
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // If key, add to headers config
  if (key) {
    config.headers['Authorization'] = `Token ${key}`;
  }

  return config;
};
