import React from "react";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import "react-dates/initialize";
import { connect } from 'react-redux';
import { isInclusivelyBeforeDay } from 'react-dates';

class Default extends React.Component {

  state = {
    startDate: this.props.start,
    endDate: this.props.end,
    focusedInput: null
  };

  handleDateChange = ({ startDate, endDate }) =>
    this.setState({ startDate, endDate });

  handleFocusChange = focusedInput => this.setState({ focusedInput });

  componentDidUpdate () {
    if (this.props.start !== this.state.startDate) {
      this.setState({
        startDate: this.props.start,
        endDate: this.props.end
      })
    }
  }

  render() {

    return (
      <DateRangePicker
        endDate={this.state.endDate}
        endDateId="endDate"
        focusedInput={this.state.focusedInput}
        //isOutsideRange={() => null}
        onDatesChange={this.handleDateChange}
        onFocusChange={this.handleFocusChange}
        startDate={this.state.startDate}
        startDateId="startDate"
        onClose={({ startDate, endDate }) => {this.props.datechange(startDate,endDate)}}
        isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
      />
  );
 }
}

const mapStateToProps = (state) => ({
  user_status: state.auth.user,
  theme: state.theme.currentTheme
});

export default connect(mapStateToProps, {  })(Default);
