import React from "react";
import {
  Container,
  Row,
  Col,
} from "reactstrap";
import { connect } from 'react-redux';

import Header from './Header'

import Navigation from '../Navigation';
import Imperial from './Imperial'

class Default extends React.Component {

  render() {
    return(
      <Container fluid>
      <Header />

        <Row>
          <Col md="3" xl="2">
            <Navigation activeName = {"general"}/>
          </Col>
          <Col md="9" xl="10">
            <Imperial />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, {  })(Default);
