import React from "react";
import { Redirect } from 'react-router-dom';
import {  withRouter} from 'react-router-dom';
import Select from 'react-select'
import { connect } from "react-redux";
import store from "../../redux/store/index";
import ApiLiftTracker from '../../config/lift_tracking';
import {
  API_WORKOUTS_VIEW_FULL_DETAIL
  } from '../../redux/actions/API';
import axios from "axios";
import { keyConfig } from '../../redux/actions/auth';
import {
  Button,
  Row,
  Col,
  Spinner,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput,
} from 'reactstrap';

class UpdateExerciseSet extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      originalRowCount: null,
      full_quick:[],
      full_quick_loading: true,
      rowArray: [],
      rowCount: 0,
      selectedOption: null,

      loading: false,
      id: null,

      weight_level_0: 60,
      min_reps_0: 8,
      max_reps_0: 12,
      AMRAC_0: false,

      api_exercise_list: [],
      api_exercise_list_loading: true,

      full_order: null
    };
  }

  onSubmitPatch = async(i) => {
    await ApiLiftTracker.workoutFullSetPatch(
    this.state["set_id_"+i],
    this.props.fullID,
    i+1, //set_xref
    this.state["min_reps_"+i]? this.state["min_reps_"+i] : 8,
    this.state["AMRAC_"+i]? 999: this.state["max_reps_"+i] < this.state["min_reps_"+i]? null: !this.state["AMRAC_"+i] && this.state["max_reps_"+i] === 999? null: this.state["max_reps_"+i] === ""? null:this.state["max_reps_"+i],
    !this.props.workouts_detail[0].training_max_exercise? null: this.state["weight_level_"+i]? this.state["weight_level_"+i] : 50,
    i+1,  //order
    (success, data) => {
    if ( success) {
      console.log('success')
    } else {
      console.log('fail')
    }
  });
  }

  onSubmitCreate = async(i) => {
    await ApiLiftTracker.workoutFullSetCreate(
    this.props.fullID,
    i+1, //set_xref
    this.state["min_reps_"+i]? this.state["min_reps_"+i] : 8,
    this.state["AMRAC_"+i]? 999: this.state["max_reps_"+i] < this.state["min_reps_"+i]? null: !this.state["AMRAC_"+i] && this.state["max_reps_"+i] === 999? null: this.state["max_reps_"+i] === ""? null:this.state["max_reps_"+i],
    !this.props.workouts_detail[0].training_max_exercise? null: this.state["weight_level_"+i]? this.state["weight_level_"+i] : 50,
    i+1,  //order
    (success, data) => {
    if ( success) {
      console.log('success')
    } else {
      console.log('fail')
    }
  });
  }

  onSubmit = async (dataFull) => {
    var i
    for (i = 0; i < this.state.rowCount+1; i++) {
      if(i < this.state.originalRowCount+1){
        this.onSubmitPatch(i)
      }
      else if(i >= this.state.originalRowCount+1){
        this.onSubmitCreate(i)
      }
      if (i === this.state.rowCount){
        this.props.fetchWorkouts(this.props.match.params.workoutID)
        this.props.toggle()
    }
    }
  }

  assignValues = async (sets) => {
    console.log('**')
    console.log(sets)
    await this.setState({rowCount:sets.length-1, originalRowCount:sets.length-1})
    await Object.keys(sets).map(a => {
        return this.setState({
          ['set_id_'+a] : sets[a].id,
          ['weight_level_'+a] : sets[a].weight_level,
          ['min_reps_'+a] : sets[a].rep_count,
          ['max_reps_'+a] : sets[a].rep_count_max,
          ['AMRAC_'+a] : sets[a].rep_count_max ===999? true: false,
          rowArray: this.state.rowArray.concat(Number(a))
        })
      })

  }

  fetchData = async () =>  {
    await axios.get(`${API_WORKOUTS_VIEW_FULL_DETAIL}${this.props.fullID}`, keyConfig(store.getState)).then(res => {
      this.assignValues(res.data.sets)
      this.setState({
          full_order: res.data.length + 1,
          full_quick: res.data,
          full_quick_loading: false
      });
    }).catch((err) => {
        console.log(err)
      });
  }

  componentDidMount() {
    this.fetchData()
  }

  onChange = (e) => {
    if(e.target.name === "max_reps"){
      this.setState({max_reps: e.target.value})
    }
    else if(e.target.name === "min_reps"){
      this.setState({min_reps: e.target.value})
    }
    else if(e.target.name === "ABC"){
      if(this.state.amayc) {
        this.setState({amayc: false})
      }
      else if(!this.state.amayc) {
        this.setState({amayc: true})
      }
    }
  }

  handleExerciseChange = (selectedOption: any,) => {
    this.setState({ selectedOption });
    console.log(selectedOption)
};

  addSet = async () => {
    await this.setState({rowCount: this.state.rowCount+1})
    await this.setState({
      ['min_reps_'+this.state.rowCount] : 8,
      ['max_reps_'+this.state.rowCount] : 12,
      ['AMRAC_'+this.state.rowCount] : false
    })
    await this.setState({ rowArray:  this.state.rowArray.concat([this.state.rowCount]) })
    await console.log(this.state.rowArray)
  }

  onChangeCheck = (b,name) => {
    console.log(b)
    console.log(name)
    console.log(this.state["AMRAC_"+b])
      if(this.state["AMRAC_"+b]) {
        this.setState({["AMRAC_"+b]: false})
      }
      else if(!this.state["AMRAC_"+b]) {
        this.setState({["AMRAC_"+b]: true})
      }
    }

  renderSet = (a,b,c)  => {
    return(
      <Col md={c}>
        {b ===0?<Label>Set #</Label> : null}
        <p> {b+1} </p>
      </Col>
    )
  }


  renderMinReps = (a,b,c)  => {
    return(
      <Col md={c}>
        {b ===0?<Label>Minium Reps</Label>:null}

        <Input
          type="number"
          name={"min_reps_"+b}
          id={"min_reps_"+b}
          min="3"
          max="20"
          required
          value={this.state['min_reps_'+b]}
          onChange={(e) => this.setState({['min_reps_'+b] : e.target.value <0? 2 : e.target.value > 100? 100 : e.target.value})}
          className={this.state["min_reps_"+b] !== null && this.state["min_reps_"+b] !== 0? "border-success mb-2 " : "mb-2" }
          />
      </Col>
    )
  }



  renderMaxReps = (a,b,c)  => {
    return(

            <Col md={c}>
              {b ===0?<Label>Maximum Reps (Optional)</Label>:null}

              <Input
                type="number"
                name={"max_reps_"+b}
                id={"max_reps_"+b}
                min="8"
                max="30"
                required
                disabled={this.state["AMRAC_"+b]? true:false}
                value={this.state["AMRAC_"+b]? 999: !this.state["AMRAC_"+b] && this.state['max_reps_'+b] ===999? 12: this.state['max_reps_'+b] === null? 12: this.state['max_reps_'+b]}
                onChange={(e) => this.setState({['max_reps_'+b] : e.target.value < 0? 2: e.target.value>100? 100 : e.target.value})}
                className="mb-2"
                />
            </Col>
    )
  }


  renderAMAC = (a,b,c)  => {
    return(

                <Col md={c}>
            {a ===0?<Label>As Many Reps As You Can?</Label>:null}


            <FormGroup>
              <CustomInput
                checked={this.state["AMRAC_"+b]}
                type="checkbox"
                name={"AMRAC_"+b}
                id={"AMRAC_"+b}
                label=""
                onChange={e => this.onChangeCheck(b,e.target.name)}
                />
            </FormGroup>


              </Col>
    )
  }


  renderWeightLevel = (a,b,c)  => {
    return(
      <Col md={c}>
        {a ===0?<Label>Weight Level (%)</Label>:null}

        <Input
          type="number"
          name={"weight_level_"+b}
          id={"weight_level_"+b}
          min="10"
          max="90"
          required
          value={this.state['weight_level_'+b]}
          onChange={(e) => this.setState({['weight_level_'+b] : e.target.value < 0 || e.target.value ===null ? 10 : e.target.value > 100? 100 : e.target.value})}
          className={this.state["weight_level_"+b] !== null && this.state["weight_level_"+b] !== 0? "border-success mb-2 " : "mb-2" }
          />
      </Col>
    )
  }


  renderFormBody = (a,b) => {
    return(
      <FormGroup key={b}>

      {this.props.workouts_detail[0].training_max_exercise?

      <Row>
        {this.renderSet(a,b,1)}
        {this.renderWeightLevel(a,b,2)}
        {this.renderMinReps(a,b,3)}
        {this.renderMaxReps(a,b,3)}
        {this.renderAMAC(a,b,3)}
        </Row>
        :

        <Row>
          {this.renderSet(a,b,1)}
          {this.renderMinReps(a,b,4)}
          {this.renderMaxReps(a,b,4)}
          {this.renderAMAC(a,b,3)}
          </Row>

        }
      </FormGroup>
    )
  }

  render() {
    if (
      this.state.full_quick_loading
    ) {
      return(
        <div className="flex d-flex justify-content-center align-items-center">
        <Spinner color="primary" />
        </div>
      )
    }
    else if (this.props.match.params.exerciseID && this.state.id !== null){
      return(
      <Redirect to={{pathname: "/exercises"}} />
      )
    }
    else if (this.state.id !== null){
      return(
      <Redirect to={{pathname: "/exercises/detail/" + this.state.id }} />
      )
    }
    return (
      <div>


      <ModalBody>


          <Form>

            <FormGroup>
              <Label>Exercise Name</Label>

              <Select


                defaultValue={{label:this.state.full_quick.exercise_name, value:0}}
                classNamePrefix="react-select"
                onChange={this.handleExerciseChange}
                placeholder= "Select An Exercise"
                openMenuOnClick={false}
                onInputChange={this.handleLocationInputChange}
                isDisabled={true}
                />


            </FormGroup>

            {this.state.rowArray.map((a,b) =>
              this.renderFormBody(a,b)
            )}


          </Form>


      </ModalBody>



      <ModalFooter className={this.state.rowCount >= 8 ? "modal-footer" : "modal-footer justify-content-between"}>

      {this.state.rowCount >= 8 ? null :
        <Button color="secondary" onClick={this.addSet} className="float-left" >Add Set</Button>}
        <div>
        <Button color="secondary" onClick={this.props.toggle}>Cancel</Button>{' '}





                  {
                    this.state.loading?
                    <Button style={{width:'110px'}} className="disabled" color="primary" > <Spinner color="light" size="sm" /> </Button>
                    :
                    (this.state.min_reps_0 !== null && this.state.min_reps_0 > 0) ?
                    <Button style={{width:'110px'}} color="primary" onClick = {this.onSubmit}> Update </Button>
                    :
                    <Button style={{width:'110px'}} className="disabled" color="primary" > Create </Button>
                  }


        </div>
      </ModalFooter>

      </div>

    );
  }
}

const mapStateToProps = (state) => ({
  user_status: state.auth.user,
  auth: state.auth,
  sidebar: store.sidebar,
});

export default connect(mapStateToProps, {  })(withRouter(UpdateExerciseSet));
