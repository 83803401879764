import React from "react";
import { connect } from 'react-redux';
import Api from '../../config/auth';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner
} from "reactstrap";




class ForgotPassword extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isSuccess: false,
      isError: false,
    };
  }


  onSubmit = async (e) => {
    e.preventDefault();
    await this.setState({isLoading: true})
    await Api.pwdreset(this.state.email, (success, data) => {
      if ( success) {
        console.log('success')
         this.setState({isSuccess: true, isLoading:false})
      } else {
        this.setState({isError: true, isLoading: false})
      }
    });
  };

  onChange = (e) => {
   this.setState({ [e.target.name]: e.target.value });
 }


  render() {
    return(

      <div className="w-100 py-5 px-md-5 px-xl-6 position-relative" >
        <div className="text-center mt-4">
          <h1 className="h2">Forgot password</h1>
          <p className="lead">Enter your email to reset your password.</p>
        </div>

        <Card>
          <CardBody>
            <div className="m-sm-4 ">
              <Form>
                <FormGroup>
                  <Label>Email</Label>
                  <Input
                    bsSize="lg"
                    type="email"
                    name="email"
                    onChange={this.onChange}
                    placeholder="Enter your email"
                  />
                </FormGroup>
                <div className="text-center mt-3 flex d-flex align-items-center justify-content-center">

                {this.state.isSuccess?


                  <Button color="success" className="mr-1 mb-1" style={{width:'175px'}}>
                    <FontAwesomeIcon icon={faCheck} /> Email Sent
                  </Button>
                    :

                    this.state.isError?

                    <Button color="danger" size="lg" onClick={this.onSubmit} style={{width:'175px'}}>
                      Something went wrong
                    </Button>
                    :

                    <Button color="primary" size="lg" onClick={this.onSubmit} style={{width:'175px'}}>
                      {this.state.isLoading? <Spinner color="secondary" /> : "Reset password" }
                    </Button>


                  }

                </div>


              </Form>
            </div>
          </CardBody>
            {this.state.isSuccess?
                <span className="text-success" style={{"alignSelf":"center"}}> An email has been sent with password reset instructions </span>
                :
                null
              }
        </Card>
      </div>


    )
  }
}

const mapStateToProps = (state) => ({
  user_status: state.auth.user,
});

export default connect(mapStateToProps, {  })(ForgotPassword);
