import React from "react";
import { connect } from 'react-redux';
import Loader from "../../components/Loader";
import FollowingLazy from './FollowingLazy'
import {
  Container,
  Row,
  Col,
} from "reactstrap";
import Header from "./Header"
import Popular from "../dashboards/Default/Popular.js"

class Discover extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      api_discover_profiles : [],
      api_discover_profiles_loading: true,
      follow_change_flag: 0,
    };
  }

  handler = (val) => {
    this.setState({
      follow_change_flag: val
    })
  }

  render() {
    if (this.props.user_status == null) {
      return <Loader />
    }
    else if (this.props.user_status.hidden) {
      return(
        <h4 className="text-center mt-5">
        Your profile is set to hidden, nothing to show you here
        </h4>
      )
    }
    return (


      <Container fluid>
        <Header search_value={this.props.match.params.searchVALUE}/>
        <Row>

        <Col lg="8" xl="8">
            <FollowingLazy list = {"discover"} search_value={this.props.match.params.searchVALUE}/>
        </Col>

          <Col lg="4" xl="4">

          <Popular />


          </Col>

        </Row>
      </Container>

  );
 }
}

const mapStateToProps = (state) => ({
  user_status: state.auth.user,
});

export default connect(mapStateToProps, {  })(Discover);
