import React from "react";
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import StickyBox from "react-sticky-box";
import {
  Container,
  Row,
  Col,
  Navbar,
  NavbarBrand,
  Button,
  } from "reactstrap";
import { API_LEGAL} from '../../../redux/actions/API';
import { keyConfig } from '../../../redux/actions/auth';
import axios from "axios";
import store from "../../../redux/store/index";
import Loader from "../../../components/Loader.js"

const IntroOrig = () => {

    return (
    <section className="landing-intro pt-5 vh-50" style={{height:'125px'}}>
      <Container fluid>
        <Row>
          <Col md="8" className="mx-auto text-left mt-5">
            <h1 className="landing-intro-title my-4" style={{fontSize:'62px'}}>Terms Of Use</h1>
          </Col>
        </Row>
        <Row>
        </Row>
      </Container>
    </section>
  )};

class Legal extends React.Component {

  fetchPrivacy = async () =>  {
    await axios.get(`${API_LEGAL}`, keyConfig(store.getState)).then(res => {
      res.data.map((a,b) => {return(this[`${b}_ref`] = React.createRef())});
      this.setState({
        api_legal: res.data,
        api_legal_loading: false
      });
    });


  }

  constructor(props) {
      super(props)
      this.myRef = React.createRef()
      this.state = {
        count: 0,
        api_legal: [],
        api_legal_loading: true
      }
  }

  componentDidMount() {
    this.fetchPrivacy();
  }

  scrollToMyRef = (b) => window.scrollTo({ behavior: 'smooth', top: this[`${b}_ref`].current.offsetTop })

  renderNav = () => {
    return(
      <Navbar   expand="sm" className="absolute-top w-100 m-0 p-3" >
        <Container >
          <NavbarBrand className="font-weight-bold text-white" href="/" style={{'fontSize':'28px'}}>
            ironroom.
          </NavbarBrand>
        </Container>
      </Navbar>
    )
  }

  renderSideBar = () => {
    return(
      <Col md="3" className="mx-auto text-left bh-50 bg-white">
        <StickyBox>
          <Container className="text-center d-flex pt-5 flex-column justify-content-start align-items-start">

          {this.state.api_legal.map((a,b) =>
            <Button
              onClick={() => window.scrollTo({ behavior: 'smooth', top: this[`${b}_ref`].current.offsetTop })}
              key={b}
              color="link"
              size="lg"
              target="_blank"
              className="btn-pill text-dark"
            >
               {
                 a.section.length > 19?

                 `${a.section.substring(0, 20)}...`

                 :

                 a.section

               }
            </Button>
            )}

          </Container>
        </StickyBox>
      </Col>
    )
  }

  renderRight = () => {
    return(
      <Col md="12" className="mx-auto text-left pt-5 bg-white pr-5">

      {this.state.api_legal.map((a,b) =>

            <div ref={this[`${b}_ref`]} key={b}>
              <h1> {b+1 + ' ' + a.section} </h1>
              <div dangerouslySetInnerHTML={ { __html: a.description } }></div>
            </div>

          )}

      </Col>
    )
  }

  renderBody = () => {
    return(
      <Container className="bg-dark">
        <Row >
          {this.renderRight()}
        </Row>
      </Container>
    )
  }

  render() {
    if (this.props.isAuthenticated) {
      return <Redirect to="/dashboard" />;
    }
    else if (this.state.api_legal_loading){
      return (<Loader />)
    }
    return (
      <div>
        {this.renderNav()}
        <IntroOrig />
        {this.renderBody()}
      </div>

    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isLoading: state.auth.isLoading,
});

export default connect(mapStateToProps)(Legal);
