import React from "react";
import {
  Container,
  Row,
  Col,
} from "reactstrap";
import { connect } from 'react-redux';


import Navigation from '../Navigation';
import Header from './Header'
import Public from './Public'
import Private from './Private'

class ProfileSettings extends React.Component {

  render() {
    return(
      <Container fluid>
        <Header />

        <Row>
          <Col md="3" xl="2">
            <Navigation activeName = {"profile"}/>
          </Col>
          <Col md="9" xl="10">
            <Public />
            <Private />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, {  })(ProfileSettings);
