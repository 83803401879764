  import React from "react";
import Chart from "react-apexcharts";
import { connect } from "react-redux";

import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";





class Default extends React.Component {



  constructor(props) {
    super(props);
    this.state = {
      data: [
        {
          name: "Mon",
          data: Array(this.props.heat_map.length).fill(0).map((a,b) => this.props.heat_map.[b].mon)
        },
        {
          name: "Tues",
          data: Array(this.props.heat_map.length).fill(0).map((a,b) => this.props.heat_map.[b].tues)
        },
        {
          name: "Wed",
          data: Array(this.props.heat_map.length).fill(0).map((a,b) => this.props.heat_map.[b].wed)
        },
        {
          name: "Thur",
          data: Array(this.props.heat_map.length).fill(0).map((a,b) => this.props.heat_map.[b].thur)
        },
        {
          name: "Fri",
          data: Array(this.props.heat_map.length).fill(0).map((a,b) => this.props.heat_map.[b].fri)
        },
        {
          name: "Sat",
          data: Array(this.props.heat_map.length).fill(0).map((a,b) => this.props.heat_map.[b].sat)
        },
        {
          name: "Sun",
          data: Array(this.props.heat_map.length).fill(0).map((a,b) => this.props.heat_map.[b].sun)
        }
      ],
      options: {
        plotOptions: {
  heatmap: {
    radius: 10
  }
},
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "smooth"
        },
        xaxis: {
          type: 'category',
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        },
        tooltip: {
          x: {
            format: "dd/MM/yy HH:mm"
          }
        },
        colors: [
          props.theme.primary,
        ]
      },

    }
  }


  render() {
    return (
      <Card className="flex-fill w-100">
        <CardHeader>
          <CardTitle tag="h5">Heat Map by Volume ( {this.props.measure_type}  )</CardTitle>
          <h6 className="card-subtitle text-muted">
            Daily volumed moved
          </h6>
        </CardHeader>
        <CardBody>
          <div className="chart w-100">
            <Chart options={this.state.options} series={this.state.data.reverse()} type="heatmap" height="350" />
          </div>
        </CardBody>
      </Card>
    );
 }
}

const mapStateToProps = (state) => ({
  user_status: state.auth.user,
  theme: state.theme.currentTheme
});

export default connect(mapStateToProps, {  })(Default);
