import React, { useState } from "react";
import {  withRouter} from 'react-router-dom';
import { connect } from "react-redux";
import store from "../../redux/store/index";
import { Button, Modal, ModalHeader,} from 'reactstrap';
import PatchQuickModalBody from './PatchQuickModalBody'

const ModalExercise = (props) => {
  const {
    className
  } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <>

      <Button color="info" className="shadow-sm mr-1 ml-1" onClick={toggle}>
         Options
      </Button>



      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>Update Workout Day</ModalHeader>

        <PatchQuickModalBody toggle={toggle} workoutID = {props.workoutID} fetchDetail = {props.fetchDetail} routine = {props.routine}/>


      </Modal>
    </>
  );
}

class PatchWorkout extends React.Component {

  componentDidMount() {

  }

  render() {
    return (
      <ModalExercise workoutID = {this.props.workout_id} fetchDetail={this.props.fetchWorkouts} routine = {this.props.routine}/>
    );
  }
}

const mapStateToProps = (state) => ({
  user_status: state.auth.user,
  auth: state.auth,
  sidebar: store.sidebar,
});

export default connect(mapStateToProps, {  })(withRouter(PatchWorkout));
